import { DarkWrapperAuth } from "../../components/DarkWrapperAuth";
import Login from "../../components/Login";

const SignIn = () => {
  return (
    <DarkWrapperAuth pageName="login">
      <Login />
    </DarkWrapperAuth>
  );
};

export default SignIn;
