import { PlaidAccount } from "react-plaid-link";

export interface CREATE_TOKEN_DATA {
  expiration: string;
  link_token: string;
  request_id: string;
  error: string;
}

export interface GENERATE_TOKEN_OUTPUT {
  expiration: string | null;
  linkToken: string | null;
  error?: ERROR;
}

export interface ERROR {
  code: string;
  message: string;
}

export enum Products {
  Assets = "assets",
  Auth = "auth",
  Balance = "balance",
  Identity = "identity",
  Investments = "investments",
  Liabilities = "liabilities",
  PaymentInitiation = "payment_initiation",
  IdentityVerification = "identity_verification",
  Transactions = "transactions",
  CreditDetails = "credit_details",
  Income = "income",
  IncomeVerification = "income_verification",
  DepositSwitch = "deposit_switch",
  StandingOrders = "standing_orders",
  Transfer = "transfer",
  Employment = "employment",
  RecurringTransactions = "recurring_transactions",
  PendingManualAuth = "pending_manual_auth",
  PendingLinkableAuth = "pending_linkable_auth",
}

export interface CustomPlaidBankAccount
  extends Omit<PlaidAccount, "verification_status"> {
  verification_status?: string;
}

export enum PlaidEvents {
  BANK_INCOME_INSIGHTS_COMPLETED = "BANK_INCOME_INSIGHTS_COMPLETED",
  // The user has completed the Assets and Bank Income Insights flow.
  CLOSE_OAUTH = "CLOSE_OAUTH",
  // The user closed the third-party website or mobile app without completing the OAuth flow.
  ERROR = "ERROR",
  // A recoverable error occurred in the Link flow, see the error_code metadata.
  EXIT = "EXIT",
  // The user has exited without completing the Link flow and the onExit callback is fired.
  FAIL_OAUTH = "FAIL_OAUTH",
  // The user encountered an error while completing the third-party's OAuth login flow.
  HANDOFF = "HANDOFF",
  // The user has exited Link after successfully linking an Item.
  IDENTITY_VERIFICATION_START_STEP = "IDENTITY_VERIFICATION_START_STEP",
  // The user has started a step of the Identity Verification flow. The step is indicated by view_name.
  IDENTITY_VERIFICATION_PASS_STEP = "IDENTITY_VERIFICATION_PASS_STEP",
  // The user has passed a step of the Identity Verification flow. The step is indicated by view_name.
  IDENTITY_VERIFICATION_FAIL_STEP = "IDENTITY_VERIFICATION_FAIL_STEP",
  // The user has failed a step of the Identity Verification flow. The step is indicated by view_name.
  IDENTITY_VERIFICATION_PENDING_REVIEW_STEP = "IDENTITY_VERIFICATION_PENDING_REVIEW_STEP",
  // The user has reached the pending review state.
  IDENTITY_VERIFICATION_CREATE_SESSION = "IDENTITY_VERIFICATION_CREATE_SESSION",
  // The user has started a new Identity Verification session.
  IDENTITY_VERIFICATION_RESUME_SESSION = "IDENTITY_VERIFICATION_RESUME_SESSION",
  // The user has resumed an existing Identity Verification session.
  IDENTITY_VERIFICATION_PASS_SESSION = "IDENTITY_VERIFICATION_PASS_SESSION",
  // The user has successfully completed their Identity Verification session.
  IDENTITY_VERIFICATION_FAIL_SESSION = "IDENTITY_VERIFICATION_FAIL_SESSION",
  // The user has failed their Identity Verification session.
  IDENTITY_VERIFICATION_OPEN_UI = "IDENTITY_VERIFICATION_OPEN_UI",
  // The user has opened the UI of their Identity Verification session.
  IDENTITY_VERIFICATION_RESUME_UI = "IDENTITY_VERIFICATION_RESUME_UI",
  // The user has resumed the UI of their Identity Verification session.
  IDENTITY_VERIFICATION_CLOSE_UI = "IDENTITY_VERIFICATION_CLOSE_UI",
  // The user has closed the UI of their Identity Verification session.
  MATCHED_SELECT_INSTITUTION = "MATCHED_SELECT_INSTITUTION",
  // The user selected an institution that was presented as a matched institution. This event can be emitted either during the Returning User Experience flow or if the institution's routing_number was provided when calling /link/token/create. To distinguish between the two scenarios, see metadata.match_reason.
  MATCHED_SELECT_VERIFY_METHOD = "MATCHED_SELECT_VERIFY_METHOD",
  // The user selected a verification method for a matched institution. This event is emitted during the Returning User Experience flow.
  OPEN = "OPEN",
  // The user has opened Link.
  OPEN_MY_PLAID = "OPEN_MY_PLAID",
  // The user has opened my.plaid.com. This event is only sent when Link is initialized with Assets as a product
  OPEN_OAUTH = "OPEN_OAUTH",
  // The user has navigated to a third-party website or mobile app in order to complete the OAuth login flow.
  SEARCH_INSTITUTION = "SEARCH_INSTITUTION",
  // The user has searched for an institution.
  SELECT_AUTH_TYPE = "SELECT_AUTH_TYPE",
  // The user has chosen whether to Link instantly or manually (i.e., with micro-deposits). This event emits the selection metadata to indicate the user's selection.
  SELECT_BRAND = "SELECT_BRAND",
  // The user selected a brand, e.g. Bank of America. The SELECT_BRAND event is only emitted for large financial institutions with multiple online banking portals.
  SELECT_DEGRADED_INSTITUTION = "SELECT_DEGRADED_INSTITUTION",
  // The user selected an institution with a DEGRADED health status and were shown a corresponding message.
  SELECT_DOWN_INSTITUTION = "SELECT_DOWN_INSTITUTION",
  // The user selected an institution with a DOWN health status and were shown a corresponding message.
  SELECT_INSTITUTION = "SELECT_INSTITUTION",
  // The user selected an institution.
  SUBMIT_ACCOUNT_NUMBER = "SUBMIT_ACCOUNT_NUMBER",
  // The user has submitted an account number. This event emits the account_number_mask metadata to indicate the mask of the account number the user provided.
  SUBMIT_CREDENTIALS = "SUBMIT_CREDENTIALS",
  // The user has submitted credentials.
  SUBMIT_DOCUMENTS = "SUBMIT_DOCUMENTS",
  // The user is being prompted to submit documents for an Income verification flow.
  SUBMIT_DOCUMENTS_ERROR = "SUBMIT_DOCUMENTS_ERROR",
  // The user encountered an error when submitting documents for an Income verification flow.
  SUBMIT_DOCUMENTS_SUCCESS = "SUBMIT_DOCUMENTS_SUCCESS",
  // The user has successfully submitted documents for an Income verification flow.
  SUBMIT_MFA = "SUBMIT_MFA",
  // The user has submitted MFA.
  SUBMIT_ROUTING_NUMBER = "SUBMIT_ROUTING_NUMBER",
  // The user has submitted routing number. This event emits the routing_number metadata to indicate user's routing number.
  TRANSITION_VIEW = "TRANSITION_VIEW",
  // The TRANSITION_VIEW event indicates that the user has moved from one view to the next.
  VIEW_DATA_TYPES = "VIEW_DATA_TYPES",
  // The user has viewed data types on the data transparency consent pane.
  TOO_MANY_VERIFICATION_ATTEMPTS = "TOO_MANY_VERIFICATION_ATTEMPTS",
  // The user do too many verification attempts trying to verify a manually added account
}

export interface PlaidAccountDetails {
  plaidAccountId: string;
  status: BankAccountVerificationStatus;
  name: string;
  mask: string;
  institutionName?: string;
  processorToken?: string;
  counterpartyId?: string;
  modernTreasuryAccountId?: string;
}

export enum BankAccountVerificationStatus {
  Verified = "verified",
  PendingAutomaticVerification = "pending_automatic_verification",
  PendingManualVerification = "pending_manual_verification",
  AutomaticallyVerified = "automatically_verified",
  ManuallyVerified = "manually_verified",
  VerificationExpired = "verification_expired",
  VerificationFailed = "verification_failed",
  Added = "added",
  PendingLoginRequired = "pending_login_required",
  UserPermissionRevoked = "user_permission_revoked",
}

export interface VerifyLinkTokenResponse {
  link_token: string;
  expiration: string;
  requestId: string;
}

export type VerifyLinkToken = Record<string, VerifyLinkTokenResponse>;

export enum LinkDestination {
  Dashboard = "dashboard",
  UserAccount = "user-account",
}
