import HeroBottomComponent from "../components/HeroBottomComponent";
import NorhartBlackCircleIcon from "../../../assets/images/norhart-black-circle-icon.svg";

const InvestWithUsSection = () => {
  return (
    <HeroBottomComponent
      backgroundColorOne="#f0f0f0"
      backgroundColorTwo="#f0f0f0"
      Icon={NorhartBlackCircleIcon}
      iconTitle="Norhart Promissory Notes"
      title="Why Invest With Us"
      subTitle="Our promissory notes fund tangible residential real estate projects. We convert your capital into something you can see and touch. With us, your money makes better homes, lives, and dreams for people."
      imageAlt="Norhart Invest How It Works"
      subNote="Hours: M-F (8 AM - 8 PM), Saturday (10 AM - 4 PM), Sunday - (Closed)"
      textColor="#111111"
      asoEffect="fade-in"
      buttonName="Book A Call"
      buttonUrl="https://appt.norhart.com/widget/booking/BbRATKu2ujKE6T4Mp8dW"
    />
  );
};

export default InvestWithUsSection;
