import { useState, useCallback, useEffect } from "react";
import { Attention } from "../utils/attention";
import { GetAttentionDataOutput } from "../types/attention";

export const useGetAttention = () => {
  const [attentionData, setAttentionData] = useState<GetAttentionDataOutput>();
  const [loading, setLoading] = useState(true);

  const getAttentionData = useCallback(async () => {
    try {
      const response = await Attention.getData();
      setAttentionData(response);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    getAttentionData();
  }, [getAttentionData]);
  return { loading, attentionData };
};
