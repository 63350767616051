import { GetUserDocumentUrl } from "../../../../types/documents";
import { HandleClickProps } from "../../../../types/downloadQueue";
import DesktopActions from "./DesktopActions";
import MobileActions from "./MobileActions";
interface Props {
  name: string;
  formatType: string;
  s3Key: string;
  id: string;
  loading: boolean;
  updateQueue: (input: HandleClickProps<GetUserDocumentUrl>) => void;
}

const DocumentActions = ({
  name,
  formatType,
  s3Key,
  id,
  loading,
  updateQueue,
}: Props) => {
  return (
    <>
      <DesktopActions
        name={name}
        formatType={formatType}
        s3Key={s3Key}
        id={id}
        loading={loading}
        updateQueue={updateQueue}
      />
      <MobileActions
        name={name}
        formatType={formatType}
        s3Key={s3Key}
        id={id}
        loading={loading}
        updateQueue={updateQueue}
      />
    </>
  );
};

export default DocumentActions;
