import { useState, useEffect } from "react";
interface Props {
  start?: number;
  end: number;
  duration?: number;
}
export const useAnimatedCounter = ({
  start = 0,
  end,
  duration = 3000,
}: Props): number => {
  const [count, setCount] = useState(start);
  const increment: number = (end - start) / duration;
  useEffect(() => {
    let startTime: number | undefined;
    let animationFrame: number;

    const animate = (time: number) => {
      if (!startTime) {
        startTime = time;
      }
      const progress: number = (time - startTime) / duration;
      const delay = (t: number, pow: number) => 1 - Math.pow(1 - t, pow);
      const currentCount: number = start + (end - start) * delay(progress, 5);
      setCount(currentCount);
      if (progress < 1) {
        setTimeout(() => {
          animationFrame = requestAnimationFrame(animate);
        }, delay(progress, 8) * 200);
      } else {
        setCount(end);
      }
    };

    animationFrame = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationFrame);
  }, [start, end, duration, increment]);

  return Math.round(count);
};
