import { useRef } from "react";

export const useSubmitWithEnter = () => {
  const ref = useRef<null | HTMLFormElement>(null);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      ref.current?.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };
  return { ref, handleKeyUp };
};
