import { CustomAccordion } from "../../../styles/accordion";
import { AccordionItem } from "../../../components/landing/components/AccordionItem";
import { ReleaseVersionList } from "./ReleaseVersionList";

const ReleaseNotesAccordion = () => {
  return (
    <CustomAccordion>
      <br />
      <AccordionItem
        eventKey="0"
        header="Version 1.0"
        description={
          <ReleaseVersionList
            title="VERSION 1.0"
            date="Jan, 20th 2024"
            description="Welcome to our initial Version 1.0. We are excited about this visual and feature-rich release."
            updates={[
              "Updated Brand Look and Feel",
              "Updated User Interface",
              "User Experience Enhancements",
              "New Dark /Light Mode",
              "Updated Withdrawal System",
              "New “Cash” / “Buying Power” Feature",
              "New Data-Driven Dashboard",
              "New Release Notes System",
              "Updated Privacy Policy",
              "Bug Fixes and Performance Enhancements",
              "General System Improvements and Optimizations.",
            ]}
          />
        }
      />
    </CustomAccordion>
  );
};

export default ReleaseNotesAccordion;
