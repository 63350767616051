import TransactionsList from "./TransactionsList";
import { Loading } from "./Loading";
import { LedgerTransactionOutput } from "../types";

interface Props {
  hideTitle?: boolean;
  transactions: LedgerTransactionOutput[] | undefined;
  loading?: boolean;
  handleNext: () => Promise<void>;
  handlePrevious: () => Promise<void>;
  isLastPage: boolean;
  isFirstPage: boolean;
}

const LatestTransactionsWithPagination = ({
  hideTitle,
  transactions,
  loading,
  handleNext,
  handlePrevious,
  isFirstPage,
  isLastPage,
}: Props) => {
  return (
    <>
      {loading || !transactions ? (
        <Loading />
      ) : (
        <TransactionsList
          transactions={transactions}
          pagination
          isLastPage={isLastPage}
          isFirstPage={isFirstPage}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          hideTitle={hideTitle}
        />
      )}
    </>
  );
};
export default LatestTransactionsWithPagination;
