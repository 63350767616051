import { ListGroup, Placeholder } from "react-bootstrap";
import FormWrapper from "../../../components/FormWrapper";
import { BankAccount } from "../../../types/bank-accounts";
import { FormEvent, useMemo } from "react";
import BankInformationRow from "./BankInformationRow";
import { Plaid } from "../../../utils";
interface Props {
  bankAccounts: BankAccount[];
  ready: boolean;
  open: Function;
  waitingBankAccounts: boolean;
  handleDelete: (v: string) => Promise<void>;
  isActive: boolean;
}

const MINIMUM_BANK_ACCOUNTS = 1;

const BankInformation = ({
  bankAccounts,
  open,
  ready,
  waitingBankAccounts,
  handleDelete,
  isActive,
}: Props) => {
  const canDelete = useMemo(() => {
    const activeBankAccounts = bankAccounts.filter((bankAccount) =>
      Plaid.bankAccountIsVerified(bankAccount.status)
    );
    return activeBankAccounts.length > MINIMUM_BANK_ACCOUNTS;
  }, [bankAccounts]);

  const onClick = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    open();
  };

  return (
    <FormWrapper
      title="Bank Information"
      buttonMessage={ready ? "Add" : "Loading"}
      loading={!ready}
      onSubmit={onClick}
      needAddButton
      collapseKey="accountBankAccountsCollapsed"
      isActive={isActive}
    >
      <div className="row g-3 w-100">
        <div className="col-12">
          <ListGroup>
            <>
              {bankAccounts.map((bankAccount) => {
                const {
                  id,
                  bankName,
                  last4,
                  status,
                  linkToken: token,
                } = bankAccount;
                return (
                  <BankInformationRow
                    key={id}
                    id={id}
                    bankName={bankName}
                    last4={last4}
                    status={status}
                    canDelete={canDelete}
                    handleDelete={handleDelete}
                    linkToken={token}
                  />
                );
              })}
              {waitingBankAccounts && (
                <Placeholder as="p" animation="glow">
                  <Placeholder xs={12} style={{ height: "48px" }} />
                </Placeholder>
              )}
            </>
          </ListGroup>
        </div>
      </div>
    </FormWrapper>
  );
};

export default BankInformation;
