import { FormEvent } from "react";
import { Form } from "../../../styles";
import { validateEmail, Auth } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../../hooks/useForm";
import { SEND_EMAIL_CUSTOM_ERRORS } from "./types";
import { initialData, initialErrors, initialTouch } from "./constants";
import { FormInput } from "../../../components/forms/formsComponents/FormInput";
import { AuthButton } from "../../../components/AuthButton";
import { Alert } from "react-bootstrap";
import { DarkWrapperAuth } from "../../../components/DarkWrapperAuth";

const SendEmail = () => {
  const {
    fields,
    errors,
    handleErrors,
    handleFields,
    handleTouch,
    submitLoading,
    onSubmit,
    submitWithEnter: { ref, handleKeyUp },
  } = useForm({
    initialFields: initialData,
    initialErrors: initialErrors,
    initialTouch: initialTouch,
    customErrors: SEND_EMAIL_CUSTOM_ERRORS,
  });
  const navigate = useNavigate();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    onSubmit(e, onSubmitAuth(fields.username));
  };

  const onSubmitAuth = (username: string) => async () => {
    await Auth.sendForgotPasswordEmail(username);
    navigate("/forgot-password/reset-password", {
      state: { username },
    });
  };

  return (
    <DarkWrapperAuth>
      <Form onSubmit={handleSubmit} ref={ref} onKeyUp={handleKeyUp}>
        {!!errors.form && <Alert variant="secondary">{errors.form}</Alert>}
        <div className="form-group">
          <FormInput
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            validation={validateEmail}
            message="The username must be an email"
            required
            label="Email"
            notShowLabel
            name="username"
            value={fields.username}
            placeholder="Your Email Address"
            error={errors.username}
            errorColor="white"
          />
        </div>
        <AuthButton loading={!!submitLoading} padlock={!submitLoading}>
          Send
        </AuthButton>
      </Form>
    </DarkWrapperAuth>
  );
};

export default SendEmail;
