import {
  Container,
  ContentHeroSpace,
  InvestRateNumbers,
  InvestRateSubtext,
  InvestRateText,
  InvestStatsText,
  InvestStatsTitle,
} from "../../../styles/adds";

const InvestIRARates = () => {
  return (
    <section className="container-fluid pt-5" style={{ background: "#e1e1e1" }}>
      <Container
        className="container pl-1 pr-1"
        style={{ paddingBottom: "100px" }}
      >
        <ContentHeroSpace className="hidden-xs" />
        <div
          className="container-fluid p-0 ms-3 me-3 pe-4"
          style={{ background: "#e1e1e1", color: "#111111" }}
        >
          <div className="row align-items-start justify-content-start">
            <div className="col-sm-12">
              <div
                className="mt-1"
                style={{
                  backgroundColor: "#51976f",
                  width: "100%",
                  height: "4px",
                }}
              ></div>
              <InvestStatsTitle className="mb-3 invest-stats-title">
                Invest. Enjoy. Repeat.
              </InvestStatsTitle>
              <InvestStatsText
                className="mb-0 invest-stats-text mb-2"
                style={{ width: "100%" }}
              ></InvestStatsText>
              <InvestStatsText
                className="mt-0 mb-3 invest-stats-text mb-5"
                style={{ width: "100%", fontWeight: "600" }}
              ></InvestStatsText>

              <div className="container ms-0 p-0">
                <div className="row">
                  <div
                    className="col-sm-2"
                    style={{ backgroundColor: "#000000", color: "#ffffff" }}
                  >
                    <div>
                      <InvestRateNumbers className="invest-rates-numbers">
                        7.6%
                      </InvestRateNumbers>
                      <InvestRateText className="invest-ira-rates-text">
                        6 Month
                      </InvestRateText>
                      <InvestRateSubtext className="invest-rates-subtext">
                        Annual Yield
                      </InvestRateSubtext>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div>
                      <InvestRateNumbers className="invest-rates-numbers">
                        7.9%
                      </InvestRateNumbers>
                      <InvestRateText className="invest-ira-rates-text">
                        9 Month
                      </InvestRateText>
                      <InvestRateSubtext className="invest-rates-subtext">
                        Annual Yield
                      </InvestRateSubtext>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div>
                      <InvestRateNumbers className="invest-rates-numbers">
                        8.4%
                      </InvestRateNumbers>
                      <InvestRateText className="invest-ira-rates-text">
                        12 Month
                      </InvestRateText>
                      <InvestRateSubtext className="invest-rates-subtext">
                        Annual Yield
                      </InvestRateSubtext>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div>
                      <InvestRateNumbers className="invest-rates-numbers">
                        8.45%
                      </InvestRateNumbers>
                      <InvestRateText className="invest-ira-rates-text">
                        18 Month
                      </InvestRateText>
                      <InvestRateSubtext className="invest-rates-subtext">
                        Annual Yield
                      </InvestRateSubtext>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div>
                      <InvestRateNumbers className="invest-rates-numbers">
                        8.5%
                      </InvestRateNumbers>
                      <InvestRateText className="invest-ira-rates-text">
                        24 Month
                      </InvestRateText>
                      <InvestRateSubtext className="invest-rates-subtext">
                        Annual Yield
                      </InvestRateSubtext>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default InvestIRARates;
