import { InitialData, InitialErrors, InitialTouch } from "./types";

export const initialData: InitialData = {
  password: "",
  repeatPassword: "",
};

export const initialErrors: InitialErrors = {
  password: "",
  repeatPassword: "",
  form: "",
};

export const initialTouch: InitialTouch = {
  password: false,
  repeatPassword: false,
};
