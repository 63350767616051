import CarouselBanner from "../../../pages/(landing)/components/CarouselBanner";
import MainTechHero1 from "../../../assets/images/landing/main-banner/norhart-tech-hero-1-min.png";
import MainTechHero2 from "../../../assets/images/landing/main-banner/norhart-tech-hero-2-min.png";
import MainTechHero3 from "../../../assets/images/landing/main-banner/norhart-tech-hero-3-min.png";

const MainBanner = () => {
  return (
    <CarouselBanner
      items={[
        {
          image: MainTechHero1,
        },
        {
          image: MainTechHero2,
        },
        {
          image: MainTechHero3,
        },
      ]}
    />
  );
};

export default MainBanner;
