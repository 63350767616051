import { useScrollToTop } from "../hooks/useScrollToTop";

interface Props {
  children: JSX.Element;
}

export const ScrollToTop = ({ children }: Props) => {
  useScrollToTop();
  return <>{children}</>;
};
