import { FunctionComponent } from "react";
import {
  Container,
  ContentHeroSubtitle,
  ContentHeroTitle,
  TopHr,
  SubNoteText,
  StyledIcon,
} from "../../../styles/adds";
import styled from "styled-components";

interface Props {
  backgroundColorOne: string;
  backgroundColorTwo: string;
  Icon: any;
  iconTitle: string;
  title: string;
  subTitle?: string;
  image?: string;
  imageAlt?: string;
  textColor: string;
  buttonName?: string;
  buttonUrl?: string;
  subNote?: any;
  asoEffect: string;
  galleries?: Gallery[];
}

interface Gallery {
  images: Image[];
}

interface Image {
  src: string;
  alt: string;
}

const HeroBottomComponent: FunctionComponent<Props> = ({
  backgroundColorOne,
  backgroundColorTwo,
  Icon,
  iconTitle,
  title,
  subTitle,
  image,
  imageAlt,
  textColor,
  buttonName,
  buttonUrl,
  subNote,
  asoEffect,
  galleries,
}) => {
  return (
    <section
      className="container-fluid pt-5"
      style={{
        background: `linear-gradient(${backgroundColorOne} 30%, ${backgroundColorTwo} 70%)`,
      }}
    >
      <Container className="container pl-3 pr-3 text-center">
        <div className="text-center pb3">
          <StyledIcon src={Icon} alt="norhart-logo" />
        </div>
        <ContentHeroTitle
          className="hidden-sm hidden-md hidden-lg hidden-xl text-center"
          style={{ fontSize: "15px", color: `${textColor}` }}
        >
          {iconTitle}
        </ContentHeroTitle>
        <TopHr
          color={textColor}
          as="hr"
          className="hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
            color: `${textColor}`,
          }}
        />
        <ContentHeroTitle style={{ color: `${textColor}` }}>
          {title}
        </ContentHeroTitle>
        <ContentHeroSubtitle
          className="mb-0"
          style={{ color: `${textColor}`, opacity: "0.8" }}
        >
          {subTitle}
        </ContentHeroSubtitle>

        {subNote && (
          <SubNoteText
            className="mt-3"
            style={{ color: `${textColor}`, opacity: "0.8" }}
          >
            {subNote}
          </SubNoteText>
        )}

        {buttonUrl && (
          <div className="d-flex justify-content-center mt-5 pb-5">
            <a
              className="btn btn-md btn-outline-dark animate__animated animate__animated animate__pulse animate__infinite animate__slow"
              href={buttonUrl}
              target="_blank"
              // style={{
              //   backgroundColor: "#ffffff",
              //   border: "0px",
              //   color: "#333333",
              // }}
              rel="noreferrer"
            >
              {buttonName}
            </a>
          </div>
        )}
        {image && (
          <div>
            <img
              className="img-fluid z-depth-1 rounded"
              data-aos={asoEffect}
              src={image}
              loading="lazy"
              decoding="async"
              alt={imageAlt}
            />
          </div>
        )}
        {!!galleries?.length &&
          galleries.map((gallery, index) => {
            return (
              <GalleryStyle
                className={`gallery ${
                  galleries?.length - 1 === index ? "pb-5" : ""
                } mb-12 w-100`}
                id={`gallery-${index}`}
                key={index}
              >
                {gallery.images.map(({ src, alt }, index) => {
                  return (
                    <div className="mb-3 item" key={index}>
                      <img
                        className="img-fluid z-depth-1 rounded"
                        src={src}
                        loading="lazy"
                        decoding="async"
                        alt={alt}
                        width="346px"
                        height="231px"
                      />
                    </div>
                  );
                })}
              </GalleryStyle>
            );
          })}
      </Container>
    </section>
  );
};

export default HeroBottomComponent;

const GalleryStyle = styled.div`
  column-count: 3;
`;
