import { LoginErrors, LoginForm, LoginTouch } from "./types";

export const initialData: LoginForm = {
  username: "",
  password: "",
};

export const initialErrors: LoginErrors = {
  ...initialData,
  form: "",
};

export const initialTouch: LoginTouch = {
  username: false,
  password: false,
};
