import HeroVideoComponent from "../components/HeroVideoComponent";
const videoFile = require("../../../assets/videos/norhart-msp.mp4");

const BenefitsForYouSection = () => {
  return (
    <HeroVideoComponent
      title="Tailored Benefits For You"
      subtitle="At Norhart, we are committed to solving housing affordability. We fuse extensive expertise in uncovering real estate investment prospects with the warmth of a family-run enterprise."
      buttonName="Invest Now"
      buttonUrl="/signup"
      videoID="2"
      videoFile={videoFile}
    />
  );
};

export default BenefitsForYouSection;
