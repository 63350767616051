import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useState,
  KeyboardEvent,
  FocusEvent,
  useEffect,
  MouseEvent,
  FormEvent,
  RefObject,
} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { Auth } from "../utils";
import { useSubmitWithEnter } from "../hooks/useSubmitWithEnter";
import { notifications } from "../utils/notifications";
import { ResponseStatus } from "../types/ResponseStatus";

export interface ValidationPopUpProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  username: string;
  password: string;
  needResendCode?: boolean;
  handleSubmit: (
    e: FormEvent<HTMLFormElement>,
    verificationCode: string[]
  ) => Promise<void>;
  isSubmitting: boolean | undefined;
  buttonRef: RefObject<HTMLButtonElement>;
}

interface CustomInput extends HTMLInputElement {
  maxLength: number;
}

const VerificationPopUp = ({
  show,
  setShow,
  username,
  password,
  needResendCode,
  handleSubmit,
  isSubmitting,
}: ValidationPopUpProps) => {
  const CODE_LENGTH = new Array(6).fill("");
  const [verificationCode, setVerificationCode] = useState(CODE_LENGTH);

  const { ref, handleKeyUp } = useSubmitWithEnter();

  const handleClose = () => setShow(false);

  const moveNext = (number: number) => {
    const nextSibling: HTMLInputElement | null = document.querySelector(
      `input[id="${number + 1}"]`
    );
    if (nextSibling !== null) {
      nextSibling.focus();
    }
  };
  const movePrevious = (number: number) => {
    const previousSibling: HTMLInputElement | null = document.querySelector(
      `input[id="${number - 1}"]`
    );
    if (previousSibling !== null) {
      previousSibling.focus();
    }
  };

  const handleChange = (e: ChangeEvent<CustomInput>) => {
    const { value, id } = e.target;
    const number = parseInt(id, 10);

    if (!value && number > 0) {
      movePrevious(number);
    }
    setVerificationCode((prev) => {
      const localCopy = [...prev];
      localCopy[number] = value;
      return localCopy;
    });
    if (number < CODE_LENGTH.length && value.length > 0) {
      moveNext(number);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
    const target = event.target as HTMLInputElement;
    const id = target.id;
    const number = parseInt(id, 10);

    if (event.key === "ArrowLeft") {
      movePrevious(number);
    }
    if (event.key === "ArrowRight") {
      moveNext(number);
    }
  };

  const handleSelect = (
    e: FocusEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>
  ) => {
    (e.target as HTMLInputElement).select();
  };

  const handlePaste = (e: ClipboardEvent) => {
    if (e !== null) {
      if ((e.target as HTMLInputElement).type === "text") {
        const data = e.clipboardData?.getData("Text");
        if (data && !isNaN(parseInt(data, 10))) {
          setVerificationCode(data.substring(0, 6).split(""));
          const splittedData = data.split("");
          document
            .querySelectorAll("input[name=validation-code]")
            ?.forEach((node, index) => {
              (node as HTMLInputElement).value = splittedData[index] || "0";
            });
        }
      }
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
    const target = event.target as HTMLInputElement;
    const id = target.id;
    const number = parseInt(id, 10);
    if (event.key === target.value) {
      event.preventDefault();
      moveNext(number);
    }
  };

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => document.removeEventListener("paste", handlePaste);
  }, []);

  const resendConfirmationCode = async () => {
    try {
      await Auth.resendConfirmationCode(username);
      notifications({
        status: ResponseStatus.SUCCESS,
        successMessage: `A new verification code was sent to ${username}`,
      });
    } catch (e) {
      notifications({
        status: ResponseStatus.ERROR,
        successMessage: `Oops something wrong happened trying to re send you verification code`,
      });
    }
  };

  return (
    <StyledModal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Verify your email</Modal.Title>
      </Modal.Header>
      <form
        onSubmit={(e) => handleSubmit(e, verificationCode)}
        ref={ref}
        onKeyUp={handleKeyUp}
      >
        <Modal.Body>
          We sent you an email with your verification code, please insert below
          <CenterDiv>
            {CODE_LENGTH.map((_, index) => {
              return (
                <Input
                  key={index}
                  id={`${index}`}
                  pattern="[0-9]{1}"
                  name="validation-code"
                  maxLength={1}
                  onChange={handleChange}
                  placeholder="0"
                  onFocus={handleSelect}
                  onKeyDown={handleKeyDown}
                  onKeyPress={handleKeyPress}
                />
              );
            })}
          </CenterDiv>
          <ButtonWrapper>
            <StyledButton
              type="submit"
              disabled={
                !verificationCode.every((element) => !!element === true) ||
                isSubmitting
              }
            >
              Verify
            </StyledButton>
            {needResendCode && (
              <ResendCode onClick={resendConfirmationCode}>
                Send confirmation code again
              </ResendCode>
            )}
          </ButtonWrapper>
        </Modal.Body>
      </form>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  color: black;
`;

const Input = styled.input`
  padding: 12px 14px;
  text-rendering: optimizelegibility;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  -webkit-tap-highlight-color: transparent;
  box-shadow: rgb(0 0 0 / 3%) 0px 2px 1px;
  border-style: solid;
  background-color: transparent;
  border-width: 1px;
  border-color: rgb(224, 224, 224);
  border-radius: 6px;
  text-align: center;
  color: rgb(17, 17, 17);
  display: inline-block;
  height: 48px;
  width: 53px;
  font-size: 16px;
  font-weight: 600;
`;

const CenterDiv = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;

const StyledButton = styled(Button)`
  width: 50%;
  background-color: ${({ theme }) => theme.GREEN} !important;
  border-color: ${({ theme }) => theme.GREEN} !important;
`;

const ButtonWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

const ResendCode = styled.div`
  cursor: pointer;
  color: gray;
  font-size: 14px;
  text-decoration: underline;
`;

export default VerificationPopUp;
