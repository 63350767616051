import HeroVideoComponent from "../../../components/landing/components/HeroVideoComponent";
const videoFile = require("../../../assets/videos/norhart-invest-ira-hero.mp4");

const GolfBanner = () => {
  return (
    <HeroVideoComponent
      title="Invest your IRA, buy your time back."
      subtitle="Invest for the future, where every moment is yours to cherish. Enjoy up to 10% returns on your IRA, turning savings into the currency of time. With Norhart Invest, tomorrow’s freedom starts today."
      buttonName="Invest Now"
      buttonUrl="https://invest.norhart.com/signup"
      videoID="2"
      videoFile={videoFile}
    />
  );
};

export default GolfBanner;
