import { useEffect, useMemo, useState } from "react";
import { handleList } from "../utils";
import { useSearchParams } from "react-router-dom";
import { QueryParams } from "../types";

interface Props<T> {
  list: T[] | undefined;
  limit: number;
  paginationId?: string;
}

export const usePagination = <T>({
  list,
  limit,
  paginationId = "",
}: Props<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageId = QueryParams.Page + paginationId;

  const skipNumber = useMemo(
    () => Number(searchParams.get(pageId)) || 0,
    [searchParams, pageId]
  );
  const [skip, setSkip] = useState(skipNumber);
  const [returnList, setReturnList] = useState<T[]>();

  const handleNext = () => {
    setSkip((prev) => {
      const nextPage = prev + 1;
      setSearchParams((prev) => {
        prev.set(pageId, nextPage.toString());
        return prev;
      });
      return nextPage;
    });
  };
  const handlePrevious = () => {
    setSkip((prev) => {
      const previousPage = prev - 1;
      setSearchParams((prev) => {
        prev.set(pageId, previousPage.toString());
        return prev;
      });
      return previousPage;
    });
  };

  const isLastPage = useMemo(() => {
    if (list) {
      return list.length - skip * limit <= limit;
    }
    return false;
  }, [list, limit, skip]);
  const isFirstPage = useMemo(() => skip === 0, [skip]);
  useEffect(() => {
    if (list) {
      setReturnList(handleList(list, limit, skip));
    }
  }, [list, limit, skip]);
  return { handleNext, handlePrevious, isLastPage, isFirstPage, returnList };
};
