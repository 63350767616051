import { useCallback } from "react";
import { CacheKeys } from "../types";
import { useFetch } from "./useFetch";
import { Dashboard } from "../utils/dasboard";

export const useDashboardInfo = () => {
  const fetchFunction = useCallback(async () => {
    const response = await Dashboard.getData();
    return response && Dashboard.mapData(response);
  }, []);

  const { data, loading } = useFetch({
    cacheKey: CacheKeys.dashboardInfo,
    fetchFunction,
  });

  return { data, loading };
};
