import styled from "styled-components";
import {
  Arrow,
  Description,
  Item,
  LeftWrapper,
  RightWrapper,
  TextWrapper,
  Title,
} from "../styles/list";
import { formatNumbers } from "../utils";
import { OptionLabelFlexAccount } from "./OptionLabel";
interface Props {
  disable: Boolean;
  flexAccount: OptionLabelFlexAccount;
}
const FlexAccountOptionLabel = ({ disable, flexAccount }: Props) => {
  if (disable) {
    return (
      <StyledItem>
        <LeftWrapper>
          <TextWrapper>
            <FlexAccountTitle>Flex Account</FlexAccountTitle>
            <Description>
              Available Balance: ${formatNumbers(flexAccount.amount)}
            </Description>
          </TextWrapper>
        </LeftWrapper>
        <RightWrapper>
          <Arrow />
        </RightWrapper>
      </StyledItem>
    );
  }
  return (
    <Item className="p-1" data-cy="flex-account-option">
      <LeftWrapper>
        <TextWrapper>
          <Title style={{ color: "inherit" }}>Flex Account</Title>
          <Description>
            Current Balance: ${formatNumbers(flexAccount.amount)}
          </Description>
        </TextWrapper>
      </LeftWrapper>
      <RightWrapper>
        <Arrow />
      </RightWrapper>
    </Item>
  );
};

const StyledItem = styled(Item)`
  padding: 0px;
`;

const FlexAccountTitle = styled(Title)`
  margin-bottom: -5px;
  color: grey;
  font-weight: 700;
`;

export default FlexAccountOptionLabel;
