import React from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

interface Props {
  videoID: string;
  title: string;
  subtitle: string;
  buttonName?: string;
  buttonUrl: string;
  videoFile: string;
  buttonUrl2?: string;
  buttonName2?: string;
}

const HeroVideo: React.FC<Props> = (props) => {
  return (
    <section id={props.videoID} className="d-flex align-items-center">
      <DarkVideo autoPlay muted loop className="w-100 darker-image">
        <source src={props.videoFile} type="video/mp4" />
      </DarkVideo>

      <StyledHeroVideo className="invest-video-hero position-absolute container-sm w-100 mt-0 pt-0">
        <Text className="invest-video-text">{props.title}</Text>
        <SubText className="invest-video-subtext">{props.subtitle}</SubText>
        <div className="d-flex gap-4">
          <Link to={props.buttonUrl}>
            <Button
              type="button"
              className="invest-video-button btn btn-success btn-sm btn-get-started scrollto"
            >
              {props.buttonName}
            </Button>
          </Link>
          {props.buttonUrl2 && (
            <Link to={props.buttonUrl2} target="_blank" rel="noreferrer">
              <Button
                type="button"
                className="invest-video-button btn btn-success btn-sm btn-get-started scrollto"
              >
                {props.buttonName2}
              </Button>
            </Link>
          )}
        </div>
      </StyledHeroVideo>
    </section>
  );
};

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledHeroVideo = styled.div`
  color: white;
  padding: 5px;
  padding-left: 105px;
  padding-right: 20px;
  @media (max-width: 600px) {
    font-size: 15px !important;
    padding-left: 35px;
    padding-right: 20px;
  }
  animation: ${fadeInUp} 1s ease-in-out;
  animation-delay: 0.3s;
  transition-timing-function: ease;
`;

const Text = styled.h1`
  font-weight: bold;
  margin-top: 10px;
  @media (max-width: 600px) {
    font-size: 15px;
  }
  @media (max-width: 320px) {
    font-size: 12px;
  }
`;

const SubText = styled.h5`
  font-weight: normal;
  opacity: 0.7;
  font-size: 25px;
  @media (max-width: 510px) {
    font-size: 15px;
  }
  @media (max-width: 320px) {
    font-size: 10px;
  }
`;
const Button = styled.button`
  @media (max-width: 320px) {
    font-size: 10px;
  }
`;

const DarkVideo = styled.video`
  filter: brightness(0.6);
`;

export default HeroVideo;
