export const initialErrors = {
  account: "",
  amount: "",
  product: "",
  form: "",
};
export const initialTouch = {
  account: false,
  amount: false,
  product: false,
};
export const customErrors = {
  account: "",
  amount: "",
  product: "",
  DefaultError: "",
};
