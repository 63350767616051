import { useEffect, useState } from "react";
import PromissoryNoteList from "../../components/PromissoryNoteList";
import { NorhartWrapper } from "../../components/NorhartWrapper";
import styled from "styled-components";
import { usePagination } from "../../hooks/usePagination";
import { usePromissoryNotes } from "../../hooks/usePromissoryNotes";
import { Loading } from "../../components/Loading";
import { PromissoryNoteAccount } from "../../types";
const LIMIT = 5;
const height = `${72.38 * LIMIT + 41.5}px`;
enum PromissoryNotesStatus {
  completed = "Completed",
  uncompleted = "Uncompleted",
}

const PromissoryNotes = () => {
  const { data: promissoryNotes, loading } = usePromissoryNotes();
  const [completedPromissoryNotes, setCompletedPromissoryNotes] =
    useState<PromissoryNoteAccount[]>();
  const [uncompletedPromissoryNotes, setUncompletedPromissoryNotes] =
    useState<PromissoryNoteAccount[]>();
  useEffect(() => {
    if (promissoryNotes) {
      setCompletedPromissoryNotes(
        promissoryNotes?.filter((note) => note.completed === true)
      );
      setUncompletedPromissoryNotes(
        promissoryNotes?.filter((note) => note.completed === false)
      );
    }
  }, [promissoryNotes]);

  const {
    handleNext: handleNextUncompleted,
    handlePrevious: handlePreviousUncompleted,
    isLastPage: isLastUncompletedPage,
    isFirstPage: isFirstUncompletedPage,
    returnList: returnUncompletedPromissoryNotes,
  } = usePagination({
    list: uncompletedPromissoryNotes,
    limit: LIMIT,
    paginationId: PromissoryNotesStatus.uncompleted,
  });
  const {
    handleNext: handleNextCompleted,
    handlePrevious: handlePreviousCompleted,
    isLastPage: isLastCompletedPage,
    isFirstPage: isFirstCompletedPage,
    returnList: returnCompletedPromissoryNotes,
  } = usePagination({
    list: completedPromissoryNotes,
    limit: LIMIT,
    paginationId: PromissoryNotesStatus.completed,
  });
  return (
    <NorhartWrapper title="Promissory Note">
      {loading ? (
        <Loading />
      ) : (
        <PageWrapper>
          {!!returnUncompletedPromissoryNotes?.length && (
            <ListWrapper height={height}>
              <PromissoryNoteList
                promissoryNotes={returnUncompletedPromissoryNotes}
                title="Active"
                active
                pagination
                isLastPage={isLastUncompletedPage}
                isFirstPage={isFirstUncompletedPage}
                handleNext={handleNextUncompleted}
                handlePrevious={handlePreviousUncompleted}
              />
            </ListWrapper>
          )}
          {!!returnCompletedPromissoryNotes?.length && (
            <ListWrapper height={height}>
              <PromissoryNoteList
                promissoryNotes={returnCompletedPromissoryNotes}
                title="Completed"
                pagination
                isLastPage={isLastCompletedPage}
                isFirstPage={isFirstCompletedPage}
                handleNext={handleNextCompleted}
                handlePrevious={handlePreviousCompleted}
              />
            </ListWrapper>
          )}
        </PageWrapper>
      )}
    </NorhartWrapper>
  );
};
interface ListProps {
  height: string;
}
const ListWrapper = styled.div<ListProps>`
  height: ${(props) => props.height};
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export default PromissoryNotes;
