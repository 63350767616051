import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import { Button as DefaultButton } from "../../styles";

export const ErrorText = styled.div`
  font-size: 25px;
  font-weight: 700;
  margin: 100px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled(DefaultButton)`
  width: 120px;
`;

export const StyledSpinner = styled(Spinner)`
  margin: 0px;
  height: 20px;
  width: 20px;
`;

export const Wrapper = styled.div`
  margin-bottom: 1.2rem;
  min-height: 351px;
  @media (min-width: 410px) {
    min-height: 321px;
  }
  @media (min-width: 350px) {
    min-height: 321px;
  }
  @media (min-width: 318px) {
    min-height: 411px;
  }
`;

export const Desktop = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Mobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;
