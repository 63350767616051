import WelcomeVideoComponent from "../components/LandingVideoComponent";
import WhyInvestVideo from "../components/WhyInvestVideo";

const WhyInvestVideoSection = () => {
  return (
    <WelcomeVideoComponent
      backgroundColorOne="#f4f4f4"
      backgroundColorTwo="#f4f4f4"
      title="Why Invest With Norhart"
      subTitle="Listen To Our Story"
      textColor="#111111"
      children={<WhyInvestVideo />}
    />
  );
};

export default WhyInvestVideoSection;
