import {
  PlaidLinkOnEventMetadata,
  PlaidLinkOnSuccessMetadata,
} from "react-plaid-link";
import { PlaidEvents, Products } from "../types";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "../utils";
import { OnboardingPopUp } from "../components/Onboarding";
import { useIsVerified } from "../hooks/useIsVerified";
import { Counterparty } from "../utils/counterparties";
import { Page } from "../types/page";
import { useDelay } from "./useDelay";
import { IdentityVerified, ReviewStatus } from "../types/cognito-attributes";
import { useMailChimpForm } from "use-mailchimp-form";

const DELAY_SECONDS = 6;

export const useIdentityVerification = () => {
  const navigate = useNavigate();
  const identity = useMemo(() => [Products.IdentityVerification], []);
  const [showPopUp, setShowPopUp] = useState(false);
  const [loading, setLoading] = useState<boolean>();
  const [showVerifyFlow, setShowVerifyFlow] = useState<boolean>(false);
  const { isLoading, isVerified, verify } = useIsVerified();
  const { ready: delayReady } = useDelay(DELAY_SECONDS);
  const { handleSubmit: mailchimpSubmit } = useMailChimpForm(
    process.env.REACT_APP_MAILCHIMP_URL!
  );

  const localOnSuccess = async (
    public_token: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    setLoading(true);
    const delayPromise = new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, DELAY_SECONDS * 1000);
    });
    try {
      const createCounterpartyPromise = Counterparty.create(
        metadata.link_session_id
      );
      const [, counterparty] = await Promise.all([
        delayPromise,
        createCounterpartyPromise,
      ]);
      mailchimpSubmit({
        EMAIL: counterparty.user.email_address,
        FNAME: counterparty.user?.name?.given_name,
        LNAME: counterparty.user?.name?.family_name,
        PHONE: counterparty.user.phone_number,
      });
      // navigate("/", {
      //   state: { from: Page.identityVerification },
      // });
      navigate("/onboarding/bank-account", {
        state: { from: Page.identityVerification },
      });
    } catch (e) {
      await verify();
    }
    setShowVerifyFlow(false);
    setShowPopUp(false);
    setLoading(false);
  };

  const onEvent = async (event: string, metadata: PlaidLinkOnEventMetadata) => {
    if (event === PlaidEvents.IDENTITY_VERIFICATION_CLOSE_UI) {
      setShowPopUp(true);
    }
  };
  const secondButtonHandleClick = async () => {
    await Auth.signOut();
    navigate("/signin");
  };

  const handleClick = async () => {
    setShowPopUp(false);
  };

  const handleRetryClick = async () => {
    setShowVerifyFlow(true);
  };
  const logOut = async () => {
    await Auth.signOut();
    navigate("/signin");
  };

  useEffect(() => {
    const checkIdentityVerified = async () => {
      if (isVerified === IdentityVerified.pendingReview) {
        await Auth.signOut();
        navigate("/review", {
          state: { from: ReviewStatus.pending },
        });
        return;
      }
      if (isVerified === IdentityVerified.unsafeFailure) {
        await Auth.signOut();
        navigate("/review", {
          state: { from: ReviewStatus.rejected },
        });
        return;
      }
      if (!isLoading && isVerified === undefined) {
        setShowVerifyFlow(true);
      }
    };
    checkIdentityVerified();
  }, [isVerified, isLoading, navigate]);

  const modalProps: OnboardingPopUp = {
    show: showPopUp,
    title: "Identity Verification",
    body: "Oops it's look like you stop your identity verification, please click on try again to continue or log out",
    secondButtonHandleClick,
    secondButtonMessage: "Log out",
    buttonMessage: "Try again",
    needOpen: true,
    handleClick,
  };

  return {
    handleRetryClick,
    logOut,
    modalProps,
    localOnSuccess,
    identity,
    loading,
    onEvent,
    showVerifyFlow,
    isVerified,
    isLoading,
    delayReady,
  };
};
