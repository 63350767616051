export enum CustomCognitoAttributes {
  has_bank_account = "has_bank_account",
  income = "income",
  identity_verified = "identity_verified",
  accredited_investor = "accredited_investor",
}

export interface AttributesProps {
  "custom:has_bank_account"?: string;
  "custom:income"?: string;
  "custom:identity_verified"?: string;
  "custom:accredited_investor"?: string;
}

export enum CognitoAttributes {
  address = "address",
  birthdate = "birthdate",
  email = "email",
  family_name = "family_name",
  gender = "gender",
  given_name = "given_name",
  locale = "locale",
  middle_name = "middle_name",
  name = "name",
  nickname = "nickname",
  phone_number = "phone_number",
  picture = "picture",
  preferred_username = "preferred_username",
  profile = "profile",
  updated_at = "updated_at",
  website = "website",
  zoneinfo = "zoneinfo",
}

export enum IdentityVerified {
  verified = "verified",
  safeFailure = "safeFailure",
  unsafeFailure = "unsafeFailure",
  pendingReview = "pending_review",
}

export enum ReviewStatus {
  pending = "pending",
  rejected = "rejected",
}
