import styled from "styled-components";
// import BannerImage from "../../../assets/images/invest-dash-ad-image-1.png";
import { GreenButton } from "../../../styles";
import { Spinner } from "react-bootstrap";
import { UserData } from "../../../types/users";
import { useHasBankAccounts } from "../../../hooks/useHasBankAccounts";
import { BannerWrapper } from "../styles";

interface BannerProps {
  onClose: () => void;
  openAddMoney: () => void;
  userData: UserData | undefined;
  interest?: number;
  ready: boolean;
  openPlaidPopUp: () => void;
}

const StartBanner: React.FC<BannerProps> = ({
  openAddMoney,
  userData,
  interest,
  openPlaidPopUp,
  ready,
}) => {
  const {
    hasBankAccounts,
    loading: loadingHasBankAccount,
    getVerifiedStatus,
  } = useHasBankAccounts();
  const handleClick = async () => {
    const hasBankAccountsResult = await getVerifiedStatus();
    if (hasBankAccountsResult) {
      openAddMoney();
      return;
    }
    if (!hasBankAccountsResult) openPlaidPopUp();
  };
  const loading = hasBankAccounts ? loadingHasBankAccount : !ready;
  return (
    <BannerWrapper className="text-center">
      <div className="d-flex align-items-end gap-4">
        <TextBox>
          <div>
            <Title>Hey {userData?.name?.given_name}!</Title>
            <Message>
              Start earning up to {interest}% with our fixed promissory notes
              today. Your "Withdrawal" button will activate when your promissory
              note has matured.
            </Message>
          </div>
          <div className="w-100 d-flex">
            <StyledButton disabled={loading} onClick={handleClick}>
              {loading ? <Spinner size="sm" /> : "Start Now"}
            </StyledButton>
          </div>
        </TextBox>
      </div>
    </BannerWrapper>
  );
};

const TextBox = styled.div`
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
`;

// const Image = styled.img`
//   @media (max-width: 767px) {
//     display: none;
//   }
// `;

const StyledButton = styled(GreenButton)`
  @media (max-width: 767px) {
    width: 100% !important;
  }
  @media (max-width: 350px) {
    font-size: 13px;
    padding: 5px;
  }
`;

const Title = styled.div`
  text-align: start;
  padding: 0;
  @media (max-width: 767px) {
    text-align: center;
  }
  font-size: 30px;
  font-weight: 700;
  line-height: initial;
  @media (max-width: 410px) {
    font-size: 25px;
    font-weight: 600;
  }
  @media (max-width: 350px) {
    font-size: 22px;
    font-weight: 600;
  }
  @media (max-width: 280px) {
    font-size: 20px;
    font-weight: 600;
  }
`;

const Message = styled.div`
  text-align: start;
  font-size: 19px;
  font-weight: 400;
  padding: 0;
  @media (max-width: 767px) {
    text-align: center;
  }
  @media (max-width: 410px) {
    font-size: 15px;
  }
  @media (max-width: 350px) {
    font-size: 13px;
  }
  @media (max-width: 280px) {
    font-size: 12px;
  }
`;

export default StartBanner;
