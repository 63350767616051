export interface CreateAccountForm {
  phoneNumber: string;
  username: string;
  password: string;
  income: string;
  accredited: string;
  firstName: string;
  lastName: string;
  didSignTOSPolicy: boolean;
  didSignPrivacyPolicy: boolean;
}
export interface CreateAccountErrors
  extends Omit<CreateAccountForm, "didSignTOSPolicy" | "didSignPrivacyPolicy"> {
  didSignTOSPolicy: string;
  didSignPrivacyPolicy: string;
  form: string;
}

export interface CreateAccountTouch {
  firstName: boolean;
  phoneNumber: boolean;
  username: boolean;
  password: boolean;
  income: boolean;
  accredited: boolean;
  lastName: boolean;
  didSignTOSPolicy: boolean;
  didSignPrivacyPolicy: boolean;
}

export enum CUSTOM_ERRORS {
  UsernameExistsException = "UsernameExistsException",
  InvalidParameterException = "InvalidParameterException",
  InvalidPasswordException = "InvalidPasswordException",
  DefaultError = "Something wrong happened creating your account",
}
