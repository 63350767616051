import { useEffect } from "react";

const TrustpilotWidget = () => {
  useEffect(() => {
    const scriptElement = document.createElement("script");
    scriptElement.type = "text/javascript";
    scriptElement.src =
      "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    scriptElement.async = true;
    document.head.appendChild(scriptElement);

    const htmlString =
      '<div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="653949cf133ded4c363f7261" data-style-height="50px" data-style-width="110px" style="width:110px" data-theme="light"><a href="undefined" target="_blank" rel="noopener">Trustpilot</a></div>';
    const widget = new DOMParser().parseFromString(htmlString, "text/html");
    const trustbox = widget.body.children[0];
    const id = "trustpilot-gtm-floating";
    trustbox.setAttribute("id", id);
    const wrapper = document.createElement("div");
    wrapper.setAttribute("id", id + "-wrapper");
    wrapper.setAttribute(
      "style",
      "position: fixed !important; z-index: 1000; bottom: 20px; left: 16px; background-color: white; padding: 8px; box-shadow: 0px 2px 4px 0px #dcdce6; border-radius: 3px;"
    );
    wrapper.appendChild(trustbox);
    document.body.appendChild(wrapper);
    scriptElement.onload = function () {
      window.Trustpilot.loadFromElement(trustbox);
    };
    return () => {
      document.head.removeChild(scriptElement);
      document.body.removeChild(wrapper);
    };
  }, []);

  return null;
};

export default TrustpilotWidget;
