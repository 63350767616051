import { PaginationResponse } from "./pagination";

export interface UserDocumentData {
  id: string;
  cognitoId: string;
  name: string;
  s3Key: string;
  size: number;
  formatType: string; // Can be turned into an enum later
  date: string;
  tags: string[];
  lastAccessDate?: string;
  lastAccessType?: string;
}

export enum DocumentAccessType {
  view = "view",
  download = "download",
}

export interface GetUserDocumentUrl {
  key: string;
  name: string;
  format: string;
  accessType: DocumentAccessType;
}

export interface CreateS3SignedURLControllerOutput {
  url: string;
  expiresAt: string;
}

export type DocumentsResponse = PaginationResponse<UserDocumentData>;
