import Input, { InputFieldProps } from "../formInputsTypes/Input";
import { isValidOnBlur } from "../../../utils";
import React from "react";

interface Props extends InputFieldProps {
  handleFields?: (args: Record<string, string>) => void;
  message?: string;
  validation?: (value: string) => { isValid: boolean; message?: string };
  handleTouch: (args: Record<string, boolean>) => void;
  handleErrors: (args: Record<string, string>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, props?: any) => void;
  animateError?: boolean;
}

export const FormInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      handleFields,
      message,
      handleTouch,
      validation,
      handleErrors,
      onFocus,
      onBlur,
      onChange,
      required,
      type,
      animateError,
      ...rest
    },
    ref
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name, checked, type } = e.target;
      const isCheckbox = type === "checkbox";
      if (handleFields)
        handleFields({ [name]: isCheckbox ? JSON.stringify(checked) : value });
      handleValidation(e);
      if (onChange) onChange(e, { isMoney: rest.isMoney });
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      const { name } = e.currentTarget;
      handleTouch({ [name]: true });
      if (onFocus) onFocus(e);
    };

    const handleValidation = (
      e:
        | React.FocusEvent<HTMLInputElement, Element>
        | React.ChangeEvent<HTMLInputElement>
    ) => {
      const newError = isValidOnBlur(e, {
        required,
        validation: validation,
        message,
        label: rest.label,
      });
      handleErrors(newError);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      handleValidation(e);
      if (onBlur) onBlur(e);
    };

    return (
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        type={type}
        required={required}
        ref={ref}
        animateError={animateError}
        {...rest}
      />
    );
  }
);
