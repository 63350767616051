import { Form } from "react-bootstrap";
import styled from "styled-components";

export const GreenSwitch = styled(Form.Check)`
  & input:checked {
    background-color: ${({ theme }) => theme.GREEN};
    border: ${({ theme }) => theme.GREEN};
  }
  & input:focus {
    box-shadow: none;
    border-color: ${({ theme }) => theme.GREEN};
  }
`;

export const GreySwitch = styled(Form.Check)`
  & input:checked {
    background-color: #212121;
    border: #212121;
  }
  & input:focus {
    box-shadow: none;
    border-color: #212121;
  }
`;
export const CustomSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 33px;
  min-width: 33px;
  height: 17px;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
  border: 1px solid #ccc;
  &:before {
    content: "";
    position: absolute;
    height: 15px;
    width: 15px;
    left: 0px;
    bottom: 0px;
    background-color: ${({ theme }) => theme.BANNER_BACKGROUND};
    transition: 0.4s;
    transform: translateX(0);
    border-radius: 50%;
  }
`;

export const StyledInput = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + ${Slider} {
    background-color: ${({ theme }) => theme.GREEN};
  }
  &:checked + ${Slider}::before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
`;
