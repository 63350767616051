import { useEffect, useState } from "react";
import { CustomCognitoAttributes } from "../types/cognito-attributes";
import { Auth } from "../utils";
import { CacheKeys } from "../types";

export const useHasBankAccounts = () => {
  const [hasBankAccounts, setHasBankAccounts] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const getVerifiedStatus = async () => {
    setLoading(true);
    let status = false;
    const attributes = await Auth.getUserAttributes();
    const has_bank_account =
      attributes[`${CustomCognitoAttributes.has_bank_account}`];
    if (has_bank_account === "true") {
      setHasBankAccounts(true);
      status = true;
    } else {
      const result = localStorage.getItem(CacheKeys.bankAccounts);
      if (result) {
        const bankAccounts = JSON.parse(result);
        if (bankAccounts.length > 0) {
          setHasBankAccounts(true);
          status = true;
        }
      }
    }
    setLoading(false);
    return status;
  };
  useEffect(() => {
    getVerifiedStatus();
  }, []);

  return { hasBankAccounts, loading, getVerifiedStatus };
};
