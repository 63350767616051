import { FormEvent, useState } from "react";
import { Alert } from "react-bootstrap";
import { Navigate, useLocation } from "react-router-dom";
import { FormInput } from "../../../components/forms/formsComponents/FormInput";
import VerifyCodeResetPassword from "../../../components/VerifyCodeResetPassword";
import { Form, Ul } from "../../../styles";
import { RESET_PASSWORD_CUSTOM_ERRORS } from "./types";
import { initialData, initialErrors, initialTouch } from "./constants";
import { useForm } from "../../../hooks/useForm";
import { validateAmazonPassword, validateEqual } from "../../../utils";
import { AuthButton } from "../../../components/AuthButton";
import { DarkWrapperAuth } from "../../../components/DarkWrapperAuth";

const ResetPassword = () => {
  const {
    fields,
    errors,
    handleErrors,
    handleFields,
    handleTouch,
    submitLoading,
    onSubmit,
    submitWithEnter: { ref, handleKeyUp },
  } = useForm({
    initialFields: initialData,
    initialErrors: initialErrors,
    initialTouch: initialTouch,
    customErrors: RESET_PASSWORD_CUSTOM_ERRORS,
  });

  const [showValidationPopUp, setShowValidationPopUp] =
    useState<boolean>(false);
  const { state } = useLocation();

  if (!state?.username) {
    return <Navigate to="/forgot-password/send-email" replace={true} />;
  }

  const repeatPasswordValidation = (value: string) =>
    validateEqual(fields.password, value);

  const onSubmitPassword = () => async () => {
    setShowValidationPopUp(true);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    onSubmit(e, onSubmitPassword());
  };

  return (
    <DarkWrapperAuth>
      <>
        <Form onSubmit={handleSubmit} ref={ref} onKeyUp={handleKeyUp}>
          <Alert variant="secondary">
            Enter your new password and then press "Verify code" to verify the
            code that we sent you to your email
          </Alert>
          <FormInput
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            validation={validateAmazonPassword}
            message="Please insert a valid password"
            required
            label="Password"
            notShowLabel
            name="password"
            value={fields.password}
            placeholder="Insert your password"
            type="password"
            error={errors.password}
            errorColor="white"
          />
          <FormInput
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            validation={repeatPasswordValidation}
            message="Passwords must be equal"
            required
            name="repeatPassword"
            label="Repeat Password"
            notShowLabel={true}
            value={fields.repeatPassword}
            placeholder="Repeat your password"
            type="password"
            error={errors.repeatPassword}
            errorColor="white"
          />
          <Ul>
            <li>Contains at least 1 number</li>
            <li>Contains at least 1 special character</li>
            <li>Contains at least 1 uppercase letter</li>
            <li>Contains at least 1 lowercase letter</li>
          </Ul>
          <AuthButton loading={!!submitLoading} padlock={!submitLoading}>
            Verify Code
          </AuthButton>
        </Form>
        <VerifyCodeResetPassword
          show={showValidationPopUp}
          setShow={setShowValidationPopUp}
          username={state.username}
          password={fields.password}
        />
      </>
    </DarkWrapperAuth>
  );
};

export default ResetPassword;
