export interface LedgerTransactionOutput {
  id: string;
  description: string;
  effectiveDate: string;
  ledgerEntries: LedgerEntryOutput[];
  metadata: Record<string, string>;
  amount: number;
  status: TransactionStatus;
}

export interface LedgerEntryOutput {
  id: string;
  amount: number;
  direction: Direction;
  ledgerAccountId: string;
  status: TransactionStatus;
}

export enum Direction {
  Credit = "credit",
  Debit = "debit",
}

export enum TransactionStatus {
  archived = "archived",
  canceledInvestment = "canceledInvestment",
  returned = "returned",
  reversed = "reversed",
  denied = "denied",
  cancelled = "cancelled",
  failed = "failed",
  pending = "pending",
  posted = "posted",
}

export interface TransactionsResponse {
  items: LedgerTransactionOutput[];
  page: Page;
}

export interface Page {
  perPage: number;
  cursor: string;
  isLastPage: boolean;
}
