import styled from "styled-components";
import { MappedDashboardInfo } from "../../../types";
import { StyledBannerWrapper } from "../styles/bannerBox";
import { useState } from "react";
import BannerBox from "./BannerBox";

interface Props {
  data: MappedDashboardInfo;
}

const MobileDashboardInfo = ({ data }: Props) => {
  const [activeSlide, setActiveSlide] = useState<0 | 1 | 2>(0);
  const { investments, earned, upcomingMaturityDate } = data;
  return (
    <MobileWrapper>
      <StyledBannerWrapper>
        <SliderContainer>
          <SliderWrapper translateValue={-activeSlide * 100}>
            <Slide>
              <BannerBox title={"TOTAL INVESTMENTS"} value={investments} />
            </Slide>
            <Slide>
              <BannerBox title={"TOTAL APY EARNED"} value={earned} isMoney />
            </Slide>
            <Slide>
              <BannerBox
                title={"UPCOMING MATURITY DATE"}
                value={upcomingMaturityDate}
              />
            </Slide>
            {/* Add more slides as needed */}
          </SliderWrapper>
        </SliderContainer>
        <ButtonsWrapper>
          <ButtonDot
            isActive={activeSlide === 0}
            onClick={() => setActiveSlide(0)}
          />
          <ButtonDot
            isActive={activeSlide === 1}
            onClick={() => setActiveSlide(1)}
          />
          <ButtonDot
            isActive={activeSlide === 2}
            onClick={() => setActiveSlide(2)}
          />
        </ButtonsWrapper>
      </StyledBannerWrapper>
    </MobileWrapper>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
`;

interface ButtonDotProps {
  isActive?: boolean;
}

const ButtonDot = styled.div<ButtonDotProps>`
  border: solid 1px;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  border-color: ${({ isActive, theme }) =>
    isActive ? theme.PRIMARY_COLOR : theme.NAVBAR_BACKGROUND};
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.PRIMARY_COLOR : theme.NAVBAR_BACKGROUND};
`;

const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
`;

const SliderContainer = styled.div`
  overflow-x: clip;
  width: 100%; /* Set the width based on your design */
`;

interface SliderWrapperProps {
  translateValue: number;
}

const SliderWrapper = styled.div<SliderWrapperProps>`
  display: flex;
  transition: transform 0.5s ease;
  transform: translateX(${(props) => props.translateValue}%);
  width: 100%;
`;

const Slide = styled.div`
  flex: 0 0 100%;
`;
export default MobileDashboardInfo;
