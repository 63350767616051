import { PlaidAccountDetails } from "./plaid";

export interface GetAttentionDataOutput {
  bankAccounts: {
    requireAttention: boolean;
    subject: BankAccountsPendingVerification;
  };
}

export interface BankAccountsPendingVerification {
  pendingManualMicroDeposit: BankPendingAlert<CustomPlaidAccountDetails>;
  pendingAutomaticMicroDeposit: BankPendingAlert<{
    accounts: PlaidAccountDetails[];
  }>;
  pendingLoginRequired: BankPendingAlert<CustomPlaidAccountDetails>;
  pendingExpiration: BankPendingAlert<CustomPlaidAccountDetails>;
}

export interface BankPendingAlert<T> {
  requireAttention: boolean;
  accountsGrouping: Record<string, T>;
}

export interface CustomPlaidAccountDetails {
  accounts: PlaidAccountDetails[];
  linkToken: string;
}

export enum AlertTypes {
  pendingManualMicroDeposit = "pendingManualMicroDeposit",
  pendingAutomaticMicroDeposit = "pendingAutomaticMicroDeposit",
  pendingLoginRequired = "pendingLoginRequired",
  pendingExpiration = "pendingExpiration",
}

export interface AlertProps {
  bankName: string;
  last4: string;
}

export interface ExtendedAlertProps extends AlertProps {
  token: string;
  id: string;
}

export type AlertComponentConstructor = (
  props: ExtendedAlertProps
) => JSX.Element | null;
