import { useEffect, useState } from "react";
import { Product } from "../types";
import { Auth } from "../utils";

export const useProductWs = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState("");
  const [defaultProductLoading, setDefaultProductLoading] =
    useState<boolean>(true);
  const [defaultProduct, setDefaultProduct] = useState<Product>();

  useEffect(() => {
    const getToken = async () => {
      const token = await Auth.getUserToken();
      setToken(token);
    };

    getToken();
  }, []);

  useEffect(() => {
    if (token) {
      const socket = new WebSocket(
        `${process.env.REACT_APP_WS_BASE_URL_INT}?token=${token}`
      );

      socket.addEventListener("open", (event) => {
        socket?.send(
          JSON.stringify({ action: "subscribe", subscription: "products" })
        );
      });

      socket.addEventListener("message", (event) => {
        const productResponse: Product[] = JSON.parse(event.data);
        productResponse.sort((a, b) => a.term - b.term);
        setProducts(productResponse);
        setLoading(false);
      });

      socket.addEventListener("close", (event) => {
        console.log("WebSocket connection closed");
      });

      socket.addEventListener("error", (event) => {
        console.log(`WebSocket error: ${event}`);
      });

      return () => {
        socket.close();
      };
    }
  }, [token]);

  useEffect(() => {
    if (!!products.length) {
      let longestPromissoryNote = products[0];
      products.forEach((product) => {
        if (product.term > longestPromissoryNote.term) {
          longestPromissoryNote = product;
        }
      });
      setDefaultProduct(longestPromissoryNote);
    }
    if (!loading) setDefaultProductLoading(false);
  }, [products, loading]);

  return { loading, products, defaultProductLoading, defaultProduct };
};
