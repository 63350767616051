import Onboarding from "../../../components/Onboarding";
import ModalPopUp from "../../../components/ModalPopUp";
import { IdentityVerified } from "../../../types/cognito-attributes";
import { useIdentityVerification } from "../../../hooks/useIdentityVerification";

const IdentityVerification = () => {
  const {
    handleRetryClick,
    logOut,
    modalProps,
    localOnSuccess,
    identity,
    loading,
    onEvent,
    showVerifyFlow,
    isVerified,
    isLoading,
    delayReady,
  } = useIdentityVerification();

  return (
    <>
      <ModalPopUp
        show={isVerified === IdentityVerified.safeFailure && !showVerifyFlow}
        title={"Identity Validation"}
        body={
          "Oops it looks like your identity verification fails, please click on try again button to validate your identity again"
        }
        buttonMessage={"Try again"}
        handleClick={handleRetryClick}
        secondButtonMessage={"Log out"}
        secondButtonHandleClick={logOut}
      />
      {/* <ModalPopUp
        show={isVerified === IdentityVerified.unsafeFailure}
        title={"Identity Validation"}
        body={
          "Oops it looks like your identity verification fails, please contact your team to solve your problem"
        }
        buttonMessage={"Log out"}
        handleClick={logOut}
      /> */}
      {!isLoading && showVerifyFlow && (
        <Onboarding
          product={identity}
          onSuccess={localOnSuccess}
          onEvent={onEvent}
          modalProps={modalProps}
          isFetching={loading}
          delayReady={delayReady}
        />
      )}
    </>
  );
};

export default IdentityVerification;
