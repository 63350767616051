import styled from "styled-components";
import NorhartHeader from "./NorhartHeader";
import { Link } from "react-router-dom";

interface Props {
  title?: string;
  message?: string;
  children: React.ReactNode;
}

export const NorhartWrapper = ({ title, message, children }: Props) => {
  return (
    <WrapperDiv className="container-lg">
      <NorhartHeader title={title} message={message} />
      {children}

      <NorhartBottomDiv>
        <h5>Legal</h5>
        <StyledP>
          Notes are being offered by Norhart Invest LLC pursuant to an offering
          statement qualified by the SEC under Regulation A of the securities
          act of 1933. To view the offering statement and our current filings
          please{" "}
          <Anchor
            to="https://www.sec.gov/Archives/edgar/data/1964262/000121390023089682/ea188940-1apos_norhart.htm"
            target="_blank"
            rel="noreferrer"
          >
            click here
          </Anchor>
          . Neither the SEC nor any state securities authority has passed upon
          or approved this offering.
        </StyledP>
        <BottomDiv>
          <Anchor
            to="https://cdn.norhart.com/2023-06-30_Terms_of_Service.pdf"
            target="_blank"
          >
            Terms & Conditions
          </Anchor>
          <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>|</span>
          <Anchor to="/legal/privacy/">Privacy Policy</Anchor>
          <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>|</span>
          <StyledLink to="/release-notes">Version 1.0</StyledLink>
        </BottomDiv>

        <NorhartDiv style={{ marginTop: "10px" }}>
          <span>© Norhart Invest LLC</span>
        </NorhartDiv>
      </NorhartBottomDiv>
    </WrapperDiv>
  );
};

const Anchor = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.BANNER_COLOR};
  font-weight: 500;
  @media (max-width: 600px) {
    font-size: 15px;
  }
  @media (max-width: 410px) {
    font-size: 14px;
  }
  @media (max-width: 350px) {
    width: 33%;
    font-size: 13px;
  }
  @media (max-width: 280px) {
    font-size: 12px;
  }
`;

const StyledLink = styled(Anchor)`
  color: ${({ theme }) => theme.GREEN};
  font-weight: 600;
  text-decoration: none;
`;

const StyledP = styled.p`
  color: ${({ theme }) => theme.BANNER_COLOR};
  text-align: start;
`;

const WrapperDiv = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 600px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media (max-width: 410px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media (max-width: 350px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @media (max-width: 280px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

const BottomDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  @media (max-width: 280px) {
    margin-bottom: 60px;
  }
`;

const NorhartDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  @media (max-width: 280px) {
    font-size: 14px;
  }
`;

const NorhartBottomDiv = styled(NorhartDiv)`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 100px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 15px;
  }
  @media (max-width: 410px) {
    font-size: 14px;
  }
  @media (max-width: 350px) {
    font-size: 13px;
  }
  @media (max-width: 280px) {
    font-size: 12px;
    margin-bottom: 60px;
  }
`;
