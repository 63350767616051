import {
  Item,
  LeftWrapper,
  TextWrapper,
  Description,
  Title,
} from "../../../styles/list";
import { GetUserDocumentUrl } from "../../../types/documents";
import { HandleClickProps } from "../../../types/downloadQueue";
import { formatDate, formatNumbers } from "../../../utils";
import DocumentActions from "./DocumentActions/DocumentActions";
// import { ReactComponent as OpenEye } from "../../../assets/icons/eye-open.svg";
// import { ReactComponent as ClosedEye } from "../../../assets/icons/eye-closed.svg";
interface Props {
  name: string;
  size: number;
  formatType: string;
  s3Key: string;
  id: string;
  loading: boolean;
  updateQueue: (input: HandleClickProps<GetUserDocumentUrl>) => void;
  lastAccessDate?: string;
}

const DocumentRow = ({
  name,
  formatType,
  size,
  s3Key,
  id,
  loading,
  updateQueue,
  lastAccessDate,
}: Props) => {
  return (
    <Item style={{ cursor: "default" }}>
      <LeftWrapper>
        <TextWrapper>
          <Title>{name + `(${formatType})`}</Title>
          <Description className="text-secondary">
            Size: {formatNumbers(size, 1000, 0)} kB{" "}
            {lastAccessDate && `| Last Accessed: ${formatDate(lastAccessDate)}`}
            {/* <OpenEye /> */}
          </Description>
        </TextWrapper>
      </LeftWrapper>
      <DocumentActions
        name={name}
        formatType={formatType}
        s3Key={s3Key}
        id={id}
        loading={loading}
        updateQueue={updateQueue}
      />
    </Item>
  );
};
export default DocumentRow;
