import { SmallDot } from "../../../styles/list";
import { LeftWrapper, BankName, DeleteButton, Text, DeleteRow } from "./styles";
import { useState } from "react";
import { Colors } from "../../../styles/constants";
import { BeneficiariesData } from "../../../types/beneficiaries";

interface Props {
  beneficiary: BeneficiariesData;
  handleDelete: (v: string) => Promise<void>;
}

const BeneficiariesRow = ({
  beneficiary: { givenName, familyName, allocation, id },
  handleDelete,
}: Props) => {
  const [isDeleting, setIsDeleting] = useState<boolean>();

  const handleClick = async () => {
    setIsDeleting(true);
    await handleDelete(id);
    setIsDeleting(false);
  };

  return (
    <DeleteRow>
      <LeftWrapper>
        <SmallDot displaydot="true" color={Colors.GREEN} />
        <Text>
          <BankName>
            {givenName + " " + familyName} Allocation {`(${allocation}%)`}
          </BankName>
        </Text>
      </LeftWrapper>
      <DeleteButton onClick={() => handleClick()} disabled={isDeleting}>
        {isDeleting ? "Deleting" : "Delete"}
      </DeleteButton>
    </DeleteRow>
  );
};

export default BeneficiariesRow;
