import NorhartModal from "../NorhartModal";
import { AddMoneyInitialData, Steps } from "../../types/payment-modal";
import { OptionLabelFlexAccount } from "../OptionLabel";
import { Loading } from "../Loading";
import { FlexAccount, Product } from "../../types";
import { BankAccount } from "../../types/bank-accounts";
import BankBanner from "../BankBanner";
import { useHasBankAccounts } from "../../hooks/useHasBankAccounts";
import { TransactionGroup } from "../../types/payments";
import AmountForm from "./AmountForm";
import { useState, useMemo, useEffect } from "react";
import { Payments, Plaid, formatNumbers } from "../../utils";
import { flexAccountKey } from "./constants";

interface Props {
  show: boolean;
  onClose: (step: Steps) => void;
  step: Steps;
  nextStep: (step: Steps) => void;
  attributes: AddMoneyInitialData;
  loadingProducts: boolean;
  products: Product[];
  bankAccounts?: BankAccount[];
  bankAccountsLoading: boolean;
  isAddMoney?: boolean;
  flexAccount?: FlexAccount;
  flexAccountLoading: boolean;
  onSubmitAmount: (
    fields: AddMoneyInitialData,
    transactionGroup?: TransactionGroup
  ) => Promise<void>;
  handleBankClose?: () => void;
  needDefaultValue?: boolean;
  ready: boolean;
  waitingBankAccounts: boolean;
  openPlaidPopUp: () => void;
}

export interface GroupedOption {
  label: string;
  options: (Product | OptionLabelFlexAccount)[];
}

const AddMoneyAmount = ({
  show,
  onClose,
  step,
  nextStep,
  attributes,
  loadingProducts,
  products,
  bankAccounts,
  bankAccountsLoading,
  isAddMoney,
  flexAccount,
  flexAccountLoading,
  onSubmitAmount,
  handleBankClose,
  needDefaultValue,
  waitingBankAccounts,
  openPlaidPopUp,
  ready,
}: Props) => {
  const { hasBankAccounts, loading: loadingHasBankAccount } =
    useHasBankAccounts();
  const handleClose = () => {
    onClose(step);
  };

  const [reactSelectOptions, setReactSelectOptions] =
    useState<GroupedOption[]>();
  const [defaultProduct, setDefaultProduct] = useState<{
    name: string;
    id: string;
  }>();

  const bankOptions = useMemo(() => {
    if (!bankAccounts) return;
    const list: string[][] = [];
    bankAccounts.forEach((bankAccount) => {
      const { id, bankName, last4 } = bankAccount;
      if (Plaid.bankAccountIsVerified(bankAccount.status))
        list.push([
          JSON.stringify({ id: id, name: bankName }),
          bankName +
            ` (${
              bankAccount?.availableBalance
                ? `$${bankAccount.availableBalance}`
                : last4
            }) `,
        ]);
    });

    list?.unshift([
      JSON.stringify({ id: flexAccountKey, name: "Flex Account" }),
      `Cash ($${
        flexAccount && formatNumbers(flexAccount?.currentBalance.posted)
      })`,
    ]);
    return list;
  }, [bankAccounts, flexAccount]);
  useEffect(() => {
    const productOptions: {
      label: string;
      options: Product[] | OptionLabelFlexAccount[];
    }[] = [
      {
        label: "Offerings",
        options: products,
      },
    ];
    // if (isAddMoney) {
    //   productOptions.unshift({
    //     label: "",
    //     options: [
    //       {
    //         name: "Flex Account",
    //         id: flexAccountKey,
    //         amount: Number(flexAccount?.currentBalance.posted),
    //       },
    //     ],
    //   });
    // }
    setReactSelectOptions(productOptions);

    if (!!products.length && needDefaultValue) {
      let longestPromissoryNote = products[0];
      products.forEach((product) => {
        if (product.term > longestPromissoryNote.term) {
          longestPromissoryNote = product;
        }
      });
      setDefaultProduct({
        name: longestPromissoryNote.name,
        id: longestPromissoryNote.id!,
      });
    }
  }, [products, flexAccount?.currentBalance, needDefaultValue]);

  const userCanInvest = Payments.canInvest(hasBankAccounts, bankAccounts);

  return (
    <NorhartModal title="Invest" show={show} onClose={handleClose}>
      {loadingProducts ||
      bankAccountsLoading ||
      flexAccountLoading ||
      (hasBankAccounts && !bankAccounts?.length) ||
      loadingHasBankAccount ||
      !reactSelectOptions ||
      !bankOptions ||
      (!defaultProduct && needDefaultValue) ? (
        <Loading />
      ) : (
        <>
          {!userCanInvest && (
            <BankBanner
              needAlert
              needLoading
              onClose={handleBankClose}
              ready={ready}
              waitingBankAccounts={waitingBankAccounts}
              handleClick={openPlaidPopUp}
            />
          )}
          {userCanInvest && (
            <AmountForm
              step={step}
              nextStep={nextStep}
              attributes={attributes}
              products={products}
              bankAccounts={bankAccounts}
              isAddMoney={isAddMoney}
              flexAccount={flexAccount}
              onSubmitAmount={onSubmitAmount}
              onClose={onClose}
              bankOptions={bankOptions}
              reactSelectOptions={reactSelectOptions}
              defaultProduct={defaultProduct}
            />
          )}
        </>
      )}
    </NorhartModal>
  );
};

export default AddMoneyAmount;
