import YoutubeVideo from "./YoutubeVideo";

import MaxResolutionPhoto from "../../../assets/images/landing/youtube-image/why-invest/maxresdefault.png";
import SdDefaultPhoto from "../../../assets/images/landing/youtube-image/why-invest/sddefault.png";
import HqDefaultPhoto from "../../../assets/images/landing/youtube-image/why-invest/hqdefault.png";
import MqDefaultPhoto from "../../../assets/images/landing/youtube-image/why-invest/mqdefault.png";

const WhyInvestVideo = () => {
  return (
    <YoutubeVideo
      MaxResolutionPhoto={MaxResolutionPhoto}
      SdDefaultPhoto={SdDefaultPhoto}
      HqDefaultPhoto={HqDefaultPhoto}
      MqDefaultPhoto={MqDefaultPhoto}
      title={"Why Invest - Norhart Invest"}
      videoUrl={"https://www.youtube.com/embed/k9hoBkeJLQc"}
    />
  );
};

export default WhyInvestVideo;
