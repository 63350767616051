import { NorhartWrapper } from "../../components/NorhartWrapper";
import { useDocumentList } from "../../hooks/useDocumentList";
import { DocumentsList } from "./components/DocumentList/DocumentList";

const Documents = () => {
  const { loading: downloading, updateQueue } = useDocumentList();

  return (
    <NorhartWrapper title="Documents">
      <DocumentsList
        tag="promissory-note"
        title="Promissory Notes"
        downloading={downloading}
        updateQueue={updateQueue}
        documentDescription="promissory notes"
      />
      <DocumentsList
        tag="tax"
        title="Taxes"
        downloading={downloading}
        updateQueue={updateQueue}
        documentDescription="tax"
      />
    </NorhartWrapper>
  );
};

export default Documents;
