import { useEffect, useMemo } from "react";
import { LinkDestination, Products } from "../types";
import { useLinkToken } from "./useLinkToken";
import { useVerifyPlaidBankAccount } from "./useVerifyBankAccounts";

export const usePlaidBankAccount = (
  handlePlaidOnSuccess?: () => void,
  handlePlaidOnExit?: () => void,
  destination?: LinkDestination
) => {
  const product = useMemo(() => [Products.Auth], []);
  const { linkToken, isOAuthRedirect } = useLinkToken(product, destination);
  const { open, ready, waitingBankAccounts } = useVerifyPlaidBankAccount({
    linkToken,
    handlePlaidOnSuccess,
    handlePlaidOnExit,
    isOAuthRedirect,
  });

  useEffect(() => {
    if (ready && isOAuthRedirect) open();
  }, [ready, isOAuthRedirect, open]);

  return {
    open,
    ready,
    waitingBankAccounts,
  };
};
