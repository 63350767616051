import { CustomError, TransactionsResponse } from "../types";

interface Props {
  perPage?: string;
  afterCursor?: string;
  id?: string;
  signal?: AbortSignal;
}

const getData = async ({ id, perPage, afterCursor, signal }: Props) => {
  const path = `/ledger-transactions${id ? `/${id}` : ""}${
    perPage ? `?perPage=${perPage}` : ""
  }${afterCursor ? `&afterCursor=${afterCursor}` : ""}`;
  const response = await fetch(path, {
    method: "GET",
    signal: signal || null,
  });
  const res = await response.json();
  if (!response.ok) {
    CustomError.throw(res, res?.error || res?.message);
  }
  return res as TransactionsResponse;
};

export const Transactions = {
  getData,
};
