export interface LoginForm {
  username: string;
  password: string;
}

export interface LoginErrors extends LoginForm {
  form: string;
}

export interface LoginTouch {
  username: boolean;
  password: boolean;
}

export enum LOGIN_CUSTOM_ERRORS {
  UserNotConfirmed = "Your email have to be validate, please click here to do it.",
  NotAuthorizedException = "Incorrect username or password.",
  UserNotFoundException = "User does not exist.",
  DefaultError = "An Unexpected Error Occurred.",
}
