import { useVerifyPlaidBankAccount } from "../../hooks/useVerifyBankAccounts";
import { useState } from "react";
import { ExtendedAlertProps } from "../../types/attention";
import {
  AlertWrapperWithButton,
  CardIcon,
  BannerTitle,
  BannerDescription,
  StyledButton,
  LeftWrapper,
} from "../../styles/bankAccountsAlerts";

const ManualMicroDeposit = (props: ExtendedAlertProps) => {
  const { bankName, token } = props;
  const [show, setShow] = useState<boolean>(true);
  const onSuccess = () => {
    setShow(false);
  };
  const turnOffNotifications = () => {
    setShow(false);
  };
  const { open, ready, waitingBankAccounts, buttonText } =
    useVerifyPlaidBankAccount({
      linkToken: token,
      handlePlaidOnSuccess: onSuccess,
      id: props.id,
      turnOffNotifications,
    });

  if (!show) return <></>;

  return (
    <div>
      <AlertWrapperWithButton>
        <LeftWrapper>
          <CardIcon />
          <div>
            <BannerTitle>Bank Verification Required</BannerTitle>
            <BannerDescription>
              A micro deposit was sent to {bankName}; please verify
            </BannerDescription>
          </div>
        </LeftWrapper>
        <StyledButton
          onClick={() => open()}
          disabled={!ready || waitingBankAccounts}
        >
          {buttonText}
        </StyledButton>
      </AlertWrapperWithButton>
    </div>
  );
};

export default ManualMicroDeposit;
