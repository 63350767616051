export interface InitialData {
  username: string;
}

export interface InitialErrors extends InitialData {
  form: string;
}

export interface InitialTouch {
  username: boolean;
}

export enum SEND_EMAIL_CUSTOM_ERRORS {
  UserNotFoundException = "UserNotFoundException",
  DefaultError = "Something wrong happened sending the email",
}
