import { useEffect } from "react";
import { Auth, User } from "../utils";
import { useIsVerified } from "./useIsVerified";

export const useSocialMedia = () => {
  const { isVerified } = useIsVerified();
  useEffect(() => {
    const sendData = async () => {
      const googleAnalyticsClientId = Auth.getGoogleAnalyticsClientId();
      await User.sendSocialMedia(googleAnalyticsClientId);
    };
    if (isVerified) {
      sendData();
    }
  }, [isVerified]);
};
