import { NorhartWrapper } from "../../../components/NorhartWrapper";
import { PrivacyPolicy } from "../../../components/PrivacyPolicy";

const page = () => {
  return (
    <NorhartWrapper title="Privacy">
      <PrivacyPolicy />
    </NorhartWrapper>
  );
};

export default page;
