import {
  PaginationButtonsWrapper,
  PaginationLeftButton,
  PaginationRightButton,
} from "../styles/list";
import ChevronRight from "../assets/images/right-chevron.png";

interface Props {
  handleNext: () => void;
  handlePrevious: () => void;
  isLastPage: boolean;
  isFirstPage: boolean;
}

const PaginationButtons = ({
  isLastPage,
  isFirstPage,
  handleNext,
  handlePrevious,
}: Props) => {
  return (
    <PaginationButtonsWrapper>
      <PaginationLeftButton onClick={handlePrevious} disabled={isFirstPage}>
        <img
          src={ChevronRight}
          alt="light-white-arrow"
          style={{ objectFit: "contain", rotate: "180deg" }}
          width={25}
          height={25}
          className="object-fit-cover"
        />
      </PaginationLeftButton>
      <PaginationRightButton onClick={handleNext} disabled={isLastPage}>
        <img
          src={ChevronRight}
          style={{ objectFit: "contain" }}
          width={25}
          height={25}
          alt="light-white-arrow"
          className="object-fit-cover"
        />
      </PaginationRightButton>
    </PaginationButtonsWrapper>
  );
};

export default PaginationButtons;
