import classNames from "classnames";
import styled from "styled-components";

export const Loading = ({
  withoutMargin,
  width,
  height,
  color,
}: {
  withoutMargin?: boolean;
  width?: string;
  height?: string;
  color?: string;
}) => {
  return (
    <div
      className={classNames("d-flex justify-content-center h-100", {
        "my-3": !withoutMargin,
      })}
    >
      <Spinner
        className="spinner-border"
        role="status"
        style={{ width, height }}
        color={color}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

interface Props {
  color?: string;
}

const Spinner = styled.div<Props>`
  color: ${({ theme, color }) =>
    color ? color : theme.PRIMARY_COLOR} !important;
`;
