export interface ModalInitialData {
  account: Account;
  amount: string;
  product: Account;
}

export interface AddMoneyInitialData extends ModalInitialData {
  autoReInvest: boolean;
}

interface Account {
  name: string;
  id: string;
}

export enum Steps {
  Amount = "amount",
  Confirm = "confirm",
  Success = "success",
}

export const flowDirection = [Steps.Amount, Steps.Confirm, Steps.Success];

export interface DepositData {
  account: Account;
  amount: string;
}
