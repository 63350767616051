import { useCallback, useEffect, useState } from "react";
import { HandleClickProps } from "../types/downloadQueue";

interface Props<T> {
  fetchUrl: (input: T) => Promise<string | undefined>;
}

export const useDownloadQueue = <T>({ fetchUrl }: Props<T>) => {
  const [downloadQueue, setDownloadQueue] = useState<HandleClickProps<T>[]>([]);
  const [loading, setLoading] = useState<string[]>([]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleClick = useCallback(
    async ({ input, id }: HandleClickProps<T>) => {
      console.log("++", { id });
      setIsDownloading(true);
      const url = await fetchUrl(input);
      if (!url) return;
      const link = document.createElement("a");
      link.href = url;
      // link.setAttribute("target", "_blank")
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading((prev) => {
        const newQueue = [...prev];
        return newQueue.filter((loadingId) => loadingId !== id);
      });
      setDownloadQueue((prev) => {
        const newQueue = [...prev];
        return newQueue.filter((download) => download.id !== id);
      });
      setIsDownloading(false);
    },
    [fetchUrl]
  );

  useEffect(() => {
    console.log("++", { downloadQueue, isDownloading });
    if (!!downloadQueue.length && !isDownloading) {
      handleClick(downloadQueue[0]);
    }
  }, [downloadQueue, isDownloading, handleClick]);

  const updateQueue = (input: HandleClickProps<T>) => {
    setLoading((prev) => [...prev, input.id]);
    setDownloadQueue((prev) => [...prev, input]);
  };

  return { loading, updateQueue };
};
