import {
  BottomDiv,
  ConfirmButton,
  CustomStrong,
  GreyText,
  StyledDiv,
  StyledNumber,
  StyledText,
  Wrapper,
  Image,
} from "../../styles/popUp";
import { formatDate, formatNumbers, removeCommas } from "../../utils";
import NorhartModal from "../NorhartModal";
import { AddMoneyInitialData, Steps } from "../../types/payment-modal";
import CheckIcon from "../../assets/images/badge-check.png";

interface Props {
  show: boolean;
  onClose: (step: Steps) => void;
  step: Steps;
  displayData: AddMoneyInitialData;
}

const AddMoneySuccess = ({
  show,
  onClose,
  step,
  displayData: { amount, account, product },
}: Props) => {
  const handleCancel = () => {
    onClose(step);
  };

  return (
    <NorhartModal title="Confirmation" show={show} onClose={handleCancel}>
      <>
        <Wrapper>
          <StyledNumber>
            ${formatNumbers(Number(removeCommas(amount)), 1)}
          </StyledNumber>
          <StyledText>
            {product.name} Investment <br />
            {formatDate(new Date().toDateString())}
          </StyledText>
        </Wrapper>
        <BottomDiv>
          <div>
            <CustomStrong>
              Status: Completed
              <Image src={CheckIcon} width={20} height={20} alt="check-icon" />
            </CustomStrong>
            <GreyText>from: {account.name}</GreyText>
          </div>
          <StyledDiv>
            <CustomStrong>Total</CustomStrong>
            <CustomStrong>
              ${formatNumbers(Number(removeCommas(amount)), 1)}
            </CustomStrong>
          </StyledDiv>
          <BottomDiv>
            <ConfirmButton onClick={handleCancel} data-cy="add-money-success">
              Done
            </ConfirmButton>
          </BottomDiv>
        </BottomDiv>
      </>
    </NorhartModal>
  );
};

export default AddMoneySuccess;
