import styled from "styled-components";
import {
  Arrow,
  Description,
  Interest,
  ReactSelectItem,
  LeftWrapper,
  NumberWrapper,
  RightWrapper,
  TextWrapper,
  BasicItem,
} from "../styles/list";
import { Product } from "../types";
import { PromissoryNote } from "../types/payments";
import FlexAccountOptionLabel from "./FlexAccountOptionLabel";
export interface OptionLabelFlexAccount {
  id: string;
  name: string;
  amount: number;
  disable?: boolean;
}

export const OptionLabel = ({
  promissoryNote,
  context,
}: {
  promissoryNote: OptionLabelFlexAccount | Product;
  context: string;
}) => {
  if ((promissoryNote as PromissoryNote)?.term) {
    const { name, term, interest, id } = promissoryNote as PromissoryNote;

    if (context === "value") {
      return (
        <BasicItem>
          {name}
          <CustomInterest>{interest.toFixed(2)}%</CustomInterest>
        </BasicItem>
      );
    }

    return (
      <ReactSelectItem key={id} data-cy="promissory-note-option">
        <LeftWrapper>
          <TextWrapper>
            <div>{name}</div>
            <Description>{term} Month Term</Description>
          </TextWrapper>
        </LeftWrapper>
        <RightWrapper>
          <NumberWrapper>
            <CustomInterest>{interest.toFixed(2)}%</CustomInterest>
          </NumberWrapper>
          <Arrow />
        </RightWrapper>
      </ReactSelectItem>
    );
  }

  if (context === "value") {
    return <div>Flex Account</div>;
  }

  return (
    <FlexAccountOptionLabel
      disable={!!(promissoryNote as OptionLabelFlexAccount)?.disable}
      flexAccount={promissoryNote as OptionLabelFlexAccount}
    />
  );
};

const CustomInterest = styled(Interest)`
  background-color: ${({ theme }) => theme.GREEN};
  color: ${({ theme }) => theme.SECONDARY_COLOR};
`;
