import { useEffect, useState } from "react";
import { LedgerTransactionOutput, TransactionStatus } from "../types";

interface Props {
  transactions: LedgerTransactionOutput[];
}

export const useTransactionsList = ({ transactions }: Props) => {
  const [hasPendingTransactions, setHasPendingTransactions] =
    useState<boolean>(false);
  useEffect(() => {
    const checkPendingTransactions = () => {
      for (const transaction of transactions) {
        if (transaction.status === TransactionStatus.pending) {
          setHasPendingTransactions(true);
          break;
        }
      }
    };
    checkPendingTransactions();
  }, [transactions]);
  return { hasPendingTransactions };
};
