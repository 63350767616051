import { Dispatch, SetStateAction } from "react";

export const validateEmail = (email: string) => {
  return {
    isValid: !!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ),
  };
};

export const validateAmazonPassword = (email: string) => {
  return {
    isValid: !!String(email).match(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
    ),
  };
};

export const validateEqual = (firstValue: string, secondValue: string) => {
  return { isValid: firstValue === secondValue };
};

interface ValidateUsernameProps {
  name: string;
  value?: string;
  setError: Dispatch<SetStateAction<string>>;
  required?: boolean;
  validation: (value: string) => { isValid: boolean; message?: string };
  errorMessage?: string;
}

export const validateField = ({
  value,
  setError,
  required,
  validation,
  name,
  errorMessage,
}: ValidateUsernameProps) => {
  setError("");
  if (required && !value) {
    setError(`${name} is required`);
    return false;
  }
  if (!value) return true;
  const isValid = validation(value);
  if (!isValid)
    setError(errorMessage ? errorMessage : `Please insert a valid ${name}`);
  return isValid;
};

interface GetErrorMessage {
  name: string;
  value?: string;
  required?: boolean;
  validation?: (value: string) => { isValid: boolean; message?: string };
  message?: string;
  label?: string;
}

const getErrorMessage = ({
  required,
  value,
  name,
  validation,
  message,
  label,
}: GetErrorMessage) => {
  if (required && !value) return `${label || name} is required`;
  if (!value) return "";
  if (!validation) return "";
  const { isValid, message: validateMessage } = validation(value);
  if (!isValid)
    return (
      validateMessage || message || `Please insert a valid ${label || name}`
    );
  return "";
};

interface IsValidOnBlur {
  required?: boolean;
  validation?: (value: string) => { isValid: boolean; message?: string };
  message?: string;
  label?: string;
}

export const isValidOnBlur = (
  e:
    | React.FocusEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
        Element
      >
    | React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
  props: IsValidOnBlur
) => {
  const { name, value } = e.target;
  const error = getErrorMessage({ value, name, ...props });
  return { [name]: error };
};

export const isValidOnBlurReactSelect = (
  name: string,
  value: string | undefined,
  props: IsValidOnBlur
) => {
  const error = getErrorMessage({ value, name, ...props });
  return { [name]: error };
};

export const touchInputs = (
  inputTracker: string = "defaultForm",
  touchInputs: boolean = true
) => {
  const input = Array.from(
    document.querySelectorAll(`input[form="${inputTracker}"]`)
  );
  const select = Array.from(
    document.querySelectorAll(`select[form="${inputTracker}"]`)
  );
  const textArea = Array.from(
    document.querySelectorAll(`textArea[form="${inputTracker}"]`)
  );
  const inputs = [...input, ...select, ...textArea];
  const emptyAndRequired = inputs.map((node) => {
    const value = ((node as HTMLSelectElement) || HTMLInputElement).value;
    const isPlaceholder =
      node instanceof HTMLSelectElement
        ? node.selectedOptions[0]?.id === "placeholder"
        : false;
    const isRequired = ((node as HTMLInputElement) || HTMLSelectElement)
      .required;
    if (touchInputs) {
      (node as HTMLInputElement).focus();
      (node as HTMLInputElement).blur();
    }
    return (!value || isPlaceholder) && isRequired;
  });
  const hasEmptyRequired = !emptyAndRequired.every((x) => !x);
  return { hasEmptyRequired, findInputs: !!inputs.length };
};

export const validateFutureDate = (date: string) => {
  const now = new Date();
  const selectedDate = new Date(date);
  return { isValid: selectedDate.getTime() > now.getTime() };
};

export const repeatPasswordValidation = (value: string, password: string) =>
  validateEqual(password, value);
