import {
  Container,
  ContentHeroSpace,
  InvestIraRateText,
  InvestRateNumbers,
  InvestStatsText,
  InvestStatsTitle,
} from "../../../styles/adds";

const InvestIRAPromise = () => {
  return (
    <section className="container-fluid pt-5" style={{ background: "#e1e1e1" }}>
      <Container
        className="container pl-1 pr-1"
        style={{ paddingBottom: "100px" }}
      >
        <ContentHeroSpace className="hidden-xs" />
        <div
          className="container-fluid p-0 ms-3 me-3 pe-4"
          style={{ background: "#e1e1e1", color: "#111111" }}
        >
          <div className="row align-items-start justify-content-start">
            <div className="col-sm-12">
              <div
                className="mt-1"
                style={{
                  backgroundColor: "#51976f",
                  width: "100%",
                  height: "4px",
                }}
              ></div>
              <InvestStatsTitle className="mb-3">
                Elevate Your IRA With Our Promise
              </InvestStatsTitle>
              <InvestStatsText
                className="mb-0 mb-2"
                style={{ width: "100%" }}
              />
              <InvestStatsText
                className="mt-0 mb-3 mb-5"
                style={{ width: "100%", fontWeight: "600" }}
              />
              <div className="container ms-0 p-0">
                <div className="row">
                  <div className="col-sm-12">
                    <div>
                      <InvestRateNumbers className="invest-rates-numbers">
                        Effortless Transitions
                      </InvestRateNumbers>
                      <InvestIraRateText className="invest-ira-rates-text">
                        Our skilled team guides you seamlessly through the
                        rollover process, ensuring your switch to Norhart Invest
                        is smooth and straightforward.
                      </InvestIraRateText>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div>
                      <InvestRateNumbers className="invest-rates-numbers">
                        Top-Tier Returns
                      </InvestRateNumbers>
                      <InvestIraRateText className="invest-ira-rates-text">
                        Expect more from your money with competitive yields
                        designed to enhance your portfolio's performance, giving
                        you freedom and flexibility for your future.
                      </InvestIraRateText>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div>
                      <InvestRateNumbers className="invest-rates-numbers">
                        Smart Tax Benefits
                      </InvestRateNumbers>
                      <InvestIraRateText className="invest-ira-rates-text">
                        Enjoy substantial earnings with the added advantage of
                        tax efficiency, keeping more of your investment gains
                        working for you.
                      </InvestIraRateText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default InvestIRAPromise;
