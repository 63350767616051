import { useState, useCallback, useEffect, ChangeEvent } from "react";
import { Interest } from "../utils/interest";
import { removeCommas } from "../utils";

interface Props {
  initialTerm?: string;
  initialInterest?: string;
  initialAmount?: string;
  isLanding?: boolean;
}

export const useCalculatorSection = ({
  initialTerm = "0",
  initialInterest = "0",
  initialAmount = "0",
  isLanding,
}: Props) => {
  const [principal, setPrincipal] = useState(initialAmount);
  const [term, setTerm] = useState(initialTerm);
  const [earned, setEarned] = useState<string>();
  const [interest, setInterest] = useState(initialInterest);

  const updateEarned = useCallback(() => {
    const foundRate =
      !!isLanding || !interest ? Interest.termToInterest[term] : interest;
    const { earned } = Interest.calculate(principal, term, foundRate);
    setInterest(foundRate);
    setEarned(earned);
  }, [principal, term, interest, isLanding]);

  const onChangeAmount = (e?: ChangeEvent<HTMLInputElement>, value?: string) =>
    setPrincipal(removeCommas(e?.target.value || value || ""));
  const onChangeTerm = (
    e?: ChangeEvent<HTMLSelectElement>,
    value?: { term: string; interest: string }
  ) => {
    setInterest(value?.interest || "");
    setTerm(e?.target.value || value?.term || "");
  };

  useEffect(() => {
    updateEarned();
  }, [updateEarned]);

  return { principal, term, interest, earned, onChangeAmount, onChangeTerm };
};
