import React, { useState } from "react";
import classNames from "classnames";
import { Variant } from "../../../types";
import Select, { Props as ReactSelectProps } from "react-select";
import { InputWrapper } from "../../../styles/Inputs";
import styled from "styled-components";

export interface SelectProps extends ReactSelectProps {
  label?: string;
  variant?: Variant;
  error?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  placeHolder?: string;
  notShowLabel?: boolean;
  // options: string[][];
}

const ReactSelect = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    const [touched, setTouched] = useState<boolean>(false);
    const {
      label,
      variant,
      id,
      name,
      error = false,
      options,
      placeHolder,
      onFocus,
      notShowLabel,
      form,
      theme,
      ...rest
    } = props;

    const isFloating = variant === "floating";

    const labelComp = !!label && !notShowLabel && (
      <label className={classNames("selectLabel", { col: variant === "row" })}>
        <strong>{label}</strong>
      </label>
    );

    const selectProps = {
      // ref: ref as React.ForwardedRef<HTMLSelectElement>,
      form: form || "defaultForm",
      onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus) onFocus(e);
        setTouched(true);
      },
      name,
      placeHolder,
      id,
      options,
      ...rest,
    };

    // const selectComp = (
    //   <select {...selectProps}>
    //     {placeHolder ? (
    //       <option value={""} disabled>
    //         {placeHolder}
    //       </option>
    //     ) : (
    //       <option value={""} disabled>
    //         Choose....
    //       </option>
    //     )}

    //     {options.map((option, index) => {
    //       return (
    //         <option value={option[0]} key={option[0]}>
    //           {option[1]}
    //         </option>
    //       );
    //     })}
    //   </select>
    // );

    const selectComp = (
      <CustomSelects isInvalid={!!touched && !!error} {...selectProps} />
    );

    return (
      <InputWrapper
        className={classNames(
          { "form-floating": isFloating },
          { "row d-flex align-items-center": variant === "row" }
        )}
      >
        {isFloating ? (
          <>
            {selectComp}
            {labelComp}
          </>
        ) : (
          <>
            {labelComp}
            {selectComp}
          </>
        )}
        <div
          className="invalid-feedback"
          style={{
            visibility: `${touched && error ? "visible" : "hidden"}`,
            minHeight: "20px",
            display: "block",
          }}
        >
          {error}
        </div>
      </InputWrapper>
    );
  }
);

interface CustomSelectProps {
  isInvalid: boolean;
}

const CustomSelects = styled(Select)<CustomSelectProps>`
  .react-select__menu {
    z-index: 99999;
  }
  .react-select__option--is-selected {
    background-color: ${({ theme }) => theme.BANNER_BACKGROUND};
    color: ${({ theme }) => theme.INPUT_COLOR};
  }

  .react-select__option--is-focused:not(.react-select__option--is-selected) {
    background-color: ${({ theme }) => theme.BANNER_BACKGROUND};
  }

  --bs-body-color: ${({ theme }) => theme.INPUT_COLOR};
  --bs-body-bg: ${({ theme }) => theme.INPUT_BACKGROUND};
  --bs-border-color: ${({ theme }) => theme.LIST_BORDER_COLOR};
  --bs-secondary-bg: ${({ theme }) => theme.DISABLED_INPUT_BACKGROUND_COLOR};
  .react-select__menu-list {
    background-color: ${({ theme }) => theme.INPUT_BACKGROUND};
  }
  .react-select__group-heading {
    color: ${({ theme }) => theme.INPUT_COLOR};
  }
  .react-select__placeholder {
    color: ${({ theme }) => theme.INPUT_COLOR};
  }
  .react-select__single-value {
    color: ${({ theme }) => theme.INPUT_COLOR};
  }
  span {
    background-image: ${({ theme }) => `url(${theme.SELECT_ARROW})`}!important;
  }
  .react-select__control {
    border-color: ${({ theme, isInvalid }) =>
      isInvalid ? theme.PRIMARY_COLOR : theme.INPUT_BORDER_COLOR};
    box-shadow: none;
  }
  .react-select__control--is-focused:hover {
    border-color: ${({ theme }) => theme.INPUT_BORDER_COLOR};
  }
`;

export default ReactSelect;
