import { InitialData, InitialErrors, InitialTouch } from "./types";

export const initialData: InitialData = {
  username: "",
};

export const initialErrors: InitialErrors = {
  username: "",
  form: "",
};

export const initialTouch: InitialTouch = {
  username: false,
};
