import { Amplify } from "aws-amplify";

export default Amplify.configure({
  Auth: {
    region: "us-east-1",

    // Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID,

    // Amazon Cognito Web Client ID
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

    // // Manually set the authentication flow type.
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
    // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
    signUpVerificationMethod: "link", // 'code' | 'link'
  },
});
