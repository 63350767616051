import styled from "styled-components";
import { Link } from "react-router-dom";

export const Title = styled.div`
  font-size: 40px;
  font-weight: 600;
  padding: 0;
  margin-top: 30px;
  padding-bottom: 5px;
  color: #ffffff;
`;

export const SubTitle = styled.div`
  color: #ffffff;
  opacity: 0.6;
  font-weight: 300;
  font-size: 25px;
  margin-top: -10px;
  margin-bottom: 40px;
`;

export const TryAgainBtn = styled.button`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #1f3855;
  color: #ffffff;
  font-weight: bold;
  border: 0px;
  &:hover {
    color: white;
  }
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
  width: 100%;
`;

export const Container = styled.div`
  background: linear-gradient(#2477ce, #2477ce) !important;
  max-height: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
`;
export const EmojiImg = styled.img`
  width: 80%;
`;
export const FormWrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 100%;
`;

export const NorhartImage = styled.img`
  margin-top: 35px;
  height: 80px;
  width: 80px;
  @media (max-width: 500px) {
    height: 50px;
    width: 50px;
  }
  @media (max-width: 320px) {
    height: 40px;
    width: 40px;
  }
`;

export const MiddleBackground = styled.div`
  background: linear-gradient(#2477ce, #a2dbff);
  color: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const WrapperBotText = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
`;

export const VerticalBar = styled.span`
  padding-left: 5px;
  padding-right: 5px;
  color: #ffffff;
`;

export const HorizontalBar = styled.hr`
  border-color: #ffffff;
  opacity: 0.2;
  margin-top: 20px;
  margin-bottom: 10px;
  border-top: 3px solid rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(255, 255, 255, 1);
`;

export const MiddleLine = styled.div`
  border: 0;
  height: 0;
  border-top: 3px solid rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(255, 255, 255, 1);
  opacity: 1;
  margin-top: 0px;
  background-color: white;
`;

export const BottomContainer = styled.div`
  margin-top: 20px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

export const InputContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

export const InputCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledLink = styled(Link)`
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  text-decoration: none;
`;
export const StyledLinkBottom = styled(StyledLink)`
  font-size: 13px;
`;
export const NorhartWrapper = styled.div`
  margin-top: "5px";
`;
