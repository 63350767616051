import InvestCalculatorComponent from "../components/InvestCalculatorComponent";

const WelcomeInvestCalculatorSection = () => {
  return (
    <>
      <InvestCalculatorComponent
        backgroundColorOne="#e1e1e1"
        backgroundColorTwo="#e1e1e1"
        title="How Much Can You Make"
        subTitle="Select an amount to calculate your potential Norhart Invest return."
        textColor="#111111"
        isWelcome
        bottomText="#999999"
        inputBackgroundColor="#cccccc"
        inputTextColor="#555555"
        inputBorderColor="#cccccc"
        percentageTextColor="#555555"
      />
    </>
  );
};

export default WelcomeInvestCalculatorSection;
