import { convertToDBPhoneNumber, formatToPhone } from "../utils";

export interface UserData {
  phone_number?: string;
  date_of_birth: string;
  ip_address: string;
  email_address: string;
  name?: UserName;
  address?: UserAddress;
  id_number?: UserIDNumber;
  hasSigned?: boolean;
}

export class UserInitialData {
  firstName: string;
  phoneNumber: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  constructor(userData: UserData) {
    this.firstName =
      userData.name?.given_name + " " + userData.name?.family_name;
    this.phoneNumber = formatToPhone(userData.phone_number || "");
    this.email = userData.email_address;
    this.addressLine1 = userData.address?.street || "";
    this.addressLine2 = userData.address?.street2 || "";
    this.city = userData.address?.city || "";
    this.state = userData.address?.region || "";
    this.zipCode = userData.address?.postal_code || "";
  }
}

export class UpdateUserBody {
  phone_number?: string;
  address?: UserAddress;
  constructor(userData: UserInitialData) {
    this.phone_number = convertToDBPhoneNumber(userData.phoneNumber);
    this.address = {
      city: userData.city,
      street: userData.addressLine1,
      street2: userData.addressLine2,
      postal_code: userData.zipCode,
      region: userData.state,
    };
  }
}

export interface UserName {
  given_name: string;
  family_name: string;
}

export interface UserAddress {
  street?: string;
  street2?: string;
  city?: string;
  region?: string;
  postal_code?: string;
  country?: string;
}

export interface UserIDNumber {
  value: string;
  type: IDNumberType;
}

export declare enum IDNumberType {
  ArDni = "ar_dni",
  AuDriversLicense = "au_drivers_license",
  AuPassport = "au_passport",
  BrCpf = "br_cpf",
  CaSin = "ca_sin",
  ClRun = "cl_run",
  CnResidentCard = "cn_resident_card",
  CoNit = "co_nit",
  DkCpr = "dk_cpr",
  EgNationalId = "eg_national_id",
  EsDni = "es_dni",
  EsNie = "es_nie",
  HkHkid = "hk_hkid",
  InPan = "in_pan",
  ItCf = "it_cf",
  JoCivilId = "jo_civil_id",
  JpMyNumber = "jp_my_number",
  KeHudumaNamba = "ke_huduma_namba",
  KwCivilId = "kw_civil_id",
  MxCurp = "mx_curp",
  MxRfc = "mx_rfc",
  MyNric = "my_nric",
  NgNin = "ng_nin",
  NzDriversLicense = "nz_drivers_license",
  OmCivilId = "om_civil_id",
  PhPsn = "ph_psn",
  PlPesel = "pl_pesel",
  RoCnp = "ro_cnp",
  SaNationalId = "sa_national_id",
  SePin = "se_pin",
  SgNric = "sg_nric",
  TrTcKimlik = "tr_tc_kimlik",
  UsSsn = "us_ssn",
  UsSsnLast4 = "us_ssn_last_4",
  ZaSmartId = "za_smart_id",
}

export interface UserPreferences {
  darkModeOn?: boolean;
  dashboardPromissoryNotesCollapsed?: boolean;
  dashboardTransactionsCollapsed?: boolean;
  dashboardCashAccountCollapsed?: boolean;
  accountPersonalInformationCollapsed?: boolean;
  accountSecurityCollapsed?: boolean;
  accountFinancialsCollapsed?: boolean;
  accountBeneficiariesCollapsed?: boolean;
  accountBankAccountsCollapsed?: boolean;
}
