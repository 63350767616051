import { useProductWs } from "../../hooks";
import { useBankAccounts } from "../../hooks/useBankAccounts";
import { useFlexAccount } from "../../hooks/useFlexAccount";
import { usePaymentModal } from "../../hooks/usePaymentModal";
import {
  AddMoneyInitialData,
  flowDirection,
  Steps,
} from "../../types/payment-modal";
import AddMoneyAmount from "./AddMoneyAmount";
import AddMoneyConfirm from "./AddMoneyConfirm";
import AddMoneySuccess from "./AddMoneySuccess";
import styled from "styled-components";

interface Props {
  startFlow: boolean;
  onClose: () => void;
  isAddMoney?: boolean;
  handleBankClose?: () => void;
  needDefaultValue?: boolean;
  ready: boolean;
  waitingBankAccounts: boolean;
  openPlaidPopUp: () => void;
}

const initialData = {
  account: { name: "", id: "" },
  amount: "",
  product: { name: "", id: "" },
  autoReInvest: false,
};

const AddMoneyModal = ({
  startFlow,
  onClose,
  isAddMoney,
  handleBankClose,
  needDefaultValue,
  waitingBankAccounts,
  openPlaidPopUp,
  ready,
}: Props) => {
  const {
    showAmount,
    showConfirm,
    showSuccess,
    attributes,
    handleClose,
    nextStep,
    handleSubmit,
    paymentId,
    updateSignatureUrl,
    signatureUrl,
    onSubmitAmount,
  } = usePaymentModal({ initialData, flowDirection, onClose, startFlow });
  const { loading: loadingProducts, products } = useProductWs();
  const { bankAccounts, loading: bankAccountsLoading } = useBankAccounts();
  const {
    data: flexAccount,
    loading: flexAccountLoading,
    refetchData: refetchFlexAccount,
  } = useFlexAccount();

  return (
    <Wrapper>
      <AddMoneyAmount
        show={showAmount}
        onClose={handleClose}
        step={Steps.Amount}
        nextStep={nextStep}
        attributes={attributes as AddMoneyInitialData}
        loadingProducts={loadingProducts}
        products={products}
        bankAccounts={bankAccounts}
        bankAccountsLoading={bankAccountsLoading}
        isAddMoney={isAddMoney}
        flexAccount={flexAccount}
        flexAccountLoading={flexAccountLoading}
        onSubmitAmount={onSubmitAmount}
        handleBankClose={handleBankClose}
        needDefaultValue={needDefaultValue}
        ready={ready}
        waitingBankAccounts={waitingBankAccounts}
        openPlaidPopUp={openPlaidPopUp}
      />
      <AddMoneyConfirm
        show={showConfirm}
        onClose={handleClose}
        step={Steps.Confirm}
        nextStep={nextStep}
        displayData={attributes as AddMoneyInitialData}
        handleSubmit={handleSubmit}
        paymentId={paymentId}
        signatureUrl={signatureUrl}
        updateSignatureUrl={updateSignatureUrl}
        products={products}
        refetchFlexAccount={refetchFlexAccount}
      />
      <AddMoneySuccess
        show={showSuccess}
        onClose={handleClose}
        step={Steps.Success}
        displayData={attributes as AddMoneyInitialData}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
`;

export default AddMoneyModal;
