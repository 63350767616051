import { FormEvent, useState } from "react";
import { touchInputs, isEmptyObject, formatMoneyInput } from "../utils";
import { useSubmitWithEnter } from "./useSubmitWithEnter";

interface Props<T> {
  initialFields: T;
  initialErrors: Record<keyof T, string> & { form: string };
  initialTouch: Record<keyof T, boolean>;
  customErrors: Record<string, string> & { DefaultError: string };
  inputTracker?: string;
}

export const useForm = <T>({
  initialFields,
  initialErrors,
  initialTouch,
  customErrors,
  inputTracker,
}: Props<T>) => {
  const [fields, setFields] = useState<typeof initialFields>(initialFields);
  const [errors, setErrors] = useState<typeof initialErrors>(initialErrors);
  const [touch, setTouch] = useState<typeof initialTouch>(initialTouch);
  const [submitLoading, setSubmitLoading] = useState<boolean>();

  const submitWithEnter = useSubmitWithEnter();
  const handleChange = (
    args: Record<string, any>,
    setState: React.Dispatch<React.SetStateAction<any>>
  ) => {
    setState((prev: any) => {
      return { ...prev, ...args };
    });
  };

  const handleFields = (args: Record<string, any>) => {
    handleChange(args, setFields);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    props: { isMoney: boolean }
  ) => {
    const { value, name, checked, type } = e.target;
    if (props.isMoney) {
      const hasMoreThanOneDots = (value.match(/\./g) || []).length > 1;
      setFields((prev: any) => {
        return {
          ...prev,
          ...(!hasMoreThanOneDots && {
            [name]: formatMoneyInput(value, prev[name]),
          }),
        };
      });
      return;
    }
    const isCheckbox = type === "checkbox";
    handleFields({ [name]: isCheckbox ? JSON.stringify(checked) : value });
  };

  const handleTouch = (args: Record<string, boolean>) => {
    handleChange(args, setTouch);
  };
  const handleErrors = (args: Record<string, string>) => {
    handleChange(args, setErrors);
  };

  const onSubmit = async (
    e: FormEvent<HTMLFormElement>,
    handleSubmit: (...args: any[]) => Promise<void>
  ) => {
    e.preventDefault();
    setSubmitLoading(true);
    const resetError = { ...errors, form: "" };
    setErrors((prev) => {
      return { ...prev, form: "" };
    });

    if (!isEmptyObject(resetError)) {
      setSubmitLoading(false);
      return;
    }

    if (!Object.values(touch).every((x) => x)) {
      const { hasEmptyRequired } = touchInputs(inputTracker);
      if (hasEmptyRequired) {
        setSubmitLoading(false);
        return;
      }
    }

    try {
      await handleSubmit();
    } catch (e: any) {
      const { message, code } = e;
      const form =
        Object.values(customErrors).includes(message) ||
        Object.values(customErrors).includes(code)
          ? message
          : customErrors.DefaultError;
      setErrors({ ...errors, form });
    }
    setSubmitLoading(false);
  };

  const resetForm = () => {
    setFields(initialFields);
    setErrors(initialErrors);
    setTouch(initialTouch);
  };

  const resetTouch = () => {
    setTouch(initialTouch);
  };

  return {
    fields,
    setFields,
    errors,
    setErrors,
    touch,
    submitLoading,
    setSubmitLoading,
    setTouch,
    handleFields,
    handleTouch,
    handleErrors,
    onSubmit,
    resetForm,
    submitWithEnter,
    resetTouch,
    handleInputChange,
  };
};
