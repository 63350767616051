import { FunctionComponent } from "react";
import { Loading } from "./Loading";
import styled from "styled-components";

export const WorkingOnIt: FunctionComponent = () => {
  return (
    <Container className="container">
      <Title>Wait Just A Moment</Title>
      <HeroSubtitle style={{ opacity: "0.7" }}>
        Our team is building your dashboard
      </HeroSubtitle>
      <div>
        <Loading />
      </div>
      {/* <div className="m-2 d-flex justify-content-center align-items-center">
        <ImageBackground>
          <video
            src={require("../assets/videos/loading_desktop_darkblue.mp4")}
            autoPlay
            muted
            loop
            width={424}
            height={245}
            style={{
              maxWidth: "100%",
              height: "auto",
              verticalAlign: "bottom",
            }}
          />
        </ImageBackground>
      </div> */}
    </Container>
  );
};

const Container = styled.div`
  padding-top: 75px;
  height: calc(100vh - 50px);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

// const ImageBackground = styled.div`
//   border-radius: 8px;
//   border: transparent;
//   background-color: #e0ecf9;
//   color: #2f5265;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

const Title = styled.h3`
  margin-left: 0px !important;
  margin-right: 0px !important;
  text-align: center;
  font-size: 20px;
  line-height: 31px;
  font-weight: 800;
  padding: 0;
`;
const HeroSubtitle = styled.h2`
  margin-left: 75px !important;
  margin-right: 75px !important;
  text-align: center !important;
  font-size: 26px !important;
  line-height: 36px;
  font-weight: 300;
  padding-top: 0;
  padding-bottom: 5px;
`;
