import { useCallback } from "react";
import { FetchFunctionParams } from "../types/pagination";
import { Transactions } from "../utils";
import { useCachedPagination } from "./useCachedPagination";
import { CacheKeys } from "../types";

export const useTransactions = (
  cacheId: string,
  perPage: number,
  id?: string
) => {
  const fetchFunction = useCallback(
    ({ perPage, afterCursor, id, signal }: FetchFunctionParams) =>
      Transactions.getData({
        perPage,
        afterCursor,
        id,
        signal,
      }),
    []
  );
  const { data, loading, handleNext, handlePrevious, isFirstPage, isLastPage } =
    useCachedPagination(
      cacheId,
      perPage,
      fetchFunction,
      CacheKeys.transactions,
      id
    );
  return {
    data: data,
    loading,
    handleNext,
    handlePrevious,
    isFirstPage,
    isLastPage,
  };
};
