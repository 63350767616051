import { useVerifyPlaidBankAccount } from "../../../hooks/useVerifyBankAccounts";
import { VerifyButton as VerificationButton } from "../../../styles";
interface Props {
  linkToken: string;
  id: string;
  handleDelete: () => Promise<void>;
}

const VerifyButton = ({ linkToken, id, handleDelete }: Props) => {
  const { open, ready, waitingBankAccounts, buttonText } =
    useVerifyPlaidBankAccount({ linkToken, id, handleDelete });
  return (
    <VerificationButton
      onClick={() => open()}
      disabled={!ready || waitingBankAccounts}
      type="button"
    >
      {buttonText}
    </VerificationButton>
  );
};

export default VerifyButton;
