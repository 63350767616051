import HeroVideoComponent from "../components/HeroVideoComponent";
const videoFile = require("../../../assets/videos/usa.mp4");

const EarthVideoSection = () => {
  return (
    <HeroVideoComponent
      title="Invest From Anywhere In The USA*"
      subtitle="Our investor presence spans from coast to coast. Our commitment to serving investors and venturesome minds remains unwavering."
      buttonName2="Invest Now"
      buttonUrl2="/signup"
      videoID="1"
      videoFile={videoFile}
      buttonName="Book A Call"
      buttonUrl="https://appt.norhart.com/widget/booking/BbRATKu2ujKE6T4Mp8dW"
    />
  );
};

export default EarthVideoSection;
