import React, { TextareaHTMLAttributes, useState } from "react";
import classNames from "classnames";
import { Variant } from "../../../types";

export interface TextAreaFieldProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  variant?: Variant;
  isMoney?: boolean;
  error?: string;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  notShowLabel?: boolean;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  (props, ref) => {
    const [touched, setTouched] = useState<boolean>(false);
    const {
      label,
      variant,
      id,
      name,
      isMoney,
      error = false,
      onFocus,
      notShowLabel,
      form,
      ...rest
    } = props;

    const isFloating = variant === "floating";
    const htmlFor = id ?? name;

    const inputProps = {
      className: classNames(
        "form-control",
        {
          "is-invalid": touched && error,
          "rounded-0 rounded-end": isMoney && isFloating,
        },
        { "col px-4": variant === "row" },
        "form-control-disabled"
      ),
      ref: ref as React.ForwardedRef<HTMLTextAreaElement>,
      id: htmlFor,
      form: form || "defaultForm",
      onFocus: (e: React.FocusEvent<HTMLTextAreaElement>) => {
        if (onFocus) onFocus(e);
        setTouched(true);
      },
      name,
      ...rest,
    };

    if (variant === "floating" && !label) {
      console.warn("Floating input requires a label");
    }

    const labelComp = !!label && !notShowLabel && (
      <label
        htmlFor={htmlFor}
        className={classNames("inputLabel", { col: variant === "row" })}
      >
        <strong>{label}</strong>
      </label>
    );
    const inputComp = <textarea {...inputProps} />;

    const inputWrapped =
      isMoney && !isFloating ? (
        <div className="input-group">
          <span className="input-group-text">$</span>
          {inputComp}
        </div>
      ) : (
        inputComp
      );

    const floatingComp = () => {
      if (isFloating) {
        return (
          <>
            {inputWrapped}
            {labelComp}
          </>
        );
      }
      return (
        <div
          className={classNames({
            "row d-flex align-items-center": variant === "row",
          })}
        >
          {labelComp}
          {inputWrapped}
        </div>
      );
    };

    if (isMoney && isFloating) {
      return (
        <div className="input-group">
          <span className="input-group-text">$</span>
          <div className="form-floating flex-fill">{floatingComp()}</div>
        </div>
      );
    }
    return (
      <div
        className={classNames({
          "form-floating": isFloating,
        })}
      >
        {floatingComp()}
        {touched && error && (
          <div
            className="invalid-feedback d-flex align-items-center"
            style={{ display: "block" }}
          >
            {error}
          </div>
        )}
      </div>
    );
  }
);

export default TextArea;
