import { Button, Modal } from "react-bootstrap";
import { useUserData } from "../hooks/useUserData";
import styled from "styled-components";
interface Props {
  show: boolean;
  onClose: () => void;
}

export const VipNotification = ({ show, onClose }: Props) => {
  const { data } = useUserData();
  return (
    <StyledModal show={show} centered>
      <AlertWrapper className="alert alert-success text-start mb-0 w-100">
        <h4 className="alert-heading text-left">
          Most Impressive,{data ? " " + data.name?.given_name : ""}!
        </h4>
        <p>
          We are delighted you want to invest such a substantial amount! As a
          Norhart VIP, you qualify for our white glove concierge service.
        </p>
        <hr />
        <p className="mb-0">
          Please get in touch with our CFO, Timothy Libertini, directly to
          discuss investing opportunities at (651) 212-5087. In the meantime,
          please invest less than $250K in the app to get your account
          kickstarted!
        </p>
        <Modal.Footer className="pb-0 d-flex justify-content-center">
          <Button variant="success" onClick={onClose}>
            Continue
          </Button>
        </Modal.Footer>
      </AlertWrapper>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  margin-top: 65px;
  & .modal-content {
    margin: 40px;
  }
  @media (max-width: 575px) {
    margin-top: 50px;
  }
  @media (max-width: 500px) {
    margin-top: 35px;
  }
  @media (max-width: 450px) {
    margin-top: 15px;
  }
  @media (max-width: 335px) {
    margin-top: 0px;
  }
`;

const AlertWrapper = styled.div`
  padding: 35px;
  @media (max-width: 500px) {
    padding: 20px;
  }
`;
