export interface InitialData {
  password: string;
  repeatPassword: string;
}

export interface InitialErrors extends InitialData {
  form: string;
}

export interface InitialTouch {
  password: boolean;
  repeatPassword: boolean;
}

export enum RESET_PASSWORD_CUSTOM_ERRORS {
  UserNotFoundException = "UserNotFoundException",
  DefaultError = "Something wrong happened resetting your password",
}
