import { BeneficiariesInput } from "../../types/beneficiaries";
import { UserInitialData } from "../../types/users";
import { UserErrors, UserTouch } from "./types";

export const initialData: UserInitialData = {
  firstName: "",
  phoneNumber: "",
  email: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zipCode: "",
};
export const initialErrors: UserErrors = {
  firstName: "",
  phoneNumber: "",
  email: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zipCode: "",
  form: "",
};
export const initialTouch: UserTouch = {
  firstName: false,
  phoneNumber: false,
  email: false,
  addressLine1: false,
  addressLine2: false,
  city: false,
  state: false,
  zipCode: false,
};

export const changePasswordInitialData = {
  currentPassword: "",
  newPassword: "",
  repeatPassword: "",
};
export const changePasswordInitialErrors = {
  currentPassword: "",
  newPassword: "",
  repeatPassword: "",
  form: "",
};
export const changePasswordInitialTouch = {
  currentPassword: false,
  newPassword: false,
  repeatPassword: false,
};

export const financialInitialData = {
  income: "",
  accredited: "",
  netAssets: "",
};
export const financialInitialErrors = {
  income: "",
  accredited: "",
  netAssets: "",
  form: "",
};
export const financialInitialTouch = {
  income: false,
  accredited: false,
  netAssets: false,
};

export const initialBeneficiariesInitialData: BeneficiariesInput = {
  givenName: "",
  familyName: "",
  email: "",
  dateOfBirth: "",
};
export const beneficiariesInitialErrors = {
  givenName: "",
  familyName: "",
  email: "",
  dateOfBirth: "",
  form: "",
};
export const beneficiariesInitialTouch = {
  givenName: false,
  familyName: false,
  email: false,
  dateOfBirth: false,
};

export const securityInputTracker = "security";
export const personalFormInputTracker = "personalForm";
export const financialFormInputTracker = "financialForm";
export const beneficiariesFormInputTracker = "beneficiariesForm";
