import { MappedDashboardInfo } from "../../../types/dashboard";
import DesktopDashboardInfo from "./DesktopDashboardInfo";
import MobileDashboardInfo from "./MobileDashboardInfo";

interface Props {
  data: MappedDashboardInfo;
}

const InvestData = ({ data }: Props) => {
  return (
    <>
      <MobileDashboardInfo data={data} />
      <DesktopDashboardInfo data={data} />
    </>
  );
};

export default InvestData;
