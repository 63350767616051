import styled from "styled-components";
import { ReactComponent as Add } from "../assets/icons/add.svg";

interface Props {
  disabled?: boolean;
  handleClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

const AddButton = ({ disabled, handleClick }: Props) => {
  return (
    <TransparentButton type="submit" disabled={disabled} onClick={handleClick}>
      <Button />
    </TransparentButton>
  );
};

const TransparentButton = styled.button`
  border: transparent;
  background-color: transparent;
  padding: 0px;
  margin: 0px;
  margin-bottom: 5px;
`;

const Button = styled(Add)`
  height: 25px;
  width: 25px;
  cursor: pointer;
  fill: ${({ theme }) => theme.PRIMARY_COLOR};
  @media (max-width: 400px) {
    width: 28px;
  }
`;

export default AddButton;
