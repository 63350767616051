import { FunctionComponent } from "react";
import {
  ContentHeroTitle,
  ContentHeroSubtitle,
  InvestCalculateTitle,
  InputSectionWrapper,
  Label,
  InvestInputRate,
  InvestEarnText,
  InvestCalculateText,
  CalculatorInputWrapper,
  Container,
  InputGroup,
  BottomInvestCalculatorText,
} from "../../../styles/adds";
import { useCalculatorSection } from "../../../hooks/useCalculatorSection";
import { formatInteger } from "../../../utils/currency";
import { MINIMUM_AMOUNT } from "../../addMoney/constants";

const LANDING_INITIAL_TERM = "60";

/** props */
interface Props {
  backgroundColorOne: string;
  backgroundColorTwo: string;
  Icon?: any;
  title: string;
  subTitle: string;
  textColor: string;
  isWelcome?: boolean;
  bottomText: string;
  inputBackgroundColor: string;
  inputTextColor: string;
  inputBorderColor: string;
  percentageTextColor: string;
}

const InvestCalculatorComponent: FunctionComponent<Props> = ({
  backgroundColorOne,
  backgroundColorTwo,
  Icon,
  title,
  subTitle,
  textColor,
  isWelcome,
  inputBackgroundColor,
  inputBorderColor,
  inputTextColor,
  bottomText,
  percentageTextColor,
}) => {
  const { principal, term, interest, earned, onChangeAmount, onChangeTerm } =
    useCalculatorSection({
      initialTerm: LANDING_INITIAL_TERM,
      initialAmount: MINIMUM_AMOUNT.toString(),
      isLanding: true,
    });

  return (
    <section
      className="container-fluid pt-5 pb-5"
      style={{
        background: `linear-gradient(${backgroundColorOne} 30%, ${backgroundColorTwo} 70%)`,
      }}
    >
      <Container className="container pl-3 pr-3 text-center">
        <div className="container pb-5"></div>
        {Icon && (
          <div className="text-center pb-3">
            <img src={Icon} width={85} height={85} alt="norhart-logo" />
          </div>
        )}

        <ContentHeroTitle className="mt-4" style={{ color: `${textColor}` }}>
          {title}
        </ContentHeroTitle>
        <ContentHeroSubtitle
          className="mb-0"
          style={{ color: `${textColor}`, opacity: "0.8" }}
        >
          {subTitle}
        </ContentHeroSubtitle>

        {/* CALCULATE LINE BREAK TOP */}
        <div className="text-center pt-4">
          <hr style={{ color: "white" }} />
        </div>

        {/* CALCULATE INPUTS */}
        {!isWelcome && (
          <InvestCalculateTitle
            className="text-center pb-0"
            style={{ color: `${bottomText}`, opacity: "0.8" }}
          >
            Calculate
          </InvestCalculateTitle>
        )}

        <div className="container mt-4 text-black">
          <InputSectionWrapper>
            <CalculatorInputWrapper>
              <InputGroup
                style={{ color: `${inputTextColor}`, fontWeight: "bold" }}
              >
                <div className="form-floating">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Amount"
                    id="amount"
                    name="amount"
                    style={{
                      borderRadius: "4px 4px 4px 4px",
                      backgroundColor: `${inputBackgroundColor}`,
                      color: `${inputTextColor}`,
                      border: `${inputBorderColor}`,
                      fontWeight: "bold",
                    }}
                    value={formatInteger(principal)}
                    onChange={onChangeAmount}
                    maxLength={9}
                  />

                  <Label
                    color={inputTextColor}
                    className="d-none d-md-block d-lg-block d-xl-block"
                    htmlFor="amount"
                  >
                    Amount (USD)
                  </Label>
                  <Label
                    className="d-md-none d-lg-none d-xl-none"
                    htmlFor="amount"
                  >
                    Amount
                  </Label>
                </div>
              </InputGroup>
            </CalculatorInputWrapper>

            <div className="m-1"></div>

            <CalculatorInputWrapper>
              <InputGroup
                className="form-floating invest-input-term"
                style={{
                  color: `${inputTextColor}`,
                  fontWeight: "bold",
                  width: "100%",
                }}
              >
                <select
                  className="form-select invest-input-term"
                  name="term"
                  style={{
                    backgroundColor: `${inputBackgroundColor}`,
                    color: `${inputTextColor}`,
                    border: `${inputBorderColor}`,
                    fontWeight: "bold",
                    width: "100%",
                  }}
                  value={term}
                  onChange={onChangeTerm}
                >
                  {/* <option value="flexible">Flexible</option> */}
                  <option value="6">6 months</option>
                  <option value="9">9 months</option>
                  <option value="12">12 months</option>
                  <option value="18">18 months</option>
                  <option value="24">24 months</option>
                  <option value="36">36 months</option>
                  <option value="60">60 months</option>
                </select>
                <Label color={inputTextColor} htmlFor="term">
                  Term
                </Label>
              </InputGroup>
            </CalculatorInputWrapper>

            <div className="m-1"></div>

            <CalculatorInputWrapper>
              <InvestInputRate
                className="form-floating invest-input-rate"
                style={{ color: "#ffffff", fontWeight: "bold" }}
              >
                <div
                  style={{
                    backgroundColor: "transparent",
                    color: `${percentageTextColor}`,
                    fontWeight: "bold",
                    fontSize: "30px",
                  }}
                >
                  {interest}%
                  <span
                    style={{ fontSize: "22px", float: "left", opacity: "0.7" }}
                  >
                    APY
                  </span>
                </div>
              </InvestInputRate>
            </CalculatorInputWrapper>
          </InputSectionWrapper>
        </div>

        {/* CALCULATE LINE BREAK BOTTOM */}
        <div className="text-center mb-2">
          <hr style={{ color: "white" }} />
        </div>

        {!isWelcome && (
          <InvestEarnText className="text-center">Earn</InvestEarnText>
        )}

        {/* CALCULATE NUMBER */}
        <InvestCalculateText color={textColor} className="text-center">
          {earned}
          <span style={{ fontSize: "16px", color: `${bottomText}` }}>USD</span>
        </InvestCalculateText>

        <BottomInvestCalculatorText color={bottomText} className="text-center">
          {term === "flexible" ? "Paid Annually" : "Paid At End Of Term"}
        </BottomInvestCalculatorText>
      </Container>
    </section>
  );
};

export default InvestCalculatorComponent;
