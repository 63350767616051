import HeroBanner from "./sections/heroBanner";
import InfluencerLanding from "../../components/InfluencerLanding";

export const Hutch = () => {
  return (
    <InfluencerLanding>
      <HeroBanner />
    </InfluencerLanding>
  );
};
