export interface DashboardInfo {
  maturedPromissoryNotesNumbers: InterestAndInvestments;
  immaturePromissoryNotesNumbers: InterestAndInvestments;
  allPromissoryNotesNumbers: InterestAndInvestments;
  upcomingMaturityDate: string | null;
  totalInterestsPerMonth: InterestsPerMonth<number>[];
}

export interface InterestAndInvestments {
  totalInvestments: number;
  totalInterests: number;
}

export interface MappedDashboardInfo {
  investments: BannerBoxData;
  earned: BannerBoxData;
  upcomingMaturityDate: string;
  totalInterestsPerMonth: InterestsPerMonth<string>[];
}

export interface InterestsPerMonth<T> {
  totalInterest: T;
  monthName: string;
  year: number;
}

export interface BannerBoxData {
  active: number | string;
  matured: number | string;
  all: number | string;
}

export enum BannerBoxOptions {
  active = "active",
  matured = "matured",
  all = "all",
}
