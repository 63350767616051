import { BannerBoxData } from "../../../types";
import { useBannerBox } from "../../../hooks/useBannerBox";
import { ContentWrapper, Title, TitleWrapper } from "../styles/bannerBox";
import BannerBoxDropdown from "./BannerBoxDropdown";
import { formatNumbers } from "../../../utils";
interface BannerBoxProps {
  title: string;
  value: string | BannerBoxData;
  isMoney?: boolean;
}

const BannerBox = ({ title, value, isMoney }: BannerBoxProps) => {
  const {
    displayValue,
    handleChange,
    normalizedButtonText,
    isObject,
    displayData,
  } = useBannerBox(value);
  return (
    <>
      <TitleWrapper>
        {isObject ? (
          <BannerBoxDropdown
            displayValue={displayValue}
            handleChange={handleChange}
            normalizedButtonText={normalizedButtonText}
          />
        ) : (
          <div></div>
        )}
        <Title>{title}</Title>
      </TitleWrapper>
      <ContentWrapper>
        <div className="fs-1 fw-bold text-center text-sm-end">
          {isMoney ? "$" + formatNumbers(displayData, 100, 2) : displayData}
        </div>
      </ContentWrapper>
    </>
  );
};

export default BannerBox;
