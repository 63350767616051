import Signup from "./components/Signup";
import { DarkWrapperAuth } from "../../components/DarkWrapperAuth";

const CreateAccount = () => {
  return (
    <DarkWrapperAuth pageName="signup">
      <Signup darkMode />
    </DarkWrapperAuth>
  );
};

export default CreateAccount;
