import { useState, useEffect, useCallback } from "react";
import { Auth } from "../utils";
import {
  CustomCognitoAttributes,
  IdentityVerified,
} from "../types/cognito-attributes";

export const useIsVerified = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVerified, setIsVerified] = useState<IdentityVerified>();
  const verify = useCallback(async () => {
    const attributes = await Auth.getUserAttributes();
    const verified = attributes[
      CustomCognitoAttributes.identity_verified
    ] as IdentityVerified;
    setIsVerified(verified);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    verify();
  }, [verify]);

  return { isLoading, isVerified, verify };
};
