import { AlertProps } from "../../types/attention";
import WarningAlert from "../WarningAlert";

const AutomatedMicroDeposit = ({ bankName, last4 }: AlertProps) => {
  return (
    <div>
      <WarningAlert
        title="Verification in process"
        description={`Plaid verification for ${bankName} ${last4} is in process and will
              be completed in 1-2 days`}
      />
    </div>
  );
};

export default AutomatedMicroDeposit;
