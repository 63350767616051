import { Modal } from "react-bootstrap";
import styled from "styled-components";
interface Props {
  show: boolean;
  onClose: () => void;
}

export const WithdrawAlert = ({ show, onClose }: Props) => {
  return (
    <Modal show={show} centered onHide={onClose}>
      <AlertWrapper className="alert text-start mb-0 w-100">
        <Modal.Header>
          <StyledTitle className="alert-heading text-left">
            Non-sufficient funds (NSF) for withdrawal
          </StyledTitle>
        </Modal.Header>
        <Modal.Body>
          <StyledParagraph>
            We regret to inform you that your account balance is currently
            insufficient for withdrawal. Please ensure you have a minimum
            balance before attempting to withdraw funds. If you have any
            questions or need assistance, please feel free to reach out to our
            support team.
          </StyledParagraph>
        </Modal.Body>
        <Modal.Footer className="pb-0 d-flex justify-content-center">
          <StyledButton onClick={onClose}>Continue</StyledButton>
        </Modal.Footer>
      </AlertWrapper>
    </Modal>
  );
};

const StyledTitle = styled(Modal.Title)`
  @media (max-width: 380px) {
    font-size: 20px;
  }
  @media (max-width: 330px) {
    font-size: 18px;
  }
  @media (max-width: 280px) {
    font-size: 16px;
  }
`;

const StyledParagraph = styled.p`
  @media (max-width: 380px) {
    font-size: 15px;
  }
  @media (max-width: 330px) {
    font-size: 14px;
  }
  @media (max-width: 280px) {
    font-size: 13px;
  }
`;

const StyledButton = styled.button`
  color: white;
  background-color: #000000;
  border: #000000;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 5px;
  @media (max-width: 280px) {
    font-size: 13px;
  }
`;

const AlertWrapper = styled.div`
  padding: 35px;
  background-color: #e7e7e7;
  color: #3c3c3c;
  @media (max-width: 500px) {
    padding: 20px;
  }
`;
