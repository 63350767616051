import { Link } from "react-router-dom";
import styled from "styled-components";

const LandingFooter = () => {
  return (
    <BottomContainer>
      <InputContainer>
        <InputCenter>
          <div className="col-sm-6 ">
            <HorizontalBar />
            <div>
              <StyledLinkBottom
                as={"a"}
                href="https://www.norhart.com/legal/privacy/"
                target="_blank"
              >
                Privacy
              </StyledLinkBottom>
              <VerticalBar>|</VerticalBar>
              <StyledLinkBottom
                as={"a"}
                href="https://cdn.norhart.com/2023-06-30_Terms_of_Service.pdf"
                target="_blank"
              >
                Terms
              </StyledLinkBottom>
              <VerticalBar>|</VerticalBar>
              <StyledLinkBottom
                as={"a"}
                href="https://www.norhart.com/security/"
                target="_blank"
              >
                Security
              </StyledLinkBottom>
            </div>
            <NorhartWrapper>
              <StyledLinkBottom as={"span"}>
                © Norhart Invest LLC a{" "}
                <a
                  style={{ color: "white", fontWeight: "600" }}
                  href="https://www.norhart.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Norhart
                </a>{" "}
                company
              </StyledLinkBottom>
            </NorhartWrapper>
          </div>
        </InputCenter>
      </InputContainer>
    </BottomContainer>
  );
};

const VerticalBar = styled.span`
  padding-left: 5px;
  padding-right: 5px;
  color: #ffffff;
`;

const HorizontalBar = styled.hr`
  border-color: #ffffff;
  opacity: 0.2;
  margin-top: 0px;
  margin-bottom: 10px;
  border-top: 3px solid rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(255, 255, 255, 1);
`;

const BottomContainer = styled.div`
  margin-top: 20px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

const InputContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

const InputCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: auto;
`;
const StyledLink = styled(Link)`
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  text-decoration: none;
`;
const StyledLinkBottom = styled(StyledLink)`
  font-size: 13px;
`;
const NorhartWrapper = styled.div`
  margin-top: "5px";
`;

export default LandingFooter;
