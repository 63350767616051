import { ListGroup } from "react-bootstrap";
import styled from "styled-components";

export const Item = styled(ListGroup.Item)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 14px;
  color: grey;
`;

export const Description = styled.div`
  font-size: 18px;
  color: #989797;
`;

export const DetailBoldText = styled.div`
  font-weight: 500;
  font-size: 18px;
`;

export const DetailsTitle = styled.div`
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 15px;
`;
