import { Modal } from "react-bootstrap";
import styled from "styled-components";
import NorhartHeader from "./NorhartHeader";

interface Props {
  title: string;
  children: JSX.Element;
  show: boolean;
  onClose: () => void;
  canClose?: boolean;
  size?: "sm" | "lg" | "xl";
}

const NorhartModal = ({
  title,
  children,
  show,
  onClose,
  canClose = true,
  size,
}: Props) => {
  return (
    <CustomModal
      show={show}
      onHide={onClose}
      backdrop={canClose || "static"}
      keyboard={canClose}
      size={size}
      centered
    >
      <Body>
        <NorhartHeader needIcon={false} title={title} />
        <Wrapper>{children}</Wrapper>
      </Body>
    </CustomModal>
  );
};

const CustomModal = styled(Modal)`
  --bs-modal-bg: ${({ theme }) => theme.BANNER_BACKGROUND};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 50px;
  @media (max-width: 410px) {
    font-size: 14px;
  }
  @media (max-width: 350px) {
    font-size: 13px;
  }
`;

const Body = styled(Modal.Body)`
  padding: 40px;
`;

export default NorhartModal;
