import {
  ProductForm,
  ProductErrors,
  ProductTouch,
  FlexAccountInitialData,
  FlexAccountInitialErrors,
  FlexAccountInitialTouch,
} from "./types";

export const initialData: ProductForm = {
  term: "",
  interest: "",
  interestType: "",
  allocationTerm: "",
  name: "",
  description: "",
  active: "",
  newPercentage: "",
  // endDate: new Date().toISOString().slice(0, 16),
  endDate: "",
  // id: ""
};

export const initialErrors: ProductErrors = {
  ...initialData,
  id: "",
  form: "",
};

export const initialTouch: ProductTouch = {
  term: false,
  interest: false,
  interestType: false,
  allocationTerm: false,
  name: false,
  description: false,
  active: false,
  newPercentage: false,
  endDate: false,
  id: true,
};

export const flexAccountInitialData: FlexAccountInitialData = {
  interest: "",
};

export const flexAccountInitialErrors: FlexAccountInitialErrors = {
  ...flexAccountInitialData,
  form: "",
};

export const flexAccountInitialTouch: FlexAccountInitialTouch = {
  interest: false,
};

export const flexAccountFormInputTracker = "flexAccountForm";
