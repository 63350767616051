import WelcomeVideoComponent from "../components/LandingVideoComponent";
import ZeroToUnicornVideo from "../components/ZeroToUnicornVideo";

const WelcomeVideoSection = () => {
  return (
    <WelcomeVideoComponent
      backgroundColorOne="#f4f4f4"
      backgroundColorTwo="#f4f4f4"
      title="We Are Changing An Industry"
      subTitle="That Hasn't Changed In Decades"
      textColor="#111111"
      children={<ZeroToUnicornVideo />}
    />
  );
};

export default WelcomeVideoSection;
