import styled, { keyframes } from "styled-components";

export const StyledIcon = styled.img`
  height: 85px;
  width: 85px;
  @media (max-width: 320px) {
    height: 60px;
    width: 60px;
  }
`;

export const CalculatorInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 550px) {
    width: 100%;
  }
`;

export const InputGroup = styled.div`
  max-width: 160px;
  width: 100%;
`;

export const InputSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 360px) {
    flex-wrap: wrap;
  }
`;

interface LabelProps {
  color?: string;
}

export const Label = styled.label<LabelProps>`
  color: ${(props) => (props.color ? props.color : "white")};
  opacity: 0.65 !important;
  &:after {
    background-color: transparent !important;
  }
`;

export const ContentHeroTitle = styled.h3`
  text-align: center !important;
  font-size: 28px;
  line-height: 38px;
  font-weight: 800;
  padding: 0;
  @media (max-width: 1200px) {
    font-size: 26px;
    line-height: 36px;
  }
  @media (max-width: 650px) {
    font-size: 20px;
  }
`;

interface HrProps {
  color: string;
}

export const TopHr = styled.hr<HrProps>`
  border-color: rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${(props) => props.color};
  border-bottom: ${(props) => `2px solid ${props.color}`} !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
`;

export const GradientHr = styled(TopHr)`
  border-bottom: ${(props) => `2px solid ${props.color}`} !important;
`;

export const ContentHeroSubtitle = styled.h2`
  text-align: center !important;
  font-size: 26px;
  line-height: 36px;
  font-weight: 300;
  padding-top: 0;
  padding-bottom: 5px;
  font-weight: 300;
  margin: 0px 75px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 694px) {
    font-size: 18px;
    line-height: normal;
    margin: 0;
  }
  @media (max-width: 414px) {
    font-size: 18px;
  }
  @media (max-width: 375px) {
    font-size: 14px;
  }
  @media (max-width: 320px) {
    font-size: 12px;
  }
`;

export const InvestCalculateTitle = styled.div`
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  @media (max-width: 650px) {
    font-size: 22px;
  }
`;

interface InvestCalculateTextProps {
  color?: string;
}

export const InvestCalculateText = styled.div<InvestCalculateTextProps>`
  color: ${(props) => props.color};
  font-size: 100px;
  font-weight: bold;
  text-align: center;
  @media (max-width: 650px) {
    font-size: 40px;
  }
  @media (max-width: 400px) {
    font-size: 30px;
  }
`;

export const InvestEarnText = styled.div`
  color: #ffffff;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  @media (max-width: 650px) {
    font-size: 24px;
  }
`;

export const InvestPaidText = styled.div`
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  @media (max-width: 650px) {
    font-size: 18px;
  }
`;

interface BottomInvestCalculatorTextProps {
  color: string;
}

export const BottomInvestCalculatorText = styled(
  InvestPaidText
)<BottomInvestCalculatorTextProps>`
  color: ${(props) => props.color};
  font-size: 16px;
`;

export const InvestInputRate = styled.div`
  width: 100px !important;
  color: #ffffff !important;
`;

export const ContentHeroLeftTitle = styled.h3`
  margin-left: 0px;
  margin-right: 0px;
  text-align: left;
  font-size: 28px;
  line-height: 32px;
  font-weight: 800;
  padding: 0;
  @media (max-width: 650px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const ContentHeroLeftSubTitle = styled.h3`
  margin-left: 0px;
  margin-right: 0px;
  text-align: left;
  font-size: 26px;
  line-height: 32px;
  font-weight: 400;
  color: #365c70;
  @media (max-width: 650px) {
    font-size: 17px;
    line-height: 21px;
  }
`;

export const ContentHeroSpace = styled.div`
  margin-top: 20px;
`;

export const YoutubeVideo = styled.iframe`
  aspect-ratio: 16/9;
  width: 100%;
  border: 0;
`;

const zoomIn = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const YoutubeButton = styled.a`
  animation: ${zoomIn} 4s ease-in-out infinite;
`;

export const Container = styled.div`
  max-width: 960px !important;
`;

export const ChartTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const ChartSubtitle = styled.div`
  font-size: 18px;
  font-weight: normal;
  margin-top: 15px;
`;
export const ChartLink = styled.span`
  font-size: 18px;
  font-weight: normal;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none !important;
`;

export const Ibox = styled.div`
  &:after {
    display: table;
  }
  &:before {
    display: table;
  }
`;

export const IboxContent = styled.div`
  background-color: #ffffff;
  color: inherit;
  padding: 15px 18px 18px 18px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
`;

export const ProductDesc = styled.div`
  min-height: 200px;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
`;

export const ProductName = styled.small`
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  color: #1ab394;
  display: block;
  margin: 1px 0 1px 0;
`;

export const ProductBox = styled(IboxContent)`
  padding: 50;
  border: 1px solid #e7eaec;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2) !important;
  border-top: 5px solid #31996b !important;
`;

export const Li = styled.li`
  @media (max-width: 320px) {
    font-size: 12px;
  }
`;

export const slideOutUpAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5%);
  }
`;

export const AnimateSlideOutUp = styled.div`
  &:hover {
    animation: ${slideOutUpAnimation} 1s ease-in-out forwards;
    transform: translateY(0);
  }
`;

export const InvestStatsTitle = styled.div`
  font-family: "Gotham", sans-serif;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
`;

export const InvestStatsText = styled.div`
  font-family: "Gotham", sans-serif;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 300;
  width: 50%;
`;

export const InvestRateNumbers = styled.div`
  font-family: "Gotham", sans-serif;
  margin-top: 10px;
  font-size: 22px;
  font-weight: bold !important;
  width: 50%;
  float: left;
  margin-left: 10px;
`;
export const InvestRateText = styled.div`
  font-family: "Gotham", sans-serif;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  width: 50%;
  margin-left: 10px;
`;

export const InvestRateSubtext = styled.div`
  font-family: "Gotham", sans-serif;
  margin-top: 1px;
  font-size: 12px;
  font-weight: 300;
  width: 50%;
  float: left;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const InvestStatsNumbers = styled.div`
  font-family: "Gotham", sans-serif;
  margin-top: 10px;
  font-size: 22px;
  font-weight: bold !important;
  width: 50%;
  float: left;
`;

export const InvestImageName = styled.div`
  margin-left: -30px !important;
  margin-top: 20px !important;
  font-size: 30px !important;
  font-weight: bold !important;
  @media (max-width: 600px) {
    font-size: 18px !important;
  }
`;
export const InvestImageTitle = styled.div`
  margin-left: -30px !important;
  font-size: 24px !important;
  @media (max-width: 600px) {
    font-size: 11px !important;
  }
`;

export const InvestImageButton = styled.a`
  margin-top: 0px !important;
  margin-left: -30px !important;
  font-size: 13px !important;
  @media (max-width: 320px) {
    font-size: 10px;
  }
`;

export const InvestIraRateText = styled.div`
  font-family: "Gotham", sans-serif;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  width: 50%;
  margin-left: 10px;
`;

export const SubNoteText = styled.h5`
  @media (max-width: 320px) {
    font-size: 1rem;
  }
`;
