import PaginationButtons from "../../../../components/PaginationButtons";
import { TopWrapper, TitleWrapper, ListTitle } from "../../../../styles/list";
import { Wrapper as WrapperStyle } from "../../styles";
import { PropsWithChildren } from "react";

interface PaginationProps {
  paginationProps?: {
    handleNext: () => void;
    handlePrevious: () => void;
    isLastPage: boolean;
    isFirstPage: boolean;
  };
}

export const Wrapper = ({
  title,
  children,
  paginationProps,
}: PropsWithChildren<PaginationProps & { title: string }>) => {
  return (
    <WrapperStyle>
      <TopWrapper>
        <TitleWrapper>
          <ListTitle>{title}</ListTitle>
        </TitleWrapper>
        {paginationProps && (
          <PaginationButtons
            handleNext={paginationProps.handleNext}
            handlePrevious={paginationProps.handlePrevious}
            isLastPage={!!paginationProps.isLastPage}
            isFirstPage={!!paginationProps.isFirstPage}
          />
        )}
      </TopWrapper>
      {children}
    </WrapperStyle>
  );
};
