import { Accordion } from "react-bootstrap";
import styled from "styled-components";

export const CustomAccordion = styled(Accordion)`
  --bs-accordion-color: ${({ theme }) => theme.PRIMARY_COLOR};
  --bs-accordion-bg: ${({ theme }) => theme.BACKGROUND_COLOR};
  --bs-accordion-border-color: ${({ theme }) => theme.LIST_BORDER_COLOR};
  --bs-accordion-active-bg: ${({ theme }) => theme.BANNER_BACKGROUND};
  --bs-accordion-active-color: ${({ theme }) => theme.PRIMARY_COLOR};
  --bs-accordion-btn-color: ${({ theme }) => theme.PRIMARY_COLOR};
  --bs-accordion-btn-bg: ${({ theme }) => theme.BACKGROUND_COLOR};
  --bs-accordion-btn-icon: ${({ theme }) => `url(${theme.SELECT_ARROW})`};
  --bs-accordion-btn-active-icon: ${({ theme }) =>
    `url(${theme.SELECT_ARROW})`};
  --bs-accordion-btn-focus-box-shadow: transparent;
`;

export const AccordionBody = styled(Accordion.Body)`
  overflow: hidden;
  text-overflow: ellipsis;
`;
