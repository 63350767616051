export const GRAPHIC_COLORS = [
  {
    color: "#c6f5b4",
    height: 33.33,
  },
  {
    color: "#aee299",
    height: 46.67,
  },
  {
    color: "#82cc73",
    height: 60,
  },
  {
    color: "#82cc73",
    height: 73.33,
  },
  {
    color: "#5d9944",
    height: 86.67,
  },
  {
    color: "#427b2c",
    height: 100,
  },
];
