import { ListGroup } from "react-bootstrap";
import DocumentRow from "../DocumentRow";
import { Loading } from "../../../../components/Loading";
import { useDocuments } from "../../../../hooks/useDocuments";
import { Wrapper } from "./Wrapper";
import WarningAlert from "../../../../components/WarningAlert";

interface Props {
  title: string;
  downloading: string[];
  updateQueue: any;
  tag: string;
  documentDescription: string;
}

export const DocumentsList = ({
  title,
  downloading,
  updateQueue,
  tag,
  documentDescription,
}: Props) => {
  const {
    documents,
    loading,
    handleNext,
    handlePrevious,
    isFirstPage,
    isLastPage,
  } = useDocuments({ perPage: 5, cacheId: `all${tag}`, tag });

  if (loading)
    return (
      <Wrapper title={title}>
        <Loading />
      </Wrapper>
    );

  if (!documents.length) {
    if (documentDescription === "tax") {
      return (
        <Wrapper title={title}>
          <WarningAlert
            title="No Tax Forms"
            description="There are no required tax forms for your account this year"
          />
        </Wrapper>
      );
    }
    return (
      <Wrapper title={title}>
        <WarningAlert
          title="No documents available at the moment."
          description={`Oops! It looks like you don't have any ${documentDescription} documents yet`}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper
      title={title}
      paginationProps={{
        handleNext,
        handlePrevious,
        isFirstPage,
        isLastPage,
      }}
    >
      <ListGroup>
        {documents.map(
          ({ id, name, size, formatType, s3Key, lastAccessDate }) => {
            return (
              <DocumentRow
                key={id}
                name={name}
                size={size}
                formatType={formatType}
                s3Key={s3Key}
                loading={downloading.includes(id)}
                id={id}
                updateQueue={updateQueue}
                lastAccessDate={lastAccessDate}
              />
            );
          }
        )}
      </ListGroup>
    </Wrapper>
  );
};
