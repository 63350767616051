import { NewCustomError } from "../types";
import { SignUpInput } from "../types/signup";
import { LogRocket } from "./logrocket";

const signUp = async (signUpBody: SignUpInput) => {
  const path = "/sign-up";
  const response = await fetch(path, {
    method: "POST",
    body: JSON.stringify(signUpBody),
  });
  const res = await response.json();
  if (!response.ok) {
    NewCustomError.throw(res, response);
  }
  const { username } = signUpBody;
  const {
    data: { userSub },
  } = res;
  LogRocket.identify(userSub, username);
  return res;
};

const confirmSignUp = async (username: string, verificationCode: string) => {
  const path = "/verify-email-with-code";
  const response = await fetch(path, {
    method: "POST",
    body: JSON.stringify({ username, verificationCode }),
  });
  const res = await response.json();
  if (!response.ok) {
    NewCustomError.throw(res, response);
  }
  return res as { success: boolean };
};

export const PlutusAuth = {
  signUp,
  confirmSignUp,
};
