import { Auth as AuthAmplify } from "aws-amplify";
import { Dispatch, SetStateAction } from "react";
import {
  AttributesProps,
  CognitoAttributes,
  CustomCognitoAttributes,
} from "../types/cognito-attributes";
import { LogRocket } from "./logrocket";
import { Roles } from "../types/roles";
import { PlutusAuth } from "./plutusAuth";
import {
  convertToDBPhoneNumber,
  convertToNumber,
  normalizeString,
} from "./common";
import { LocalStorage } from "./localStorage";
import { CacheKeys } from "../types";
interface SignInProps {
  username: string;
  password: string;
}

interface SignUpProps extends SignInProps {
  income: string;
  accredited: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  didSignTOSPolicy: boolean;
  didSignPrivacyPolicy: boolean;
}

const getGoogleAnalyticsClientId = () => {
  const cookies: Record<string, string> = {};
  document.cookie.split(";").forEach((el) => {
    const splitCookie = el.split("=");
    const key = splitCookie[0].trim();
    const value = splitCookie[1];
    cookies[key] = value;
  });
  return cookies["_ga"].substring(6);
};

const signUp = async (props: SignUpProps) => {
  props.username = props.username.toLowerCase();
  const { phoneNumber, firstName, lastName, ...rest } = props;
  const dbPhoneNumber = convertToDBPhoneNumber(phoneNumber);
  const dbFirstName = normalizeString(firstName);
  const dbLastName = normalizeString(lastName);
  await PlutusAuth.signUp({
    ...rest,
    phoneNumber: dbPhoneNumber,
    firstName: dbFirstName,
    lastName: dbLastName,
    googleAnalyticsClientId: getGoogleAnalyticsClientId(),
  });
  LogRocket.identify("", props.username);
  window.dataLayer.push({
    user_id: "", // DO NOT DELETE: Needs to be an empty string to prevent old UUID's from logout
    event: "sign_up",
    email_address: props.username,
    income: convertToNumber(props.income),
    phone_number: dbPhoneNumber,
    first_name: dbFirstName,
    last_name: dbLastName,
  });
};

const resendConfirmationCode = async (username: string) => {
  const name = username.toLowerCase();
  try {
    await AuthAmplify.resendSignUp(name);
  } catch (err) {
    console.log("error resending code: ", err);
    throw new Error();
  }
};

interface ConfirmSignUp {
  username: string;
  code: string;
}

const confirmSignUp = async ({ username, code }: ConfirmSignUp) => {
  const name = username.toLowerCase();
  await PlutusAuth.confirmSignUp(name, code);
};

const signIn = async ({ username, password }: SignInProps) => {
  const name = username.toLowerCase();
  const user = await AuthAmplify.signIn(name, password);
  const payload = user.signInUserSession.idToken.payload;
  if (payload)
    LogRocket.identify(
      payload.sub,
      username,
      payload["cognito:groups"]?.includes(Roles.Admin)
    );
  localStorage.setItem(CacheKeys.signedAfterStorageUpdate, "true");
  return user;
};

const signOut = async () => {
  try {
    LocalStorage.deleteUserData();
    await AuthAmplify.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
    throw new Error();
  }
};

export const sendForgotPasswordEmail = async (username: string) => {
  const name = username.toLowerCase();
  await AuthAmplify.forgotPassword(name);
};

export const forgotPasswordSubmit = async (
  username: string,
  code: string,
  new_password: string
) => {
  const name = username.toLowerCase();
  try {
    await AuthAmplify.forgotPasswordSubmit(name, code, new_password);
  } catch (e) {
    console.log(e);
  }
};

const isLoggedIn = async (
  setIsLoading?: Dispatch<SetStateAction<boolean>>,
  setIsLogged?: Dispatch<SetStateAction<boolean>>,
  setGroups?: Dispatch<SetStateAction<string[]>>,
  setIsIdentityVerified?: Dispatch<SetStateAction<boolean>>
) => {
  let groups;
  try {
    const user = await AuthAmplify.currentSession();
    groups = user.getIdToken().payload["cognito:groups"];
    if (setGroups) setGroups(groups || []);
    if (setIsLogged) setIsLogged(true);
  } catch (e) {
    if (setIsLogged) setIsLogged(false);
    if (setIsIdentityVerified) setIsIdentityVerified(false);
  }
  if (setIsLoading) setIsLoading(false);
};

const getUserToken = async () => {
  const user = await AuthAmplify.currentSession();
  const token = user.getIdToken().getJwtToken();
  return token;
};

const getUserName = async () => {
  const currentSession = await AuthAmplify.currentSession();
  const { sub, email } = currentSession.getIdToken().payload;
  return { token: sub, email };
};

const updateAttributes = async (
  key: CognitoAttributes | CustomCognitoAttributes,
  data: string
) => {
  const user = await AuthAmplify.currentAuthenticatedUser();
  const attributes = {
    ...user.attributes,
    [`custom:${key}`]: data,
  };
  await AuthAmplify.updateUserAttributes(user, attributes);
};

const updateIdentityVerified = async (identity_verification_id: string) => {
  const path = `/attributes`;
  try {
    const response = await fetch(path, {
      method: "PATCH",
      body: JSON.stringify({
        name: "identity_verified",
        identity_verification_id,
      }),
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      throw new Error("Something wrong happened verifying your identity");
    }
  } catch (e) {
    console.log(e);
  }
};

const getUserAttributes = async () => {
  const response: { attributes: Partial<AttributesProps> } =
    await AuthAmplify.currentUserInfo();
  let attributes: Record<string, string | undefined> = {};
  Object.entries(CustomCognitoAttributes).forEach(([key, value]) => {
    attributes = {
      ...attributes,
      [key]: response.attributes.hasOwnProperty(`custom:${value}`)
        ? response.attributes[`custom:${value}`]
        : undefined,
    };
  });
  return attributes;
};

const changePassword = async ({
  currentPassword,
  newPassword,
}: {
  currentPassword: string;
  newPassword: string;
}) => {
  const user = await AuthAmplify.currentAuthenticatedUser();
  await AuthAmplify.changePassword(user, currentPassword, newPassword);
};

export const Auth = {
  isLoggedIn,
  signOut,
  signIn,
  confirmSignUp,
  resendConfirmationCode,
  signUp,
  sendForgotPasswordEmail,
  forgotPasswordSubmit,
  getUserToken,
  updateAttributes,
  updateIdentityVerified,
  getUserAttributes,
  changePassword,
  getUserName,
  getGoogleAnalyticsClientId,
};
