import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { ReviewStatus } from "../../types/cognito-attributes";
import { DarkWrapperAuth } from "../../components/DarkWrapperAuth";

const Review = () => {
  const location = useLocation();
  const review: ReviewStatus = location.state?.from;
  return (
    <DarkWrapperAuth>
      <>
        <H6>
          {review === ReviewStatus.pending ? "Pending Review" : "Review Failed"}
        </H6>
        <StyledP>
          {review === ReviewStatus.pending
            ? "Your account is pending review; please check back in 1-2 business days."
            : "Your account was not approved; please get in touch with support at support@norhart.com for additional information."}
        </StyledP>
      </>
    </DarkWrapperAuth>
  );
};

const H6 = styled.h6`
  color: white;
  font-size: calc(1.325rem + 1.9vw);
  font-weight: 600;
`;
const StyledP = styled.p`
  color: white;
`;

export default Review;
