import { useCallback } from "react";
import { CacheKeys } from "../types";
import { ResponseStatus } from "../types/ResponseStatus";
import { notifications } from "../utils/notifications";
import { useFetch } from "./useFetch";
import { Beneficiaries } from "../utils/beneficiaries";
import { BeneficiariesInput } from "../types/beneficiaries";

export const useBeneficiaries = () => {
  const fetchFunction = useCallback(async () => {
    const response = await Beneficiaries.getBeneficiaries();
    return response;
  }, []);

  const { data, loading, refreshData } = useFetch({
    cacheKey: CacheKeys.bankAccounts,
    fetchFunction,
  });

  const deleteBeneficiary = async (id: string) => {
    try {
      const response = await Beneficiaries.deleteBeneficiaries(id);
      if (response) refreshData(response);
      notifications({
        status: ResponseStatus.SUCCESS,
        successMessage: `The beneficiary was deleted successfully`,
      });
    } catch (e: any) {
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage: e?.message || `Unexpected error deleting the beneficiary`,
      });
    }
  };

  const createBeneficiary = async (
    fields: BeneficiariesInput,
    resetForm: () => void
  ) => {
    try {
      const response = await Beneficiaries.createBeneficiaries(fields);
      if (response) refreshData(response);
      resetForm();
      notifications({
        status: ResponseStatus.SUCCESS,
        successMessage: `The beneficiary was created successfully`,
      });
    } catch (e: any) {
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage: e?.message || `Unexpected error creating the beneficiary`,
      });
    }
  };

  return {
    beneficiaries: data,
    loading: loading,
    refreshData,
    deleteBeneficiary,
    createBeneficiary,
  };
};
