import { Accordion, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  Item,
  LeftWrapper,
  Dot,
  TextWrapper,
  Title,
  Description,
  RightWrapper,
  NumberWrapper,
  Interest,
  ListTitle,
  TitleWrapper,
  ViewAllButton,
  TopWrapper,
  AddButton,
  RightArrow,
  TransparentButton,
} from "../styles/list";
import { User, formatNumbers } from "../utils";
import ChevronRight from "../assets/images/right-chevron.png";
import PaginationButtons from "./PaginationButtons";
import { PromissoryNoteAccount } from "../types";
import { AccordionArrow } from "./AccorditionArrow";
import { useMemo } from "react";

interface Props {
  homepage?: boolean;
  promissoryNotes: PromissoryNoteAccount[];
  title: string;
  active?: boolean;
  plusButtonClick?: () => void;
  pagination?: boolean;
  handleNext?: () => void;
  handlePrevious?: () => void;
  isLastPage?: boolean;
  isFirstPage?: boolean;
}

const PromissoryNoteList = ({
  homepage,
  promissoryNotes,
  title,
  active,
  plusButtonClick,
  pagination,
  isLastPage,
  isFirstPage,
  handleNext,
  handlePrevious,
}: Props) => {
  const navigate = useNavigate();
  const isDefaultActive = useMemo(
    () =>
      User.getUsersPreferencesFromLocalStorage()
        ?.dashboardPromissoryNotesCollapsed,
    []
  );
  return (
    <Accordion defaultActiveKey={!!isDefaultActive ? undefined : "0"}>
      <TopWrapper>
        <TitleWrapper>
          <ListTitle>{title}</ListTitle>
          {homepage && (
            <ViewAllButton
              className="align-top"
              onClick={() => navigate("/promissory-note")}
            >
              View All
            </ViewAllButton>
          )}
          {homepage && (
            <>
              <TransparentButton onClick={plusButtonClick}>
                <AddButton />
              </TransparentButton>
              <AccordionArrow
                eventKey="0"
                preferenceKey="dashboardPromissoryNotesCollapsed"
              />
            </>
          )}
        </TitleWrapper>
        {pagination && handleNext && handlePrevious && (
          <PaginationButtons
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            isLastPage={!!isLastPage}
            isFirstPage={!!isFirstPage}
          />
        )}
      </TopWrapper>
      <Accordion.Collapse eventKey="0">
        <ListGroup>
          {promissoryNotes.map(
            (
              {
                product: { term, name },
                currentBalance: { posted, pending },
                interestRate,
                id,
                status,
              },
              i
            ) => {
              return (
                <Item
                  key={id}
                  onClick={() => navigate(`/promissory-note/${id}`)}
                >
                  <LeftWrapper>
                    <div>
                      <Dot
                        status={status}
                        displaydot="true"
                        color={!active ? "#d4d4d4" : undefined}
                      />
                    </div>
                    <TextWrapper>
                      <Title>{name}</Title>
                      <Description className="text-secondary">
                        {term} Month Term
                      </Description>
                    </TextWrapper>
                  </LeftWrapper>
                  <RightWrapper>
                    <NumberWrapper>
                      <Title data-cy={`promissory-amount-${i}`}>
                        ${formatNumbers(posted || pending)}
                      </Title>
                      <Interest>{interestRate.toFixed(2)}%</Interest>
                    </NumberWrapper>
                    <div className="d-flex">
                      <RightArrow
                        src={ChevronRight}
                        alt=""
                        // layout="fixed"
                        className="object-fit-cover"
                      />
                    </div>
                  </RightWrapper>
                </Item>
              );
            }
          )}
        </ListGroup>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default PromissoryNoteList;
