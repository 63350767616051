import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { usePlaidBankAccount } from "../../../hooks/usePlaidBankAccount";
import { WorkingOnIt } from "../../../components/WorkingOnIt";
import { useDelay } from "../../../hooks/useDelay";

const DELAY_SECONDS = 6;

const BankAccount = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const previousPath = location.state?.from;

  const { ready: delayReady, resetCount } = useDelay(DELAY_SECONDS);
  const [hasRun, setHasRun] = useState(false);
  const handlePlaidCallback = () => {
    setHasRun(true);
    resetCount();
  };

  const { open, ready } = usePlaidBankAccount(
    handlePlaidCallback,
    handlePlaidCallback
  );

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  useEffect(() => {
    if (!hasRun || !delayReady) return;
    navigate("/dashboard", {
      state: { from: previousPath },
    });
  }, [hasRun, delayReady, navigate, previousPath]);

  return <WorkingOnIt />;
};

export default BankAccount;
