import { useEffect, useState } from "react";
import { CacheKeys, ERROR, LinkDestination, Products } from "../types";
import { Plaid } from "../utils";
import { LocalStorage } from "../utils/localStorage";

export const useLinkToken = (
  products: Products[],
  destination?: LinkDestination
) => {
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [error, setError] = useState<ERROR>();
  const isOAuthRedirect = !!window.location.href.includes("?oauth_state_id=");

  useEffect(() => {
    if (isOAuthRedirect) {
      setLinkToken(localStorage.getItem(CacheKeys.currentLinkToken));
      localStorage.removeItem(CacheKeys.currentLinkToken);
      return;
    }
    const getLinkToken = async () => {
      const response = await Plaid.generateToken({ products, destination });
      if (response.error) {
        setError(response.error);
        return;
      }
      setLinkToken(response.linkToken);
      LocalStorage.setUserData(CacheKeys.currentLinkToken, response.linkToken!);
    };

    getLinkToken();
  }, [products, isOAuthRedirect, destination]);

  return { linkToken, loading: !linkToken || !error, error, isOAuthRedirect };
};
