import { useEffect, useState } from "react";
import { formatMoneyInput } from "../utils";
import { useNetAssets } from "./useNetAssets";
import { useCognitoAttributes } from "./useCognitoAttributes";

export const useFinancialData = () => {
  const [initialData, setInitialData] = useState<{
    income: string;
    accredited: string;
    netAssets: string;
  }>();
  const [loading, setLoading] = useState<boolean>(true);
  const {
    loading: loadingAssets,
    data: netAssets,
    updateNetAssets,
  } = useNetAssets();
  const {
    income,
    accredited,
    loading: loadingAttributes,
  } = useCognitoAttributes();
  useEffect(() => {
    const getAttributes = async () => {
      if (
        !loadingAssets &&
        netAssets?.netAssets !== undefined &&
        !loadingAttributes
      ) {
        try {
          setInitialData({
            accredited: accredited || "",
            income: income || "",
            netAssets: formatMoneyInput(netAssets.netAssets.toString()),
          });
        } catch (e) {
          console.error(e);
        }
        setLoading(false);
      }
    };
    getAttributes();
  }, [loadingAssets, netAssets, accredited, income, loadingAttributes]);

  return { initialData, loading: loading || loadingAssets, updateNetAssets };
};
