import YoutubeVideo from "./YoutubeVideo";

import MaxResolutionPhoto from "../../../assets/images/landing/youtube-image/zero-to-unicorn/maxresdefault.png";
import SdDefaultPhoto from "../../../assets/images/landing/youtube-image/zero-to-unicorn/sddefault.png";
import HqDefaultPhoto from "../../../assets/images/landing/youtube-image/zero-to-unicorn/hqdefault.png";
import MqDefaultPhoto from "../../../assets/images/landing/youtube-image/zero-to-unicorn/mqdefault.png";

const ZeroToUnicornVideo = () => {
  return (
    <YoutubeVideo
      MaxResolutionPhoto={MaxResolutionPhoto}
      SdDefaultPhoto={SdDefaultPhoto}
      HqDefaultPhoto={HqDefaultPhoto}
      MqDefaultPhoto={MqDefaultPhoto}
      title={"Unveiling the Billion-Dollar Journey - Zero to Unicorn Trailer"}
      videoUrl={"https://www.youtube.com/embed/1GbXi7LcFR4"}
    />
  );
};

export default ZeroToUnicornVideo;
