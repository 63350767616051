export enum publicRoutes {
  landing = "/",
  welcome = "/welcome",
  signin = "/signin",
  signup = "/signup",
  createAccount = "/create-account",
  forgotPassword = "/forgot-password",
  maintenance = "/maintenance",
  notFound = "/not-found",
  review = "/review",
}

export enum privateRoutes {
  admin = "/admin",
  dashboard = "/dashboard",
  flexAccount = "/flex-account",
  bankAccount = "/onboarding/bank-account",
  identityVerification = "/onboarding/identity-verification",
  promissoryNote = "/promissory-note",
  transactions = "/transactions",
  userAccount = "/user-account",
}
