import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as NorhartLogo } from "../../assets/images/norhart-logo-navbar-dark.svg";
import NorhartWhiteLogo from "../../assets/images/white-norhart-logo.png";
import { FunctionComponent } from "react";
import { usePagePath } from "../../hooks/usePagePath";
import { publicRoutes } from "../../types";

interface Props {
  pageName?: string;
  darkMode?: boolean;
}

export const AuthNavbar: FunctionComponent<Props> = ({ darkMode }) => {
  const { path } = usePagePath();
  return (
    <NavbarContainer
      style={{ backgroundColor: "#000000" }}
      className={`navbar sticky-top`}
    >
      <NavbarDivContainer className="container-lg">
        <Link
          target="_blank"
          className=" d-flex justify-content-center"
          to="https://www.norhart.com"
        >
          {darkMode ? (
            <NorhartLogoImg src={NorhartWhiteLogo} alt="norhart-white-logo" />
          ) : (
            <NorhartLogoSvg width="100" height="20" xmlSpace="preserve">
              <NorhartLogo />
            </NorhartLogoSvg>
          )}
        </Link>
        <div className="d-flex gap-4">
          {![publicRoutes.signup, publicRoutes.signin].includes(
            path as publicRoutes
          ) && (
            <StyledLink
              className=" d-flex justify-content-center"
              to={publicRoutes.signup}
            >
              <StyledDiv darkMode={darkMode}>Invest</StyledDiv>
            </StyledLink>
          )}
          <StyledLink
            className=" d-flex justify-content-center"
            to={
              path === publicRoutes.signin
                ? publicRoutes.signup
                : publicRoutes.signin
            }
          >
            <StyledDiv darkMode={darkMode}>
              Sign {path === publicRoutes.signin ? "Up" : "In"}
            </StyledDiv>
          </StyledLink>
        </div>
      </NavbarDivContainer>
    </NavbarContainer>
  );
};

const NorhartLogoImg = styled.img`
  height: 20px;
  width: 100px;
  @media (max-width: 280px) {
    height: 15px;
    width: 75px;
  }
`;

const NorhartLogoSvg = styled.svg`
  height: 20px;
  width: 100px;
`;

const NavbarContainer = styled.nav`
  height: 50px;
  margin-top: -1px;
  @media (max-width: 280px) {
    height: 38px;
    padding: 0px;
  }
`;

const NavbarDivContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

interface StyledDivProps {
  darkMode?: boolean;
}

const StyledDiv = styled.div<StyledDivProps>`
  font-weight: 900;
  color: ${(props) => (props.darkMode ? "white" : "#1e1b1a")};
  @media (max-width: 280px) {
    font-size: 15px;
    font-weight: 800;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
