import { useState } from "react";
import { normalizeWord, stringOrObject } from "../utils";
import { BannerBoxData, BannerBoxOptions } from "../types";
import { useAnimatedCounter } from "./useAnimatedCounter";

export const useBannerBox = (value: string | BannerBoxData) => {
  const [displayValue, setDisplayValue] = useState(BannerBoxOptions.active);
  const handleChange = (option: BannerBoxOptions) => {
    setDisplayValue(option);
  };
  const isObject = stringOrObject(value);
  const displayData = isObject ? value[displayValue] : value;
  const normalizedButtonText = normalizeWord(displayValue);
  const isNumber = typeof displayData === "number";
  const count = useAnimatedCounter({ end: isNumber ? displayData : 0 });

  return {
    displayValue,
    handleChange,
    normalizedButtonText,
    isObject,
    displayData: isNumber ? count : displayData,
  };
};
