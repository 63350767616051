import { FormEvent } from "react";
import { FormInput } from "../../../components/forms/formsComponents/FormInput";
import { useForm } from "../../../hooks/useForm";
import {
  initialBeneficiariesInitialData,
  beneficiariesInitialErrors,
  beneficiariesInitialTouch,
  beneficiariesFormInputTracker,
} from "../constants";
import FormWrapper from "../../../components/FormWrapper";
import { ACCOUNT_CUSTOM_ERRORS } from "../types";
import {
  BeneficiariesData,
  BeneficiariesInput,
} from "../../../types/beneficiaries";
import BeneficiariesRow from "./BeneficiariesRow";
import { ListGroup } from "react-bootstrap";
interface Props {
  beneficiariesData?: BeneficiariesData[];
  createBeneficiary: (
    fields: BeneficiariesInput,
    resetForm: () => void
  ) => Promise<void>;
  deleteBeneficiary: (email: string) => Promise<void>;
  isActive: boolean;
}

const Beneficiaries = ({
  beneficiariesData,
  createBeneficiary,
  deleteBeneficiary,
  isActive,
}: Props) => {
  const {
    handleErrors,
    handleFields,
    handleTouch,
    submitLoading,
    onSubmit,
    fields,
    errors,
    resetForm,
  } = useForm({
    initialFields: initialBeneficiariesInitialData,
    initialErrors: beneficiariesInitialErrors,
    initialTouch: beneficiariesInitialTouch,
    customErrors: ACCOUNT_CUSTOM_ERRORS,
    inputTracker: beneficiariesFormInputTracker,
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    onSubmit(e, () => createBeneficiary(fields, resetForm));
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit}
      loading={!!submitLoading}
      buttonMessage={submitLoading ? "Saving" : "Save"}
      title={"Beneficiaries"}
      collapseKey="accountBeneficiariesCollapsed"
      isActive={isActive}
    >
      <div className="row g-3 w-100">
        <div className="col-12">
          <ListGroup>
            <div className="mb-3">
              {beneficiariesData &&
                beneficiariesData.map((beneficiary) => {
                  const { id } = beneficiary;
                  return (
                    <BeneficiariesRow
                      key={id}
                      beneficiary={beneficiary}
                      handleDelete={deleteBeneficiary}
                    />
                  );
                })}
            </div>
          </ListGroup>
        </div>
      </div>
      <div className="row g-3 w-100">
        <div className="col-md-6">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            name="givenName"
            label="First Name"
            value={fields.givenName}
            error={errors.givenName}
            form={beneficiariesFormInputTracker}
          />
        </div>
        <div className="col-md-6">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            value={fields.familyName}
            error={errors.familyName}
            name="familyName"
            label="Last Name"
            form={beneficiariesFormInputTracker}
          />
        </div>
        <div className="col-md-6">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            value={fields.email}
            error={errors.email}
            name="email"
            type="email"
            label="Email Address"
            form={beneficiariesFormInputTracker}
          />
        </div>
        <div className="col-md-6">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            value={fields.dateOfBirth}
            error={errors.dateOfBirth}
            name="dateOfBirth"
            label="DOB"
            type="date"
            form={beneficiariesFormInputTracker}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export default Beneficiaries;
