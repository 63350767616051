export interface Product extends CreateProductInput {
  id?: string;
}

export enum InterestType {
  Simple = "simple",
  Compound = "compound",
}

export class CreateProductInput {
  term: number;
  interest: number;
  interestType: InterestType;
  allocationTerm: number;
  name: string;
  description: string;
  active: boolean;
  promo?: Promo | null;

  constructor(input: any) {
    this.term = Number(input.term);
    this.interest = Number(input.interest);
    this.interestType = input.interestType;
    this.allocationTerm = Number(input.allocationTerm);
    this.name = input.name;
    this.description = input.description;
    this.active = input.active === "true";

    if (input.newPercentage) {
      const promo = new Promo({
        newPercentage: input.newPercentage,
        endDate: input?.endDate,
      });
      this.promo = promo;
    }
  }
}

export class UpdateProductInput extends CreateProductInput {
  id?: string;
  constructor(input: any) {
    super(input);
    this.id = input.id;
    let promo;
    if (!input.newPercentage && !input.endDate) {
      promo = undefined;
    } else {
      promo = new Promo({
        newPercentage: input.newPercentage,
        endDate: input?.endDate,
      });
    }
    this.promo = promo;
  }
}

export class Promo {
  newPercentage: number;
  endDate?: string;
  constructor(input: Promo) {
    this.newPercentage = Number(input.newPercentage);
    this.endDate = input.endDate && new Date(input.endDate).toISOString();
  }
}

// export class UpdatePromo {
//   newPercentage?:string; endDate?:string
//   constructor(input: UpdatePromo) {
//     this.newPercentage=
//   }
// }
