import { ResponseStatus } from "../types/ResponseStatus";
import { Alerts } from "./Alerts";

interface Props {
  errorMessage?: string;
  successMessage?: string;
  status?: ResponseStatus;
}

export const StoreNotifications = ({
  status,
  errorMessage,
  successMessage,
}: Props) => {
  if (status === ResponseStatus.SUCCESS) {
    return (
      <Alerts
        isSuccessful={ResponseStatus.SUCCESS}
        successfulMessage={successMessage}
        isNotification
      />
    );
  }
  return (
    <Alerts
      isSuccessful={ResponseStatus.ERROR}
      errorMessage={errorMessage}
      isNotification
    />
  );
};
