import styled from "styled-components";
import { GreenLink } from "../styles";
interface Props {
  ispublic?: boolean;
}

export const PrivacyPolicy = ({ ispublic }: Props) => {
  return (
    <PageWrapper ispublic={!!ispublic}>
      <LegalTitle className="text-start">
        NORHART, INC. (“Norhart”) PRIVACY POLICY (“Privacy Policy”)
      </LegalTitle>
      <div className="container-sm text-start m-0">
        <LegalTitle className="text-start">
          (Effective Date: Jan 1, 2024)
        </LegalTitle>

        <Description className="text-start">
          At Norhart, we value your privacy, and protecting it is our priority.
          Please read our customer Privacy Policy for an explanation of how we
          collect, use, disclose, transfer, and store your personal information.
        </Description>
        <Description>
          This Privacy Policy governs your use of{" "}
          <StyledGreenLink to="https://www.norhart.com" target="_blank">
            https://www.norhart.com
          </StyledGreenLink>{" "}
          and other Norhart websites such as invest.norhart.com (collectively,
          the “Websites”) and other products or services that we provide
          (collectively, the “Services”). By using our Websites or Services, you
          are acknowledging that you have read, understood, and agree to our
          Privacy Policy. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, YOU MAY
          NOT ACCESS THE WEBSITES OR THE SERVICES.
        </Description>

        <LegalTitle className="text-start">
          I. Personal Information We Collect and Receive.
        </LegalTitle>
        <hr />
        <Description className="text-start">
          A. Personal Information We Collect and Receive. In order for you to
          access and use our Websites and Services, we may collect and process
          certain types of personal information as you interact with us.
          Depending on your activities on our Websites and Services this may
          include:
        </Description>
        <Description as="ul" className="list-group ms-3 gap-2">
          <li>
            Personal information you provide to us directly or that others
            provide about you, such as your name, address, email address, phone
            number, username, password, date of birth, gender, and household
            income;
          </li>
          <li>
            Transaction information you provide to us or that others provide
            about you, such as payment card information or information about the
            Services purchased, used, or considered;
          </li>
          <li>
            Financial information that you provide to us or that others provide
            about you, such as information about your financial and investment
            history and the information you authorize us to collect from any
            financial accounts that you allow to be connected to the Services;
          </li>
          <li>
            Information about your activity when using our Websites and
            Services. This may include information we collect from the device
            you use, such as your IP address, the type of device or browser you
            use, and information about your interaction with the Websites and
            Services, including applications, widgets, and advertisements;
          </li>
          <li>
            Information about your geolocation, such as physical location based
            on IP address, device data, or region;
          </li>
          <li>
            Professional or employment information you provide to us directly or
            that others provide about you;
          </li>
          <li>
            Physical characteristics or descriptions. For example, if you
            provide a photograph of yourself or your ID; and
          </li>
          <li>
            Inferences we draw from your personal information, such as a profile
            reflecting your preferences, characteristics, and behavior with our
            Websites or Services.
          </li>
        </Description>

        <Description className="blockquote text-start">
          Please note that personal information for the purposes of this Privacy
          Policy does not include aggregate information from which individual
          identities have been removed.
        </Description>

        <Description className="blockquote text-start">
          B. Personal Information from Children. Norhart does not knowingly
          solicit or collect any personal information from anyone under 13 years
          of age, and children under 13 are not permitted to register for or use
          our Websites or Services. If we become aware that a child under the
          age of 13 has provided us with their personal information, we will
          delete it.
        </Description>

        <LegalTitle className="blockquote text-start pl-5">
          II. How We Collect Personal Information.
        </LegalTitle>

        <Description className="blockquote text-start">
          Norhart collects personal information directly from you. In order to
          provide the Services, Norhart also frequently receives personal
          information about you from others, such as indirectly from your
          financial institution, as further described below:
        </Description>
        <Description className="blockquote text-start">
          A. Personal Information You Provide Directly. We collect your personal
          information that you provide to us directly when you take actions such
          as filling out a form on our Websites; registering for an account with
          us; contacting us by email, letter, phone, or chat; interacting with
          us on social media; interacting with our Websites or Services; or
          engaging in a transaction with us.
        </Description>
        <Description className="blockquote text-start">
          B. Personal Information Provided About You by Others. We also collect
          personal information about you provided to us by others in order to
          enable you to use or benefit from our Services or Website. For
          example:
        </Description>

        <Description as="ul" className="list-group ms-3 gap-2">
          <li>
            Norhart uses Plaid Inc. (“Plaid”) to gather your personal and
            financial information from financial institutions. By using the
            Services, you grant Norhart and Plaid the right, power, and
            authority to act on your behalf to access and receive your personal
            and financial information from your relevant financial institution.
            You also agree to Plaid processing your personal and financial
            information in accordance with the Plaid End User Privacy Policy.
          </li>
          <li>
            Our partners and service providers may also provide us with personal
            information about you.
          </li>
        </Description>
        <Description className="text-start">
          C. Automatically Collected Personal Information – Browsers and
          Devices. We and our Websites collect information that is sent to us
          automatically by your device or web browser, such as your IP address,
          browser type, and information about your browsing history.:
        </Description>
        <Description className="text-start">
          D. Automatically Collected Personal Information - Cookies and Tracers.
          Our Websites may use cookies and similar technologies (e.g., web
          beacons, pixels, ad tags, device identifiers, and other tracking
          tools) to recognize you and/or your device(s) on, off, and across the
          Websites and different Services and devices. Cookies are used to
          enable certain website features and functionalities. A cookie is a
          small file that is placed onto your device (e.g., computer, smartphone
          or other electronic device) that stores information when you visit a
          website. Cookies and these similar technologies also help us segment
          and analyze usage of our Websites and Services and may help us or
          third parties serve advertising to you on the Websites or across the
          Internet. These technologies may also combine information they collect
          from your interaction with the Websites or Services with information
          they collect from other sources. We are not responsible for any third
          parties’ use of cookies or other tracking technologies. Please contact
          these third parties directly for more information about their privacy
          policies and your choices regarding their tracking technologies. You
          can control certain cookies through your browser settings and other
          tools. Please note that if you choose to decline cookies, you may not
          be able to fully experience our Website or Services.
        </Description>
        <LegalTitle className="blockquote text-start">
          III. How We Use Personal Information.
        </LegalTitle>
        <hr />
        <Description className="text-start">
          Norhart may use the personal information we collect for the following
          purposes:
        </Description>

        <Description as="ul" className="list-group ms-3 gap-2">
          <li>To meet any contractual obligations with you;</li>
          <li>
            To provide and deliver our Services and Websites and related
            support;
          </li>
          <li>To allow you to log into and access your account;</li>
          <li>
            To process any purchases, transactions, or payments made on our
            Websites;
          </li>
          <li>
            To keep your information and our systems secure such as by detecting
            security incidents, and to be able to verify your identity and
            contact information;
          </li>
          <li>
            To alert you about updates, special offers, and other news,
            services, products, and information from us, our affiliates,
            partners, or others that may be of interest to you;
          </li>
          <li>
            For testing, research, analysis, and product development, including
            to develop and improve our content, Websites, Services, and
            advertising;
          </li>
          <li>
            To ensure our content, Websites, and Services are functional,
            efficient, and relevant to you and your interests;
          </li>
          <li>To respond to your inquiries and requests;</li>
          <li>
            To enforce this Privacy Policy, our Terms and Conditions, and our
            rights; and to protect our business interests and the interests and
            rights of third parties;
          </li>
          <li>
            To respond to law enforcement requests and as required by applicable
            law, court order, or governmental regulations; and
          </li>
          <li>
            To evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of our assets.
          </li>
        </Description>
        <LegalTitle className="blockquote text-start">
          IV. How Personal Information May Be Disclosed.
        </LegalTitle>
        <hr />
        <Description className="text-start">
          Norhart does not sell or rent any of your personal information. We
          only disclose your personal information for the following business and
          operational purposes:
        </Description>

        <Description as="ul" className="list-group ms-3 gap-2">
          <li>
            To our service providers: We rely on certain trusted third-party
            service providers to provide, process, improve, promote, or protect
            our Websites and Services. These service providers do things like
            help us store your data, process your payments, deliver the Websites
            and/or Services to you, improve our advertising, and secure our
            systems.
          </li>
          <li>
            To provide and deliver our Services and Websites and related
            support;
          </li>
          <li>
            To other entities related to Norhart: We may disclose personal
            information to our related companies, such as affiliates,
            subsidiaries, or other companies under common control with us.
          </li>
          <li>
            To our business partners who may be of interest to you: We may
            disclose personal information to our third party business partners
            or allow those partners to collect information about you (including
            through the use of cookies, pixels, or similar technologies) for
            their own business or marketing purposes, including to promote their
            products or services.
          </li>
          <li>
            In the event of a change in control: We may disclose personal
            information to other companies for the purpose of evaluating or
            conducting a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of our assets.
          </li>
          <li>
            As necessary or otherwise required by law: We also reserve the right
            to disclose personal information when it is reasonably necessary to
            conduct our business, protect Norhart’s rights and property, protect
            personal or public safety, or to comply with the law.
          </li>
        </Description>

        <LegalTitle className="blockquote text-start">
          V. Protection of Your Personal Information.
        </LegalTitle>
        <hr />
        <Description className="text-start">
          We take the security of your personal information seriously. Norhart
          has therefore put in place commercially reasonable measures to
          safeguard and secure the personal information that we collect.
        </Description>

        <Description className="text-start">
          Please remember, however, that the safety and security of your
          personal information also depend on you. Where you have chosen, or
          where we have given you, a user name and password for access to
          certain parts of the Websites and Services, you are responsible for
          keeping the user name and password confidential and for using secure
          connections. No electronic storage or method of transmission over the
          internet or a wireless network is completely secure, so Norhart cannot
          guarantee the absolute security of your personal information. As a
          result, you acknowledge that: (a) there are security and privacy
          limitations inherent to the Internet that are beyond Norhart’s
          control; and (b) the security, integrity, and privacy of your personal
          information collected or received by Norhart cannot be guaranteed.
        </Description>

        <LegalTitle className="blockquote text-start">
          VI. Control Norhart Communications.
        </LegalTitle>
        <hr />
        <Description className="text-start">
          We respect your privacy and give you an opportunity to opt-out of
          certain Norhart communications. From time to time, Norhart may contact
          you via email for the purpose of providing announcements, promotional
          offers, alerts, confirmations, surveys, and/or general communications.
          In order to improve our Services, we may receive a notification when
          you open an email from Norhart or click on a link therein
        </Description>
        <Description className="text-start">
          If you would like to stop receiving marketing or promotional
          communications via email from Norhart, you may opt out of such
          communications by replying “STOP” or clicking on the “unsubscribe”
          button.
        </Description>
        <Description className="text-start">
          By providing your phone number, you agree to receive text messages
          from Norhart regarding updates, offers, and promotions. Reply “STOP”
          to opt out. Message and data rates may apply.
        </Description>

        <LegalTitle className="text-start">VII. Data Transfers.</LegalTitle>
        <hr />

        <Description className="text-start">
          Because Norhart is a US-based company, your personal information may
          be collected and processed in the United States. Additionally, as part
          of the Website and Services offered to you, information that we
          receive about you may be transferred to and stored in one or more
          countries other than where you currently reside. For example, this may
          occur if any of our servers or third-party service providers are
          located in a country other than yours. These countries may have
          different data protection laws than your current residence. However,
          if we transfer your personal information in this way, we will put in
          place appropriate protection to make sure it is treated in line with
          this Privacy Policy.
        </Description>

        <LegalTitle className="text-start">
          VIII. Mobile and Email Notifications.
        </LegalTitle>
        <hr />
        <Description className="text-start">
          We try to give you the ability to opt-in/opt-out to any marketing
          communications we may send. For example, we may send you email
          relating to the Services we offer. You may also elect to receive
          certain marketing communications in accordance with your preferences.
          We will provide you with an opportunity to opt-out and/or unsubscribe
          from such communications.
        </Description>

        <LegalTitle className="blockquote text-start">
          IX. Retention.
        </LegalTitle>
        <hr />
        <Description className="text-start">
          We will keep your personal information for as long as is necessary to
          perform the processing activity for that information, as long as is
          set out in any relevant contract you hold with us, or as required or
          allowed by law. The relevant retention period is determined on a
          case-by-case basis because it depends on things like the nature of the
          data, why it is collected, why it is processed, and any legal or
          operational retention needs. For example, we may retain your data for
          as long as you use our Services. When the relevant processing activity
          has been completed, we may destroy your data or, alternatively,
          anonymize the data such that the data is not personally identifiable.
          We do not have any obligation or liability to you or any other party
          with respect to personal information that is deleted or anonymized.
        </Description>

        <LegalTitle className="text-start">
          X. Special Notice to Nevada Residents.
        </LegalTitle>
        <hr />
        <Description className="text-start">
          We do not sell, rent, or lease your personally identifiable
          information to third parties. However, if you are a resident of Nevada
          and would like to submit a request not to sell your personally
          identifiable information, you may do so by emailing us at{" "}
          <StyledGreenLink
            target="_blank"
            to="mailto:support@norhart.com?subject=Norhart%20Invest%20Support%20Question%20-%20"
          >
            contact@norhart.com
          </StyledGreenLink>
          .
        </Description>

        <LegalTitle className="text-start">
          XI. Questions, Comments, or Complaints.
        </LegalTitle>
        <hr />
        <Description className="text-start">
          If you have questions or comments about this Privacy Policy, we invite
          you to contact us at{" "}
          <StyledGreenLink
            target="_blank"
            to="mailto:support@norhart.com?subject=Norhart%20Invest%20Support%20Question%20-%20"
          >
            contact@norhart.com
          </StyledGreenLink>
          .
        </Description>

        <LegalTitle className="text-start">
          XII. Updates and Changes to this Privacy Policy.
        </LegalTitle>
        <hr />
        <Description className="text-start">
          This Privacy Policy is effective as of Jan 1, 2024. We may update or
          change this Privacy Policy from time to time. Your continued use of
          the Websites or Services after such changes will constitute your
          acknowledgement of and agreement to the modified Privacy Policy.
        </Description>
      </div>
    </PageWrapper>
  );
};

const LegalTitle = styled.blockquote`
  padding-top: 20px !important;
  font-size: 20px;
  line-height: 27px;
  font-weight: bold;
`;

interface PageWrapperProps {
  ispublic: boolean;
}

const PageWrapper = styled.div<PageWrapperProps>`
  color: ${({ theme, ispublic }) => (ispublic ? "white" : theme.PRIMARY_COLOR)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
`;

const Description = styled.blockquote`
  font-size: 22px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
  @media (max-width: 694px) {
    font-size: 16px;
    line-height: normal;
    margin: 0;
  }
  @media (max-width: 414px) {
    font-size: 14px;
  }
  @media (max-width: 375px) {
    font-size: 12px;
  }
  @media (max-width: 320px) {
    font-size: 10px;
  }
`;

const StyledGreenLink = styled(GreenLink)`
  overflow: hidden;
  text-overflow: ellipsis;
`;
