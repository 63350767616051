import { GreenLink } from "../../../styles";
import { AccordionItem } from "./AccordionItem";
import { CustomAccordion } from "../../../styles/accordion";
interface Props {
  ispublic?: boolean;
}

const FaqAccordion = ({ ispublic }: Props) => {
  return (
    <CustomAccordion>
      <br />
      <AccordionItem
        ispublic={ispublic}
        eventKey="0"
        header="What is Norhart Invest?"
        description="
          Norhart Invest is an inclusive investment platform designed to provide
          access to all individuals who are looking to grow their returns
          hassle-free. With our tailored investment offering specifically for
          Norhart ventures, investors have the chance to participate in a unique
          and potentially lucrative investment opportunity. While Norhart Invest
          offers a passive investment approach, we are committed to achieving
          optimal results for each of our investors."
      />
      <AccordionItem
        ispublic={ispublic}
        eventKey="1"
        header="What is the minimum investment amount?"
        description="Norhart Invest's current minimum investment amount is $2,500."
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="2"
        header="Is there a maximum investment amount?"
        description="For non accredited investors there is a limit of 10% of their annual
        income or net worth (whichever is greater). For accredited investors
        (pursuant to Rule 501(a) of Regulation D) there is no limit to how
        much you can invest. However the company may set its own limits as to
        how much one individual can invest with us."
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="3"
        header="How do I report on my taxes for my investments?"
        description="In a calendar year, if your promotional or interest income is more
          than $10, you will be issued a single, yearly 1099-INT form by January
          31st of the following year per regulations the Internal Revenue
          Service set forth. Principal losses are reported separately on a form
          1099-B per property. All tax documents will be available and
          accessible via the Norhart Invest Tax Center (coming at the end of the
          year)"
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="4"
        header="How do I get started on the Norhart Invest app?"
        description="You can access your account directly from your smartphone or laptop on
        our website to invest, check your balance, and transfer your balance
        in and out of your account.)"
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="5"
        header="
        
        What security is in place to keep my data safe?"
        description={
          <>
            Norhart Invest’s top priority is keeping your funds safe and
            accessible at your convenience. We ensure your personal information
            is safeguarded through our data-privacy assessments which follow a
            Secure Software Development Lifecycle.
            <p />
            <b>Security Measures:</b>
            <p />
            The Norhart Invest Security Team uses several industry-standard
            measures to protect your account and ensure your safe and secure
            experience with us. We understand that you place a lot of trust in
            our services, and we're constantly working to ensure that Norhart
            Invest is a safe place to build your real estate financial future.
            <p />
            Here are a few of the security measures we take to help keep your
            information safe:
            <br />
            <b>• Password</b> <br />
            <b>• Encryption</b> <br />
            <b>• Two-Factor Authentication</b> <br />
          </>
        }
      />
      <AccordionItem
        ispublic={ispublic}
        eventKey="6"
        header="Where can I access Norhart Invest from?"
        description="We are available in all US States."
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="7"
        header="Is Norhart Invest FDIC insured?"
        description="No, Norhart Invest is not FDIC insured. However, you can rest assured
        that Norhart Invest is regulated by the SEC (U.S. Securities and
        Exchange Commission), ensuring compliance with stringent financial
        regulations and providing an added layer of investor protection. Our
        commitment to transparency and security is paramount, making Norhart
        Invest a reliable and responsible choice for your investment needs."
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="8"
        header="How does Norhart Invest compare to other investments?"
        description="Unlike other high-volatile investment options such as stocks or bonds,
        Norhart Invest stands out with its fixed investment options backed by
        promissory notes at locked return rates. With Norhart Invest, you can
        enjoy the assurance of steady growth without the rollercoaster ride of
        unpredictable markets. Our platform prioritizes stability and
        security, making it a reliable choice for those seeking a more
        controlled and rewarding investment experience."
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="9"
        header="How does Norhart Invest work?"
        description={
          <>
            Norhart Invest's process is simple and rewarding. Here's how it
            works:
            <p />
            <b>• Create an Account:</b> Begin by signing up on our user-friendly
            platform.
            <p />
            <b>• ID Verification:</b> Complete a secure ID verification process
            to ensure compliance.
            <p />
            <b>• Select Fixed Promissory Notes:</b> Choose from a variety of
            fixed promissory notes with locked return rates.
            <p />
            <b>• Input Amount:</b> Decide on your investment amount to start
            your journey.
            <p />
            <b>• Purchase:</b> With just a few clicks, purchase your chosen
            promissory notes and watch your money grow.
            <p />
            Norhart Invest raises funds for future real-estate construction
            projects, eliminating bank profits in the process. This direct
            approach allows us to offer you higher returns on your invested
            amount, making your financial growth a priority. With Norhart
            Invest, investing has never been more accessible, transparent, and
            rewarding.
          </>
        }
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="10"
        header="What is a fixed promissory note?"
        description="A fixed promissory note is a financial instrument that represents a
        legal promise made by the issuer (in this case, Norhart Invest) to pay
        a fixed amount of interest over a specific period to the investor. It
        serves as evidence of debt and outlines the terms of the investment,
        including the principal amount, interest rate, maturity date, and
        payment schedule. Unlike variable investments, fixed promissory notes
        offer a stable and predictable return rate, providing investors with
        greater financial security and peace of mind."
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="11"
        header="What are Norhart Invest Fixed promissory notes?"
        description={
          <>
            Norhart Invest Fixed promissory notes are a series of investment
            options designed to cater to diverse financial goals. We offer five
            distinct fixed promissory notes with varying investment terms and
            attractive returns:
            <br />
            <b>• 6 months (7.60% returns)</b>
            <br />
            <b>• 9 months (7.90% returns)</b>
            <br />
            <b>• 12 months (8.40% returns)</b>
            <br />
            <b>• 18 months (8.45% returns)</b>
            <br />
            <b>• 24 months (8.50% returns)</b>
            <br />
            <b>• 36 months (9.00% returns)</b>
            <br />
            <b>• 60 months (10.00% returns)</b>
            <p />
            Each fixed promissory note provides a locked return rate over the
            specified term, ensuring steady growth for your investments. Whether
            you prefer short or long-term options, Norhart Invest has the
            perfect solution to suit your financial aspirations.
          </>
        }
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="12"
        header="What am I investing in?"
        description="When you invest with Norhart Invest, you are investing in fixed
        promissory notes that play a crucial role in driving the capital
        growth of our real-estate projects. By choosing to invest in these
        notes, you are providing essential funding that allows us to undertake
        and expand our future real-estate ventures. Your investment helps fuel
        the development and success of Norhart's real-estate projects, making
        you a valued partner in our journey towards financial prosperity and
        growth."
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="13"
        header="What does Norhart Invest do to protect my Investments?"
        description={
          <>
            At Norhart Invest, we understand that every investment comes with
            some level of risk. That's why we take several critical steps to
            protect your hard-earned money and ensure your peace of mind.
            <p />
            The most significant measure we take is investing our own money into
            the deposit pool alongside yours. This alignment of interests means
            that we have a vested stake in the success of every venture.
            <p />
            Moreover, if any real estate project were to encounter difficulties,
            our investment would be at risk first. This ensures that you are in
            a more secure position, and it motivates us to maintain the utmost
            diligence and care in managing our projects.
            <p />
            We believe in complete transparency, which is why we do not charge
            any fees. The rate you see is the rate you'll earn—no hidden
            surprises or deductions.
            <p />
            Rest assured, we are dedicated to safeguarding your investments and
            working tirelessly to uphold the integrity of each venture. With
            Norhart Invest, you can enjoy the potential for financial growth
            with the added assurance of our responsible and committed approach
            to protection.
          </>
        }
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="14"
        header="Do I have to be an accredited investor to invest with Norhart Invest?"
        description="Not at all! At Norhart Invest, we're changing the game by offering an
        inclusive platform for all investors, regardless of whether you are
        accredited or non-accredited. Our mission is to democratize
        investments and make wealth-building opportunities accessible to
        everyone. So, whether you're a seasoned investor or just getting
        started, Norhart Invest welcomes you with open arms."
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="15"
        header="Can I withdraw my investments before the maturity period?"
        description="As much as we value your investment with Norhart Invest, we do not
        permit early withdrawals before the maturity period. Our fixed
        promissory notes are designed with specific terms and locked return
        rates, ensuring stability and predictability for all investors. While
        we cannot facilitate withdrawals before the agreed-upon maturity
        period, we're committed to providing you with a secure and rewarding
        investment experience throughout the duration of your investment. If
        you have any further questions or need additional information, you can
        get in touch with our team on invest@norhart.com email. Thank you for
        your understanding and trust in Norhart Invest!"
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="16"
        header="How do I transfer funds to and from my Norhart Invest account?"
        description={
          <>
            Transferring funds to and from your Norhart Invest account is
            simple! To invest, simply link your bank account securely to our
            platform. This seamless process allows you to fund your Norhart
            Invest account effortlessly.
            <p />
            After the maturity period of your fixed promissory note, your
            investment will be available to withdraw back to your linked bank
            account. This ensures a convenient and straightforward experience,
            making it easy for you to access your returns.
          </>
        }
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="17"
        header="Why do you need my bank account and Social Security Number to set up
        my account?"
        description={
          <>
            We understand that providing personal information is a significant
            concern, but rest assured, your privacy and security are of utmost
            importance to us.
            <p />
            When setting up your Norhart Invest account, your Social Security
            Number is required for identity verification purposes. This process
            is essential to comply with regulatory requirements and ensure that
            only authorized individuals have access to Norhart Invest account.
            <p />
            We request your bank account details to facilitate the purchase and
            withdrawal of investments. We have partnered with Plaid, which is a
            safe way to link your financial accounts such as Chase, Wells Fargo,
            Citibank, Bank of America, and more.
          </>
        }
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="18"
        header="Is Norhart Invest compatible with credit union accounts?"
        description={
          <>
            Our current compatibility with credit union accounts is limited, but
            we are actively expanding the list of supported credit unions that
            can be linked to your Norhart Invest account for your convenience.
            <p />
            Our commitment is to provide you with the most convenient and
            accessible investment experience possible. We understand the value
            of choice and are dedicated to broadening our range of supported
            financial institutions. Keep an eye out for updates as we continue
            to enhance our platform to cater to a wider range of credit union
            accounts. Your investment journey with Norhart Invest is important
            to us, and we appreciate your patience and understanding as we work
            to improve your experience.
          </>
        }
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="19"
        header="What's an IRA and how does it work?"
        description="An Individual Retirement Account (IRA) is a tax-advantaged savings
        account that allows individuals to save and invest for retirement. It
        comes in various types, including Traditional and Roth IRAs, each
        offering specific tax advantages."
      />

      <AccordionItem
        ispublic={ispublic}
        eventKey="20"
        header="How to invest with your IRA on Norhart Invest?"
        description={
          <>
            To invest with your IRA, send us an email on invest@norhart.com or
            book a call with one of our Norhart Invest agent to walk you though
            the process:{" "}
            <GreenLink
              to="https://outlook.office365.com/owa/calendar/NorhartInvestIRA401KRollover@norhart.com/bookings/"
              target="_blank"
              rel="noreferrer"
            >
              https://outlook.office365.com/owa/calendar/NorhartInvestIRA401KRollover@norhart.com/bookings/
            </GreenLink>
          </>
        }
      />
    </CustomAccordion>
  );
};

export default FaqAccordion;
