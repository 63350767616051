import { formatNumbers, switchDateFormat } from ".";
import { DashboardInfo, CustomError, MappedDashboardInfo } from "../types";

const getData = async () => {
  const path = "/dashboard";
  const response = await fetch(path, {
    method: "GET",
  });
  const res = await response.json();
  if (response.ok) {
    return res as DashboardInfo;
  }
  CustomError.throw(res, res?.error || res?.message);
};

const mapData = (data: DashboardInfo): MappedDashboardInfo => {
  const {
    upcomingMaturityDate,
    allPromissoryNotesNumbers,
    maturedPromissoryNotesNumbers,
    immaturePromissoryNotesNumbers,
    totalInterestsPerMonth,
  } = data;
  return {
    investments: {
      active: immaturePromissoryNotesNumbers.totalInvestments,
      matured: maturedPromissoryNotesNumbers.totalInvestments,
      all: allPromissoryNotesNumbers.totalInvestments,
    },
    earned: {
      active: immaturePromissoryNotesNumbers.totalInterests,
      matured: maturedPromissoryNotesNumbers.totalInterests,
      all: allPromissoryNotesNumbers.totalInterests,
    },
    upcomingMaturityDate: upcomingMaturityDate
      ? switchDateFormat(upcomingMaturityDate)
      : "••/••/••••",
    totalInterestsPerMonth: totalInterestsPerMonth.map(
      ({ totalInterest, year, monthName }) => {
        return {
          totalInterest: "$" + formatNumbers(totalInterest, 100, 2),
          year,
          monthName: monthName.slice(0, 3),
        };
      }
    ),
  };
};

export const Dashboard = {
  getData,
  mapData,
};
