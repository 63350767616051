import { ListGroup } from "react-bootstrap";
import { NorhartWrapper } from "../../../components/NorhartWrapper";
import {
  CenteredNumber,
  ViewAllPagesWrapper,
  ProductText,
} from "../../../styles";
import { Item } from "../../../styles/list";
import { useParams } from "react-router-dom";
import { formatDate, formatNumbers } from "../../../utils";
import {
  Description,
  DetailBoldText,
  DetailsTitle,
} from "../../../styles/details";
import { usePromissoryNotesById } from "../../../hooks/usePromissoryNoteById";
import { Loading } from "../../../components/Loading";
import { useTransactions } from "../../../hooks/useTransactions";
import LatestTransactionsWithPagination from "../../../components/LatestTransactionWithPagination";
import { CacheKeys } from "../../../types";

const PromissoryNote = () => {
  const { id } = useParams();
  const {
    data: transactions,
    loading: loadingTransactions,
    handleNext,
    handlePrevious,
    isFirstPage,
    isLastPage,
  } = useTransactions(CacheKeys.promissoryNotes + id, 2, id);

  const {
    data: promissoryNote,
    loading,
    // updateAutoReinvest,
    // loadingUpdate,
  } = usePromissoryNotesById(id as string);

  if (!promissoryNote || loading) {
    return <Loading />;
  }

  const {
    currentBalance,
    interestRate,
    product: { name, term },
    startDate,
    endDate,
    principalAmount,
  } = promissoryNote;
  return (
    <NorhartWrapper title="Promissory Note">
      <ViewAllPagesWrapper>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <CenteredNumber className="badge">
            ${formatNumbers(currentBalance.posted || currentBalance.pending)}
          </CenteredNumber>
          <ProductText>{name}</ProductText>
        </div>
        <div>
          <DetailsTitle>Details</DetailsTitle>
          <ListGroup>
            <Item>
              <DetailBoldText>Rate:</DetailBoldText>
              <Description>{interestRate}%</Description>
            </Item>
            <Item>
              <DetailBoldText>Term:</DetailBoldText>
              <Description>{term} months</Description>
            </Item>{" "}
            <Item>
              <DetailBoldText>Start Date:</DetailBoldText>
              <Description>
                {formatDate(startDate.split("T")[0], true)}
              </Description>
            </Item>
            <Item>
              <DetailBoldText>End Date:</DetailBoldText>
              <Description>{formatDate(endDate, true)}</Description>
            </Item>
            <Item>
              <DetailBoldText>Principal:</DetailBoldText>
              <Description>${formatNumbers(principalAmount!)}</Description>
            </Item>
            <Item>
              <DetailBoldText>Pending:</DetailBoldText>
              <Description>
                ${formatNumbers(currentBalance.pending)}
              </Description>
            </Item>
          </ListGroup>
        </div>
        {/* <div className="d-flex flex-column w-100">
          <TitleWrapper className="w-100">
            <ListTitle>Reinvest</ListTitle>
          </TitleWrapper>
          <ListGroup className="w-100">
            <Item>
              <TextWrapper>
                <Title style={{ opacity: "0.5" }}>Activate</Title>
              </TextWrapper>
              <Form
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {loadingUpdate && (
                  <Loading width="20px" height="20px" withoutMargin />
                )}
                <Form.Check
                  type="switch"
                  id="my-switch"
                  label={promissoryNote.autoReInvest ? "On" : "Off"}
                  checked={promissoryNote.autoReInvest}
                  onChange={async () =>
                    await updateAutoReinvest(!promissoryNote.autoReInvest)
                  }
                  disabled={loadingUpdate}
                />
              </Form>
            </Item>
          </ListGroup>
        </div> */}
        <LatestTransactionsWithPagination
          transactions={transactions}
          loading={loadingTransactions}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          isFirstPage={isFirstPage}
          isLastPage={isLastPage}
        />
      </ViewAllPagesWrapper>
    </NorhartWrapper>
  );
};

export default PromissoryNote;
