import { useState, useEffect } from "react";
import { Auth } from "../utils";

export const useIsLogged = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(false);
  const [groups, setGroups] = useState<string[]>([]);
  useEffect(() => {
    Auth.isLoggedIn(setIsLoading, setIsLogged, setGroups);
  }, []);

  return { isLoading, isLogged, groups };
};
