import styled from "styled-components";
import {
  Container,
  ContentHeroLeftSubTitle,
  ContentHeroLeftTitle,
  ContentHeroSpace,
} from "../../../styles/adds";

/** props */
interface Props {
  backgroundColorOne: string;
  backgroundColorTwo: string;
  badgeTitle: string;
  bottomMargin?: number;
  buttonName: string;
  buttonUrl?: string;
  buttonName2: string;
  buttonUrl2?: string;
  buttonName3: string;
  buttonUrl3?: string;
  colSizeOne: number;
  colSizeTwo: number;
  fontTextColor: string;
  image: string;
  imageTitle: string;
  subtitle: string;
  title: string;
  titleTopLeft: string;
  imageSubtitle?: string;
  imageTitleSubtitle?: string;
  asoEffect: string;
}

/** const */
const HeroInvestRightSection: React.FC<Props> = (props) => {
  return (
    <section
      className={`container-fluid text-center pt-5 pb-${props.bottomMargin}`}
      style={{
        background: `linear-gradient(${props.backgroundColorOne} 30%, ${props.backgroundColorTwo} 70%)`,
      }}
    >
      <Container className="container pl-2 pr-2">
        <ContentHeroLeftTitle
          className="hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
          style={{ fontSize: "15px", color: `${props.fontTextColor}` }}
        >
          {props.titleTopLeft}
        </ContentHeroLeftTitle>
        <ContentHeroLeftTitle
          as="hr"
          className="hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            height: "3px",
            color: "#999999",
            opacity: "0.5",
          }}
        />
        <ContentHeroSpace className="hidden-xs"></ContentHeroSpace>

        <div className="row">
          <div
            className={`col-sm-${props.colSizeOne}`}
            style={{
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <ContentHeroLeftTitle
              className="text-start m-0 p-0"
              style={{ color: `${props.fontTextColor}` }}
            >
              {props.title}
              <br />
              <span
                className="badge badge-secondary ms-0 mt-2 mb-4"
                style={{
                  fontSize: "0.9rem",
                  verticalAlign: "middle",
                  backgroundColor: "#444444",
                }}
              >
                {props.badgeTitle}
              </span>
            </ContentHeroLeftTitle>
            <ContentHeroLeftSubTitle
              className="m-0 p-0"
              style={{
                color: `${props.fontTextColor}`,
                opacity: "0.7",
              }}
            >
              {props.subtitle} <br />
            </ContentHeroLeftSubTitle>

            {!!props.imageSubtitle && (
              <img
                className="pt-5 pb-0 ps-4 pe-4"
                data-aos={props.asoEffect}
                src={props.imageSubtitle}
                loading="lazy"
                decoding="async"
                alt={props.imageTitleSubtitle}
                width="550"
                height="60"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            )}

            {!!props.buttonUrl && (
              <WrapperButtons className="mt-5">
                <a
                  className="btn btn-md btn-outline-dark "
                  rel="noreferrer"
                  href={props.buttonUrl}
                  target="_blank"
                  style={{
                    fontWeight: "BOLD",
                  }}
                >
                  {props.buttonName}
                </a>

                {props.buttonUrl2 && (
                  <a
                    className="btn btn-md btn-outline-dark animate__animated animate__animated animate__pulse animate__infinite animate__slow ms-3"
                    href={props.buttonUrl2}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      fontWeight: "BOLD",
                    }}
                  >
                    {props.buttonName2}
                  </a>
                )}

                {props.buttonUrl2 && (
                  <a
                    className="btn btn-md btn-outline-dark animate__animated animate__animated animate__pulse animate__infinite animate__slow ms-3"
                    href={props.buttonUrl3}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      fontWeight: "BOLD",
                    }}
                  >
                    {props.buttonName3}
                  </a>
                )}
              </WrapperButtons>
            )}
          </div>

          <div className={`col-sm-${props.colSizeTwo} mt-5`}>
            <img
              className="pb-0 pl-0 pr-0 d-none d-sm-block"
              data-aos={props.asoEffect}
              src={props.image}
              loading="lazy"
              decoding="async"
              alt={props.imageTitle}
              width={900}
              height={400}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
            <img
              className="pb-0 pl-5 pr-5 ps-5 pe-5 d-block d-sm-none"
              src={props.image}
              loading="lazy"
              decoding="async"
              alt={props.imageTitle}
              width={900}
              height={400}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

const WrapperButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: space-around;
  > a {
    @media (max-width: 300px) {
      font-size: 12px;
    }
  }
`;

export default HeroInvestRightSection;
