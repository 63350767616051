import { Alert, Spinner } from "react-bootstrap";
import styled from "styled-components";

export const ConfirmButton = styled.button`
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.ALERT_BACKGROUND : theme.GREEN};
  color: ${({ theme }) => theme.SECONDARY_COLOR};
  font-size: 15px;
  padding: 5px;
  width: 170px;
  width: 100%;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CancelButton = styled(ConfirmButton)`
  background-color: ${({ theme }) => theme.BANNER_BACKGROUND};
  color: ${({ theme }) => theme.BANNER_COLOR};
  border: none;
  width: 100%;
`;

export const BottomDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

export const CustomStrong = styled.div`
  font-weight: 700;
  font-size: 15px;
  display: flex;
  gap: 8px;
  align-items: center;
  @media (max-width: 410px) {
    font-size: 14px;
  }
  @media (max-width: 350px) {
    font-size: 13px;
  }
`;
export const GreyText = styled.div`
  color: #acacac;
  font-size: 15px;
  @media (max-width: 410px) {
    font-size: 14px;
  }
  @media (max-width: 350px) {
    font-size: 13px;
  }
`;

export const StyledDiv = styled.div`
  border-top: 1px solid #acacac58;
  border-bottom: 1px solid #acacac58;
  padding: 10px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SwitchWrapper = styled(StyledDiv)`
  gap: 10px;
  display: flex;
  justify-content: start;
`;

export const StyledNumber = styled.h3`
  margin-bottom: 0px;
`;

export const StyledText = styled.div`
  font-size: 16px;
  color: #acacac;
  text-align: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const Image = styled.img`
  object-fit: cover;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledLabel = styled.div`
  padding-bottom: 25px;
  @media (max-width: 500px) {
    padding-bottom: 5px;
  }
`;

interface SpinnerProps {
  hide: string;
}

export const CustomSpinner = styled(Spinner)<SpinnerProps>`
  display: ${(props) => (props.hide === "true" ? "none" : "block")};
  padding: 8px;
`;

export const CustomAlert = styled(Alert)`
  font-size: 12px;
  padding: 8px;
  background-color: ${({ theme }) => theme.ALERT_BACKGROUND};
  color: ${({ theme }) => theme.PRIMARY_COLOR};
  border-color: ${({ theme }) => theme.INPUT_BORDER_COLOR};
  @media (max-width: 350px) {
    font-size: 11px;
  }
`;

interface TermsAndConditionsProps {
  textColor?: string;
}

export const TermAndConditions = styled.a<TermsAndConditionsProps>`
  text-decoration: none;
  color: ${({ theme, textColor }) => (textColor ? textColor : theme.GREEN)};
`;
