import { flexAccountKey } from "../components/addMoney/constants";
import { FlexAccount } from "./flex-account";
import { AddMoneyInitialData, ModalInitialData } from "./payment-modal";
import { LedgerTransactionOutput } from "./transactions";

export enum TransactionGroup {
  Invest = "invest",
  Withdraw = "withdraw",
  Deposit = "deposit",
}

interface Group {
  transactionGroup?: TransactionGroup;
}

export class CreatePaymentInput {
  accountId?: string;
  amount: string;
  productId?: string;
  transactionGroup: TransactionGroup;
  autoReInvest?: boolean;
  constructor(input: (ModalInitialData | AddMoneyInitialData) & Group) {
    const { amount, product, account, transactionGroup } = input;
    const amountNumber = Number(amount.replace(/,/g, ""));
    this.amount = Math.floor(amountNumber * 100).toString();
    this.transactionGroup = transactionGroup
      ? transactionGroup
      : TransactionGroup.Invest;
    if (product.id !== flexAccountKey) {
      this.productId = product.id;
    } else {
      this.transactionGroup = TransactionGroup.Deposit;
    }
    if (account.id !== flexAccountKey) {
      this.accountId = account.id;
    }
    if (this.transactionGroup === TransactionGroup.Invest) {
      this.autoReInvest = (input as AddMoneyInitialData)?.autoReInvest;
    }
  }
}

export interface PromissoryNote {
  id: string;
  name: string;
  term: number;
  amount: number;
  interest: number;
  completed: boolean;
}
export interface PaymentResponse {
  flexAccount: FlexAccount;
  promissoryNote?: PromissoryNote;
  transaction: LedgerTransactionOutput;
}

export interface VerifyPaymentCreationOutput {
  paymentId: string;
  hasSigned: boolean;
  amount: number;
  contractURL: string;
}
