import { useCallback } from "react";
import { CacheKeys } from "../types";
import { useFetch } from "./useFetch";
import { FlexAccount } from "../utils";
import { ResponseStatus } from "../types/ResponseStatus";
import { notifications } from "../utils/notifications";

export const useFlexAccountInterest = () => {
  const fetchFunction = useCallback(async () => {
    const response = await FlexAccount.getInterest();
    return response?.value.toString();
  }, []);
  const { data, loading, refreshData } = useFetch({
    cacheKey: CacheKeys.flexAccountInterest,
    fetchFunction,
  });

  const updateInterest = async (interest: string) => {
    try {
      await FlexAccount.updateInterest(interest);
      refreshData(interest);
      notifications({
        status: ResponseStatus.SUCCESS,
        successMessage: `The interest information was updated successfully`,
      });
    } catch (e: any) {
      console.log(e);
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage:
          e?.message || `Unexpected error updating the interest information`,
      });
    }
  };

  return { data, loading, updateInterest };
};
