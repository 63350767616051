import { useEffect } from "react";
import { Auth } from "../utils";

export const useUpdateGtmUser = () => {
  useEffect(() => {
    const updateGtmUser = async () => {
      const { token, email } = await Auth.getUserName();

      window.dataLayer.push({ user_id: token, email_address: email });
      const attr = await Auth.getUserAttributes();
      if ("income" in attr && attr["income"] !== "") {
        window.dataLayer.push({ income: attr["income"] });
      }
    };
    updateGtmUser();
  }, []);
};
