import { useState, useEffect } from "react";

// Custom hook to change state after a specified number of seconds
export const useDelay = (delayInSeconds: number) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true);
    }, delayInSeconds * 1000);
    return () => clearTimeout(timer);
  }, [delayInSeconds]);

  const resetCount = () => {
    setReady(false);
    setTimeout(() => {
      setReady(true);
    }, delayInSeconds * 1000);
  };

  return { ready, resetCount };
};
