import { Accordion } from "react-bootstrap";
import { AccordionBody } from "../../../styles/accordion";
import styled from "styled-components";

interface Props {
  eventKey: string;
  header: string;
  description: React.ReactNode;
  ispublic?: boolean;
}

export const AccordionItem = ({
  eventKey,
  header,
  description,
  ispublic,
}: Props) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Header ispublic={ispublic}>{header}</Header>
      <AccordionBody>{description}</AccordionBody>
    </Accordion.Item>
  );
};

interface HeaderProps {
  ispublic: boolean;
}

const Header = styled(Accordion.Header)<HeaderProps>`
  > button {
    padding-left: ${({ ispublic }) => (!ispublic ? "0" : "1rem")};
  }
`;
