import { FormEvent } from "react";
import { useForm } from "../../hooks/useForm";
import {
  BottomDiv,
  CancelButton,
  ConfirmButton,
  CustomSpinner,
  Form,
  InputWrapper,
  StyledLabel,
} from "../../styles/popUp";
import { FormInput } from "../forms/formsComponents/FormInput";
import { FormSelect } from "../forms/formsComponents/FormSelect";
import { AddMoneyInitialData, Steps } from "../../types/payment-modal";
import {
  initialErrors,
  initialTouch,
  customErrors,
  SUGGESTED_AMOUNT,
} from "./constants";
import { notifications } from "../../utils/notifications";
import { ResponseStatus } from "../../types/ResponseStatus";
import { TransactionGroup } from "../../types/payments";
import { formatNumbers } from "../../utils";

interface Props {
  onClose: (step: Steps) => void;
  step: Steps;
  nextStep: (step: Steps) => void;
  onSubmitAmount: (
    fields: AddMoneyInitialData,
    transactionGroup?: TransactionGroup
  ) => Promise<void>;
  bankOptions: string[][];
  attributes: AddMoneyInitialData;
}

const AmountForm = ({
  step,
  nextStep,
  onSubmitAmount,
  onClose,
  bankOptions,
  attributes,
}: Props) => {
  const {
    fields,
    errors,
    handleErrors,
    handleFields,
    handleTouch,
    submitLoading,
    onSubmit,
    resetForm,
    handleInputChange,
  } = useForm({
    initialFields: attributes,
    initialErrors: initialErrors,
    initialTouch: initialTouch,
    customErrors: customErrors,
  });

  const onSubmitForm = async () => {
    try {
      await onSubmitAmount(fields);
      resetForm();
      nextStep(step);
    } catch (e: any) {
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage: e?.message || "Something went wrong creating the payment",
      });
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    await onSubmit(e, onSubmitForm);
  };

  const amountValidation = (value: string) => {
    if (value === "0")
      return { isValid: false, message: "Amount must be greater than 0" };
    return { isValid: true };
  };

  const handleClose = () => {
    resetForm();
    onClose(step);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <InputWrapper>
          <StyledLabel>From:</StyledLabel>
          <FormSelect
            name="account"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            options={bankOptions}
            value={fields.account.id}
            error={errors.account}
            required
            placeHolder="Select a bank account..."
            label="Bank Account"
            notShowLabel={true}
            data-cy="select-bank-account"
          />
        </InputWrapper>
        <InputWrapper>
          <StyledLabel>Amount:</StyledLabel>
          <FormInput
            name="amount"
            value={fields.amount}
            error={errors.amount}
            handleErrors={handleErrors}
            onChange={handleInputChange}
            handleTouch={handleTouch}
            validation={amountValidation}
            required
            isMoney
            placeholder={`Amount (Example: $${formatNumbers(
              SUGGESTED_AMOUNT,
              1,
              0
            )})`}
            label="Amount"
            notShowLabel={true}
            data-cy="amount"
          />
          <div></div>
        </InputWrapper>
        <BottomDiv>
          <ConfirmButton
            data-cy="add-money-submit"
            type="submit"
            disabled={submitLoading}
          >
            <CustomSpinner hide={(!submitLoading)?.toString()} size="sm" />
            {!submitLoading && "Continue"}
          </ConfirmButton>
          <CancelButton type="button" onClick={handleClose}>
            Cancel
          </CancelButton>
        </BottomDiv>
      </Form>
    </>
  );
};

export default AmountForm;
