import styled from "styled-components";
import NorhartModal from "../NorhartModal";
import { GreenButton } from "../../styles";
import { MINIMUM_AMOUNT } from "../addMoney/constants";

interface Props {
  show: boolean;
  onClose: () => void;
  withdrawal: () => void;
}

const WithdrawError = ({ show, onClose, withdrawal }: Props) => {
  const handleCancel = () => {
    onClose();
  };
  return (
    <NorhartModal
      title="Confirm your transfer"
      show={show}
      onClose={handleCancel}
      canClose={true}
      size="sm"
    >
      <>
        <div className="text-center p-4">
          We require a minimum balance of ${MINIMUM_AMOUNT} in your flex account
        </div>
        <ButtonsDiv>
          <GreenButton onClick={handleCancel}>Cancel</GreenButton>
          <GreenButton onClick={withdrawal}>Withdrawal</GreenButton>
        </ButtonsDiv>
      </>
    </NorhartModal>
  );
};

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 10px;
`;

export default WithdrawError;
