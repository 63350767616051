export const initialErrors = {
  account: "",
  amount: "",
  product: "",
  autoReInvest: "",
  form: "",
};
export const initialTouch = {
  account: false,
  amount: false,
  product: false,
  autoReInvest: false,
};
export const customErrors = {
  account: "",
  amount: "",
  product: "",
  autoReInvest: "",
  DefaultError: "",
};

export const MINIMUM_AMOUNT = 2500;

export const SUGGESTED_AMOUNT = 5000;

export const flexAccountKey = "FlexAccount";
