import LatestTransactionsWithPagination from "../../components/LatestTransactionWithPagination";
import { NorhartWrapper } from "../../components/NorhartWrapper";
import { useTransactions } from "../../hooks/useTransactions";
import { CacheKeys } from "../../types";

const Transactions = () => {
  const {
    data,
    loading: loadingTransactions,
    handleNext,
    handlePrevious,
    isFirstPage,
    isLastPage,
  } = useTransactions(CacheKeys.allTransactions, 10);
  return (
    <NorhartWrapper title="All Transactions">
      <LatestTransactionsWithPagination
        transactions={data}
        loading={loadingTransactions}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        isFirstPage={isFirstPage}
        isLastPage={isLastPage}
      />
    </NorhartWrapper>
  );
};
export default Transactions;
