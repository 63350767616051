import { CacheKeys, CustomError } from "../types";
import { BankAccountResponse } from "../types/bank-accounts";
import { UserData, UpdateUserBody, UserPreferences } from "../types/users";
import { LocalStorage } from "./localStorage";

const getData = async () => {
  const path = `/users`;
  const response = await fetch(path, {
    method: "GET",
  });
  const res = await response.json();
  if (response.ok) {
    return res as UserData;
  }
  CustomError.throw(res, res?.error || res?.message);
};

const updateData = async (updateData: UpdateUserBody) => {
  const path = `/users`;
  const response = await fetch(path, {
    method: "PATCH",
    body: JSON.stringify(updateData),
  });
  const res = await response.json();
  if (!response.ok) {
    CustomError.throw(res, res?.error || res?.message);
  }
  return res as UserData;
};

const getBankAccounts = async () => {
  const path = `/bank-accounts`;
  const response = await fetch(path, {
    method: "GET",
  });
  const res = await response.json();
  if (!response.ok) {
    CustomError.throw(res, res?.error || res?.message);
  }
  return res as BankAccountResponse;
};

const getNetAssets = async () => {
  const path = `/users/financials`;
  const response = await fetch(path, {
    method: "GET",
  });
  const res = await response.json();
  if (!response.ok) {
    CustomError.throw(res, res?.error || res?.message);
  }
  return res as { netAssets: number };
};

const updateNetAssets = async (netAssets: string) => {
  const netAssetsNumber = Number(netAssets.replace(/,/g, ""));
  const path = `/users/financials`;
  const response = await fetch(path, {
    method: "PATCH",
    body: JSON.stringify({ netAssets: netAssetsNumber }),
  });
  if (response.ok) {
    return;
  } else {
    const res = await response.json();
    CustomError.throw(res, res?.error || res?.message);
  }
};

const sendSocialMedia = async (googleAnalyticsClientId: string) => {
  const path = `/users/social-media`;
  const response = await fetch(path, {
    method: "PATCH",
    body: JSON.stringify({ googleAnalyticsClientId }),
  });
  if (response.ok) {
    return;
  } else {
    const res = await response.json();
    CustomError.throw(res, res?.error || res?.message);
  }
};

const updateUserPreferences = async (props: UserPreferences) => {
  const path = `/user-preferences`;
  const response = await fetch(path, {
    method: "PATCH",
    body: JSON.stringify({ ...props }),
  });
  updateUserPreferencesInCache(props);
  if (response.ok) {
    return;
  } else {
    const res = await response.json();
    CustomError.throw(res, res?.error || res?.message);
  }
};

const updateUserPreferencesInCache = (newPreferences: UserPreferences) => {
  const currentPreferences = localStorage.getItem(CacheKeys.userPreferences);
  if (!currentPreferences) {
    localStorage.setItem(
      CacheKeys.userPreferences,
      JSON.stringify({ ...newPreferences })
    );
    return;
  }
  const parsedPreferences = JSON.parse(currentPreferences);
  localStorage.setItem(
    CacheKeys.userPreferences,
    JSON.stringify({ ...parsedPreferences, ...newPreferences })
  );
};

const getUsersPreferences = async () => {
  const path = `/user-preferences`;
  const response = await fetch(path, {
    method: "GET",
  });
  const res = await response.json();
  if (!response.ok) {
    CustomError.throw(res, res?.error || res?.message);
  }
  LocalStorage.setUserData(CacheKeys.userPreferences, JSON.stringify(res));
  return res as UserPreferences;
};

const getUsersPreferencesFromLocalStorage = () => {
  const preferences = localStorage.getItem(CacheKeys.userPreferences);
  if (!preferences) return;
  return JSON.parse(preferences) as UserPreferences;
};

export const User = {
  getData,
  updateData,
  getBankAccounts,
  getNetAssets,
  updateNetAssets,
  sendSocialMedia,
  updateUserPreferences,
  getUsersPreferences,
  getUsersPreferencesFromLocalStorage,
  updateUserPreferencesInCache,
};
