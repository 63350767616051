import {
  NOTIFICATION_CONTAINER,
  NotificationContent,
  Store,
} from "react-notifications-component";
import { StoreNotifications } from "../components/StoreNotifications";
import { ResponseStatus } from "../types/ResponseStatus";

interface Props {
  status?: ResponseStatus;
  errorMessage?: string;
  successMessage?: string;
  duration?: number;
  container?: NOTIFICATION_CONTAINER;
  content?: NotificationContent;
  click?: boolean;
}

export const notifications = ({
  status,
  errorMessage,
  successMessage,
  duration = 5000,
  container = "top-right",
  content,
  click,
}: Props) => {
  Store.addNotification({
    // message,
    // type,
    container,
    content: content ? (
      content
    ) : (
      <StoreNotifications
        status={status}
        errorMessage={errorMessage}
        successMessage={successMessage}
      />
    ),
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration,
      click,
    },
  });
};
