import React from "react";
import { Carousel } from "react-bootstrap";
import {
  InvestImageButton,
  InvestImageName,
  InvestImageTitle,
} from "../../../styles/adds";

interface Props {
  items: {
    name: string;
    title: string;
    image: string;
    buttonName: string;
    buttonUrl: string;
  }[];
}

const CarouselComponent: React.FC<Props> = ({ items }) => {
  return (
    <Carousel controls={false} indicators={true} interval={500000}>
      {items.map((item, i) => {
        return (
          <Carousel.Item key={i}>
            <img src={item.image} className="d-block w-100" alt="" />
            <Carousel.Caption className="h-100 d-flex align-items-center">
              <div className="d-flex flex-column gap-2 align-items-start">
                <InvestImageName className="p-0 m-0" style={{ float: "left" }}>
                  {item.name}
                </InvestImageName>
                <InvestImageTitle
                  className="fs-5 p-0 m-0 "
                  style={{ opacity: "0.6" }}
                >
                  {item.title}
                </InvestImageTitle>
                <InvestImageButton
                  href={item.buttonUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="grid gap-2 d-md-block btn btn-outline-light btn-md"
                >
                  {item.buttonName}
                </InvestImageButton>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default CarouselComponent;
