import React from "react";

interface Props {
  imageHero?: string;
}

const ImageComponent: React.FC<Props> = (props) => {
  return (
    <div
      className="container-fluid text-center p-0 m-0 border-1px"
      style={{
        color: "#FFF",
        backgroundColor: "#FFF",
      }}
    >
      <div className="jumbotron text-center">
        <div className="w-100">
          <div>
            <img
              src={props.imageHero}
              alt=""
              loading="eager"
              className="img-fluid w-100"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageComponent;
