import { useLocation } from "react-router-dom";
import { publicRoutes, privateRoutes } from "../types";

export const usePagePath = () => {
  const location = useLocation();
  const path = location.pathname;
  const ispublic = Object.values(publicRoutes).includes(path as publicRoutes);
  const isPrivate = Object.values(privateRoutes).includes(
    path as privateRoutes
  );
  return { isPrivate, ispublic, path };
};
