import { createContext, FunctionComponent } from "react";
import { LightColorPalette } from "../styles/constants";
import { useDarkMode } from "./useDarkMode";
import { DefaultTheme } from "styled-components";

interface Context {
  switchColorPalette: () => void;
  ColorsPalette: DefaultTheme;
}

export const AppContext = createContext<Context>({
  switchColorPalette: () => {},
  ColorsPalette: LightColorPalette,
});

interface ProviderProps {
  children?: JSX.Element;
}

const AppProvider: FunctionComponent<ProviderProps> = ({ children }) => {
  const { ColorsPalette, switchColorPalette } = useDarkMode();
  const value = {
    ColorsPalette,
    switchColorPalette,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
