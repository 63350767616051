import styled from "styled-components";
import GraphicChart from "../../../components/GraphicChart";
import { GRAPHIC_COLORS } from "../../../styles";
import { BannerWrapper } from "../styles";
import { formatNumbers } from "../../../utils";
import { useAnimatedCounter } from "../../../hooks/useAnimatedCounter";
import { mapGraphicColors } from "../../../utils";
import { InterestsPerMonth } from "../../../types";
// import BannerImage from "../../../assets/images/invest-dash-ad-image-0.png";
interface BannerProps {
  balance: number;
  onClickView?: () => void;
  latestMonthAmounts: InterestsPerMonth<string>[];
}

const InvestBanner: React.FC<BannerProps> = ({
  balance,
  onClickView,
  latestMonthAmounts,
}) => {
  const count = useAnimatedCounter({ end: balance, start: balance / 2 });
  return (
    <BannerWrapper>
      <div className="hstack gap-5">
        {/* <div className="h-25 align-self-end d-none d-md-block">
          <img
            src={BannerImage}
            alt=""
            className="img-fluid"
            style={{ maxHeight: "200px" }}
          />
        </div> */}
        <div className="vstack gap-3 align-items-start d-none d-sm-block">
          <div className="fw-light">NORHART INVEST</div>
          <div className="fs-1 fw-bolder">Grow your wealth</div>
          <GraphicWrapper>
            <GraphicChart
              bars={mapGraphicColors(latestMonthAmounts, GRAPHIC_COLORS)}
            />
          </GraphicWrapper>
        </div>

        <div className="ms-auto vstack gap-2">
          <div className="text-center text-sm-end">CURRENT BALANCE</div>
          <div className="fs-1 fw-bold text-center text-sm-end">{`$${formatNumbers(
            count
          )}`}</div>

          {/* <button
            className="mt-auto btn btn-link text-decoration-none text-reset text-sm-end"
            onClick={onClickView}
          >
            VIEW CURRENT OFFERS
          </button> */}
        </div>
      </div>
    </BannerWrapper>
  );
};

const GraphicWrapper = styled.div`
  height: 150px;
  display: block;
  padding: 10px;
`;

export default InvestBanner;
