import {
  AlertWrapperWithoutButton,
  CardIcon,
  BannerTitle,
  BannerDescription,
} from "../styles/bankAccountsAlerts";
import { LeftWrapper } from "../styles/list";

interface Props {
  title: string;
  description: string;
}

const WarningAlert = ({ title, description }: Props) => {
  return (
    <div>
      <AlertWrapperWithoutButton>
        <LeftWrapper>
          <CardIcon />
          <div>
            <BannerTitle>{title}</BannerTitle>
            <BannerDescription>{description}</BannerDescription>
          </div>
        </LeftWrapper>
      </AlertWrapperWithoutButton>
    </div>
  );
};

export default WarningAlert;
