import DarkScreen from "../../../assets/images/norhart-invest-platform-mobile.png";
import GradientComponent from "../../../components/landing/components/GradientComponent";

const IraApply = () => {
  return (
    <GradientComponent
      backgroundColorOne="#f0f0f0"
      backgroundColorTwo="#f0f0f0"
      badgeTitle="Get Started Today"
      colSizeOne={5}
      colSizeTwo={7}
      fontTextColor="#111111"
      image={DarkScreen}
      imageTitle="Sign Up To Invest"
      title="Connect Now"
      subtitle=""
      titleTopLeft="Norhart IRA and 401K"
      badgeColor="black"
      hrColor="black"
      needApplyForm
      darkSignUpButton
      displayImgOnMobile
    />
  );
};

export default IraApply;
