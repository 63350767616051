import "./index.css";
// dependencies
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Roles } from "./types/roles";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./styles/globalStyle";
import { useContext } from "react";
import { AppContext } from "./hooks/AppContext";
import { ScrollToTop } from "./components/ScrollToTop";
import { useLogRocket } from "./hooks/useLogRocket";
import { Maintenance } from "./pages/maintenance/page";
import { RemoveTrailingSlash } from "./components/RemoveTrailingSlash";
import { Interceptor } from "./components/Interceptor";

//pages
import SignIn from "./pages/signin/page";
import CreateAccount from "./pages/signup/page";
import Home from "./pages/dashboard/page";
import SendEmail from "./pages/forgot-password/sendEmail/page";
import ResetPassword from "./pages/forgot-password/reset-password/page";
import Header from "./components/navbar/Header";
import { NotFound } from "./pages/not-found/page";
import IdentityVerification from "./pages/onboarding/identity-verification/page";
import Admin from "./pages/admin/page";
import Transactions from "./pages/tansactions/page";
import Account from "./pages/user-account/page";
// import FlexAccount from "./pages/flex-account/page";
import PromissoryNotes from "./pages/promissory-note/page";
import PromissoryNote from "./pages/promissory-note/[id]/page";
import Review from "./pages/review/Review";
import BankAccount from "./pages/onboarding/bank-account/page";
import Redirect from "./pages/create-account/redirect";
import { Welcome } from "./pages/(landing)/page";
import { Hutch } from "./pages/hutch/page";
import { Ira } from "./pages/ira/page";
import FAQPage from "./pages/faq/page";
import ReleaseNotes from "./pages/release-notes/page";
import Documents from "./pages/documents/page";
import Privacy from "./pages/legal/privacy/page";
import PrivacyPolicy from "./pages/legal/privacy-policy/page";

const App = () => {
  useLogRocket();
  const { ColorsPalette } = useContext(AppContext);
  return (
    <>
      <BrowserRouter>
        <Interceptor>
          <ScrollToTop>
            <ThemeProvider theme={ColorsPalette}>
              <GlobalStyles />
              <>
                <RemoveTrailingSlash />
                <ReactNotifications />
                <Routes>
                  <Route element={<ProtectedRoute.Private />}>
                    <Route element={<Header />}>
                      <Route element={<ProtectedRoute.NotVerified />}>
                        <Route
                          path="/onboarding/identity-verification"
                          element={<IdentityVerification />}
                        />
                      </Route>
                      <Route element={<ProtectedRoute.Verified />}>
                        <Route path="/dashboard" element={<Home />} />
                        <Route
                          path="/onboarding/bank-account"
                          element={<BankAccount />}
                        />
                        <Route
                          path="/transactions"
                          element={<Transactions />}
                        />
                        <Route path="/user-account" element={<Account />} />
                        {/* <Route path="/flex-account" element={<FlexAccount />} /> */}
                        <Route
                          path="/promissory-note"
                          element={<PromissoryNotes />}
                        />
                        <Route
                          path="/promissory-note/:id"
                          element={<PromissoryNote />}
                        />
                        <Route path="/faq" element={<FAQPage />} />
                        <Route
                          path="/release-notes"
                          element={<ReleaseNotes />}
                        />
                        <Route path="/documents" element={<Documents />} />
                        <Route path="/legal/privacy" element={<Privacy />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute.Private
                        roles={[Roles.Admin]}
                        redirectPath="/"
                      />
                    }
                  >
                    <Route element={<Header />}>
                      <Route path="/admin" element={<Admin />} />
                    </Route>
                  </Route>
                  <Route element={<ProtectedRoute.Restricted />}>
                    <Route path="review" element={<Review />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/signup" element={<CreateAccount />} />
                    <Route path="/" element={<Welcome />} />
                    <Route path="/hutch" element={<Hutch />} />
                    <Route path="/ira" element={<Ira />} />
                    <Route path="/welcome" element={<Redirect path="/" />} />
                    <Route
                      path="/auth/create-account"
                      element={<Redirect path="/create-account" />}
                    />
                    <Route
                      path="/meet"
                      element={
                        <Redirect path="https://outlook.office365.com/owa/calendar/NorhartInvest3@norhart.com/bookings/" />
                      }
                    />
                    <Route
                      path="/forgot-password/send-email"
                      element={<SendEmail />}
                    />
                    <Route
                      path="/forgot-password/reset-password"
                      element={<ResetPassword />}
                    />
                  </Route>
                  <Route path="/maintenance" element={<Maintenance />} />
                  <Route path="/not-found" element={<NotFound />} />
                  <Route path="*" element={<Navigate to="/not-found" />} />
                  <Route
                    path="/legal/privacy-policy"
                    element={<PrivacyPolicy />}
                  />
                </Routes>
              </>
            </ThemeProvider>
          </ScrollToTop>
        </Interceptor>
      </BrowserRouter>
    </>
  );
};

export default App;
