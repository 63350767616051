import React, { useEffect } from "react";
import styled from "styled-components";

type Props = {
  url: string;
  onExit?: () => Promise<void> | void;
};

export const SignIframe: React.FC<Props> = ({ url, onExit }) => {
  useEffect(() => {
    // Define a message handler function to listen for messages sent to the window
    const messageHandler = async (event: MessageEvent) => {
      if (event.data === "esignatureFlowCompleted") {
        if (onExit) await onExit();
        const element = document.getElementById("esignature");
        if (element) element?.remove();
      }
    };

    // Add a message listener to the window object
    window.addEventListener("message", messageHandler, false);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [onExit]);

  return (
    <div>
      <StyledIframe title="eSignatures.io" id="esignature" src={url} />
    </div>
  );
};

const StyledIframe = styled.iframe`
  z-index: 99999;
  position: fixed;
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
`;
