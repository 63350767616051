import { useEffect, useState } from "react";
import { CustomCognitoAttributes } from "../types/cognito-attributes";
import { Auth, Payments, formatMoneyInput } from "../utils";

export const useCognitoAttributes = () => {
  const [accredited, setAccredited] = useState<string>();
  const [income, setIncome] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const getAttributes = async () => {
      try {
        const attributes = await Auth.getUserAttributes();
        setAccredited(
          attributes![`${CustomCognitoAttributes.accredited_investor}`]
        );
        const income = attributes![`${CustomCognitoAttributes.income}`];
        if (income) {
          setIncome(formatMoneyInput(Payments.convertToDollars(income)));
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    getAttributes();
  }, []);

  return { accredited, income, loading };
};
