import { NorhartWrapper } from "../../components/NorhartWrapper";
import ReleaseNotesAccordion from "./components/ReleaseNotesAccordion";

const ReleaseNotes = () => {
  return (
    <NorhartWrapper title="Norhart Invest Release Notes">
      <ReleaseNotesAccordion />
    </NorhartWrapper>
  );
};

export default ReleaseNotes;
