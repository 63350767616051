import { useCallback, useMemo } from "react";
import { CacheKeys } from "../types";
import { PromissoryNotes } from "../utils";
import { useFetch } from "./useFetch";
import { useState } from "react";
import { ResponseStatus } from "../types/ResponseStatus";
import { notifications } from "../utils/notifications";

export const usePromissoryNotesById = (id: string) => {
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>();
  const cacheKey = useMemo(() => `${CacheKeys.promissoryNotes + id}`, [id]);
  const fetchFunction = useCallback(async () => {
    const response = await PromissoryNotes.getDataById(id);
    return response;
  }, [id]);

  const { data, loading, refreshData } = useFetch({
    cacheKey,
    fetchFunction,
  });

  const updateAutoReinvest = async (autoReInvest: boolean) => {
    setLoadingUpdate(true);
    try {
      const response = await PromissoryNotes.update({ id, autoReInvest });
      refreshData(response);
      notifications({
        status: ResponseStatus.SUCCESS,
        successMessage: `Auto reinvest updated successfully`,
      });
    } catch (e) {
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage: `Unexpected error updating the auto reinvest`,
      });
    }
    setLoadingUpdate(false);
  };

  return { data, loading, updateAutoReinvest, loadingUpdate };
};
