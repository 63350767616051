import { FormEvent, useState } from "react";
import { AlertWithButton, Form as StyledForm } from "../../../styles";
import {
  Auth,
  formatMoneyInput,
  handlePhoneChange,
  handlePhoneKeyDown,
  removeCommas,
  validateAmazonPassword,
  validateEmail,
} from "../../../utils";
import { Alert, Button } from "react-bootstrap";
import ConfirmAccountPopUp from "../../../components/ConfirmAccountPopUp";
import {
  createAccountInitialData,
  createAccountInitialErrors,
  createAccountTouch,
} from "../constants";
import { FormInput } from "../../../components/forms/formsComponents/FormInput";
import { useForm } from "../../../hooks/useForm";
import { CUSTOM_ERRORS } from "../types";
import { AuthButton } from "../../../components/AuthButton";
import styled from "styled-components";
import { CustomSwitch, Slider, StyledInput } from "../../../styles/switch";
import { TermAndConditions } from "../../../styles/popUp";

const MAINTENANCE = process.env.REACT_APP_MAINTENANCE === "true";
const ACCREDITED_INVEST_AMOUNT = 200000;

interface Props {
  darkMode?: boolean;
  landing?: boolean;
}

const Signup = ({ landing, darkMode }: Props) => {
  const {
    fields,
    errors,
    handleErrors,
    handleFields,
    handleTouch,
    submitLoading,
    onSubmit,
    setFields,
    submitWithEnter: { ref, handleKeyUp },
  } = useForm({
    initialFields: createAccountInitialData,
    initialErrors: createAccountInitialErrors,
    initialTouch: createAccountTouch,
    customErrors: CUSTOM_ERRORS,
  });

  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const onSubmitAuth =
    (
      username: string,
      password: string,
      income: string,
      accredited: string,
      firstName: string,
      phoneNumber: string,
      lastName: string,
      didSignPrivacyPolicy: boolean,
      didSignTOSPolicy: boolean
    ) =>
    async () => {
      await Auth.signUp({
        username: username.toLowerCase(),
        password,
        income,
        accredited,
        firstName,
        phoneNumber,
        lastName,
        didSignPrivacyPolicy,
        didSignTOSPolicy,
      });
      setIsSuccess(true);
      setShowPopUp(true);
    };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (MAINTENANCE) return;
    const {
      username,
      password,
      income,
      accredited,
      firstName,
      phoneNumber,
      lastName,
      didSignPrivacyPolicy,
      didSignTOSPolicy,
    } = fields;
    onSubmit(
      e,
      onSubmitAuth(
        username,
        password,
        income,
        accredited,
        firstName,
        phoneNumber,
        lastName,
        didSignPrivacyPolicy,
        didSignTOSPolicy
      )
    );
  };

  const handleIncomeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFields((prev) => {
      return { ...prev, income: formatMoneyInput(event.target.value) };
    });
  };

  const handleTermsChange = () => {
    setFields((prev) => {
      return {
        ...prev,
        didSignPrivacyPolicy: !prev.didSignPrivacyPolicy,
        didSignTOSPolicy: !prev.didSignTOSPolicy,
      };
    });
  };

  const handleAccreditedSwitchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked;
    setFields({ ...fields, accredited: checked.toString() });
  };
  return (
    <>
      <StyledForm onSubmit={handleSubmit} ref={ref} onKeyUp={handleKeyUp}>
        {isSuccess && (
          <AlertWithButton
            variant={`${landing ? "dark" : darkMode ? "secondary" : "success"}`}
          >
            User was created please validate.
            <Button
              variant={`${
                landing ? "dark" : darkMode ? "secondary" : "success"
              }`}
              onClick={() => setShowPopUp(true)}
              disabled={!fields.username || !fields.password}
            >
              Validate
            </Button>
          </AlertWithButton>
        )}
        {!!errors.form && <Alert variant="danger">{errors.form}</Alert>}
        <div className="row">
          <div className="col-md-6">
            <FormInput
              handleErrors={handleErrors}
              handleFields={handleFields}
              handleTouch={handleTouch}
              required
              value={fields.firstName}
              name="firstName"
              label="First Name"
              notShowLabel
              placeholder="First Name"
              error={errors.firstName}
              errorColor={landing ? "black" : "white"}
            />
          </div>
          <div className="col-md-6">
            <FormInput
              handleErrors={handleErrors}
              handleFields={handleFields}
              handleTouch={handleTouch}
              value={fields.lastName}
              label="Last Name"
              notShowLabel
              name="lastName"
              placeholder="Last Name"
              error={errors.lastName}
              errorColor={landing ? "black" : "white"}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <FormInput
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            validation={validateEmail}
            message="The username must be an email"
            required
            value={fields.username}
            name="username"
            label="Email"
            notShowLabel
            placeholder="Email Address"
            error={errors.username}
            errorColor={landing ? "black" : "white"}
          />
        </div>
        <div>
          <FormInput
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            validation={validateAmazonPassword}
            message="Password must contain one: number, a symbol, an uppercase
          character, and a lowercase character."
            required
            name="password"
            value={fields.password}
            placeholder="Password"
            type="password"
            label="Password"
            notShowLabel
            error={errors.password}
            errorColor={landing ? "black" : "white"}
          />
        </div>
        <div className="form-group">
          <FormInput
            handleErrors={handleErrors}
            handleFields={(phoneNumber) =>
              handlePhoneChange(phoneNumber, handleFields)
            }
            handleTouch={handleTouch}
            onKeyDown={handlePhoneKeyDown}
            required
            value={fields.phoneNumber}
            name="phoneNumber"
            placeholder="Phone Number *"
            label="Phone Number"
            notShowLabel
            error={errors.phoneNumber}
            errorColor={landing ? "black" : "white"}
          />
          <p
            style={{
              color: landing ? "#000000" : "#F0F0F0",
              textAlign: "left",
            }}
          >
            * Disclaimer: Receive significant opportunities and discounts.
            Messaging and data rates may apply. Reply STOP to cancel at any
            time.{" "}
            <a
              href="https://www.norhart.com/legal/privacy/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#F0F0F0" }}
            >
              Privacy Policy
            </a>
          </p>
        </div>
        <div className="form-group">
          <FormInput
            style={{ minHeight: "0 !important" }}
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            onChange={handleIncomeChange}
            message="Please insert your income"
            required
            name="income"
            placeholder="Your Income"
            label="Income"
            notShowLabel
            value={fields.income}
            error={errors.income}
            isMoney
            errorColor={landing ? "black" : "white"}
          />
          <InputBox
            landing={!!landing}
            visibility={(
              Number(removeCommas(fields.income)) >= ACCREDITED_INVEST_AMOUNT
            ).toString()}
            className="form-control"
          >
            <CustomSwitch>
              <StyledInput
                onChange={handleAccreditedSwitchChange}
                defaultChecked={false}
              />
              <Slider />
            </CustomSwitch>
            <span>Accredited Investor</span>
          </InputBox>
        </div>
        <TermsWrapper landing={!!landing}>
          <CustomSwitch>
            <StyledInput onChange={handleTermsChange} defaultChecked={false} />
            <Slider />
          </CustomSwitch>
          <span>
            I Agree to the{" "}
            <TermAndConditions
              textColor={landing ? "black" : undefined}
              href="https://cdn.norhart.com/2023-06-30_Terms_of_Service.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </TermAndConditions>{" "}
            |{" "}
            <TermAndConditions
              textColor={landing ? "black" : undefined}
              target="_blank"
              href="/legal/privacy-policy"
            >
              Privacy
            </TermAndConditions>
          </span>
        </TermsWrapper>
        <AuthButton
          loading={!!submitLoading}
          darkMode={landing}
          padlock={!submitLoading}
          disabled={MAINTENANCE || !fields.didSignPrivacyPolicy}
          title="Terms must be agreed to continue."
        >
          Sign Up
        </AuthButton>
      </StyledForm>
      <ConfirmAccountPopUp
        show={showPopUp}
        setShow={setShowPopUp}
        username={fields.username}
        password={fields.password}
        needResendCode={true}
      />
    </>
  );
};

interface InputBoxProps {
  visibility: string;
  landing: boolean;
}

const InputBox = styled.div<InputBoxProps>`
  margin-top: -40px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  background-color: transparent;
  color: ${(props) => (props.landing ? "black" : "white")};
  border: none;
  visibility: ${(props) =>
    props.visibility === "true" ? "visible" : "hidden"};
  padding: 0;
  @media (max-width: 280px) {
    height: 50px;
    margin-top: -35px;
  }
`;

const TermsWrapper = styled.div<{ landing: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  background-color: transparent;
  color: ${(props) => (props.landing ? "black" : "white")};
  border: none;
  padding: 0;
  margin-bottom: 20px;
  @media (max-width: 330px) {
    font-size: 12px;
  }
  @media (max-width: 280px) {
    height: 50px;
    margin-top: -35px;
  }
`;

export default Signup;
