import { Auth } from "../utils";
import { Dispatch, FormEvent, SetStateAction, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import VerificationPopUp from "./VerificationPopUp";
import { Roles } from "../types/roles";

interface SignUpVerificationPopUpProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  username: string;
  password: string;
}

const VerifyCodeResetPassword = ({
  show,
  setShow,
  username,
  password,
}: SignUpVerificationPopUpProps) => {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState<boolean>();

  const ref = useRef<HTMLButtonElement>(null);
  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    verificationCode: string[]
  ) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (ref?.current?.disabled) return;
    try {
      await Auth.forgotPasswordSubmit(
        username,
        verificationCode.join(""),
        password
      );
      const { attributes, signInUserSession } = await Auth.signIn({
        username,
        password,
      });
      const identity_verified = attributes["custom:identity_verified"];
      if (!identity_verified) {
        navigate("/onboarding/identity-verification");
        return;
      }
      if (
        signInUserSession.idToken.payload["cognito:groups"]?.includes(
          Roles.Admin
        )
      ) {
        navigate("/admin");
        return;
      }
      navigate("/dashboard");
    } catch {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  return (
    <VerificationPopUp
      buttonRef={ref}
      show={show}
      setShow={setShow}
      username={username!}
      password={password!}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    />
  );
};

export default VerifyCodeResetPassword;
