import { useMemo, useState } from "react";
import { LightColorPalette, DarkColorPalette } from "../styles/constants";
import { User, debounce } from "../utils";

type ColorsPaletteType = typeof LightColorPalette;

export const useDarkMode = () => {
  const handleColorPalette = () => {
    const userPreferences = User.getUsersPreferencesFromLocalStorage();
    return !!userPreferences?.darkModeOn ? DarkColorPalette : LightColorPalette;
  };
  const [ColorsPalette, setColorPalette] = useState<ColorsPaletteType>(
    handleColorPalette()
  );

  const switchColorPalette = (): ColorsPaletteType => {
    const userPreferences = User.getUsersPreferencesFromLocalStorage();
    const newColorPallette = !!userPreferences?.darkModeOn
      ? LightColorPalette
      : DarkColorPalette;
    setColorPalette(newColorPallette);
    User.updateUserPreferencesInCache({
      darkModeOn: newColorPallette === DarkColorPalette,
    });
    return newColorPallette;
  };

  const debounceFunction = useMemo(() => {
    const callback = async (newColorPallette?: ColorsPaletteType) => {
      try {
        await User.updateUserPreferences({
          darkModeOn: newColorPallette === DarkColorPalette,
        });
      } catch (e) {
        console.error(e);
      }
    };
    return debounce(
      (newColorPallette?: ColorsPaletteType) => callback(newColorPallette),
      500
    );
  }, []);

  return {
    ColorsPalette,
    switchColorPalette: () => debounceFunction(switchColorPalette()),
  };
};
