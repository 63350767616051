import { Navigate, Outlet } from "react-router-dom";
import { useIsLogged } from "../hooks/useIsLogged";
import { useIsVerified } from "../hooks/useIsVerified";
import { Loading } from "./Loading";
import { IdentityVerified } from "../types/cognito-attributes";

interface Props {
  redirectPath?: string;
}

interface PrivateProps extends Props {
  roles?: string[];
}

export const ProtectedRoute = () => {};

/**
 * Private component:
 * This component is for private components.
 * If the user is logged will return the children.
 * If the user is not logged will redirect to the redirectPath provided by props or by default to '/signIn'.
 * */
const Private = ({ redirectPath, roles }: PrivateProps) => {
  const { isLoading, isLogged, groups } = useIsLogged();

  if (isLoading) {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  }

  const hasRoles = () => {
    if (!roles) return true;
    return roles?.every((element) => groups?.includes(element));
  };

  if (isLogged && hasRoles()) return <Outlet />;
  return <Navigate to={redirectPath || "/signin"} replace />;
};

/**
 * Restricted component:
 * This component is for restricted components.
 * If the user is logged will redirect to the redirectPath provided by props or by default to '/'.
 * If the user is not logged will return the children.
 * */
const Restricted = ({ redirectPath }: Props) => {
  const { isLoading, isLogged } = useIsLogged();
  if (isLoading) {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  }

  if (!isLogged) return <Outlet />;
  return <Navigate to={redirectPath || "/dashboard"} replace />;
};

const Verified = () => {
  const { isLoading, isVerified } = useIsVerified();

  if (isLoading) {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  }

  if (isVerified === IdentityVerified.verified) return <Outlet />;
  return <Navigate to={"/onboarding/identity-verification"} replace />;
};

const NotVerified = () => {
  const { isLoading, isVerified } = useIsVerified();

  if (isLoading) {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  }

  if (isVerified !== IdentityVerified.verified) return <Outlet />;
  return <Navigate to={"/dashboard"} replace />;
};

ProtectedRoute.Private = Private;
ProtectedRoute.Restricted = Restricted;
ProtectedRoute.Verified = Verified;
ProtectedRoute.NotVerified = NotVerified;
