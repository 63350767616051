import classNames from "classnames";
import { FunctionComponent } from "react";
import { ResponseStatus } from "../types/ResponseStatus";

interface Props {
  isSuccessful?: ResponseStatus;
  successfulMessage?: JSX.Element | string;
  errorMessage?: JSX.Element | string;
  warningMessage?: JSX.Element | string;
  isNotification?: boolean;
}

export const Alerts: FunctionComponent<Props> = ({
  isSuccessful,
  successfulMessage,
  errorMessage,
  warningMessage,
  isNotification,
}) => {
  return (
    <>
      {isSuccessful === ResponseStatus.SUCCESS && (
        <div
          className={classNames("alert alert-success w-100", {
            "m-0": isNotification,
          })}
          role="alert"
        >
          {successfulMessage}
        </div>
      )}
      {isSuccessful === ResponseStatus.ERROR && (
        <div
          className={classNames("alert alert-danger w-100", {
            "m-0": isNotification,
          })}
          role="alert"
        >
          {errorMessage}
        </div>
      )}
      {isSuccessful === ResponseStatus.WARNING && (
        <div className="alert alert-warning w-100">{warningMessage}</div>
      )}
    </>
  );
};
