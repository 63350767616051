import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "../styles";

interface Props {
  message: string;
}

export const TooltipDisabledButton = ({ message }: Props) => {
  const renderTooltip = (props: any) => (
    <HoverBox id="tooltip-disabled" {...props}>
      {message}
    </HoverBox>
  );
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <span className="d-inline-block">
        <Button
          disabled
          style={{ pointerEvents: "none", cursor: "not-allowed" }}
        >
          Delete
        </Button>
      </span>
    </OverlayTrigger>
  );
};

const HoverBox = styled(Tooltip)`
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
  .tooltip-inner {
    color: black !important;
    background-color: #ebebeb !important;
  }
  --bs-tooltip-bg: #ebebeb !important;
`;
