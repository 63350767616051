import { FunctionComponent } from "react";
import { isValidOnBlur } from "../../../utils";
import TextArea, { TextAreaFieldProps } from "../formInputsTypes/TextArea";

interface Props extends TextAreaFieldProps {
  handleFields: (args: Record<string, string>) => void;
  message?: string;
  validation?: (value: string) => { isValid: boolean; message?: string };
  handleTouch: (args: Record<string, boolean>) => void;
  handleErrors: (args: Record<string, string>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const FormTextArea: FunctionComponent<Props> = ({
  handleFields,
  message,
  handleTouch,
  validation,
  handleErrors,
  onFocus,
  onBlur,
  onChange,
  required,
  ...rest
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = e.target;
    handleFields({ [name]: value });
    handleValidation(e);
    if (onChange) onChange(e);
  };

  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const { name } = e.currentTarget;
    handleTouch({ [name]: true });
    if (onFocus) onFocus(e);
  };

  const handleValidation = (
    e:
      | React.FocusEvent<HTMLTextAreaElement, Element>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newError = isValidOnBlur(e, {
      required,
      validation: validation,
      message,
      label: rest.label,
    });
    handleErrors(newError);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    handleValidation(e);
    if (onBlur) onBlur(e);
  };

  return (
    <TextArea
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      required={required}
      {...rest}
    />
  );
};
