import React from "react";
import { Carousel } from "react-bootstrap";

interface Props {
  items: {
    title?: string;
    image: string;
  }[];
  url?: string;
}

const CarouselBanner: React.FC<Props> = ({ items }) => {
  return (
    <Carousel controls={false} indicators={true} interval={5000}>
      {items.map(({ image, title }, i) => {
        return (
          <Carousel.Item key={i}>
            <img src={image} className="d-block w-100" alt={title} />
            <Carousel.Caption className="h-100 d-flex align-items-center"></Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default CarouselBanner;
