export interface ProductForm {
  term: string;
  interest: string;
  interestType: string;
  allocationTerm: string;
  name: string;
  description: string;
  active: string;
  newPercentage: string;
  endDate: string;
  id?: string;
}

export interface Product extends ProductForm {
  id: string;
}

export interface ProductErrors extends ProductForm {
  id: string;
  form: string;
}

export interface ProductTouch {
  term: boolean;
  interest: boolean;
  interestType: boolean;
  allocationTerm: boolean;
  name: boolean;
  description: boolean;
  active: boolean;
  newPercentage: boolean;
  endDate: boolean;
  id: boolean;
}

export enum CREATE_PRODUCT_CUSTOM_ERRORS {
  DefaultError = "Oops something wrong happened creating the product",
}

export interface FlexAccountInitialData {
  interest: string;
}

export interface FlexAccountInitialErrors extends FlexAccountInitialData {
  form: string;
}

export interface FlexAccountInitialTouch {
  interest: boolean;
}

export enum UPDATE_FLEX_ACCOUNT_INTEREST_CUSTOM_ERROR {
  DefaultError = "Oops something wrong happened updating the interest",
}
