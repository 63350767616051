export enum Errors {
  defaultError = "Oops! Something went wrong",
}

export interface ErrorObject {
  code?: string;
  message?: string;
  data?: string;
}

export enum ErrorCodes {
  MaximumAmountPerInvestmentExceeded = "MaximumAmountPerInvestmentExceeded",
  PaymentIsBeingProcessed = "PaymentIsBeingProcessed",
  PaymentTimedOut = "PaymentTimedOut",
  IncomePercentageExceeded = "IncomePercentageExceeded",
}

export class CustomError {
  static throw(error: object, message: string = Errors.defaultError) {
    console.error(error);
    if ((error as { code: ErrorObject })?.code) {
      throw error;
    }
    throw new Error(message);
  }
}

export class NewCustomError {
  static throw(error: ErrorObject, response: Response) {
    console.error(error);
    const newError = { ...error, response };
    throw newError;
  }
}
