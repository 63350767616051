import { InterestsPerMonth } from "../types";

export const isEmptyObject = (obj: Object) => {
  return (
    JSON.stringify(obj) === "{}" ||
    Object.values(obj).every((x) => x === null || x === "")
  );
};

export const convertToNumber = (number: string) => {
  const withoutCommas = removeCommas(number);
  return Number(withoutCommas);
};

export const convertFormatNumber = (
  number: string | number,
  divideBy: number = 100
) => {
  let temporalNumber = number;
  if (typeof number === "string") temporalNumber = Number(number);
  return (temporalNumber as number) / divideBy;
};

export const formatNumbers = (
  number: number | string,
  divideBy: number = 100,
  digits: number = 2
) => {
  let input = typeof number === "number" ? number : Number(number);
  return (input / divideBy).toLocaleString("en-US", {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};

export const formatDate = (input: string, switchFormat?: boolean) => {
  let initialDate = input;
  if (switchFormat) initialDate = switchDateFormat(input);
  const date = new Date(initialDate);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return formattedDate;
};

export const switchDateFormat = (input: string) => {
  const parts = input.split("-");
  if (parts.length !== 3) {
    throw new Error("Invalid date format. Expected 'yyyy/mm/dd'.");
  }

  const year = parts[0];
  const month = parts[1];
  const day = parts[2];

  return `${month}/${day}/${year}`;
};

export const parseStringToObject = (str: string) => {
  try {
    const obj = JSON.parse(str);
    if (typeof obj === "object" && obj !== null) {
      return obj;
    }
  } catch (e) {
    // do nothing, return the original string
  }
  return str;
};

const localStringToNumber = (s: string | number) => {
  return Number(String(s).replace(/[^0-9.,-]+/g, ""));
};

export const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
  const value = e.target.value;
  e.target.value = value ? localStringToNumber(value).toString() : "";
};

export const formatToPhone = (phoneNumber: string) => {
  if (phoneNumber.startsWith("+1")) {
    phoneNumber = phoneNumber.slice(2);
  }
  const input = phoneNumber.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    return `(${areaCode}) ${middle} - ${last}`;
  }
  if (input.length > 3) {
    return `(${areaCode}) ${middle}`;
  }
  if (input.length > 0) {
    return `(${areaCode}`;
  }
  return "";
};

export const handlePhoneChange = (
  phoneNumberRecord: Record<string, string>,
  handleFields: (args: Record<string, any>) => void
) => {
  if (phoneNumberRecord.phoneNumber.length === 17) return;
  const newValue = formatToPhone(phoneNumberRecord.phoneNumber);
  handleFields({ phoneNumber: newValue });
};

export const handlePhoneKeyDown = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  const { selectionStart, value } = e.target as HTMLInputElement;
  const cursorPosition = handlePhoneCursorPosition(
    selectionStart!,
    e.key,
    value
  );
  requestAnimationFrame(() => {
    if (e.metaKey || e.ctrlKey) return; //alow user to do copy content
    (e.target as HTMLInputElement).setSelectionRange(
      cursorPosition || selectionStart,
      cursorPosition || selectionStart
    );
  });
};

const handlePhoneCursorPosition = (
  cursorPosition: number,
  key: string,
  value: string
) => {
  const isNumber = /^\d$/.test(key);
  const isBackSpace = key === "Backspace";
  const isArrowLeft = key === "ArrowLeft";
  const isArrowRight = key === "ArrowRight";
  const isBack = isArrowLeft || isBackSpace;
  const isArrow = isArrowLeft || isArrowRight;
  const importantForward = [0, 4, 5, 9, 10, 11];
  const importantBack = [12, 11, 6, 1];
  const importantPositions = importantForward.concat(importantBack);
  const isImportantPosition = importantPositions.includes(cursorPosition);
  const isImportantBack = importantBack.includes(cursorPosition);
  const isImportantForward = importantForward.includes(cursorPosition);
  if (
    (value.length === 16 && !(isArrow || isBackSpace)) ||
    (!isNumber && !(isArrow || isBackSpace))
  ) {
    return cursorPosition;
  }

  if (
    (!isImportantBack && isBack) ||
    (!isImportantForward && isArrowRight) ||
    !isImportantPosition
  ) {
    if (isBack) return cursorPosition - 1;
    return cursorPosition + 1;
  }
  if (isBack && cursorPosition === 0) return;
  if (cursorPosition === 12 && isBack) {
    return cursorPosition - 3;
  }
  if (cursorPosition === 11 && isBack) {
    return cursorPosition - 2;
  }
  if (cursorPosition === 6 && isBack) {
    return cursorPosition - 2;
  }
  if (cursorPosition === 0 && !isBack) {
    return isArrowRight ? cursorPosition + 1 : cursorPosition + 2;
  }
  if (cursorPosition === 4 && !isBack) {
    return isArrowRight ? cursorPosition + 2 : cursorPosition + 3;
  }
  if (cursorPosition === 5 && !isBack) {
    return isArrowRight ? cursorPosition + 1 : cursorPosition + 2;
  }
  if (cursorPosition === 9 && !isBack) {
    return isArrowRight ? cursorPosition + 3 : cursorPosition + 4;
  }
  if (cursorPosition === 10 && !isBack) {
    return isArrowRight ? cursorPosition + 2 : cursorPosition + 3;
  }
  if (cursorPosition === 11 && !isBack) {
    return isArrowRight ? cursorPosition + 1 : cursorPosition + 2;
  }
  if (isBack) return cursorPosition - 1;
  return cursorPosition + 1;
};

export const formatMoneyInput = (inputValue: string, prev?: string) => {
  // Remove non-digit and non-dot characters

  inputValue = inputValue.replace(/[^\d.]/g, "");

  // Replace multiple dots with a single dot

  inputValue = inputValue.replace(/(\..*)\./g, "$1");

  // Remove 0s from the left

  inputValue = inputValue.replace(/^0+/, "");

  // Format currency with commas for thousands

  const parts = inputValue.split(".");

  if (prev === "0") parts[0] = parts[0].replace("0", "");

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Limit decimal places to two

  if (parts.length > 1) {
    parts[1] = parts[1].slice(0, 2);
  }

  // Join parts with optional decimal places

  inputValue = parts.join(".");

  return inputValue || "0";
};

export const removeCommas = (value: string) => {
  return value.replace(/,/g, "");
};

export const convertToDBPhoneNumber = (phoneNumber: string) => {
  // Remove non-digit characters from the phone number
  const cleanedNumber = phoneNumber.replace(/\D/g, "");

  // Add the country code
  const countryCode = "+1"; // Assuming the country code is 1 for the United States
  const formattedNumber = countryCode + cleanedNumber;

  return formattedNumber;
};

export const handleCurrencyKeyDown = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  const { selectionStart, value } = e.target as HTMLInputElement;
  const cursorPosition = handleCurrencyCursorPosition(
    selectionStart!,
    e.key,
    value
  );
  requestAnimationFrame(() => {
    if (e.metaKey || e.ctrlKey) return; //alow user to do copy content
    (e.target as HTMLInputElement).setSelectionRange(
      cursorPosition,
      cursorPosition
    );
  });
};

const handleCurrencyCursorPosition = (
  cursorPosition: number,
  key: string,
  value: string
) => {
  const isNumber = /^\d$/.test(key);
  const isDot = key === ".";
  const hasMoreThanOneDots = (value.match(/\./g) || []).length > 0;
  const isFirstDot = !hasMoreThanOneDots && isDot;
  const isBackSpace = key === "Backspace";
  const isArrowLeft = key === "ArrowLeft";
  const isArrowRight = key === "ArrowRight";
  const isBack = isArrowLeft || isBackSpace;
  const isArrow = isArrowLeft || isArrowRight;
  const dotIndex = value.indexOf(".");
  const isAfterPoint = dotIndex !== -1 && dotIndex < cursorPosition;
  const isAfterComma =
    removeCommas(value.split(".")[0]).length % 3 === 1 &&
    value.length &&
    (cursorPosition - 2) % 4 === 0 &&
    !isAfterPoint;
  const isAddingComma = removeCommas(value.split(".")[0]).length % 3 === 0;
  const isRemovingComma =
    removeCommas(value.split(".")[0]).length % 3 === 1 && isBackSpace;
  if (!(isNumber || isFirstDot) && !(isArrow || isBackSpace)) {
    return cursorPosition;
  }
  if (isAddingComma && isNumber && !(isArrow || isBackSpace) && !isAfterPoint) {
    return cursorPosition + 2;
  }
  if (isFirstDot) {
    return cursorPosition + 1;
  }
  if (isRemovingComma && cursorPosition !== 1 && !isAfterComma && !isAfterPoint)
    return cursorPosition - 2;
  if (isBack) return cursorPosition === 0 ? cursorPosition : cursorPosition - 1;
  return cursorPosition + 1;
};

export const normalizeString = (input: string) => {
  const words = input.split(" ");
  const normalizedWords = words.map((word) => normalizeWord(word));
  const normalizedString = normalizedWords.join(" ");
  return normalizedString;
};

export const normalizeWord = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};
export const debounce = <T, W>(callback: (v?: T) => W, delay: number = 200) => {
  let timer: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await callback(...args);
    }, delay);
  };
};

export const checkIfArray = <T>(value: T | T[]): value is T[] =>
  Array.isArray(value);
export const stringOrObject = <T>(value: string | T): value is T =>
  typeof value === "object";
export const booleanToString = (value: boolean) => (value ? "true" : "");

export const mapGraphicColors = (
  latestMonthAmounts: InterestsPerMonth<string>[],
  graphicColors: { color: string; height: number }[]
) => {
  return latestMonthAmounts.map((interestsPerMonth, index) => {
    return {
      ...graphicColors[index],
      interestsPerMonth,
    };
  });
};
