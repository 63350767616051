import { useVerifyPlaidBankAccount } from "../../hooks/useVerifyBankAccounts";
import { useState } from "react";
import { ExtendedAlertProps } from "../../types/attention";
import {
  CardIcon,
  BannerTitle,
  BannerDescription,
  StyledButton,
  LeftWrapper,
  AlertWrapperWithButton,
} from "../../styles/bankAccountsAlerts";

const PendingExpiration = ({ bankName, token }: ExtendedAlertProps) => {
  const [show, setShow] = useState<boolean>(true);
  const onSuccess = () => {
    setShow(false);
  };
  const { open, ready, waitingBankAccounts, buttonText } =
    useVerifyPlaidBankAccount({
      linkToken: token,
      handlePlaidOnSuccess: onSuccess,
    });

  if (!show) return <></>;

  return (
    <div>
      <AlertWrapperWithButton>
        <LeftWrapper>
          <CardIcon />
          <div>
            <BannerTitle>Bank Login is close to Expire</BannerTitle>
            <BannerDescription>
              It seems like the credentials of {bankName} will expire. We need
              you to log in again to prevent future issues.
            </BannerDescription>
          </div>
        </LeftWrapper>
        <StyledButton
          onClick={() => open()}
          disabled={!ready || waitingBankAccounts}
        >
          {buttonText}
        </StyledButton>
      </AlertWrapperWithButton>
    </div>
  );
};
export default PendingExpiration;
