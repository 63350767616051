import NorhartBlackCircleIcon from "../../../assets/images/norhart-black-circle-icon.svg";
import HeroBottomComponent from "../components/HeroBottomComponent";

const IRAInvesting = () => {
  return (
    <HeroBottomComponent
      backgroundColorOne="#f0f0f0"
      backgroundColorTwo="#f0f0f0"
      Icon={NorhartBlackCircleIcon}
      iconTitle="Norhart IRA"
      title="Seamless IRA & 401K Investing"
      subTitle="Unlock 10% returns for your retirement with Norhart IRA. Democratizing investments for all. Secure your prosperous future today."
      imageAlt="Norhart Invest How It Works"
      subNote="Hours: M-F (8 AM - 8 PM), Saturday (10 AM - 4 PM), Sunday - (Closed)"
      textColor="#111111"
      asoEffect="fade-in"
      buttonName="Book A Call"
      buttonUrl="https://appt.norhart.com/widget/booking/BbRATKu2ujKE6T4Mp8dW"
    />
  );
};

export default IRAInvesting;
