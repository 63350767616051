import React, { InputHTMLAttributes, useState } from "react";
import classNames from "classnames";
import { Variant } from "../../../types";
import {
  ErrorWrapper,
  InputWrapper,
  StyledInput,
  IsMoneySpan,
  StyledLabel,
} from "../../../styles/Inputs";

export interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  variant?: Variant;
  isMoney?: boolean;
  error?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  errorColor?: string;
  notShowLabel?: boolean;
  animateError?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref) => {
    const [touched, setTouched] = useState<boolean>(false);

    const {
      label,
      variant,
      id,
      name,
      type,
      isMoney,
      error = false,
      onFocus,
      errorColor,
      notShowLabel,
      placeholder,
      form,
      animateError,
      ...rest
    } = props;

    const isFloating = variant === "floating";
    const htmlFor = id ?? name;

    const inputProps = {
      className: classNames(
        "form-control",
        {
          "is-invalid": touched && error,
          "rounded-0 rounded-end": isMoney && isFloating,
        },
        { "col px-4": variant === "row" && type !== "checkbox" },
        { "form-check-input": type === "checkbox" },
        "form-control-disabled"
      ),
      ref: ref as React.ForwardedRef<HTMLInputElement>,
      type: type ?? "text",
      id: htmlFor,
      form: form || "defaultForm",
      onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus) onFocus(e);
        setTouched(true);
      },
      name,
      placeholder: placeholder || label,
      ...rest,
    };

    if (variant === "floating" && !label) {
      console.warn("Floating input requires a label");
    }

    const labelComp = !!label && !notShowLabel && (
      <StyledLabel
        htmlFor={htmlFor}
        className={classNames("inputLabel", { col: variant === "row" })}
      >
        {label}
      </StyledLabel>
    );
    const inputComp = <StyledInput {...inputProps} />;

    const inputWrapped =
      isMoney && !isFloating ? (
        <div className="input-group">
          <IsMoneySpan className="input-group-text">$</IsMoneySpan>
          {inputComp}
        </div>
      ) : (
        inputComp
      );

    const floatingComp = () => {
      if (isFloating) {
        return (
          <>
            {inputWrapped}
            {labelComp}
          </>
        );
      }
      return (
        <div
          className={classNames(
            {
              "row d-flex align-items-center": variant === "row",
            },
            {
              "d-flex flex-row-reverse form-check form-switch":
                type === "checkbox",
            }
          )}
        >
          {labelComp}
          {inputWrapped}
        </div>
      );
    };

    if (isMoney && isFloating) {
      return (
        <div className="input-group">
          <IsMoneySpan className="input-group-text">$</IsMoneySpan>
          <div className="form-floating flex-fill">{floatingComp()}</div>
        </div>
      );
    }
    return (
      <InputWrapper
        animateError={animateError}
        className={classNames({
          "form-floating": isFloating,
        })}
      >
        {floatingComp()}
        {
          <ErrorWrapper
            visible={touched && !!error}
            className="invalid-feedback d-flex align-items-center"
            style={{ display: "block", color: `${errorColor}` }}
          >
            {error}
          </ErrorWrapper>
        }
      </InputWrapper>
    );
  }
);
export default Input;
