import styled from "styled-components";
import BannerBox from "./BannerBox";
import { MappedDashboardInfo } from "../../../types";
import { StyledBannerWrapper } from "../styles/bannerBox";

interface Props {
  data: MappedDashboardInfo;
}

const DesktopDashboardInfo = ({ data }: Props) => {
  const { upcomingMaturityDate, earned, investments } = data;

  return (
    <DesktopWrapper>
      <StyledBannerWrapper>
        <BannerBox title="TOTAL INVESTMENTS" value={investments} />
      </StyledBannerWrapper>
      <StyledBannerWrapper>
        <BannerBox title="TOTAL APY EARNED" value={earned} isMoney />
      </StyledBannerWrapper>
      <StyledBannerWrapper>
        <BannerBox
          title="UPCOMING MATURITY DATE"
          value={upcomingMaturityDate}
        />
      </StyledBannerWrapper>
    </DesktopWrapper>
  );
};

const DesktopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  @media (max-width: 767px) {
    display: none;
  }
`;

export default DesktopDashboardInfo;
