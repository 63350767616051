import React, { SelectHTMLAttributes, useState } from "react";
import classNames from "classnames";
import { Variant } from "../../../types";
import {
  InputWrapper,
  StyledLabel,
  StyledSelect,
} from "../../../styles/Inputs";

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  variant?: Variant;
  error?: string;
  onFocus?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  placeHolder?: string;
  options: string[][];
  notShowLabel?: boolean;
}

const Selector = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    const [touched, setTouched] = useState<boolean>(false);
    const {
      label,
      variant,
      id,
      name,
      error = false,
      options,
      placeHolder,
      onFocus,
      notShowLabel,
      form,
      value,
      ...rest
    } = props;

    const isFloating = variant === "floating";

    const labelComp = !!label && !notShowLabel && (
      <StyledLabel
        className={classNames("selectLabel", { col: variant === "row" })}
      >
        <strong>{label}</strong>
      </StyledLabel>
    );

    const selectProps = {
      className: classNames(
        "form-control form-select",
        {
          "is-invalid": touched && error,
        },
        { "col px-4": variant === "row" }
      ),
      ref: ref as React.ForwardedRef<HTMLSelectElement>,
      form: form || "defaultForm",
      onFocus: (e: React.FocusEvent<HTMLSelectElement>) => {
        if (onFocus) onFocus(e);
        setTouched(true);
      },
      name,
      defaultValue: "",
      ...rest,
    };

    const selectComp = (
      <StyledSelect {...selectProps}>
        {placeHolder ? (
          <option value={""} id="placeholder" disabled>
            {placeHolder}
          </option>
        ) : (
          <option value={""} id="placeholder" disabled>
            Choose....
          </option>
        )}

        {options.map((option) => {
          return (
            <option value={option[0]} key={option[0]} data-cy="select-option">
              {option[1]}
            </option>
          );
        })}
      </StyledSelect>
    );

    return (
      <InputWrapper
        className={classNames(
          { "form-floating": isFloating },
          { "row d-flex align-items-center": variant === "row" }
        )}
      >
        {isFloating ? (
          <>
            {selectComp}
            {labelComp}
          </>
        ) : (
          <>
            {labelComp}
            {selectComp}
          </>
        )}
        {touched && error ? (
          <div className="invalid-feedback">{error}</div>
        ) : null}
      </InputWrapper>
    );
  }
);

export default Selector;
