import { Button, Modal } from "react-bootstrap";

export interface ModalProps {
  show: boolean;
  handleClose?: () => void;
  title: string;
  body: JSX.Element | string;
  handleClick?: (e?: React.MouseEvent<HTMLElement>) => Promise<void>;
  buttonMessage?: string;
  secondButtonMessage?: string;
  secondButtonHandleClick?: (
    e?: React.MouseEvent<HTMLElement>
  ) => Promise<void>;
}

const ModalPopUp = ({
  show,
  handleClose,
  title,
  body,
  handleClick,
  buttonMessage,
  secondButtonMessage,
  secondButtonHandleClick,
}: ModalProps) => {
  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton={!!handleClose}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {!!handleClick && !!buttonMessage && (
        <Modal.Footer>
          <Button onClick={handleClick}>{buttonMessage}</Button>
          {!!secondButtonMessage && secondButtonHandleClick && (
            <Button onClick={secondButtonHandleClick}>
              {secondButtonMessage}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalPopUp;
