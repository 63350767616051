import { RightWrapper } from "../../../../styles/list";
import {
  DocumentAccessType,
  GetUserDocumentUrl,
} from "../../../../types/documents";
import { HandleClickProps } from "../../../../types/downloadQueue";
import { useMemo } from "react";
import { useOpenDocument } from "../../hooks/useCreateDocumentViewURL";
import { Mobile } from "../../styles";
import { Dropdown } from "react-bootstrap";
import { FaEllipsisH } from "react-icons/fa";
import styled, { css } from "styled-components";
interface Props {
  name: string;
  formatType: string;
  s3Key: string;
  id: string;
  loading: boolean;
  updateQueue: (input: HandleClickProps<GetUserDocumentUrl>) => void;
}

const MobileActions = ({
  name,
  formatType,
  s3Key,
  id,
  loading,
  updateQueue,
}: Props) => {
  const { createDocumentViewUrl, loading: loadingViewUrl } = useOpenDocument({
    key: s3Key,
    format: formatType,
    name,
  });

  const updateQueueInput: HandleClickProps<GetUserDocumentUrl> = useMemo(() => {
    return {
      input: {
        key: s3Key,
        format: formatType,
        name,
        accessType: DocumentAccessType.download,
      },
      id,
    };
  }, [formatType, id, name, s3Key]);

  return (
    <Mobile>
      <RightWrapper>
        <Dropdown>
          <StyledToggle
            style={{
              background: "transparent",
              border: "none",
              boxShadow: "none",
            }}
            bsPrefix="text-decoration-none"
          >
            <FaEllipsisH />
          </StyledToggle>
          <Dropdown.Menu>
            <CustomDropdownItem
              disabled={loading}
              onClick={() => updateQueue(updateQueueInput)}
            >
              <ItemWrapper>Download</ItemWrapper>
            </CustomDropdownItem>
            <CustomDropdownItem
              disabled={loadingViewUrl}
              onClick={() => createDocumentViewUrl()}
            >
              <ItemWrapper>View</ItemWrapper>
            </CustomDropdownItem>
          </Dropdown.Menu>
        </Dropdown>
      </RightWrapper>
    </Mobile>
  );
};

const ItemWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const CustomDropdownItem = styled(Dropdown.Item)`
  ${() => css`
    --bs-dropdown-link-active-bg: #000000;
  `}
`;

const StyledToggle = styled(Dropdown.Toggle)`
  path {
    color: ${({ theme }) => theme.PRIMARY_COLOR} !important;
  }
  transform: rotate(90deg);
`;

export default MobileActions;
