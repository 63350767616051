import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
} from "react-plaid-link";
import {
  BankAccountVerificationStatus,
  CacheKeys,
  CustomError,
  CustomPlaidBankAccount,
} from "../types";
import { BankAccount } from "../types/bank-accounts";
import { ResponseStatus } from "../types/ResponseStatus";
import { Plaid } from "./";
import { LocalStorage } from "./localStorage";
import { notifications } from "./notifications";
import { Colors } from "../styles/constants";

const onSuccess = async (
  public_token: string,
  { accounts, institution }: PlaidLinkOnSuccessMetadata
) => {
  const bankAcc: CustomPlaidBankAccount[] = accounts.map((account) => {
    const { verification_status, ...rest } = account;
    if (verification_status === null) {
      return rest;
    }
    return account;
  });
  try {
    const response = await Plaid.createMTAccount(
      public_token,
      bankAcc,
      institution?.name
    );
    const bankAccounts: BankAccount[] = response.bankAccounts;
    notifications({
      status: ResponseStatus.SUCCESS,
      successMessage: `The bank account was added successfully`,
    });
    return bankAccounts;
  } catch (e: any) {
    console.log(e);
    notifications({
      status: ResponseStatus.ERROR,
      errorMessage:
        e?.message ||
        `Oh, No, An Error.
      This error has been logged, and we are looking into it.`,
    });
  }
};

const onExit = async (
  err: PlaidLinkError | null,
  metadata: PlaidLinkOnExitMetadata
) => {
  // The user exited the Link flow.
  if (err != null) {
    // The user encountered a Plaid API error prior to exiting.
    console.log(err);
    console.log(err.display_message);
  }
  console.log(metadata);
  // metadata contains information about the institution
  // that the user selected and the most recent API request IDs.
  // Storing this information can be helpful for support.
};

const onEvent = async (event: string, metadata: PlaidLinkOnEventMetadata) => {
  console.log({ event, metadata });
};

const deleteAccount = async (bankAccountId: string) => {
  const path = "/bank-accounts";
  const response = await fetch(path, {
    method: "DELETE",
    body: JSON.stringify({
      bankAccountId,
    }),
  });
  if (!response.ok) {
    const res = await response.json();
    CustomError.throw(res, res?.message);
  }
};

const addToLocalStorage = (bankAccount?: BankAccount[]) => {
  if (bankAccount)
    LocalStorage.updateLocalStorage(CacheKeys.bankAccounts, bankAccount);
};

const handleStatus = (status: BankAccountVerificationStatus) => {
  const {
    PendingAutomaticVerification,
    PendingLoginRequired,
    PendingManualVerification,
    VerificationFailed,
    VerificationExpired,
    UserPermissionRevoked,
  } = BankAccountVerificationStatus;
  if (
    [
      PendingAutomaticVerification,
      PendingLoginRequired,
      PendingManualVerification,
    ].includes(status)
  ) {
    return Colors.YELLOW;
  }
  if (
    [VerificationFailed, VerificationExpired, UserPermissionRevoked].includes(
      status
    )
  ) {
    return Colors.RED;
  }
  return Colors.GREEN;
};

export const PlaidBankAccount = {
  onSuccess,
  onExit,
  onEvent,
  deleteAccount,
  addToLocalStorage,
  handleStatus,
};
