export const handleList = <T>(
  listItems: T[],
  limit?: number,
  skip: number = 0
) => {
  if (limit) {
    return listItems.slice(skip * limit, (skip + 1) * limit);
  }
  return listItems;
};
