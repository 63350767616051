import {
  CreateAccountForm,
  CreateAccountErrors,
  CreateAccountTouch,
} from "./types";

export const createAccountInitialData: CreateAccountForm = {
  firstName: "",
  username: "",
  password: "",
  income: "",
  accredited: "false",
  phoneNumber: "",
  lastName: "",
  didSignTOSPolicy: false,
  didSignPrivacyPolicy: false,
};

export const createAccountInitialErrors: CreateAccountErrors = {
  ...createAccountInitialData,
  didSignPrivacyPolicy: "",
  didSignTOSPolicy: "",
  accredited: "",
  form: "",
};
export const createAccountTouch: CreateAccountTouch = {
  firstName: false,
  username: false,
  password: false,
  income: false,
  accredited: true,
  phoneNumber: false,
  lastName: false,
  didSignTOSPolicy: false,
  didSignPrivacyPolicy: false,
};
