import { useLinkToken } from "../hooks";
import {
  PlaidLinkError,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnExitMetadata,
  usePlaidLink,
  PlaidLinkOnEventMetadata,
} from "react-plaid-link";
import { useEffect } from "react";
import { Products } from "../types";
import ModalPopUp, { ModalProps } from "./ModalPopUp";
import { WorkingOnIt } from "./WorkingOnIt";

interface Props {
  product: Products[];
  onSuccess: (
    public_token: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => Promise<void>;
  onExit?: (
    err: PlaidLinkError | null,
    metadata: PlaidLinkOnExitMetadata
  ) => Promise<void>;
  onEvent: (event: string, metadata: PlaidLinkOnEventMetadata) => Promise<void>;
  modalProps?: OnboardingPopUp;
  isFetching?: boolean;
  delayReady: boolean;
}

export interface OnboardingPopUp extends ModalProps {
  needOpen?: boolean;
}

const Onboarding = ({
  product,
  onSuccess,
  onEvent,
  modalProps,
  isFetching,
  delayReady,
}: Props) => {
  const { linkToken, loading, error } = useLinkToken(product);
  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess,
    onEvent,
  });

  useEffect(() => {
    if (ready && delayReady) {
      open();
    }
  }, [ready, open, delayReady]);

  if (!delayReady || loading || isFetching) {
    return <WorkingOnIt />;
  }

  if (error) {
    return <div>Error generating link token</div>;
  }

  let handleClick;

  if (modalProps) {
    handleClick = async () => {
      if (modalProps.handleClick) {
        modalProps.handleClick();
      }

      if (modalProps.needOpen) {
        open();
      }
    };
  }

  return (
    <>
      {modalProps && (
        <ModalPopUp
          show={modalProps.show}
          title={modalProps.title}
          body={modalProps.body}
          buttonMessage={modalProps.buttonMessage}
          handleClick={handleClick}
          secondButtonHandleClick={modalProps.secondButtonHandleClick}
          secondButtonMessage={modalProps.secondButtonMessage}
          handleClose={modalProps.handleClose}
        />
      )}
    </>
  );
};

export default Onboarding;
