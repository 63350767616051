import {
  BottomDiv,
  CancelButton,
  ConfirmButton,
  CustomSpinner,
  CustomStrong,
  GreyText,
  StyledDiv,
  StyledNumber,
  StyledText,
  Wrapper,
  Image,
} from "../../styles/popUp";
import { formatDate, formatNumbers, removeCommas } from "../../utils";
import NorhartModal from "../NorhartModal";
import { AddMoneyInitialData, Steps } from "../../types/payment-modal";
import { useState } from "react";
import { notifications } from "../../utils/notifications";
import { ResponseStatus } from "../../types/ResponseStatus";
import CheckIcon from "../../assets/images/badge-check.png";
import { ErrorCodes } from "../../types";
import { flexAccountKey } from "./constants";

interface Props {
  show: boolean;
  onClose: (step: Steps) => void;
  step: Steps;
  nextStep: (step: Steps) => void;
  displayData: AddMoneyInitialData;
  handleSubmit: (paymentId: string) => Promise<void>;
  paymentId?: string;
  refetchFlexAccount: () => Promise<void>;
}

const REFETCH_TIMEOUT = 10000;

const DepositConfirm = ({
  show,
  onClose,
  step,
  nextStep,
  displayData: { amount, account, product },
  handleSubmit,
  paymentId,
  refetchFlexAccount,
}: Props) => {
  const [loading, setIsLoading] = useState<boolean>();
  const [refetching, setRefetching] = useState<boolean>();

  const handleClick = async () => {
    setIsLoading(true);
    try {
      if (!paymentId) {
        handleCancel();
        throw new Error();
      }
      await handleSubmit(paymentId);
      if (account.id === flexAccountKey) {
        await refetchFlexAccount();
      }
      nextStep(step);
    } catch (e: any) {
      if (e?.response?.status === 504) {
        if (paymentId) {
          setRefetching(true);
          await refetchFunction();
          return;
        }
      }
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage: e?.message || "Something went wrong creating the payment",
      });
    }
    setIsLoading(false);
  };
  const handleCancel = () => {
    onClose(step);
  };

  const refetchFunction = async () => {
    try {
      await handleSubmit(paymentId!);
      nextStep(step);
    } catch (e: any) {
      if (e?.code === ErrorCodes.PaymentIsBeingProcessed) {
        setTimeout(async () => {
          await refetchFunction();
        }, REFETCH_TIMEOUT);
        return;
      }
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage: e?.message || "Something went wrong creating the payment",
      });
      if (e?.code === ErrorCodes.PaymentTimedOut) {
        handleCancel();
        return;
      }
    }
    setRefetching(false);
  };

  return (
    <div>
      <NorhartModal
        title="Confirm your transfer"
        show={show}
        onClose={handleCancel}
        canClose={!loading}
      >
        <>
          <Wrapper>
            <StyledNumber>
              ${formatNumbers(Number(removeCommas(amount)), 1)}
            </StyledNumber>
            <StyledText>
              {product.name}
              <br />
              {formatDate(new Date().toDateString())}
            </StyledText>
          </Wrapper>
          <BottomDiv>
            <div>
              <CustomStrong>Status: Confirm </CustomStrong>
              <CustomStrong>
                Signed
                <Image
                  src={CheckIcon}
                  width={20}
                  height={20}
                  alt="check-icon"
                />
              </CustomStrong>
              <GreyText>From: {account.name}</GreyText>
            </div>
            <StyledDiv>
              <CustomStrong>Total</CustomStrong>
              <CustomStrong>
                ${formatNumbers(Number(removeCommas(amount)), 1)}
              </CustomStrong>
            </StyledDiv>
            <BottomDiv>
              <ConfirmButton
                onClick={handleClick}
                disabled={loading}
                data-cy="add-money-confirm-submit"
              >
                <CustomSpinner
                  hide={(!loading)?.toString()}
                  animation={refetching ? "grow" : "border"}
                  size="sm"
                />
                {!loading && "Confirm & Continue"}
                <div style={{ marginLeft: "5px" }}>
                  {refetching && "Processing your payment..."}
                </div>
              </ConfirmButton>
              {!loading && (
                <CancelButton onClick={handleCancel}>Cancel</CancelButton>
              )}
            </BottomDiv>
          </BottomDiv>
        </>
      </NorhartModal>
    </div>
  );
};
export default DepositConfirm;
