import { Navigate, useLocation } from "react-router-dom";

export const RemoveTrailingSlash = ({ ...rest }) => {
  const { search, pathname } = useLocation();

  // If the last character of the url is '/'
  if (pathname.match("/.*/$")) {
    return (
      <Navigate
        replace
        {...rest}
        to={{
          pathname: pathname.replace(/\/+$/, ""),
          search: search,
        }}
      />
    );
  }
  return null;
};
