import { FormEvent, useEffect, useMemo, useState } from "react";
import { useForm } from "../../hooks/useForm";
import { initialErrors, initialTouch, customErrors } from "./constants";
import {
  BottomDiv,
  CancelButton,
  ConfirmButton,
  CustomSpinner,
  Form,
  InputWrapper,
  StyledLabel,
} from "../../styles/popUp";
import { BankAccount } from "../../types/bank-accounts";
import { ModalInitialData, Steps } from "../../types/payment-modal";
import BankBanner from "../BankBanner";
import { FormInput } from "../forms/formsComponents/FormInput";
import { FormReactSelect } from "../forms/formsComponents/FormReactSelect";
import { FormSelect } from "../forms/formsComponents/FormSelect";
import { Loading } from "../Loading";
import NorhartModal from "../NorhartModal";
import { useHasBankAccounts } from "../../hooks/useHasBankAccounts";
import { TransactionGroup } from "../../types/payments";
import { notifications } from "../../utils/notifications";
import { ResponseStatus } from "../../types/ResponseStatus";
import { FlexAccount } from "../../types";
import {
  Payments,
  Plaid,
  convertToNumber,
  formatNumbers,
  touchInputs,
} from "../../utils";
import WithdrawError from "./WithdrawError";
// import { MINIMUM_AMOUNT } from "../addMoney/constants";

interface Props {
  show: boolean;
  onClose: (step: Steps) => void;
  step: Steps;
  nextStep: (step: Steps) => void;
  updateAttributes: (attributes: ModalInitialData) => void;
  attributes: ModalInitialData;
  bankAccounts?: BankAccount[];
  bankAccountsLoading: boolean;
  onSubmitAmount: (
    fields: ModalInitialData,
    transactionGroup?: TransactionGroup
  ) => Promise<void>;
  handleBankClose?: () => void;
  flexAccount?: FlexAccount;
  flexAccountLoading: boolean;
  ready: boolean;
  waitingBankAccounts: boolean;
  openPlaidPopUp: () => void;
}

const WithdrawAmount = ({
  show,
  onClose,
  step,
  nextStep,
  attributes,
  bankAccountsLoading,
  bankAccounts,
  onSubmitAmount,
  handleBankClose,
  flexAccount,
  flexAccountLoading,
  waitingBankAccounts,
  openPlaidPopUp,
  ready,
}: Props) => {
  const {
    fields,
    setFields,
    errors,
    handleErrors,
    handleFields,
    handleTouch,
    submitLoading,
    onSubmit,
    resetForm,
    handleInputChange,
  } = useForm({
    initialFields: attributes,
    initialErrors: initialErrors,
    initialTouch: initialTouch,
    customErrors: customErrors,
  });

  const [showWithdrawalError, setShowWithdrawalError] = useState(false);
  const [requiredInputsEmpty, setRequiredInputsEmpty] = useState(true);
  useEffect(() => {
    const { hasEmptyRequired, findInputs } = touchInputs(undefined, false);
    if (findInputs) setRequiredInputsEmpty(hasEmptyRequired);
  }, [fields]);

  const withdrawalAll = () => {
    setFields((prev) => {
      const amount = formatNumbers(flexAccount!.currentBalance.available);
      return {
        ...prev,
        amount,
      };
    });
    setShowWithdrawalError(false);
  };

  const amountValidation = (value: string) => {
    if (value === "0")
      return { isValid: false, message: "Amount must be greater than 0" };
    const valueNumber = convertToNumber(value);
    const balance = convertToNumber(
      formatNumbers(flexAccount!.currentBalance.available)
    );
    const newBalance = balance - valueNumber;
    return { isValid: newBalance >= 0 };
  };

  // const minimumAmountValidation = (value: string) => {
  //   const valueNumber = convertToNumber(value);
  //   const balance = convertToNumber(
  //     formatNumbers(flexAccount!.currentBalance.available)
  //   );
  //   const newBalance = balance - valueNumber;
  //   const condition = newBalance >= MINIMUM_AMOUNT || newBalance === 0;
  //   console.log(condition);
  //   return !condition;
  // };

  const { hasBankAccounts, loading: loadingHasBankAccount } =
    useHasBankAccounts();

  const onSubmitForm = async () => {
    // if (minimumAmountValidation(fields.amount)) {
    //   setShowWithdrawalError(true);
    //   return;
    // }
    try {
      await onSubmitAmount(fields, TransactionGroup.Withdraw);
      resetForm();
      nextStep(step);
    } catch (e: any) {
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage: e?.message || "Something went wrong creating the payment",
      });
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    await onSubmit(e, onSubmitForm);
  };

  const handleClose = () => {
    resetForm();
    onClose(step);
  };

  const reactSelectOptions = [{ name: "Cash", disable: true }];

  const bankOptions = useMemo(() => {
    if (!bankAccounts) return;
    const list: string[][] = [];
    bankAccounts.forEach((bankAccount) => {
      const { id, bankName, last4 } = bankAccount;
      if (Plaid.bankAccountIsVerified(bankAccount.status))
        list.push([
          JSON.stringify({ id: id, name: bankName }),
          bankName +
            ` (${
              bankAccount?.availableBalance
                ? `$${bankAccount.availableBalance}`
                : last4
            }) `,
        ]);
    });
    return list;
  }, [bankAccounts]);

  const userCanWithdraw = Payments.canWithdraw(hasBankAccounts, bankAccounts);
  console.log({ requiredInputsEmpty });
  return (
    <NorhartModal title="Withdraw" show={show} onClose={handleClose}>
      <>
        {bankAccountsLoading ||
        flexAccountLoading ||
        !bankOptions ||
        (hasBankAccounts && !bankAccounts?.length) ||
        loadingHasBankAccount ? (
          <Loading />
        ) : (
          <>
            <WithdrawError
              show={showWithdrawalError}
              onClose={() => setShowWithdrawalError(false)}
              withdrawal={withdrawalAll}
            />
            {!userCanWithdraw && (
              <BankBanner
                needAlert
                needLoading
                onClose={handleBankClose}
                ready={ready}
                waitingBankAccounts={waitingBankAccounts}
                handleClick={openPlaidPopUp}
              />
            )}
            {userCanWithdraw && (
              <Form onSubmit={handleSubmit}>
                <InputWrapper>
                  <StyledLabel>From:</StyledLabel>
                  <FormReactSelect
                    name="product"
                    handleErrors={handleErrors}
                    handleFields={handleFields}
                    handleTouch={handleTouch}
                    options={reactSelectOptions}
                    value={reactSelectOptions[0]}
                    error={errors.product}
                    required
                    isSearchable={false}
                    placeHolder="Select a product..."
                    getOptionLabel={(v: any) => {
                      return v.name;
                    }}
                    getOptionValue={(v: any) => {
                      return v.name;
                    }}
                    isDisabled
                    label="Product"
                    notShowLabel={true}
                    handleValue={(value: BankAccount) => {
                      return { name: value.bankName, id: value.id };
                    }}
                  />
                </InputWrapper>
                <InputWrapper>
                  <StyledLabel>To:</StyledLabel>
                  <FormSelect
                    name="account"
                    handleErrors={handleErrors}
                    handleFields={handleFields}
                    handleTouch={handleTouch}
                    options={bankOptions!}
                    value={fields.account.id}
                    error={errors.account}
                    required
                    placeHolder="Select a bank account..."
                    label="Bank Account"
                    notShowLabel={true}
                    defaultValue=""
                    data-cy="select-bank-account"
                  />
                </InputWrapper>
                <InputWrapper>
                  <StyledLabel>Amount:</StyledLabel>
                  <FormInput
                    name="amount"
                    value={fields.amount}
                    error={errors.amount}
                    handleErrors={handleErrors}
                    onChange={handleInputChange}
                    handleTouch={handleTouch}
                    required
                    isMoney
                    validation={amountValidation}
                    message={`Your flex account balance is ${formatNumbers(
                      flexAccount!.currentBalance.available
                    )} `}
                    placeholder="Amount (Example: $500)"
                    label="Amount"
                    notShowLabel={true}
                    data-cy="amount"
                  />
                </InputWrapper>
                <BottomDiv>
                  <ConfirmButton
                    type="submit"
                    disabled={requiredInputsEmpty || submitLoading}
                    data-cy="withdraw-submit"
                  >
                    <CustomSpinner
                      hide={(!submitLoading)?.toString()}
                      size="sm"
                    />
                    {!submitLoading && "Continue"}
                  </ConfirmButton>
                  <CancelButton type="button" onClick={handleClose}>
                    Cancel
                  </CancelButton>
                </BottomDiv>
              </Form>
            )}
          </>
        )}
      </>
    </NorhartModal>
  );
};

export default WithdrawAmount;
