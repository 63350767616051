import {
  DocumentAccessType,
  GetUserDocumentUrl,
} from "../../../../types/documents";
import { HandleClickProps } from "../../../../types/downloadQueue";
import { useMemo } from "react";
import { useOpenDocument } from "../../hooks/useCreateDocumentViewURL";
import { Button, Desktop, StyledSpinner } from "../../styles";
import { RightWrapper } from "../../../../styles/list";
interface Props {
  name: string;
  formatType: string;
  s3Key: string;
  id: string;
  loading: boolean;
  updateQueue: (input: HandleClickProps<GetUserDocumentUrl>) => void;
}

const DesktopActions = ({
  name,
  formatType,
  s3Key,
  id,
  loading,
  updateQueue,
}: Props) => {
  const { createDocumentViewUrl, loading: loadingViewUrl } = useOpenDocument({
    key: s3Key,
    format: formatType,
    name,
  });

  const updateQueueInput: HandleClickProps<GetUserDocumentUrl> = useMemo(() => {
    return {
      input: {
        key: s3Key,
        format: formatType,
        name,
        accessType: DocumentAccessType.download,
      },
      id,
    };
  }, [formatType, id, name, s3Key]);

  return (
    <Desktop>
      <RightWrapper>
        <Button
          disabled={loading}
          onClick={() => updateQueue(updateQueueInput)}
        >
          {loading ? <StyledSpinner /> : "Download"}
        </Button>
        <Button
          disabled={loadingViewUrl}
          onClick={() => createDocumentViewUrl()}
        >
          {loadingViewUrl ? <StyledSpinner /> : "View"}
        </Button>
      </RightWrapper>
    </Desktop>
  );
};

export default DesktopActions;
