import { Auth, User, validateEmail } from "../utils";
import { useNavigate } from "react-router-dom";
import { Form, AlertWithButton } from "../styles";
import { FormEvent, useState } from "react";
import { Button, Alert } from "react-bootstrap";
import ConfirmAccountPopUp from "../components/ConfirmAccountPopUp";
import { LOGIN_CUSTOM_ERRORS, LoginForm } from "../pages/signin/types";
import { useForm } from "../hooks/useForm";
import { FormInput } from "../components/forms/formsComponents/FormInput";
import {
  initialTouch,
  initialData,
  initialErrors,
} from "../pages/signin/constants";
import { Roles } from "../types/roles";
import { AuthButton } from "../components/AuthButton";
import { CustomCognitoAttributes } from "../types/cognito-attributes";

const Login = () => {
  const {
    fields,
    errors,
    handleErrors,
    handleFields,
    handleTouch,
    submitLoading,
    onSubmit,
    submitWithEnter: { ref, handleKeyUp },
  } = useForm({
    initialFields: initialData,
    initialErrors: initialErrors,
    initialTouch: initialTouch,
    customErrors: LOGIN_CUSTOM_ERRORS,
  });
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [needValidation, setNeedValidation] = useState<boolean>();
  const navigate = useNavigate();
  const [credentialsBackup, setCredentialsBackup] =
    useState<LoginForm>(initialData);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    onSubmit(e, onSubmitAuth(fields.username, fields.password));
  };

  const getUserPreferences = async () => {
    try {
      await User.getUsersPreferences();
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmitAuth = (username: string, password: string) => async () => {
    setNeedValidation(false);
    try {
      const { attributes, signInUserSession } = await Auth.signIn({
        username,
        password,
      });
      const { token, email } = await Auth.getUserName();
      window.dataLayer.push({ user_id: token, email_address: email });
      const attr = await Auth.getUserAttributes();
      await getUserPreferences();
      if ("income" in attr && attr["income"] !== "") {
        window.dataLayer.push({ income: attr["income"] });
      }

      const identity_verified =
        attributes[`custom:${CustomCognitoAttributes.identity_verified}`];
      if (!identity_verified) {
        navigate("/onboarding/identity-verification");
        return;
      }
      const has_bank_account =
        attributes[`custom:${CustomCognitoAttributes.has_bank_account}`];
      if (!has_bank_account) {
        navigate("/onboarding/bank-account");
        return;
      }
      if (
        signInUserSession.idToken.payload["cognito:groups"]?.includes(
          Roles.Admin
        )
      ) {
        navigate("/admin");
        return;
      }
      navigate("/dashboard");
    } catch (error: any) {
      if (error?.code === "UserNotConfirmedException") {
        setNeedValidation(true);
        return;
      }
      if (error?.code === "NotAuthorizedException") {
        throw new Error(LOGIN_CUSTOM_ERRORS.NotAuthorizedException);
      }
      if (error?.code === "UserNotFoundException") {
        throw new Error(LOGIN_CUSTOM_ERRORS.UserNotFoundException);
      }
      throw new Error(error?.code);
    }
  };

  const handleValidateButtonClick = async () => {
    setCredentialsBackup(fields);
    setShowPopUp(true);
  };
  return (
    <>
      <Form onSubmit={handleSubmit} ref={ref} onKeyUp={handleKeyUp}>
        {needValidation && (
          <AlertWithButton variant="secondary">
            <div>
              Your email have to be validate, please click here to do it.
            </div>
            <Button
              onClick={handleValidateButtonClick}
              disabled={!fields.username || !fields.password}
              variant="dark"
            >
              Validate email
            </Button>
          </AlertWithButton>
        )}
        {errors.form && <Alert variant="secondary">{errors.form}</Alert>}
        <div className="form-group">
          <FormInput
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            validation={validateEmail}
            message="The username must be an email"
            required
            label="Email"
            notShowLabel
            name="username"
            value={fields.username}
            error={errors.username}
            placeholder="Your Email Address"
            errorColor="white"
          />
        </div>
        <div className="form-group">
          <FormInput
            formNoValidate
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            required
            value={fields.password}
            name="password"
            type="password"
            label="Password"
            notShowLabel
            placeholder="Your Password"
            errorColor="white"
          />
        </div>
        <AuthButton
          loading={!!submitLoading}
          disabled={submitLoading}
          padlock={!submitLoading}
        >
          Sign In
        </AuthButton>
      </Form>
      <ConfirmAccountPopUp
        show={showPopUp}
        setShow={setShowPopUp}
        username={credentialsBackup.username}
        password={credentialsBackup.password}
        needResendCode={true}
      />
    </>
  );
};

export default Login;
