import { Modal } from "react-bootstrap";
import { useUserData } from "../hooks/useUserData";
import styled from "styled-components";
import {
  Auth,
  Payments,
  formatNumbers,
  handleCurrencyKeyDown,
  removeCommas,
} from "../utils";
import { CustomCognitoAttributes } from "../types/cognito-attributes";
import { FormEvent, useMemo } from "react";
import { MINIMUM_AMOUNT } from "./addMoney/constants";
import { FormInput } from "./forms/formsComponents/FormInput";
import { useForm } from "../hooks/useForm";
import { CHANGE_PASSWORD_CUSTOM_ERRORS } from "../pages/user-account/types";
import { notifications } from "../utils/notifications";
import { ResponseStatus } from "../types/ResponseStatus";

export const financialInitialData = {
  income: "",
};
export const financialInitialErrors = {
  income: "",
  form: "",
};
export const financialInitialTouch = {
  income: false,
};

export const incomeInputTracker = "incomeInputTracker";

interface Props {
  show: boolean;
  onClose: (v?: boolean) => void;
  userAmount: number | string;
  remainingAmount: string;
  setAmount: (amount: string) => void;
  income: string;
}

export const PercentageNotification = ({
  show,
  onClose,
  userAmount,
  remainingAmount,
  setAmount,
  income,
}: Props) => {
  const {
    handleErrors,
    handleInputChange,
    handleTouch,
    fields,
    errors,
    onSubmit,
    touch,
  } = useForm({
    initialFields: { income },
    initialErrors: financialInitialErrors,
    initialTouch: financialInitialTouch,
    customErrors: CHANGE_PASSWORD_CUSTOM_ERRORS,
    inputTracker: incomeInputTracker,
  });

  const onSubmitForm = async () => {
    try {
      const promises: Promise<any>[] = [];
      if (touch.income && fields.income)
        promises.push(
          Auth.updateAttributes(
            CustomCognitoAttributes.income,
            Payments.convertToCents(fields.income)
          )
        );
    } catch (e: any) {
      console.log(e);
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage:
          e?.message || `Unexpected error updating the financial information`,
      });
      throw new Error(
        "Something wrong happened updating the user financial information"
      );
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    onSubmit(e, onSubmitForm);
  };

  const { data } = useUserData();

  const accreditedOnClick = async () => {
    try {
      await Auth.updateAttributes(
        CustomCognitoAttributes.accredited_investor,
        "true"
      );
      onClose(true);
    } catch {
      console.error("Something wrong happened");
    }
  };

  const continueClick = () => {
    if (isLessThanMinimum) {
      setAmount(MINIMUM_AMOUNT.toString());
    } else {
      setAmount(remainingAmount);
    }
    onClose(!isLessThanMinimum);
  };

  const isLessThanMinimum = useMemo(() => {
    return Number(removeCommas(remainingAmount)) < MINIMUM_AMOUNT;
  }, [remainingAmount]);

  return (
    <StyledModal show={show} centered onHide={() => onClose(false)}>
      <AlertWrapper
        className="alert text-start mb-0 w-100"
        onSubmit={handleSubmit}
      >
        <StyledHeader closeButton>
          <Modal.Title className="alert-heading text-left">
            {data?.name?.given_name}
          </Modal.Title>
        </StyledHeader>

        <p>
          Looks like you want to invest more than 10% of your annual income,
          which is not allowed.
        </p>
        <hr />
        <div className="mb-0">
          <p>You have a couple of options to proceed.</p>
          <ol>
            <li className="my-3">
              If you are an accredited investor, please click the accredited
              button below to sign up to be an accredited investor and invest $
              {userAmount} today.
            </li>
            {isLessThanMinimum ? (
              <li>
                <p>
                  Your investment amount is less than our $
                  {formatNumbers(MINIMUM_AMOUNT, 1, 0)} minimum and exceeds 10%
                  of your income. If your income has changed you can adjust it
                  below.
                </p>
                <FormInput
                  variant="floating"
                  handleErrors={handleErrors}
                  onChange={handleInputChange}
                  handleTouch={handleTouch}
                  name="income"
                  label="Income"
                  value={fields.income}
                  error={errors.income}
                  form={incomeInputTracker}
                  onKeyDown={handleCurrencyKeyDown}
                  isMoney
                />
              </li>
            ) : (
              <li className="my-3">
                Click the continue button to invest ${remainingAmount}, which is
                less than 10% of your listed annual income.
              </li>
            )}
          </ol>
        </div>
        <Modal.Footer className="pb-0 d-flex justify-content-center">
          <StyledButton onClick={accreditedOnClick} type="button">
            Accredited
          </StyledButton>
          <StyledButton onClick={continueClick} type="submit">
            Continue
          </StyledButton>
        </Modal.Footer>
      </AlertWrapper>
    </StyledModal>
  );
};

const StyledHeader = styled(Modal.Header)`
  padding: 10px 0;
`;

const StyledModal = styled(Modal)`
  /* margin-top: 65px; */
  & .modal-content {
    margin: 40px;
  }
  @media (max-width: 575px) {
    margin-top: 50px;
  }
  @media (max-width: 500px) {
    margin-top: 35px;
  }
  @media (max-width: 450px) {
    margin-top: 15px;
  }
  @media (max-width: 335px) {
    margin-top: 0px;
  }
`;

const AlertWrapper = styled.form`
  background-color: #e7e7e7;
  color: #3c3c3c;
  padding: 35px;
  @media (max-width: 500px) {
    padding: 20px;
  }
`;

const StyledButton = styled.button`
  color: white;
  background-color: #000000;
  border: #000000;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 5px;
  @media (max-width: 280px) {
    font-size: 13px;
  }
`;
