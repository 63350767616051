import NorhartModal from "../NorhartModal";
import { AddMoneyInitialData, Steps } from "../../types/payment-modal";
import { OptionLabelFlexAccount } from "../OptionLabel";
import { Loading } from "../Loading";
import { FlexAccount, Product } from "../../types";
import { BankAccount } from "../../types/bank-accounts";
import BankBanner from "../BankBanner";
import { useHasBankAccounts } from "../../hooks/useHasBankAccounts";
import { TransactionGroup } from "../../types/payments";
import AmountForm from "./AmountForm";
import { useMemo } from "react";
import { Payments, Plaid } from "../../utils";

interface Props {
  show: boolean;
  onClose: (step: Steps) => void;
  step: Steps;
  nextStep: (step: Steps) => void;
  bankAccounts?: BankAccount[];
  bankAccountsLoading: boolean;
  flexAccount?: FlexAccount;
  flexAccountLoading: boolean;
  onSubmitAmount: (
    fields: AddMoneyInitialData,
    transactionGroup?: TransactionGroup
  ) => Promise<void>;
  handleBankClose?: () => void;
  ready: boolean;
  waitingBankAccounts: boolean;
  openPlaidPopUp: () => void;
  attributes: AddMoneyInitialData;
}

export interface GroupedOption {
  label: string;
  options: (Product | OptionLabelFlexAccount)[];
}

const DepositAmount = ({
  show,
  onClose,
  step,
  nextStep,
  bankAccounts,
  bankAccountsLoading,
  flexAccount,
  flexAccountLoading,
  onSubmitAmount,
  waitingBankAccounts,
  openPlaidPopUp,
  ready,
  attributes,
}: Props) => {
  const { hasBankAccounts, loading: loadingHasBankAccount } =
    useHasBankAccounts();
  const handleClose = () => {
    onClose(step);
  };

  const bankOptions = useMemo(() => {
    if (!bankAccounts) return;
    const list: string[][] = [];
    bankAccounts.forEach((bankAccount) => {
      const { id, bankName, last4 } = bankAccount;
      if (Plaid.bankAccountIsVerified(bankAccount.status))
        list.push([
          JSON.stringify({ id: id, name: bankName }),
          bankName +
            ` (${
              bankAccount?.availableBalance
                ? `$${bankAccount.availableBalance}`
                : last4
            }) `,
        ]);
    });
    return list;
  }, [bankAccounts]);

  const userCanInvest = Payments.canInvest(hasBankAccounts, bankAccounts);

  return (
    <NorhartModal title="Deposit" show={show} onClose={handleClose}>
      {bankAccountsLoading ||
      flexAccountLoading ||
      (hasBankAccounts && !bankAccounts?.length) ||
      loadingHasBankAccount ||
      !bankOptions ? (
        <Loading />
      ) : (
        <>
          {!userCanInvest && (
            <BankBanner
              needAlert
              needLoading
              ready={ready}
              waitingBankAccounts={waitingBankAccounts}
              handleClick={openPlaidPopUp}
            />
          )}
          {userCanInvest && (
            <AmountForm
              attributes={attributes}
              step={step}
              nextStep={nextStep}
              onSubmitAmount={onSubmitAmount}
              onClose={onClose}
              bankOptions={bankOptions}
            />
          )}
        </>
      )}
    </NorhartModal>
  );
};

export default DepositAmount;
