import styled from "styled-components";
import { ReactComponent as CardWarning } from "../assets/images/alert.svg";
import { ReactComponent as CardWarningIcon } from "../assets/images/card-warning.svg";

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.GREEN};
  color: ${({ theme }) => theme.SECONDARY_COLOR};
  padding: 5px 28px;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  font-weight: 700;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  @media (max-width: 400px) {
    padding: 3px 20px;
    font-size: 10px;
  }
`;

export const AlertWrapperWithoutButton = styled.div`
  background-color: ${({ theme }) => theme.BANNER_BACKGROUND};
  border-color: ${({ theme }) => theme.BANNER_BACKGROUND};
  border-radius: 5px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const AlertWrapperWithButton = styled(AlertWrapperWithoutButton)`
  justify-content: space-between;
`;

export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 400px) {
    gap: 5px;
  }
`;

export const CardIcon = styled(CardWarning)`
  height: 28px;
  width: 28px;
  fill: ${({ theme }) => theme.PRIMARY_COLOR};
`;

export const WarningCardIcon = styled(CardWarningIcon)`
  height: 28px;
  width: 28px;
  path {
    fill: ${({ theme }) => theme.PRIMARY_COLOR} !important;
  }
  .warning-symbol {
    fill: ${({ theme }) => theme.BANNER_BACKGROUND} !important;
  }
`;

export const BannerTitle = styled.h5`
  font-size: 16px;
  margin-bottom: 2px;
  @media (max-width: 400px) {
    font-size: 10px;
  }
`;
export const BannerDescription = styled.p`
  font-size: 11px;
  margin-bottom: 0;
  @media (max-width: 400px) {
    font-size: 8px;
  }
`;
