import WelcomeVideoComponent from "../../../components/landing/components/LandingVideoComponent";
import ZeroToUnicornVideo from "../../../components/landing/components/ZeroToUnicornVideo";

const WelcomeVideoSection = () => {
  return (
    <WelcomeVideoComponent
      backgroundColorOne="#f4f4f4"
      backgroundColorTwo="#f4f4f4"
      title="Meet Our Real CEO, Mike Kaeding"
      subTitle="Mike is a real guy, like you, and us. He is the CEO of Norhart.
      In our first-ever webinar, Mike will tell you What Norhart Invest is, and How your hard-earned money is invested, and protected by the SEC."
      textColor="#111111"
      children={<ZeroToUnicornVideo />}
    />
  );
};

export default WelcomeVideoSection;
