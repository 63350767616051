import { FormEvent } from "react";
import { FormInput } from "../../components/forms/formsComponents/FormInput";
import { useForm } from "../../hooks/useForm";
import FormWrapper from "../../components/FormWrapper";
import {
  flexAccountFormInputTracker,
  flexAccountInitialErrors,
  flexAccountInitialTouch,
} from "./constant";
import {
  FlexAccountInitialData,
  UPDATE_FLEX_ACCOUNT_INTEREST_CUSTOM_ERROR,
} from "./types";

interface Props {
  initialData: FlexAccountInitialData;
  updateInterest: (interest: string) => Promise<void>;
}

export const FlexAccountInterest = ({ initialData, updateInterest }: Props) => {
  const {
    handleErrors,
    handleFields,
    handleTouch,
    submitLoading,
    onSubmit,
    fields,
    errors,
  } = useForm({
    initialFields: initialData,
    initialErrors: flexAccountInitialErrors,
    initialTouch: flexAccountInitialTouch,
    customErrors: UPDATE_FLEX_ACCOUNT_INTEREST_CUSTOM_ERROR,
    inputTracker: flexAccountFormInputTracker,
  });
  const onSubmitForm = async () => {
    await updateInterest(fields.interest);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    onSubmit(e, onSubmitForm);
  };
  return (
    <FormWrapper
      onSubmit={handleSubmit}
      loading={!!submitLoading}
      buttonMessage={submitLoading ? "Updating Interest" : "Update Interest"}
      title={"Flex Account Interest"}
    >
      <div className="row g-3 w-100">
        <div className="col-12">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            name="interest"
            label="Flex Account interest"
            value={fields.interest}
            error={errors.interest}
            form={flexAccountFormInputTracker}
            type="number"
          />
        </div>
      </div>
    </FormWrapper>
  );
};
