import { Navigate } from "react-router-dom";

interface Props {
  path: string;
}

const Redirect = ({ path }: Props) => {
  if (!path.startsWith("/")) {
    window.location.href = path;
    return null;
  }
  return <Navigate to={path} replace />;
};

export default Redirect;
