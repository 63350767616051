import { CacheKeys, NewCustomError, TransactionsResponse } from "../types";
import { BankAccount } from "../types/bank-accounts";
import {
  CreatePaymentInput,
  PaymentResponse,
  PromissoryNote,
  VerifyPaymentCreationOutput,
} from "../types/payments";
import { LocalStorage } from "./localStorage";
import { Plaid } from "./plaid";

const create = async (paymentId: string) => {
  const path = "/payments";
  const response = await fetch(path, {
    method: "POST",
    body: JSON.stringify({ paymentId }),
  });
  const res = await response.json();
  if (!response.ok) {
    NewCustomError.throw(res, response);
  }
  return res as PaymentResponse;
};

const updateResponseOnStorage = ({
  flexAccount,
  transaction,
  promissoryNote,
}: PaymentResponse) => {
  if (flexAccount)
    LocalStorage.updateLocalStorage(CacheKeys.flexAccount, flexAccount);
  const transactionsString = localStorage.getItem(
    CacheKeys.transactions + CacheKeys.dashboard + 0
  );
  if (transactionsString) {
    const transactions: TransactionsResponse = JSON.parse(transactionsString);
    if (!transactions.items) transactions.items = [];
    transactions.items.unshift(transaction);
    if (transactions.items.length > 3) transactions.items.pop();
    LocalStorage.updateLocalStorage(
      CacheKeys.transactions + CacheKeys.dashboard + 0,
      transactions
    );
  }
  const promissoryNotesString = localStorage.getItem(CacheKeys.promissoryNotes);
  if (promissoryNote && promissoryNotesString) {
    const promissoryNotes: PromissoryNote[] = JSON.parse(promissoryNotesString);
    promissoryNotes.unshift(promissoryNote);
    LocalStorage.updateLocalStorage(CacheKeys.promissoryNotes, promissoryNotes);
  }
};

const verifyPaymentCreation = async (paymentInput: CreatePaymentInput) => {
  const path = "/payments/verification";
  const response = await fetch(path, {
    method: "POST",
    body: JSON.stringify(paymentInput),
  });
  const res = await response.json();
  if (!response.ok) {
    NewCustomError.throw(res, response);
  }
  return res as VerifyPaymentCreationOutput;
};

const convertToCents = (value: string) => {
  const withoutCommas = value.replace(/,/g, "");
  return (Number(withoutCommas) * 100).toString();
};

const convertToDollars = (value: string) => {
  return (Number(value) / 100).toString();
};

const canInvest = (hasBankAccounts: boolean, bankAccounts?: BankAccount[]) => {
  if (!bankAccounts || !hasBankAccounts || !bankAccounts.length) return false;
  let hasVerifiedAccounts: boolean = false;
  for (const bankAccount of bankAccounts) {
    if (Plaid.bankAccountIsVerified(bankAccount.status)) {
      hasVerifiedAccounts = true;
      break;
    }
  }
  return hasVerifiedAccounts;
};

const canWithdraw = (
  hasBankAccounts: boolean,
  bankAccounts?: BankAccount[]
) => {
  if (!bankAccounts || !hasBankAccounts || !bankAccounts.length) return false;
  let hasVerifiedAccounts: boolean = false;
  for (const bankAccount of bankAccounts) {
    if (Plaid.bankAccountIsVerified(bankAccount.status)) {
      hasVerifiedAccounts = true;
      break;
    }
  }
  return hasVerifiedAccounts;
};

export const Payments = {
  create,
  updateResponseOnStorage,
  verifyPaymentCreation,
  convertToCents,
  convertToDollars,
  canInvest,
  canWithdraw,
};
