import { NewCustomError, UserLeadInput } from "../types";

const sendEmail = async (userLeadInput: UserLeadInput) => {
  const path = "/user-lead";
  const response = await fetch(path, {
    method: "POST",
    body: JSON.stringify(userLeadInput),
  });
  if (!response.ok) {
    NewCustomError.throw({}, response);
  }
};

export const UserLead = {
  sendEmail,
};
