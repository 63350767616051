import { Auth } from "../utils";
import { Dispatch, FormEvent, SetStateAction, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import VerificationPopUp from "./VerificationPopUp";
import { Roles } from "../types/roles";
import { ResponseStatus } from "../types/ResponseStatus";
import { notifications } from "../utils/notifications";

interface SignUpVerificationPopUpProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  username: string;
  password: string;
  needResendCode?: boolean;
}

const ConfirmAccountPopUp = ({
  show,
  setShow,
  username,
  password,
}: SignUpVerificationPopUpProps) => {
  const navigate = useNavigate();
  const ref = useRef<HTMLButtonElement>(null);

  const publishGTM = (user_id: any) => {
    window.dataLayer.push({
      event: "email_verification",
      user_id: user_id,
    });
  };

  const [isSubmitting, setIsSubmitting] = useState<boolean>();
  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    verificationCode: string[]
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const submitButton = document.getElementById(
      "submit-btn"
    ) as HTMLButtonElement;
    if (submitButton && submitButton.disabled) return;
    setIsSubmitting(true);
    try {
      await Auth.confirmSignUp({ username, code: verificationCode.join("") });
      const { attributes, signInUserSession } = await Auth.signIn({
        username,
        password,
      });
      publishGTM(signInUserSession.idToken.payload.sub);
      const identity_verified = attributes["custom:identity_verified"];
      if (!identity_verified) {
        navigate("/onboarding/identity-verification");
        return;
      }
      if (
        signInUserSession.idToken.payload["cognito:groups"]?.includes(
          Roles.Admin
        )
      ) {
        navigate("/admin");
        return;
      }
      navigate("/dashboard");
    } catch (e: any) {
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage:
          e.message ||
          "Something wrong happened verifying your account, please try again",
      });
      console.log(e);
    }
    setIsSubmitting(false);
  };

  return (
    <VerificationPopUp
      buttonRef={ref}
      show={show}
      setShow={setShow}
      username={username!}
      password={password!}
      needResendCode={true}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    />
  );
};

export default ConfirmAccountPopUp;
