import { Interceptor as Intercept } from "../utils";
import { useNavigate } from "react-router-dom";

interface Props {
  children: JSX.Element;
}

export const Interceptor = ({ children }: Props) => {
  const navigate = useNavigate();

  Intercept.interceptLog();
  Intercept.add(navigate);

  return <>{children}</>;
};
