import { ListGroup } from "react-bootstrap";
import { FormButton } from "../../../styles";
import styled from "styled-components";

export const Item = styled(ListGroup.Item)`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.BACKGROUND_COLOR};
  color: ${({ theme }) => theme.PRIMARY_COLOR};
  border-color: ${({ theme }) => theme.LIST_BORDER_COLOR};
  width: 100%;
  align-items: center;
  gap: 5px;
  @media (max-width: 280px) {
    padding: 5px 10px;
  }
`;

export const DeleteRow = styled(Item)`
  padding-right: 0;
`;

export const BankName = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const LeftWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
export const DeleteButton = styled(FormButton)`
  @media (max-width: 400px) {
    font-size: 13px;
    padding: 3px 18px;
  }
  @media (max-width: 320px) {
    font-size: 10px;
    padding: 3px 14px;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 400px) {
    font-size: 12px;
    width: 115px;
  }
  @media (max-width: 350px) {
    font-size: 11px;
    width: 90px;
  }
  @media (max-width: 280px) {
    font-size: 10px;
    width: 80px;
  }
`;
