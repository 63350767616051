import { ListGroup } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import styled from "styled-components";
import { TransactionStatus } from "../types";
import { ReactComponent as Add } from "../assets/icons/add.svg";

export const BasicItem = styled(ListGroup.Item)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const Item = styled(BasicItem)`
  background-color: ${({ theme }) => theme.BACKGROUND_COLOR};
  color: ${({ theme }) => theme.PRIMARY_COLOR};
  border-color: ${({ theme }) => theme.LIST_BORDER_COLOR};
  padding: 12px;
  gap: 10px;
`;

export const ReactSelectItem = styled(BasicItem)`
  padding: 12px;
  gap: 10px;
  background-color: transparent;
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  @media (max-width: 450px) {
    align-self: center;
    gap: 10px;
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  @media (max-width: 700px) {
    align-self: flex-start;
  }
  @media (max-width: 410px) {
    align-self: center;
    gap: 10px;
  }
`;

interface ArrowProps {
  displayarrow?: string;
}

export const Arrow = styled(AiOutlineRight)<ArrowProps>`
  font-size: 25px;
  color: grey;
  display: ${(props) => (!!props.displayarrow ? "flex" : "none")};
  cursor: pointer;
`;

interface DotProps {
  color?: string;
  status?: TransactionStatus;
  cursor?: string;
  displaydot?: string;
}

export const Dot = styled.div<DotProps>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => {
    if (props.color) return props.color;
    if (props.status === TransactionStatus.posted) return props.theme.GREEN;
    if (props.status === TransactionStatus.pending) return props.theme.YELLOW;
    if (props.status === TransactionStatus.failed) return props.theme.RED;
    if (
      props.status === TransactionStatus.returned ||
      props.status === TransactionStatus.reversed ||
      props.status === TransactionStatus.denied ||
      props.status === TransactionStatus.cancelled
    )
      return props.theme.RED;
    else return props.theme.GREEN;
  }};
  border-radius: 100px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: 600;
  display: ${(props) => (!!props.displaydot ? "flex" : "none")};
  cursor: ${(props) => (!!props.cursor ? "pointer" : "auto")};
`;

export const SmallDot = styled(Dot)`
  display: block;
  visibility: ${(props) => (!!props.displaydot ? "visible" : "hidden")};
  width: 10px;
  height: 10px;
`;

export const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-weight: 600;
  @media (max-width: 410px) {
    font-size: 14px;
  }
  @media (max-width: 350px) {
    font-size: 13px;
  }
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  @media (max-width: 410px) {
    font-size: 12px;
  }
  @media (max-width: 350px) {
    font-size: 10px;
  }
`;

export const NumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
`;

export const Interest = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  background-color: ${({ theme }) => theme.BANNER_BACKGROUND};
  color: ${({ theme }) => theme.BANNER_COLOR};
  display: flex;
  justify-content: center;
  padding: 0.35em 0.65em;
  border-radius: 0.375rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

export const ListTitle = styled.div`
  font-size: 21px;
  font-weight: 700;
  @media (max-width: 410px) {
    font-size: 18px;
    font-weight: 600;
  }
  @media (max-width: 350px) {
    font-size: 15px;
    font-weight: 600;
  }
`;
export const ViewAllButton = styled.button`
  line-height: 1;
  padding: 0.35em 0.65em;
  background-color: ${({ theme }) => theme.BANNER_BACKGROUND};
  color: ${({ theme }) => theme.BANNER_COLOR};
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.375rem;
  display: inline-block;
  white-space: nowrap;
  border: 0;
  @media (max-width: 450px) {
    font-size: 13px;
  }
  @media (max-width: 350px) {
    font-size: 11px;
  }
`;

export const PaginationButtonsWrapper = styled.div`
  display: flex;
  gap: 3px;
`;

export const PaginationRightButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0px;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;

export const PaginationLeftButton = styled(PaginationRightButton)`
  border-radius: 5px 0px 0px 5px;
`;

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
`;

export const TransparentButton = styled.button`
  border: transparent;
  background-color: transparent;
  padding: 0px;
  margin: 0px;
  margin-bottom: 5px;
`;

export const AddButton = styled(Add)`
  width: 25px;
  height: 25px;
  cursor: pointer;
  fill: ${({ theme }) => theme.PRIMARY_COLOR};
  @media (max-width: 410px) {
    width: 22px;
    height: 22px;
  }
  @media (max-width: 350px) {
    font-size: 16px;
    width: 20px;
  }
`;

export const RightArrow = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
  @media (max-width: 410px) {
    width: 20px;
    height: 20px;
  }
`;
