import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Logo } from "../assets/images/norhart-black-circle-icon.svg";
interface Props {
  title?: string;
  message?: string;
  needIcon?: boolean;
}

const NorhartHeader = ({ title, message, needIcon = true }: Props) => {
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center text-center">
        {needIcon && (
          <Link to="/dashboard">
            <div>
              <NorhartImage />
            </div>
          </Link>
        )}
        <PageName>{title}</PageName>
      </div>
      <>{message && <Message className="mt-4">{message}</Message>}</>
    </>
  );
};

const PageName = styled.h2`
  font-size: 23px;
  font-weight: 600;
  margin-top: 1.2rem;
  @media (max-width: 350px) {
    font-size: 20px;
    margin-bottom: 0px;
    margin-top: 10px;
  }
`;

const Message = styled.div`
  margin: auto;
  font-size: 1.5rem;
  text-align: center;
  width: 750px;
  margin-top: 10px;
  color: #7b96a4;
  @media (max-width: 800px) {
    width: 100%;
    padding: 0px 20px;
  }
`;

const NorhartImage = styled(Logo)`
  ellipse {
    fill: ${({ theme }) => theme.NORHART_CIRCLE_LOGO} !important;
  }
  height: 60px;
  width: 60px;
  @media (max-width: 350px) {
    height: 55px;
    width: 55px;
  }
  @media (max-width: 330px) {
    height: 50px;
    width: 50px;
  }
  @media (max-width: 280px) {
    height: 40px;
    width: 40px;
  }
`;

export default NorhartHeader;
