import { ButtonHTMLAttributes, FunctionComponent } from "react";
import styled from "styled-components";
import { ReactComponent as Padlock } from "../assets/images/padlock.svg";
import { Loading } from "./Loading";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: JSX.Element | string;
  padlock?: boolean;
  submitting?: boolean;
  darkMode?: boolean;
  loading?: boolean;
}

export const AuthButton: FunctionComponent<Props> = ({
  children,
  padlock,
  darkMode,
  loading,
  ...rest
}) => {
  return (
    <>
      {darkMode ? (
        <DarkModeButton
          loading={(!!loading).toString()}
          type="submit"
          className="btn btn-outline-dark"
          style={{ fontWeight: "bold" }}
          {...rest}
        >
          {loading ? <Loading withoutMargin /> : children}
        </DarkModeButton>
      ) : (
        <SignInBtn
          loading={(!!loading).toString()}
          className="btn-lg"
          type="submit"
          {...rest}
        >
          {padlock && <Padlock />}
          {loading ? <Loading withoutMargin /> : children}
        </SignInBtn>
      )}
    </>
  );
};

interface ButtonProps {
  loading: string;
}

const DarkModeButton = styled.button<ButtonProps>`
  width: fit-content;
  min-width: 86px;
  div > div {
    height: 24px;
    width: 24px;
    border-width: 3px;
    &:hover {
      color: white !important;
    }
  }
  height: 38px;
`;

const SignInBtn = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: ${({ loading, theme }) =>
    loading === "true" ? theme.GREEN : "#383838"};
  color: ${({ loading }) => (loading === "true" ? "black" : "white")};
  font-weight: bold;
  border: 0px;
  height: 50px;
  opacity: ${(props) => (props.disabled ? "0.75" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    color: ${({ disabled }) => (disabled ? "white" : "black")};
    background-color: ${({ theme, disabled, loading }) =>
      disabled && !(loading === "true") ? "#383838" : theme.GREEN};
    > svg > path {
      fill: ${({ disabled }) => (disabled ? "white" : "black")};
    }
  }
  > svg > path {
    fill: ${({ loading }) => (loading === "true" ? "black" : "white")};
  }
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
  width: 100%;
  min-width: 86px;
  @media (max-width: 280px) {
    height: 40px;
    font-size: 16px;
    padding: 5px 10px;
  }
`;
