import styled from "styled-components";
import { FunctionComponent } from "react";
import { AuthNavbar } from "../../components/navbar/AuthNavbar";
import InvestLegalSection from "../../components/landing/sections/InvestLegalSection";
import WelcomeInvestCalculatorSection from "../../components/landing/sections/WelcomeInvestCalculatorSection";
import LandingFooter from "../../components/landing/sections/LandingFooter";
import GolfBanner from "./sections/GolfBanner";
import InvestIRAPromise from "./sections/InvestIRAPromise";
import OpenToAllInvestors from "../../components/landing/sections/OpenToAllInvestors";
import InvestIRARates from "./sections/InvestIraRates";
import InvestIRASteps from "./sections/InvestIRASteps";
import WelcomeVideoSection from "./sections/WelcomeVideoSection";
import StaffSection from "../../components/landing/sections/StaffSection";
import IraApply from "./sections/IraApply";
import WhyInvestVideoSection from "../../components/landing/sections/WhyInvestVideoSection";
import TrustpilotWidget from "../../components/TrustpilotWidget";

export const Ira: FunctionComponent = () => {
  return (
    <Container>
      <AuthNavbar darkMode />
      <div style={{ maxWidth: "100vw" }}>
        <GolfBanner />
        <WelcomeInvestCalculatorSection />
        <InvestIRAPromise />
        <OpenToAllInvestors />
        <InvestIRARates />
        <InvestIRASteps />
        <WelcomeVideoSection />
        <StaffSection />
        <WhyInvestVideoSection />
        <IraApply />
        <InvestLegalSection fontColor="#111111" />
      </div>
      <LandingFooter />
      <TrustpilotWidget />
    </Container>
  );
};

const Container = styled.div`
  background: linear-gradient(#2f2f2f, #121212) !important;
  max-height: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
`;
