import { useBankAccounts } from "../../hooks/useBankAccounts";
import { useFlexAccount } from "../../hooks/useFlexAccount";
import { usePaymentModal } from "../../hooks/usePaymentModal";
import {
  flowDirection,
  ModalInitialData,
  Steps,
} from "../../types/payment-modal";
import WithdrawAmount from "./WithdrawAmount";
import WithdrawConfirm from "./WithdrawConfirm";
import WithdrawSuccess from "./WithdrawSuccess";
import styled from "styled-components";

interface Props {
  startFlow: boolean;
  onClose: () => void;
  ready: boolean;
  waitingBankAccounts: boolean;
  openPlaidPopUp: () => void;
}
const initialData = {
  account: { name: "", id: "" },
  amount: "",
  product: { name: "", id: "" },
};

const WithdrawModal = ({
  startFlow,
  onClose,
  waitingBankAccounts,
  openPlaidPopUp,
  ready,
}: Props) => {
  const {
    showAmount,
    showConfirm,
    showSuccess,
    attributes,
    updateAttributes,
    handleClose,
    nextStep,
    handleSubmit,
    paymentId,
    onSubmitAmount,
    signatureUrl,
    updateSignatureUrl,
  } = usePaymentModal({ initialData, flowDirection, onClose, startFlow });

  const { bankAccounts, loading: bankAccountsLoading } = useBankAccounts();
  const { data: flexAccount, loading: flexAccountLoading } = useFlexAccount();

  return (
    <Wrapper>
      <WithdrawAmount
        show={showAmount}
        onClose={handleClose}
        step={Steps.Amount}
        nextStep={nextStep}
        updateAttributes={updateAttributes}
        attributes={attributes as ModalInitialData}
        bankAccounts={bankAccounts}
        bankAccountsLoading={bankAccountsLoading}
        onSubmitAmount={onSubmitAmount}
        flexAccount={flexAccount}
        flexAccountLoading={flexAccountLoading}
        ready={ready}
        waitingBankAccounts={waitingBankAccounts}
        openPlaidPopUp={openPlaidPopUp}
      />
      <WithdrawConfirm
        show={showConfirm}
        onClose={handleClose}
        step={Steps.Confirm}
        nextStep={nextStep}
        attributes={attributes}
        handleSubmit={handleSubmit}
        paymentId={paymentId}
        signatureUrl={signatureUrl}
        updateSignatureUrl={updateSignatureUrl}
      />
      <WithdrawSuccess
        show={showSuccess}
        onClose={handleClose}
        step={Steps.Success}
        attributes={attributes}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: none;
`;

export default WithdrawModal;
