import { useState, useEffect, useCallback } from "react";
import { LocalStorage } from "../utils/localStorage";

type UseFetchOptions<T> = {
  cacheKey: string;
  initialValue?: T;
  fetchFunction: () => Promise<T>;
};

export const useFetch = <T>({
  cacheKey,
  initialValue,
  fetchFunction,
}: UseFetchOptions<T>) => {
  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(true);
  const [refetching, setRefetching] = useState(false);

  const handleStorageChange = useCallback(
    (event: StorageEvent) => {
      if (event.key === cacheKey && event.newValue) {
        setData(JSON.parse(event.newValue));
      }
    },
    [cacheKey]
  );

  useEffect(() => {
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      setData(JSON.parse(cachedData));
      setRefetching(true);
    } else {
      setLoading(true);
    }
    const fetchData = async () => {
      try {
        const res = await fetchFunction();
        if (!res) return;
        const response = JSON.stringify(res);
        if (response !== cachedData) {
          setData(res);
          LocalStorage.setUserData(cacheKey, response);
        }
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
      setRefetching(false);
    };
    fetchData();
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [fetchFunction, cacheKey, handleStorageChange]);

  const refetchData = useCallback(async () => {
    setLoading(true);
    const response = await fetchFunction();
    setData(response);
    setLoading(false);
  }, [fetchFunction]);

  const refreshData = useCallback(
    (newData: T) => {
      const response = JSON.stringify(newData);
      LocalStorage.setUserData(cacheKey, response);
      setData(newData);
    },
    [cacheKey]
  );

  return { data, loading, refetchData, refreshData, refetching };
};
