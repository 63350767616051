import { ResponseStatus } from "../../../types/ResponseStatus";
import { Documents } from "../utils/documents";
import { notifications } from "../../../utils/notifications";
import { DocumentAccessType } from "../../../types/documents";
import { useState } from "react";

interface Props {
  key: string;
  name: string;
  format: string;
}
export const useOpenDocument = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const createDocumentViewUrl = async () => {
    setLoading(true);
    try {
      const response = await Documents.getUrl({
        ...props,
        accessType: DocumentAccessType.view,
      });
      if (!response?.url) throw new Error("No url returned from server");
      window.open(response.url, "_blank", "noreferrer");
    } catch {
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage:
          "Oops, something went wrong trying to retrieve your document",
      });
    } finally {
      setLoading(false);
    }
  };

  return { createDocumentViewUrl, loading };
};
