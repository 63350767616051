import { CacheKeys } from "../types";

const updateLocalStorage = (cacheKey: string, data: any) => {
  localStorage.setItem(cacheKey, JSON.stringify(data));
  const event = new StorageEvent("storage", {
    key: cacheKey,
    newValue: JSON.stringify(data),
    storageArea: localStorage,
  });

  window.dispatchEvent(event);
};

const deleteUserData = () => {
  const { cacheKeys, signedAfterStorageUpdate } = CacheKeys;
  const keys = localStorage.getItem(cacheKeys);
  const hasSignedBeforeLocalStorageUpdate = !localStorage.getItem(
    signedAfterStorageUpdate
  );
  if (!keys || hasSignedBeforeLocalStorageUpdate) {
    cleanLocalStorage(["theme"]);
    return;
  }
  const cacheKeysArray: string[] = JSON.parse(keys);
  [...cacheKeysArray, cacheKeys, signedAfterStorageUpdate].forEach((key) => {
    localStorage.removeItem(key);
  });
};

const cleanLocalStorage = (keysToKeep: string[]) => {
  for (const key in localStorage) {
    if (!keysToKeep.includes(key)) {
      localStorage.removeItem(key);
    }
  }
};

const updateCacheKeys = (newKey: string) => {
  const keys = localStorage.getItem(CacheKeys.cacheKeys);
  if (!keys) {
    localStorage.setItem(CacheKeys.cacheKeys, JSON.stringify([newKey]));
    return;
  }
  const cacheKeys: string[] = JSON.parse(keys);
  if (cacheKeys.includes(newKey)) return;
  cacheKeys.push(newKey);
  localStorage.setItem(CacheKeys.cacheKeys, JSON.stringify(cacheKeys));
};

const setUserData = (cacheKey: string, data: string) => {
  localStorage.setItem(cacheKey, data);
  updateCacheKeys(cacheKey);
};

export const LocalStorage = {
  updateLocalStorage,
  deleteUserData,
  updateCacheKeys,
  setUserData,
};
