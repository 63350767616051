import {
  Container,
  YoutubeVideo as YoutubeVideoStyle,
} from "../../../styles/adds";

interface Props {
  MaxResolutionPhoto: string;
  SdDefaultPhoto: string;
  HqDefaultPhoto: string;
  MqDefaultPhoto: string;
  title: string;
  videoUrl: string;
}

const YoutubeVideo = ({
  MaxResolutionPhoto,
  SdDefaultPhoto,
  HqDefaultPhoto,
  MqDefaultPhoto,
  title,
  videoUrl,
}: Props) => {
  const text = `<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style>
  <a href=${videoUrl}>
    <picture>
      <source media="(max-width:320px)" srcset=${MqDefaultPhoto} style="object-fit:contain">
      <source media="(max-width:480px)" srcset=${HqDefaultPhoto} style="object-fit:contain">
      <source media="(max-width:640px)" srcset=${SdDefaultPhoto}  style="object-fit:contain">
      <img src=${MaxResolutionPhoto} alt="Flowers" style="object-fit:contain">
    </picture>
    <span>▶
    </span>
  </a>`;
  return (
    <Container className="container">
      <div className="row pb-5">
        <YoutubeVideoStyle
          srcDoc={text}
          className="youtube-video"
          title={title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
    </Container>
  );
};

export default YoutubeVideo;
