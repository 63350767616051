import { FunctionComponent, FocusEvent } from "react";
import { isValidOnBlurReactSelect } from "../../../utils";
import { components, ActionMeta, DropdownIndicatorProps } from "react-select";
import ReactSelect, { SelectProps } from "../formInputsTypes/ReactSelect";

interface Props extends SelectProps {
  handleFields: (args: Record<string, any>) => void;
  message?: string;
  error?: string;
  validation?: (value: string) => { isValid: boolean; message?: string };
  handleTouch: (args: Record<string, boolean>) => void;
  handleErrors: (args: Record<string, string>) => void;
  handleFocus?: (
    event: FocusEvent<HTMLDivElement>,
    inputValue?: string | null
  ) => void;
  handleBlur?: (
    event: FocusEvent<HTMLDivElement>,
    inputValue?: string | null
  ) => void;
  change?: (value: unknown, action: ActionMeta<unknown>) => void;
  label?: string;
  name: string;
  placeHolder?: string;
  handleValue: (value: any) => any;
}

export const FormReactSelect: FunctionComponent<Props> = ({
  handleFields,
  message,
  handleTouch,
  validation,
  handleErrors,
  handleFocus: onFocus,
  handleBlur: onBlur,
  change,
  required,
  name,
  components,
  handleValue,
  ...rest
}) => {
  const handleChange = (value: any, action: ActionMeta<unknown>) => {
    handleFields({ [name]: handleValue(value) });
    handleValidation(value);
    if (change) change(value, action);
  };

  const handleFocus = (
    event: FocusEvent<HTMLDivElement>,
    inputValue?: string | null
  ) => {
    handleTouch({ [name]: true });
    if (onFocus) onFocus(event, inputValue);
  };

  const handleValidation = (newValue?: { name: string }) => {
    const value = rest.value as [{ name: string }];
    let sendValue;
    if (!!value.length) {
      sendValue = value[0].name;
    } else if (!!newValue) {
      sendValue = newValue.name;
    }
    const newError = isValidOnBlurReactSelect(name, sendValue, {
      required,
      validation: validation,
      message,
      label: rest.label,
    });
    handleErrors(newError);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    handleValidation();
    if (onBlur) onBlur(e);
  };

  return (
    <ReactSelect
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      name={name}
      className="react-select-container"
      classNamePrefix="react-select"
      components={{
        DropdownIndicator,
        ...components,
      }}
      {...rest}
    />
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="dropdown-indicator"></span>
    </components.DropdownIndicator>
  );
};
