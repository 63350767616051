import { useEffect, useMemo, useState } from "react";
import {
  AddMoneyInitialData,
  ModalInitialData,
  Steps,
} from "../types/payment-modal";
import { CreatePaymentInput, TransactionGroup } from "../types/payments";
import { Payments } from "../utils";

interface Props {
  initialData: ModalInitialData | AddMoneyInitialData;
  startFlow: boolean;
  onClose: () => void;
  flowDirection: Steps[];
}

export const usePaymentModal = ({
  initialData,
  startFlow,
  onClose,
  flowDirection,
}: Props) => {
  const [showAmount, setShowAmount] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [attributes, setAttributes] = useState<
    ModalInitialData | AddMoneyInitialData
  >(initialData);
  const [paymentId, setPaymentId] = useState<string>();
  const [signatureUrl, setSignatureUrl] = useState<string>();

  const modifyStates = useMemo(() => {
    return {
      [Steps.Amount]: (state: boolean) => setShowAmount(state),
      [Steps.Confirm]: (state: boolean) => setShowConfirm(state),
      [Steps.Success]: (state: boolean) => setShowSuccess(state),
    };
  }, []);

  const onSubmitAmount = async (
    fields: ModalInitialData | AddMoneyInitialData,
    transactionGroup?: TransactionGroup
  ) => {
    const response = await handleSubmitVerification(fields, transactionGroup);
    const { hasSigned, paymentId, contractURL } = response;
    if (!hasSigned) {
      setSignatureUrl(contractURL);
    }
    setPaymentId(paymentId);
    setAttributes(fields);
  };

  const updateAttributes = (
    attributes: ModalInitialData | AddMoneyInitialData
  ) => {
    setAttributes(attributes);
  };

  const updatePaymentId = (paymentId: string) => {
    setPaymentId(paymentId);
  };

  const updateSignatureUrl = (url: string) => {
    setSignatureUrl(url);
  };

  useEffect(() => {
    setShowAmount(startFlow);
  }, [startFlow]);

  const handleClose = (step: Steps) => {
    modifyStates[step](false);
    setAttributes(initialData);
    onClose();
  };

  const nextStep = (step: Steps) => {
    modifyStates[step](false);
    const index = flowDirection.indexOf(step) + 1;
    modifyStates[flowDirection[index]](true);
  };

  const handleSubmit = async (paymentId: string) => {
    const response = await Payments.create(paymentId);
    Payments.updateResponseOnStorage(response);
    console.log(response);
  };

  const handleSubmitVerification = async (
    fields: ModalInitialData | AddMoneyInitialData,
    transactionGroup?: TransactionGroup
  ) => {
    const input = new CreatePaymentInput({ ...fields, transactionGroup });
    const response = await Payments.verifyPaymentCreation(input);
    console.log(response);
    return response;
  };

  return {
    showAmount,
    showConfirm,
    showSuccess,
    attributes,
    paymentId,
    signatureUrl,
    updateAttributes,
    handleClose,
    nextStep,
    handleSubmit,
    handleSubmitVerification,
    updatePaymentId,
    updateSignatureUrl,
    onSubmitAmount,
  };
};
