export interface UserErrors {
  firstName: string;
  phoneNumber: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  form: string;
}

export interface UserTouch {
  firstName: boolean;
  phoneNumber: boolean;
  email: boolean;
  addressLine1: boolean;
  addressLine2: boolean;
  city: boolean;
  state: boolean;
  zipCode: boolean;
}

export enum ACCOUNT_CUSTOM_ERRORS {
  DefaultError = "",
}

export enum CHANGE_PASSWORD_CUSTOM_ERRORS {
  DefaultError = "",
}
