import InvestRibbionSignup from "../components/InvestRibbonSignUp";

const InvestComfortSection = () => {
  return (
    <InvestRibbionSignup
      company="NORHART INVEST"
      title="Invest With Confidence"
      subtitle="You will find comfort and stability with Norhart Apartments, Norhart Construction, and Norhart Invest. We are a real company, you know, in Minneapolis."
      buttonName="Invest Now"
      buttonUrl="/signup"
    />
  );
};

export default InvestComfortSection;
