import styled from "styled-components";
import { Loading } from "../../components/Loading";
import { NorhartWrapper } from "../../components/NorhartWrapper";
import { useUserAccount } from "../../hooks/useUserAccount";
import BankInformation from "./components/BankInformation";
import ChangePassword from "./components/ChangePassword";
import PersonalForm from "./components/PersonalForm";
import Financials from "./components/Financials";
import Beneficiaries from "./components/Beneficiaries";
// import { BeneficiariesData } from "../../types/beneficiaries";

// const MOCK_BENEFICIARIES_DATA: BeneficiariesData[] = [
//   {
//     givenName: "Joe",
//     familyName: "Smith",
//     email: "joe51@norhart.com",
//     dateOfBirth: "12/10/27",
//     allocation: 40,
//   },
//   {
//     givenName: "Peter",
//     familyName: "Smith",
//     email: "peter99@norhart.com",
//     dateOfBirth: "15/10/23",
//     allocation: 60,
//   },
// ];

const Account = () => {
  const {
    open,
    ready,
    userDataLoading,
    bankAccountsLoading,
    userInitialData,
    bankAccounts,
    waitingBankAccounts,
    handleDelete,
    updateUserData,
    financialInitialData,
    financialLoading,
    updateNetAssets,
    userPreferences,
    loadingUserPreferences,
    createBeneficiary,
    deleteBeneficiary,
    beneficiaries,
    loadingBeneficiaries,
  } = useUserAccount();

  if (
    userDataLoading ||
    bankAccountsLoading ||
    financialLoading ||
    loadingUserPreferences ||
    loadingBeneficiaries
  )
    return <Loading />;

  return (
    <NorhartWrapper title="Account">
      <>
        {userInitialData && bankAccounts && financialInitialData && (
          <PageWrapper>
            <PersonalForm
              userInitialData={userInitialData}
              updateUserData={updateUserData}
              isActive={!!userPreferences?.accountPersonalInformationCollapsed}
            />
            <ChangePassword
              isActive={!!userPreferences?.accountSecurityCollapsed}
            />
            <Financials
              initialData={financialInitialData}
              updateNetAssets={updateNetAssets}
              isActive={!!userPreferences?.accountFinancialsCollapsed}
            />
            <Beneficiaries
              deleteBeneficiary={deleteBeneficiary}
              createBeneficiary={createBeneficiary}
              beneficiariesData={beneficiaries}
              isActive={!!userPreferences?.accountBeneficiariesCollapsed}
            />
            <BankInformation
              waitingBankAccounts={waitingBankAccounts}
              bankAccounts={bankAccounts}
              open={open}
              ready={ready}
              handleDelete={handleDelete}
              isActive={!!userPreferences?.accountBankAccountsCollapsed}
            />
          </PageWrapper>
        )}
      </>
    </NorhartWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default Account;
