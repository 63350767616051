import MikeKaeding from "../../../assets/images/landing/leadership/mike-kaeding-leadership.png";
import StacyEkholm from "../../../assets/images/landing/leadership/stacy-ekholm-leadership.png";
import TimLibertini from "../../../assets/images/landing/leadership/tim-libertini-leadership.png";
import MarieDickover from "../../../assets/images/landing/leadership/marie-dickover-leadership.png";
import JustinBartak from "../../../assets/images/landing/leadership/justin-bartak-leadership.png";
import CarouselComponent from "../components/CarouselComponent";

const StaffSection = () => {
  return (
    <CarouselComponent
      items={[
        {
          name: "Mike Kaeding",
          title: "CHIEF EXECUTIVE OFFICER",
          image: MikeKaeding,
          buttonName: "Connect Today",
          buttonUrl: "https://www.linkedin.com/in/mikekaeding/",
        },
        {
          name: "Stacy Ekholm",
          title: "CHIEF OPERATING OFFICER",
          image: StacyEkholm,
          buttonName: "Connect Today",
          buttonUrl:
            "https://www.linkedin.com/in/stacy-ekholm-cpm-cos-crm-cmca-a587647b/",
        },
        {
          name: "Tim Libertini",
          title: "CHIEF FINANCIAL OFFICER",
          image: TimLibertini,
          buttonName: "Connect Today",
          buttonUrl: "https://www.linkedin.com/in/timothy-libertini-81701744/",
        },
        {
          name: "Marie Dickover",
          title: "CHIEF DEVELOPMENT OFFICER",
          image: MarieDickover,
          buttonName: "Connect Today",
          buttonUrl: "https://www.linkedin.com/in/marie-dickover-178095225/",
        },
        {
          name: "Justin Bartak",
          title: "CHIEF DESIGN OFFICER",
          image: JustinBartak,
          buttonName: "Connect Today",
          buttonUrl: "https://www.linkedin.com/in/justinbartak/",
        },
      ]}
    />
  );
};

export default StaffSection;
