export enum CacheKeys {
  userData = "userData",
  bankAccounts = "bankAccounts",
  dashboard = "dashboard",
  flexAccount = "flexAccount",
  promissoryNotes = "promissoryNotes",
  transactions = "transactions",
  afterCursor = "afterCursor",
  allTransactions = "allTransactions",
  balance = "balance",
  flexAccountInterest = "flexAccountInterest",
  netAssets = "NetAssets",
  documents = "documents",
  cacheKeys = "cacheKeys",
  signedAfterStorageUpdate = "signedAfterStorageUpdate",
  dashboardInfo = "dashboardInfo",
  pagination = "pagination",
  isLogged = "isLogged",
  theme = "theme",
  userPreferences = "userPreferences",
  currentLinkToken = "linkToken",
  beneficiaries = "beneficiaries",
}
