import "./norhart.css";
import { DarkWrapperAuth } from "../../components/DarkWrapperAuth";
import { ContentHeroSubtitle, ContentHeroTitle } from "../../styles/adds";

export const Maintenance = () => {
  return (
    <DarkWrapperAuth>
      <div style={{ paddingBottom: "40px" }}>
        <ContentHeroTitle style={{ color: "white" }} className="text-center">
          We'll Be Back
        </ContentHeroTitle>
        <ContentHeroSubtitle style={{ color: "white", margin: "0px" }}>
          We're busy updating the Norhart Online Apply system for you and we
          will be back very soon.
        </ContentHeroSubtitle>
      </div>
    </DarkWrapperAuth>
  );
};
