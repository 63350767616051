import {
  ContentHeroSpace,
  ContentHeroTitle,
  TopHr,
  Container,
} from "../../../styles/adds";

interface Props {
  fontColor?: string;
}

const InvestLegalSection = ({ fontColor = "#365c70" }: Props) => {
  return (
    <section
      className="container-fluid pb-5 pt-0"
      style={{
        background: "linear-gradient(#EFF2F4, #EFF2F4)",
        color: `${fontColor}`,
      }}
    >
      <Container className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <ContentHeroSpace className="hidden-xs"></ContentHeroSpace>
        <ContentHeroTitle
          className="contentHeroTitle"
          style={{
            color: `${fontColor}`,
          }}
        >
          *NORHART INVEST DISCLAIMERS
        </ContentHeroTitle>
        <TopHr
          color="#365c70"
          className="hidden-sm hidden-md hidden-lg hidden-xl style-line mt-5 mb-5"
        />

        <div className="m-3" style={{ fontWeight: "bold", fontSize: "12px" }}>
          The information on this website was created by Norhart Invest to
          assist with marketing our Regulation A promissory note offering. The
          text on this website is a summary but does not contain all of the
          terms of our securities offering. In order to review all of the terms
          of our securities offering, you should review our offering circular
          that contains all of the terms, conditions, risk factors, and
          disclosures that you should read and understand before you invest in
          our company. The offering circular is available to download{" "}
          <a
            href="https://www.sec.gov/Archives/edgar/data/1964262/000121390023089682/ea188940-1apos_norhart.htm"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{" "}
          for you to read and review before you invest. You can also view it on
          the SEC’s website{" "}
          <a
            href="https://www.sec.gov/Archives/edgar/data/1964262/000121390023089682/ea188940-1apos_norhart.htm"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
          <p />
          The offering circular explains that we are offering $75,000,000 of
          Promissory Notes with a minimum purchase of $2,500.00. The United
          States Securities and Exchange Commission (the “SEC”) does not pass
          upon the merits of, or give its approval to, any of the securities we
          are offering or the terms of our offering, nor does it pass upon the
          accuracy or completeness of our offering circular, other selling
          literature, or this website. The securities we are offering are
          offered pursuant to an exemption from registration with the SEC;
          however, the SEC has not made an independent determination that the
          securities offered in our offering circular and in our offering are
          exempt from registration. 
          <p />
          When you review our offering circular, please review all of the risk
          factors before making an investment in our company. An investment in
          our company should only be made if you are capable of evaluating the
          risks and merits of this investment and if you have sufficient
          resources to bear the entire loss of your investment, should that
          occur. Generally, no sale may be made to anyone in our offering if the
          aggregate purchase price you pay is more than 10% of the greater of
          your annual income or net worth. Different rules apply to accredited
          investors and non-natural persons. Before making any representation
          that your investment does not exceed applicable thresholds, we
          encourage you to review Rule 251(d)(2)(i)(c) of Regulation A. For
          general information on investing, we encourage you to refer to{" "}
          <a href="https://www.investor.gov" target="_blank" rel="noreferrer">
            https://www.investor.gov
          </a>
          .
          <p />
          Our offering circular does not constitute an offer or solicitation in
          any jurisdiction in which such an offer or solicitation would be
          unlawful. No person has been authorized to give any information or to
          make any representations concerning our company other than those
          contained in our offering circular, and if given or made, such other
          information or representation must not be relied upon. Prospective
          investors are not to construe the contents of our offering circular,
          or of any prior or subsequent communications from our company or any
          of its employees, agents or affiliates, or on this website as
          investment, legal, financial or tax advice. Before investing in our
          offering, please review our offering circular carefully, ask any
          questions of the company’s management that you would like answered and
          consult your own counsel, accountant and other professional advisors
          as to legal, tax and other related matters concerning this investment.
          <p />
          <a
            href="https://www.sec.gov/Archives/edgar/data/1964262/000121390023089682/ea188940-1apos_norhart.htm"
            target="_blank"
            rel="noreferrer"
          >
            View the offering circular
          </a>
        </div>
      </Container>
    </section>
  );
};

export default InvestLegalSection;
