import { useState, useEffect, useMemo } from "react";
import { CacheKeys } from "../types";
import { useBalance } from "./useBalance";
import { useFlexAccount } from "./useFlexAccount";
import { usePromissoryNotes } from "./usePromissoryNotes";
import { useTransactions } from "./useTransactions";
import { useUpdateGtmUser } from "./useUpdateGtmUser";
import { useHasBankAccounts } from "./useHasBankAccounts";
import { useUserData } from "./useUserData";
import { useProductWs } from "./useProductsWs";
import { usePlaidBankAccount } from "./usePlaidBankAccount";
import { useGetAttention } from "./useGetAttention";
import { useSocialMedia } from "./useSocialMedia";
import { useDashboardInfo } from "./useDashboardInfo";

export const useDashboard = () => {
  useSocialMedia();
  useUpdateGtmUser();
  const { data: flexAccount, loading: loadingFlexAccount } = useFlexAccount();
  const { data: promissoryNotes, loading: loadingPromissoryNotes } =
    usePromissoryNotes();
  const { data: transactions, loading: loadingTransactions } = useTransactions(
    CacheKeys.dashboard,
    5
  );
  const { data: dashboardInfo, loading: loadingDashboardInfo } =
    useDashboardInfo();
  const [withdrawAlert, setWithdrawAlert] = useState<boolean>(false);

  const [addMoneyNeedDefaultValue, setAddMoneyNeedDefaultValue] =
    useState<boolean>(false);
  const { data: balance, loading: loadingBalance } = useBalance();
  const [showStartBanner, setShowStartBanner] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showAddMoney, setShowAddMoney] = useState(false);
  const [showDeposit, setShowDeposit] = useState(false);
  const { defaultProduct, defaultProductLoading: loadingProducts } =
    useProductWs();

  const [isAddMoney, setIsAddMoney] = useState(true);

  const [showBankPopUp, setShowBankPopUp] = useState(false);

  const { hasBankAccounts, loading: loadingHasBankAccount } =
    useHasBankAccounts();
  const { data: userData, loading: userDataLoading } = useUserData();

  useEffect(() => {
    if (!loadingHasBankAccount) setShowBankPopUp(!hasBankAccounts);
  }, [hasBankAccounts, loadingHasBankAccount]);

  const returnPromissoryNotes = useMemo(() => {
    if (!!promissoryNotes && !promissoryNotes?.length) {
      setShowStartBanner(true);
    }
    const completedPromissoryNotes = promissoryNotes?.filter(
      (promissoryNote) => promissoryNote.completed === false
    );
    return completedPromissoryNotes?.slice(0, 3);
  }, [promissoryNotes]);

  const handleBannerClose = () => {
    setShowStartBanner(false);
  };
  const handleBannerOpen = () => {
    setShowStartBanner(true);
  };

  const handleWithdrawClose = () => {
    setShowWithdraw(false);
    setShowAddMoney(false);
  };
  const handleAddMoneyClose = () => {
    setShowAddMoney(false);
  };

  const openWithdrawFlow = () => {
    setShowAddMoney(true);
    setIsAddMoney(false);
  };

  const handleAddMoneyClick = () => {
    setShowAddMoney(true);
    setIsAddMoney(true);
    setAddMoneyNeedDefaultValue(false);
  };
  const handleBankClose = () => {
    setShowBankPopUp(false);
  };
  const handleStartNow = () => {
    setAddMoneyNeedDefaultValue(true);
    setShowAddMoney(true);
    setIsAddMoney(true);
    handleBankClose();
  };

  const handleWithdrawClick = () => {
    setShowWithdraw(true);
  };

  const { open, ready, waitingBankAccounts } =
    usePlaidBankAccount(handleStartNow);
  const openPlaidPopUp = () => {
    if (ready) open();
  };

  const handleWithdrawAlert = () => {
    setWithdrawAlert((prev) => !prev);
  };

  const handleShowDeposit = () => {
    setShowDeposit((prev) => !prev);
  };

  const { attentionData, loading: loadingAttentionData } = useGetAttention();

  return {
    flexAccount,
    loadingFlexAccount,
    promissoryNotes: returnPromissoryNotes,
    loadingPromissoryNotes,
    transactions,
    loadingTransactions,
    showBankPopUp,
    handleBankClose,
    balance,
    loadingBalance,
    handleBannerClose,
    handleBannerOpen,
    showStartBanner,
    loadingHasBankAccount,
    userData,
    userDataLoading,
    handleWithdrawClose,
    handleAddMoneyClose,
    openWithdrawFlow,
    handleAddMoneyClick,
    showWithdraw,
    showAddMoney,
    isAddMoney,
    handleWithdrawClick,
    handleStartNow,
    addMoneyNeedDefaultValue,
    loadingProducts,
    defaultProduct,
    ready,
    waitingBankAccounts,
    openPlaidPopUp,
    handleWithdrawAlert,
    withdrawAlert,
    attentionData,
    loadingAttentionData,
    dashboardInfo,
    loadingDashboardInfo,
    showDeposit,
    handleShowDeposit,
  };
};
