import {
  BankAccountVerificationStatus,
  CREATE_TOKEN_DATA,
  CustomError,
  CustomPlaidBankAccount,
  GENERATE_TOKEN_OUTPUT,
  LinkDestination,
  Products,
  VerifyLinkToken,
} from "../types";
import { BankAccountResponse } from "../types/bank-accounts";
import { LocalStorage } from "./localStorage";

const fetchLink = async ({
  products,
  destination,
}: {
  products: Products[];
  destination?: LinkDestination;
}) => {
  const path = "/plaid-links";
  return fetch(path, {
    method: "POST",
    body: JSON.stringify({
      products,
      destination,
    }),
  });
};

const generateToken = async ({
  products,
  destination,
}: {
  products: Products[];
  destination?: LinkDestination;
}): Promise<GENERATE_TOKEN_OUTPUT> => {
  const response = await fetchLink({ products, destination });
  if (!response.ok) {
    return { linkToken: null, expiration: null };
  }
  const data: CREATE_TOKEN_DATA = await response.json();
  if (!data) {
    return { linkToken: null, expiration: null };
  }
  if (data.error != null) {
    return {
      error: { code: "LINK_TOKEN_ERROR", message: data.error },
      linkToken: null,
      expiration: null,
    };
  }
  const { expiration, link_token } = data;
  // TODO: Check if we need a context to store it
  LocalStorage.setUserData("link_token", link_token);
  return { linkToken: link_token, expiration };
};

const generateVerifyToken = async () => {
  const response = await fetchLink({
    products: [Products.PendingLinkableAuth],
  });
  const res = await response.json();
  if (!response.ok) {
    CustomError.throw(res, res?.error);
  }
  return res as VerifyLinkToken;
};

const createMTAccount = async (
  publicToken: string,
  accounts: CustomPlaidBankAccount[],
  institutionName: string = ""
) => {
  const path = "/bank-accounts";
  const response = await fetch(path, {
    method: "POST",
    body: JSON.stringify({
      publicToken,
      accounts: accounts,
      institutionName,
    }),
  });
  const res = await response.json();
  if (!response.ok) {
    CustomError.throw(res, res?.error || res?.message);
  }
  return res as BankAccountResponse;
};

const bankAccountIsVerified = (status: BankAccountVerificationStatus) => {
  const verifiedStatuses = [
    BankAccountVerificationStatus.AutomaticallyVerified,
    BankAccountVerificationStatus.Verified,
    BankAccountVerificationStatus.ManuallyVerified,
    BankAccountVerificationStatus.Added,
  ];
  return verifiedStatuses.includes(status);
};

const bankAccountIsPending = (status: BankAccountVerificationStatus) => {
  const pendingStatuses = [
    BankAccountVerificationStatus.PendingAutomaticVerification,
    BankAccountVerificationStatus.PendingManualVerification,
    BankAccountVerificationStatus.PendingLoginRequired,
  ];
  return pendingStatuses.includes(status);
};

export const Plaid = {
  generateToken,
  createMTAccount,
  generateVerifyToken,
  bankAccountIsVerified,
  bankAccountIsPending,
};
