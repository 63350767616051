import { Auth as AuthAmplify } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";
import { Dropdown } from "react-bootstrap";
import { ReactComponent as NorhartLogo } from "../../assets/images/norhart-logo-navbar-dark.svg";
import { FaEllipsisH } from "react-icons/fa";
import { Auth } from "../../utils";
import { SmallDot } from "../../styles/list";
import { AppContext } from "../../hooks/AppContext";
import { useUserData } from "../../hooks/useUserData";

export const Navbar = () => {
  const { switchColorPalette } = useContext(AppContext);
  const theme = useTheme();
  const [email, setEmail] = useState(null);
  const { pathname } = useLocation();
  const { data } = useUserData();

  useEffect(() => {
    const getSession = async () => {
      try {
        const currentSession = await AuthAmplify.currentSession();

        if (currentSession) {
          const email = currentSession.getIdToken().payload.email;
          setEmail(email);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getSession();
  }, []);

  const navigate = useNavigate();

  const handleSignOut = async () => {
    await Auth.signOut();
    navigate("/signin");
  };

  return (
    <NavbarContainer className="navbar">
      <NavbarDivContainer className="container-lg">
        <Link
          target="_blank"
          className=" d-flex justify-content-center"
          to="https://www.norhart.com/invest"
        >
          <StyledLogo width={100} />
        </Link>
        <RightWrapper>
          <ToggleIcon src={theme.ICON} onMouseDown={switchColorPalette} />
          <Dropdown>
            <StyledToggle
              style={{
                background: "transparent",
                border: "none",
                boxShadow: "none",
              }}
              bsPrefix="text-decoration-none"
            >
              <FaEllipsisH />
            </StyledToggle>
            <StyledMenu>
              <Dropdown.Header>
                <StyledDiv isVisible={!!data?.name}>
                  {data?.name?.given_name + " " + data?.name?.family_name}
                </StyledDiv>
                <Email>{email}</Email>
              </Dropdown.Header>
              <StyledDivider />
              <CustomDropdownItem
                disabled={pathname === "/dashboard"}
                onClick={() =>
                  navigate("/dashboard", { state: { from: "/algo212" } })
                }
              >
                <ItemWrapper>
                  <SmallDot
                    displaydot={pathname === "/dashboard" ? "true" : ""}
                    color={theme.GREEN}
                  />
                  Dashboard
                </ItemWrapper>
              </CustomDropdownItem>
              <CustomDropdownItem
                onClick={() => navigate("/user-account")}
                disabled={pathname === "/user-account"}
              >
                <ItemWrapper>
                  <SmallDot
                    displaydot={pathname === "/user-account" ? "true" : ""}
                    color={theme.GREEN}
                  />
                  Account
                </ItemWrapper>
              </CustomDropdownItem>
              <CustomDropdownItem
                onClick={() => navigate("/faq")}
                disabled={pathname === "/faq"}
              >
                <ItemWrapper>
                  <SmallDot
                    displaydot={pathname === "/faq" ? "true" : ""}
                    color={theme.GREEN}
                  />
                  FAQ
                </ItemWrapper>
              </CustomDropdownItem>
              {/* <CustomDropdownItem disabled>Messages</CustomDropdownItem>
            <CustomDropdownItem disabled>Feedback</CustomDropdownItem> */}
              <CustomDropdownItem
                onClick={() => navigate("/documents")}
                disabled={pathname === "/documents"}
              >
                <ItemWrapper>
                  <SmallDot
                    displaydot={pathname === "/documents" ? "true" : ""}
                    color={theme.GREEN}
                  />
                  Documents
                </ItemWrapper>
              </CustomDropdownItem>
              <CustomDropdownItem
                disabled={!data?.name}
                target="_blank"
                href={`mailto:support@norhart.com?subject=Norhart%20Invest%20Support%20Question%20-%20${data?.name?.given_name}%20${data?.name?.family_name}`}
              >
                <ItemWrapper>
                  <SmallDot />
                  Support
                </ItemWrapper>
              </CustomDropdownItem>
              <CustomDropdownItem
                disabled={!data?.name}
                target="_blank"
                href="http://stats.pingdom.com/i6owumclg489/12380377"
              >
                <ItemWrapper>
                  <SmallDot />
                  System Status
                </ItemWrapper>
              </CustomDropdownItem>
              <CustomDropdownItem
                disabled={!data?.name}
                target="_blank"
                href="https://securityheaders.com/?q=https%3A%2F%2Finvest.norhart.com&followRedirects=on"
              >
                <ItemWrapper>
                  <SmallDot />
                  Security
                </ItemWrapper>
              </CustomDropdownItem>
              <CustomDropdownItem
                onClick={() => navigate("/release-notes")}
                disabled={pathname === "/release-notes"}
              >
                <ItemWrapper>
                  <SmallDot
                    displaydot={pathname === "/release-notes" ? "true" : ""}
                    color={theme.GREEN}
                  />
                  Release Notes
                </ItemWrapper>
              </CustomDropdownItem>
              <CustomDropdownItem
                onClick={() => {
                  handleSignOut();
                }}
              >
                <ItemWrapper>
                  <SmallDot />
                  <div>Logout</div>
                </ItemWrapper>
              </CustomDropdownItem>
            </StyledMenu>
          </Dropdown>
        </RightWrapper>
      </NavbarDivContainer>
    </NavbarContainer>
  );
};

const Email = styled.div`
  color: ${({ theme }) => theme.PRIMARY_COLOR};
`;

const StyledMenu = styled(Dropdown.Menu)`
  background-color: ${({ theme }) => theme.NAVBAR_BACKGROUND};
  color: ${({ theme }) => theme.PRIMARY_COLOR};
  border-color: transparent;
`;

const StyledDivider = styled(Dropdown.Divider)`
  border-color: ${({ theme }) => theme.DROPDOWN_BORDER};
  margin-left: 15px;
  margin-right: 15px;
`;

const RightWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  @media (max-width: 350px) {
    gap: 6px;
  }
`;

const ToggleIcon = styled.img`
  transform: scale(0.5);
  cursor: pointer;
  width: 36px;
  transition: all 0.5s ease-out;
  :active {
    transform: rotate(180deg) scale(0.3);
    opacity: 0.5;
  }
  @media (max-width: 350px) {
    transform: scale(0.4);
  }
`;

const StyledLogo = styled(NorhartLogo)`
  path {
    stroke: ${({ theme }) => theme.PRIMARY_COLOR} !important;
  }
  @media (max-width: 400px) {
    transform: scale(0.85);
  }
`;

const StyledToggle = styled(Dropdown.Toggle)`
  path {
    color: ${({ theme }) => theme.PRIMARY_COLOR} !important;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  color: ${({ theme }) => theme.PRIMARY_COLOR};
`;

const CustomDropdownItem = styled(Dropdown.Item)`
  ${({ theme }) => css`
    --bs-dropdown-link-active-bg: transparent;
    --bs-dropdown-link-hover-bg: ${theme.BANNER_BACKGROUND} !important;
  `}
`;

const NavbarContainer = styled.nav`
  height: 50px;
  margin-top: -1px;
  @media (max-width: 350px) {
    height: 38px;
    padding: 0px;
  }
`;

const NavbarDivContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

interface Props {
  isVisible: boolean;
}

const StyledDiv = styled.div<Props>`
  font-weight: 900;
  color: ${({ theme }) => theme.PRIMARY_COLOR};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;
