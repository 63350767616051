import { FunctionComponent, ReactNode } from "react";
import {
  Container,
  ContentHeroSubtitle,
  ContentHeroTitle,
} from "../../../styles/adds";

interface Props {
  backgroundColorOne: string;
  backgroundColorTwo: string;
  title: string;
  subTitle: string;
  textColor: string;
  children: ReactNode;
}

const LandingVideoComponent: FunctionComponent<Props> = ({
  backgroundColorOne,
  backgroundColorTwo,
  title,
  subTitle,
  textColor,
  children,
}) => {
  return (
    <section
      className="container-fluid text-center pt-5 pb-0"
      style={{
        background: `linear-gradient(${backgroundColorOne} 30%, ${backgroundColorTwo} 70%)`,
      }}
    >
      <Container className="container pl-1 pr-1">
        <ContentHeroTitle
          className="contentHeroTitle"
          style={{ color: `${textColor}` }}
        >
          {title}
        </ContentHeroTitle>
        <ContentHeroSubtitle
          className="mt-0 mb-5"
          style={{ color: `${textColor}`, opacity: "0.6" }}
        >
          {subTitle}
        </ContentHeroSubtitle>
        {children}
      </Container>
    </section>
  );
};
export default LandingVideoComponent;
