import GradientComponent from "../components/GradientComponent";
import DarkScreen from "../../../assets/images/norhart-invest-platform-mobile.png";

const ConceptSignUp = () => {
  return (
    <GradientComponent
      backgroundColorOne="#f0f0f0"
      backgroundColorTwo="#f0f0f0"
      badgeTitle="Get Started Today"
      colSizeOne={5}
      colSizeTwo={7}
      fontTextColor="#111111"
      image={DarkScreen}
      imageTitle="Sign Up To Invest"
      title="Sign Up To Invest"
      subtitle=""
      titleTopLeft="Norhart Invest"
      badgeColor="black"
      hrColor="black"
      needSignup
      darkSignUpButton
      displayImgOnMobile
      // needPercentage
    />
  );
};

export default ConceptSignUp;
