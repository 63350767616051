import styled from "styled-components";
import {
  Container,
  ContentHeroLeftSubTitle,
  ContentHeroLeftTitle,
  ContentHeroSpace,
  TopHr,
} from "../../../styles/adds";
import Login from "../../Login";
import Signup from "../../../pages/signup/components/Signup";
import apyReturns from "../../../assets/images/8-point-5-percent-apy.png";
import ApplyForm from "./ApplyForm";

interface Props {
  backgroundColorOne: string;
  backgroundColorTwo: string;
  badgeTitle: string;
  bottomMargin?: number;
  buttonName?: string;
  buttonUrl?: string;
  buttonName2?: string;
  buttonUrl2?: string;
  colSizeOne: number;
  colSizeTwo: number;
  fontTextColor: string;
  image: string;
  imageTitle: string;
  subtitle: string;
  title: string;
  titleTopLeft: string;
  imageSubtitle?: string;
  imageTitleSubtitle?: string;
  asoEffect?: string;
  needSubtitle?: boolean;
  needLogin?: boolean;
  needSignup?: boolean;
  removeTitle?: boolean;
  colSize?: string;
  displayImgOnMobile?: boolean;
  hrColor?: string;
  badgeColor?: string;
  darkSignUpButton?: boolean;
  needPercentage?: boolean;
  needApplyForm?: boolean;
}

const GradientComponent: React.FC<Props> = ({
  colSize = "sm",
  hrColor = "#FFFF",
  badgeColor = "#274e79",
  needPercentage,
  ...props
}) => {
  return (
    <section
      className={`container-fluid text-center pt-5 pb-${props.bottomMargin}`}
      style={{
        background: `linear-gradient(${props.backgroundColorOne} 30%, ${props.backgroundColorTwo} 70%)`,
      }}
    >
      <Container className="container pl-3 pr-3">
        {!props.removeTitle && (
          <>
            <ContentHeroLeftTitle
              className="hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0 d-flex"
              style={{ fontSize: "15px", color: `${props.fontTextColor}` }}
            >
              {props.titleTopLeft}
            </ContentHeroLeftTitle>
            <TopHr
              color={hrColor}
              as="hr"
              className="hidden-sm hidden-md hidden-lg hidden-xl style-line"
              style={{
                borderColor: "rgba(0, 0, 0, 0.2)",
                marginTop: "10px",
                marginBottom: "10px",
                color: `${props.fontTextColor}`,
              }}
            />
            <ContentHeroSpace className="hidden-xs"></ContentHeroSpace>
          </>
        )}

        <BodyWrapper
          className={`row ${
            !props.displayImgOnMobile ? "flex-nowrap overflow-hidden" : ""
          } `}
          padding={!props.displayImgOnMobile ? "20px" : "0px"}
        >
          <WrapperDiv className={`col-${colSize}-${props.colSizeOne} pb-2`}>
            <ContentHeroLeftTitle
              className="text-start m-0 p-0"
              style={{ color: `${props.fontTextColor}` }}
            >
              {props.title}
              <br />
              <span
                className="badge badge-secondary ms-0 mt-2 mb-4"
                style={{
                  fontSize: "0.9rem",
                  verticalAlign: "middle",
                  backgroundColor: `${badgeColor}`,
                }}
              >
                {props.badgeTitle}
              </span>
              {needPercentage && (
                <>
                  <br />
                  <img
                    src={apyReturns}
                    alt="8.5 apy returns"
                    style={{ height: "50px", marginBottom: "25px" }}
                  />
                </>
              )}
            </ContentHeroLeftTitle>

            {props.needSubtitle && (
              <>
                <ContentHeroLeftSubTitle
                  className="m-0 p-0"
                  style={{
                    color: `${props.fontTextColor}`,
                    opacity: "0.7",
                  }}
                >
                  {props.subtitle} <br />
                </ContentHeroLeftSubTitle>

                {props.imageSubtitle && (
                  <img
                    className="pt-5 pb-0 ps-4 pe-4"
                    data-aos={props.asoEffect}
                    src={props.imageSubtitle}
                    loading="lazy"
                    decoding="async"
                    alt={props.imageTitleSubtitle}
                    width={550}
                    height={60}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                )}

                {props.buttonUrl && (
                  <div className="d-flex justify-content-left mt-5">
                    <a
                      className="btn btn-md"
                      href={props.buttonUrl}
                      style={{
                        backgroundColor: "#ffffff",
                        border: "0px",
                        color: "#333333",
                        fontWeight: "BOLD",
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {props.buttonName}
                    </a>

                    {props.buttonUrl2 && (
                      <a
                        className="btn btn-md btn-light animate__animated animate__animated animate__pulse animate__infinite animate__slow ms-3"
                        href={props.buttonUrl2}
                        target="_blank"
                        style={{
                          backgroundColor: "#0f2540",
                          border: "0px",
                          color: "#FFFFFF",
                          fontWeight: "BOLD",
                        }}
                        rel="noreferrer"
                      >
                        {props.buttonName2}
                      </a>
                    )}
                  </div>
                )}
              </>
            )}

            {props.needLogin && <Login />}
            {props.needSignup && <Signup landing={props.darkSignUpButton} />}
            {props.needApplyForm && (
              <ApplyForm darkMode={props.darkSignUpButton} />
            )}
          </WrapperDiv>

          <div
            className={`col-${colSize}-${props.colSizeTwo} mt-5 d-flex justify-content-end`}
          >
            <img
              className="pb-0 pl-0 pr-0 d-none d-sm-block"
              data-aos={props.asoEffect}
              src={props.image}
              loading="lazy"
              decoding="async"
              alt={props.imageTitle}
              width="900"
              height="400"
              style={{
                maxWidth: "100%",
                height: "auto",
                objectPosition: "bottom",
                objectFit: "contain",
              }}
            />
            <img
              className="pb-0 pl-5 pr-5 ps-5 pe-5 d-block d-sm-none"
              src={props.image}
              loading="lazy"
              decoding="async"
              alt={props.imageTitle}
              width="900"
              height="400"
              style={{
                maxWidth: "100%",
                height: "auto",
                objectPosition: "bottom",
                objectFit: "contain",
              }}
            />
          </div>
        </BodyWrapper>
      </Container>
    </section>
  );
};

interface BodyWrapperProps {
  padding?: string;
}

const BodyWrapper = styled.div<BodyWrapperProps>`
  @media (max-width: 575px) {
    padding: ${(props) => `${props.padding}`};
  }
`;

const WrapperDiv = styled.div`
  margin-top: "auto";
  margin-bottom: 20px;
  @media (max-width: 575px) {
    margin-bottom: 0px;
  }
`;

export default GradientComponent;
