import { Link } from "react-router-dom";
import {
  ContentHeroSpace,
  ContentHeroSubtitle,
  ContentHeroTitle,
  Container,
  AnimateSlideOutUp,
  Ibox,
  ProductBox,
  ProductDesc,
  ProductName,
  Li,
} from "../../../styles/adds";

const OFFERS = [
  {
    term: "12",
    interestRate: 8.4,
  },
  {
    term: "24",
    interestRate: 8.5,
  },
  {
    term: "60",
    interestRate: 10,
  },
];

const InvestOfferingsSection = () => {
  return (
    <section
      className="container-fluid pt-5 pb-5"
      style={{ background: "#EFF2F4" }}
    >
      <Container className="container pl-1 pr-1">
        <ContentHeroSpace className="hidden-xs"></ContentHeroSpace>
        <ContentHeroTitle style={{ color: "#333333" }}>
          Reg A Promissory Notes
        </ContentHeroTitle>
        <ContentHeroSubtitle
          className="mb-5"
          style={{ color: "#333333", opacity: "0.7" }}
        >
          Promissory notes are a form of debt Norhart uses to raise money. You
          loan us money, and in return, you receive a fixed high-rate amount of
          periodic income.{" "}
        </ContentHeroSubtitle>

        <div className="container">
          <div className="row">
            {OFFERS.map(({ term, interestRate }) => {
              return <Card term={term} interestRate={interestRate} />;
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

interface CardProps {
  term: string;
  interestRate: number;
}

const Card = ({ term, interestRate }: CardProps) => {
  return (
    <AnimateSlideOutUp className="col-md-4 animate__animated animate__slideOutUp pb-4">
      <Ibox className="ibox">
        <ProductBox className="ibox-content product-box bct-1">
          <ProductDesc className="product-desc">
            <ProductName className="product-name mt-1">
              {term} Month Term
            </ProductName>
            <hr />
            <div className="small m-t-xs mt-4">
              <strong>Offering:</strong>
              <br />
              <ul>
                <Li>{interestRate}% APY</Li>
                <Li>Accredited and Non-Accredited Investors</Li>
                <Li>$2,500 Min Investment</Li>
              </ul>
              <p />
              <p />
              <Link to="/signup">
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  style={{ background: "" }}
                >
                  Invest Now
                </button>
              </Link>
            </div>
            <div className="col-1 chartContent-center"></div>
          </ProductDesc>
        </ProductBox>
      </Ibox>
    </AnimateSlideOutUp>
  );
};

export default InvestOfferingsSection;
