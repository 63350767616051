import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "../hooks/useForm";
import { initialErrors, initialTouch } from "../pages/admin/constant";
import {
  CREATE_PRODUCT_CUSTOM_ERRORS,
  ProductForm,
} from "../pages/admin/types";
import { FormInput } from "./forms/formsComponents/FormInput";
import { FormTextArea } from "./forms/formsComponents/FormTextArea";
import { FormSelect } from "./forms/formsComponents/FormSelect";
import {
  CreateProductInput,
  InterestType,
  UpdateProductInput,
} from "../types/Product";
import { FormEvent, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Product } from "../utils/product";
import { validateFutureDate } from "../utils";
import { notifications } from "../utils/notifications";
import { ResponseStatus } from "../types/ResponseStatus";

interface Props {
  show: boolean;
  handleClose: () => void;
  initialData: ProductForm;
}

const ModifyProductModal = ({ show, handleClose, initialData }: Props) => {
  const {
    fields,
    errors,
    handleErrors,
    handleFields,
    handleTouch,
    submitLoading,
    setFields,
    resetForm,
    onSubmit,
  } = useForm({
    initialFields: initialData,
    initialErrors: initialErrors,
    initialTouch: initialTouch,
    customErrors: CREATE_PRODUCT_CUSTOM_ERRORS,
  });
  useEffect(() => {
    setFields(initialData);
  }, [initialData, setFields]);

  useEffect(() => {
    setHasPromo(!!initialData?.newPercentage);
  }, [initialData]);

  const [hasPromo, setHasPromo] = useState<boolean>(
    !!initialData?.newPercentage
  );

  const submitForm = async () => {
    const { method, input } = fields.id
      ? {
          input: new UpdateProductInput(fields),
          method: Product.updateProduct,
        }
      : {
          input: new CreateProductInput(fields),
          method: Product.createProduct,
        };
    try {
      await method(input);
      notifications({
        status: ResponseStatus.SUCCESS,
        successMessage: `The product was ${
          method === Product.updateProduct ? "updating" : "creating"
        } successfully`,
      });
    } catch (e) {
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage: `Unexpected error ${
          method === Product.updateProduct ? "updated" : "created"
        } the product`,
      });
      throw new Error("Unexpected error");
    } finally {
      handleClose();
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    await onSubmit(e, submitForm);
  };

  const promoLabel = useMemo(
    () => (hasPromo ? "Remove Promo" : "Add new promo"),
    [hasPromo]
  );

  const localHandleClose = () => {
    resetForm();
    handleClose();
  };

  const handlePromoChange = () => {
    const newFields: ProductForm = {
      ...fields,
      newPercentage: "",
      endDate: "",
    };
    if (hasPromo) setFields(newFields);
    setHasPromo((prev) => !prev);
  };

  return (
    <Modal show={show} onHide={localHandleClose} animation={false}>
      <Modal.Header closeButton={!!handleClose}>
        <Modal.Title>
          {initialData?.id ? "Update Product" : "Create New Product"}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <StyledDiv>
            <InputWrapper>
              <FormInput
                handleErrors={handleErrors}
                handleFields={handleFields}
                handleTouch={handleTouch}
                required
                name="name"
                value={fields.name}
                label="name"
                variant="floating"
                error={errors.name}
              />
            </InputWrapper>

            <InputWrapper>
              <FormInput
                handleErrors={handleErrors}
                handleFields={handleFields}
                handleTouch={handleTouch}
                required
                type="number"
                name="term"
                value={fields.term}
                label="term"
                variant="floating"
                error={errors.term}
              />
            </InputWrapper>
          </StyledDiv>

          <StyledDiv>
            <InputWrapper>
              <FormInput
                handleErrors={handleErrors}
                handleFields={handleFields}
                handleTouch={handleTouch}
                required
                type="number"
                name="allocationTerm"
                value={fields.allocationTerm}
                label="AllocationTerm"
                variant="floating"
                error={errors.allocationTerm}
              />
            </InputWrapper>
            <InputWrapper>
              <FormInput
                handleErrors={handleErrors}
                handleFields={handleFields}
                handleTouch={handleTouch}
                required
                type="number"
                name="interest"
                value={fields.interest}
                label="Interest"
                variant="floating"
                error={errors.interest}
              />
            </InputWrapper>
          </StyledDiv>
          <StyledDiv>
            <InputWrapper>
              <FormSelect
                handleErrors={handleErrors}
                handleFields={handleFields}
                handleTouch={handleTouch}
                required
                name="interestType"
                value={fields.interestType}
                label="InterestType"
                variant="floating"
                error={errors.interestType}
                options={[
                  [InterestType.Compound, InterestType.Compound],
                  [InterestType.Simple, InterestType.Simple],
                ]}
                placeHolder="Select one interest type"
                defaultValue={initialData.interestType}
              />
            </InputWrapper>
          </StyledDiv>
          <StyledDiv>
            <TextAreaWrapper>
              <FormTextArea
                handleErrors={handleErrors}
                handleFields={handleFields}
                handleTouch={handleTouch}
                required
                name="description"
                value={fields.description}
                label="Description"
                variant="floating"
                error={errors.description}
              />
            </TextAreaWrapper>
          </StyledDiv>
          <StyledDiv>
            <PromoWrapper>
              <Form.Check
                type="switch"
                id="custom-switch"
                label={promoLabel}
                onChange={handlePromoChange}
                checked={hasPromo}
              />
            </PromoWrapper>
          </StyledDiv>
          {!!hasPromo && (
            <>
              <StyledDiv>
                <InputWrapper>
                  <FormInput
                    handleErrors={handleErrors}
                    handleFields={handleFields}
                    handleTouch={handleTouch}
                    required={hasPromo}
                    type="number"
                    name="newPercentage"
                    value={fields.newPercentage}
                    label="New Percentage"
                    variant="floating"
                    error={errors.newPercentage}
                  />
                </InputWrapper>
                <InputWrapper>
                  <FormInput
                    handleErrors={handleErrors}
                    handleFields={handleFields}
                    handleTouch={handleTouch}
                    validation={validateFutureDate}
                    message="The selected date must be in the future"
                    type="datetime-local"
                    name="endDate"
                    value={fields.endDate}
                    label="End date"
                    variant="floating"
                    error={errors.endDate}
                  />
                </InputWrapper>
              </StyledDiv>
            </>
          )}
          <CheckboxWrapper>
            <FormInput
              handleErrors={handleErrors}
              handleFields={handleFields}
              handleTouch={handleTouch}
              type="checkbox"
              name="active"
              value={fields.active}
              defaultChecked={initialData.active === "true"}
              label="Active"
              variant="row"
              error={errors.active}
            />
          </CheckboxWrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" disabled={submitLoading}>
            {submitLoading ? "Submitting" : "Submit"}
          </Button>
          <Button onClick={localHandleClose} variant="danger">
            Cancel
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const StyledDiv = styled.div`
  display: flex;
  justify-content: start;
`;
const InputWrapper = styled.div`
  width: 50%;
  padding: 10px;
  height: 80px;
`;

const PromoWrapper = styled.div`
  padding: 10px;
`;

const TextAreaWrapper = styled.div`
  width: 100%;
  padding: 10px;
`;

const CheckboxWrapper = styled.div`
  width: 100%;
  padding: 10px;
`;

export default ModifyProductModal;
