import {
  Container,
  ContentHeroSpace,
  InvestRateNumbers,
  InvestRateText,
  InvestStatsText,
  InvestStatsTitle,
} from "../../../styles/adds";

const InvestIRASteps = () => {
  return (
    <section className="container-fluid pt-5" style={{ background: "#e1e1e1" }}>
      <Container
        className="container pl-1 pr-1"
        style={{ paddingBottom: "100px" }}
      >
        <ContentHeroSpace className="contentHeroSpace hidden-xs" />
        <div
          className="container-fluid p-0 ms-3 me-3 pe-4"
          style={{ background: "#e1e1e1", color: "#111111" }}
        >
          <div className="row align-items-start justify-content-start">
            <div className="col-sm-12">
              <div
                className="mt-1"
                style={{
                  backgroundColor: "#51976f",
                  width: "100%",
                  height: "4px",
                }}
              ></div>
              <InvestStatsTitle className="mb-3 invest-stats-title">
                Invest your IRA in three simple steps
              </InvestStatsTitle>
              <InvestStatsText
                className="mb-0 invest-stats-text mb-2"
                style={{ width: "100%" }}
              >
                Unlock the power of your IRA with Norhart Invest and turn your
                investment into the gift of time. Our seamless process guides
                you through creating a future where every second is yours to
                enjoy. Here's how you can start claiming time today:
              </InvestStatsText>
              <InvestStatsText
                className="mt-0 mb-3 invest-stats-text mb-5"
                style={{ width: "100%", fontWeight: "600" }}
              />

              <div className="container ms-0 p-0">
                <div className="row">
                  <div className="col-sm-12">
                    <div>
                      <InvestRateNumbers className="invest-rates-numbers">
                        Learn
                      </InvestRateNumbers>
                      <InvestRateText className="invest-ira-rates-text">
                        Dive into our webinar for a clear understanding on how
                        Norhart Invest primes your IRA for more than just
                        financial growth.
                      </InvestRateText>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div>
                      <InvestRateNumbers className="invest-rates-numbers">
                        Thrive
                      </InvestRateNumbers>
                      <InvestRateText className="invest-ira-rates-text">
                        Launch your investment and embrace a future where your
                        wealth and time blossom.
                      </InvestRateText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default InvestIRASteps;
