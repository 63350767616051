import {
  ContentHeroSpace,
  InvestRateNumbers,
  InvestRateSubtext,
  InvestRateText,
  InvestStatsText,
  InvestStatsTitle,
} from "../../../styles/adds";

const InvestRateSection = () => {
  return (
    <section
      className="container-fluid pt-5 pb-5"
      style={{ background: "#e1e1e1" }}
    >
      <div className="container pl-1 pr-1">
        <ContentHeroSpace className="hidden-xs"></ContentHeroSpace>
        <div
          className="container-fluid p-0 ms-3 me-3 pe-4"
          style={{ background: "#e1e1e1", color: "#111111" }}
        >
          <div className="row align-items-start justify-content-start">
            <div className="col-sm-12">
              <div
                className="mt-1"
                style={{
                  backgroundColor: "#51976f",
                  width: "100%",
                  height: "4px",
                }}
              ></div>
              <InvestStatsTitle className="mb-3">Our Rates</InvestStatsTitle>
              <InvestStatsText className="mb-0 mb-2" style={{ width: "100%" }}>
                Norhart offers a better alternative for high-yield investors.
              </InvestStatsText>
              <InvestStatsText
                className="mt-0 mb-3"
                style={{ width: "100%", fontWeight: "600" }}
              >
                <i>Data as of Sept 2023. Sources: Business Insider; CNBC.</i>
              </InvestStatsText>

              <div className="container ms-0 p-0">
                <div className="row">
                  <div
                    className="col-sm-2"
                    style={{
                      color: "white",
                      backgroundColor: "#000000",
                    }}
                  >
                    <div>
                      <InvestRateNumbers className="invest-rates-numbers">
                        10%
                      </InvestRateNumbers>
                      <InvestRateText>Norhart Invest</InvestRateText>
                      <InvestRateSubtext>
                        5-Year Promissory Note
                      </InvestRateSubtext>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div>
                      <InvestRateNumbers className="invest-rates-numbers">
                        0.47%
                      </InvestRateNumbers>
                      <InvestRateText>Savings Accounts</InvestRateText>
                      <InvestRateSubtext>National Average</InvestRateSubtext>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div>
                      <InvestRateNumbers className="invest-rates-numbers">
                        1.47%
                      </InvestRateNumbers>
                      <InvestRateText>Cash Deposits</InvestRateText>
                      <InvestRateSubtext>National Average</InvestRateSubtext>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div>
                      <InvestRateNumbers className="invest-rates-numbers">
                        4.55%
                      </InvestRateNumbers>
                      <InvestRateText>Treasury Bonds</InvestRateText>
                      <InvestRateSubtext>10-Year Average</InvestRateSubtext>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvestRateSection;
