interface Props {
  title: string;
  description?: string;
  updates?: string[];
  date: string;
}

export const ReleaseVersionList = ({
  title,
  description,
  updates,
  date,
}: Props) => {
  return (
    <div>
      <h5>{title}</h5>
      <h6>Date: {date}</h6>
      <p>{description}</p>
      <ul>
        {updates?.map((update, index) => (
          <li key={index}>{update}</li>
        ))}
      </ul>
    </div>
  );
};
