import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { BannerWrapper } from ".";
import { FaEllipsisH } from "react-icons/fa";

export const ToggleButton = styled(FaEllipsisH)`
  color: ${({ theme }) => theme.BANNER_COLOR};
`;

export const Toggle = styled(Dropdown.Toggle)`
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0px;
  --bs-btn-active-bg: transparent;
  --bs-btn-hover-bg: transparent;
`;

export const Button = styled.div`
  font-size: 12px;
  padding: 2px 12px;
  font-weight: 600;
  width: 75px;
  border: none;
  border-radius: 6px;
  color: ${({ theme }) => theme.PRIMARY_COLOR};
  background-color: ${({ theme }) => theme.NAVBAR_BACKGROUND};
`;

export const StyledBannerWrapper = styled(BannerWrapper)`
  padding-top: 10px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: 300;
  font-size: 12px;
`;

export const ContentWrapper = styled.div`
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  color: ${({ theme }) => theme.PRIMARY_COLOR};
`;

export const CustomDropdownItem = styled(Dropdown.Item)`
  --bs-dropdown-link-active-bg: transparent;
  --bs-dropdown-link-hover-bg: ${({ theme }) =>
    theme.BANNER_BACKGROUND} !important;
`;

export const StyledMenu = styled(Dropdown.Menu)`
  background-color: ${({ theme }) => theme.NAVBAR_BACKGROUND};
  color: ${({ theme }) => theme.PRIMARY_COLOR};
  border-color: transparent;
`;
