import { useState } from "react";
import {
  BottomDiv,
  CancelButton,
  ConfirmButton,
  CustomSpinner,
  CustomStrong,
  GreyText,
  StyledDiv,
  StyledNumber,
  StyledText,
  Wrapper,
  Image,
} from "../../styles/popUp";
import { ModalInitialData, Steps } from "../../types/payment-modal";
import { ResponseStatus } from "../../types/ResponseStatus";
import { formatDate, formatNumbers, removeCommas } from "../../utils";
import { notifications } from "../../utils/notifications";
import NorhartModal from "../NorhartModal";
import { SignIframe } from "../SignIframe";
import { Loading } from "../Loading";
import CheckIcon from "../../assets/images/badge-check.png";

interface Props {
  show: boolean;
  onClose: (step: Steps) => void;
  step: Steps;
  nextStep: (step: Steps) => void;
  attributes: ModalInitialData;
  handleSubmit: (paymentId: string) => Promise<void>;
  paymentId?: string;
  signatureUrl?: string;
  updateSignatureUrl: (url: string) => void;
}

const WithdrawConfirm = ({
  show,
  onClose,
  step,
  nextStep,
  attributes: { amount, account, product },
  handleSubmit,
  paymentId,
  signatureUrl,
  updateSignatureUrl,
}: Props) => {
  const [loading, setIsLoading] = useState<boolean>();
  const handleClick = async () => {
    setIsLoading(true);
    try {
      if (!paymentId) {
        handleCancel();
        throw new Error();
      }
      await handleSubmit(paymentId);
      nextStep(step);
    } catch (e: any) {
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage: e?.message || "Something went wrong creating the payment",
      });
    }
    setIsLoading(false);
  };

  const handleCancel = () => {
    onClose(step);
  };
  return (
    <div>
      {signatureUrl && (
        <SignIframe url={signatureUrl} onExit={() => updateSignatureUrl("")} />
      )}
      <NorhartModal
        title="Confirm your transfer"
        show={show}
        onClose={handleCancel}
        canClose={!loading}
      >
        {signatureUrl ? (
          <Loading />
        ) : (
          <>
            <Wrapper>
              <StyledNumber>
                ${formatNumbers(Number(removeCommas(amount)), 1)}
              </StyledNumber>
              <StyledText>
                {product.name} Withdrawal <br />
                {formatDate(new Date().toDateString())}
              </StyledText>
            </Wrapper>
            <BottomDiv>
              <div>
                <CustomStrong>Status: Confirm </CustomStrong>{" "}
                <CustomStrong>
                  Signed
                  <Image
                    src={CheckIcon}
                    width={20}
                    height={20}
                    alt="check-icon"
                  />
                </CustomStrong>
                <GreyText>To: {account.name}</GreyText>
              </div>
              <StyledDiv>
                <CustomStrong>Total</CustomStrong>
                <CustomStrong>
                  ${formatNumbers(Number(removeCommas(amount)), 1)}
                </CustomStrong>
              </StyledDiv>
              <BottomDiv>
                <ConfirmButton
                  color={"#288d05"}
                  onClick={handleClick}
                  disabled={loading}
                  data-cy="withdraw-confirm-submit"
                >
                  <CustomSpinner hide={(!loading)?.toString()} size="sm" />
                  {!loading && "Confirm & Continue"}
                </ConfirmButton>
                {!loading && (
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                )}
              </BottomDiv>
            </BottomDiv>
          </>
        )}
      </NorhartModal>
    </div>
  );
};
export default WithdrawConfirm;
