import { CustomError } from "../types";
import { CreateProductInput, UpdateProductInput } from "../types/Product";

const createProduct = async (createProductBody: CreateProductInput) => {
  const path = "/products";
  const response = await fetch(path, {
    method: "POST",
    body: JSON.stringify(createProductBody),
  });
  const res = await response.json();
  if (response.ok) {
    return res;
  }
  CustomError.throw(res, res?.error || res?.message);
};

const updateProduct = async (updateProductBody: UpdateProductInput) => {
  const path = "/products";
  const response = await fetch(path, {
    method: "PATCH",
    body: JSON.stringify(updateProductBody),
  });
  const res = await response.json();
  if (response.ok) {
    return res;
  }
  CustomError.throw(res, res?.error || res?.message);
};

export const Product = {
  createProduct,
  updateProduct,
};
