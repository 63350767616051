import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import {
  Title,
  SubTitle,
  FormWrapper,
  WrapperBotText,
  VerticalBar,
  StyledLink,
} from "./style";
import { useIsLogged } from "../../hooks/useIsLogged";
import { DarkWrapperAuth } from "../../components/DarkWrapperAuth";
import { AuthButton } from "../../components/AuthButton";

export const NotFound: FunctionComponent = () => {
  const navigate = useNavigate();
  const { isLogged } = useIsLogged();

  return (
    <DarkWrapperAuth>
      <FormWrapper>
        <>
          <Title>Oh No! An Error</Title>
          <SubTitle>Something went wrong</SubTitle>
          <AuthButton onClick={() => navigate("/")} type="button">
            {isLogged ? "Home" : "Sign In"}
          </AuthButton>
        </>
      </FormWrapper>
      <WrapperBotText>
        {!isLogged && (
          <>
            <StyledLink to="/forgot-password/send-email">
              Forgot password
            </StyledLink>
            <VerticalBar>|</VerticalBar>
            <StyledLink to="/signup">Need an account?</StyledLink>
          </>
        )}
      </WrapperBotText>
    </DarkWrapperAuth>
  );
};
