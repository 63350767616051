import InvestRibbionSignup from "../components/InvestRibbonSignUp";

const InvestProposeSection = () => {
  return (
    <InvestRibbionSignup
      company="NORHART INVEST"
      title="Tailored Benefits"
      subtitle="Norhart Invest has expert evaluations, cutting-edge technology, short and long-term options, and a great investment pathway."
      buttonName="Invest Now"
      buttonUrl="/signup"
    />
  );
};

export default InvestProposeSection;
