import logrocket from "logrocket";

const identify = (
  token: string,
  username: string,
  isAdmin: boolean = false
) => {
  logrocket.identify(username, {
    token: token,
    email: username,
    admin: !!isAdmin,
  });
};

export const LogRocket = {
  identify,
};
