import { useCallback } from "react";
import { CacheKeys } from "../types";
import { ResponseStatus } from "../types/ResponseStatus";
import { PlaidBankAccount, User } from "../utils";
import { notifications } from "../utils/notifications";
import { useFetch } from "./useFetch";

export const useBankAccounts = () => {
  const fetchFunction = useCallback(async () => {
    const response = await User.getBankAccounts();
    return response.bankAccounts;
  }, []);

  const { data, loading, refetchData, refreshData, refetching } = useFetch({
    cacheKey: CacheKeys.bankAccounts,
    fetchFunction,
  });

  const handleDelete = async (id: string) => {
    try {
      await PlaidBankAccount.deleteAccount(id);
      const newBankAccounts = data?.filter(
        (bankAccount) => bankAccount.id !== id
      );
      if (newBankAccounts) refreshData(newBankAccounts);
      notifications({
        status: ResponseStatus.SUCCESS,
        successMessage: `The bank account was deleted successfully`,
      });
    } catch (e: any) {
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage:
          e?.message || `Unexpected error deleting the bank account`,
      });
    }
  };

  return {
    loading: loading,
    bankAccounts: data,
    fetchBankAccounts: refetchData,
    refreshData,
    handleDelete,
    refetching,
  };
};
