import { useEffect } from "react";
import logrocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import LogrocketFuzzySanitizer from "logrocket-fuzzy-search-sanitizer";
import { useIsLogged } from "./useIsLogged";
import { Auth } from "../utils";
import { LogRocket } from "../utils/logrocket";

export const useLogRocket = () => {
  const { isLogged, isLoading } = useIsLogged();
  useEffect(() => {
    const identifyUser = async () => {
      const { token, email } = await Auth.getUserName();
      LogRocket.identify(token, email);
    };
    if (isLogged) {
      identifyUser();
    }
  }, [isLogged, isLoading]);
  useEffect(() => {
    const privateFieldNames = [
      "password",
      "repeatPassword",
      "newPassword",
      "authenticationToken",
      "AccessToken",
      "IdToken",
      "RefreshToken",
      "account",
      "amount",
      "phoneNumber",
      "email",
      "addressLine1",
      "addressLine2",
      "city",
      "state",
      "zipCode",
    ];

    const {
      requestSanitizer: fuzzyRequestSanitizer,
      responseSanitizer: fuzzyResponseSanitizer,
    } = LogrocketFuzzySanitizer.setup([...privateFieldNames]);

    logrocket.init(process.env.REACT_APP_LOGROCKET_APP_ID!, {
      dom: {
        // textSanitizer: true,
        inputSanitizer: true,
      },
      network: {
        isEnabled: true,
        requestSanitizer: (request) => {
          if (request.url.toLowerCase().indexOf("webpack") !== -1) {
            // ignore the request response pair
            return null;
          }

          if (request.headers["authorization"]) {
            request.headers["authorization"] = "";
          }

          return fuzzyRequestSanitizer({
            ...request,
            body: request.body || null,
          });
        },
        responseSanitizer: (response) => {
          return fuzzyResponseSanitizer({
            ...response,
            body: response.body || null,
          });
        },
      },
    });
    setupLogRocketReact(logrocket);
  }, []);
};
