import { FormEvent, useEffect, useRef, useState } from "react";
import { useForm } from "../../hooks/useForm";
import {
  BottomDiv,
  CancelButton,
  ConfirmButton,
  CustomAlert,
  CustomSpinner,
  Form,
  InputWrapper,
  StyledLabel,
  SwitchWrapper,
  TermAndConditions,
} from "../../styles/popUp";
import { FormInput } from "../forms/formsComponents/FormInput";
import { FormSelect } from "../forms/formsComponents/FormSelect";
import { AddMoneyInitialData, Steps } from "../../types/payment-modal";
import { FormReactSelect } from "../forms/formsComponents/FormReactSelect";
import { OptionLabel, OptionLabelFlexAccount } from "../OptionLabel";
import {
  initialErrors,
  initialTouch,
  customErrors,
  MINIMUM_AMOUNT,
  flexAccountKey,
  SUGGESTED_AMOUNT,
} from "./constants";
import { ErrorCodes, FlexAccount, Product } from "../../types";
import { BankAccount } from "../../types/bank-accounts";
import { notifications } from "../../utils/notifications";
import { ResponseStatus } from "../../types/ResponseStatus";
import { TransactionGroup } from "../../types/payments";
import { convertToNumber, formatNumbers, removeCommas } from "../../utils";
import { GroupedOption } from "./AddMoneyAmount";
import { VipNotification } from "../VipNotification";
import { useCalculatorSection } from "../../hooks/useCalculatorSection";
import { PercentageNotification } from "../PercentageNotification";
import { useCognitoAttributes } from "../../hooks/useCognitoAttributes";
import { CustomSwitch, Slider, StyledInput } from "../../styles/switch";

interface Props {
  onClose: (step: Steps) => void;
  step: Steps;
  nextStep: (step: Steps) => void;
  attributes: AddMoneyInitialData;
  products: Product[];
  bankAccounts?: BankAccount[];
  isAddMoney?: boolean;
  flexAccount?: FlexAccount;
  onSubmitAmount: (
    fields: AddMoneyInitialData,
    transactionGroup?: TransactionGroup
  ) => Promise<void>;
  reactSelectOptions: GroupedOption[];
  bankOptions: string[][];
  defaultProduct?: {
    name: string;
    id: string;
  };
}

const AmountForm = ({
  step,
  nextStep,
  attributes,
  flexAccount,
  onSubmitAmount,
  onClose,
  reactSelectOptions,
  bankOptions,
  defaultProduct = { name: "", id: "" },
}: Props) => {
  const {
    fields,
    errors,
    handleErrors,
    handleFields,
    handleTouch,
    submitLoading,
    onSubmit,
    resetForm,
    handleInputChange,
    setFields,
  } = useForm({
    initialFields: { ...attributes, product: defaultProduct },
    initialErrors: initialErrors,
    initialTouch: initialTouch,
    customErrors: customErrors,
  });

  const { income, loading: incomeLoading } = useCognitoAttributes();

  const setAmountField = (amount: string) => {
    setFields({ ...fields, amount });
  };

  const ref = useRef<HTMLButtonElement>(null);

  const [showAmountNotification, setShowAmountNotification] =
    useState<boolean>(false);
  const [remainingAmount, setRemainingAmount] = useState<string>();
  const [showPercentageNotification, setShowPercentageNotification] =
    useState<boolean>(false);
  const [dispatchPayment, setDispatchPayment] = useState<boolean>(false);

  const [switchOn, setSwitchOn] = useState<boolean>(false);
  const handleSwitchChange = () => {
    setSwitchOn(!switchOn);
  };

  // const [autoReInvestSwitch, setAutoReInvestSwitch] = useState<boolean>(true);
  // const handleAutoReInvestSwitch = () => {
  //   setFields((prev) => {
  //     return { ...prev, autoReInvest: !switchOn };
  //   });
  //   setAutoReInvestSwitch(!switchOn);
  // };

  const onSubmitForm = async () => {
    try {
      await onSubmitAmount(fields);
      resetForm();
      nextStep(step);
    } catch (e: any) {
      if (e?.code === ErrorCodes.IncomePercentageExceeded) {
        setRemainingAmount(JSON.parse(e?.data).remainingAmount);
        setShowPercentageNotification(true);
        return;
      }
      if (e?.code === ErrorCodes.MaximumAmountPerInvestmentExceeded) {
        setShowAmountNotification(true);
        return;
      }
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage: e?.message || "Something went wrong creating the payment",
      });
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    await onSubmit(e, onSubmitForm);
  };

  const amountValidation = (value: string) => {
    if (value === "0")
      return { isValid: false, message: "Amount must be greater than 0" };
    const valueNumber = convertToNumber(value);
    const balance = convertToNumber(
      formatNumbers(flexAccount!.currentBalance.available)
    );
    const posted = convertToNumber(
      formatNumbers(flexAccount!.currentBalance.posted)
    );
    const newBalance = valueNumber + balance;
    if (fields.account.id === flexAccountKey && posted < valueNumber) {
      return {
        isValid: false,
        message: `You don't have enough money in your dashboard account`,
      };
    }
    if (valueNumber < MINIMUM_AMOUNT && fields.product.id !== flexAccountKey) {
      return {
        isValid: false,
        message: `Minimum amount is $${formatNumbers(MINIMUM_AMOUNT, 1, 0)}`,
      };
    }
    return {
      isValid:
        fields.product.id !== flexAccountKey || newBalance >= MINIMUM_AMOUNT,
    };
  };

  const bankAccountValidation = (value: string) => {
    const data: { id: string; name: string } = JSON.parse(value);
    if (data.id !== flexAccountKey || !flexAccount) return { isValid: true };
    const posted = convertToNumber(
      formatNumbers(flexAccount!.currentBalance.posted)
    );
    if (posted < MINIMUM_AMOUNT)
      return {
        isValid: false,
        message: `You need to have at least $${MINIMUM_AMOUNT} to invest money from your cash.`,
      };
    return { isValid: true };
  };

  const handleClose = () => {
    resetForm();
    onClose(step);
  };

  const handleCloseAmountModal = () => {
    setShowAmountNotification(false);
  };

  const { earned, onChangeAmount, onChangeTerm } = useCalculatorSection({});

  const handleAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    props: { isMoney: boolean }
  ) => {
    handleInputChange(e, props);
    onChangeAmount(e);
  };

  const handleClosePercentageModal = (triggerPayment?: boolean) => {
    setShowPercentageNotification(false);
    if (triggerPayment) setDispatchPayment(!dispatchPayment);
  };

  useEffect(() => {
    ref.current?.click();
  }, [dispatchPayment]);

  return (
    <>
      <VipNotification
        show={showAmountNotification}
        onClose={handleCloseAmountModal}
      />
      {!incomeLoading && (
        <PercentageNotification
          show={showPercentageNotification}
          onClose={handleClosePercentageModal}
          remainingAmount={formatNumbers(remainingAmount || 0)}
          userAmount={formatNumbers(removeCommas(fields.amount), 1)}
          setAmount={setAmountField}
          income={income || ""}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <InputWrapper>
          <StyledLabel>From:</StyledLabel>
          <FormSelect
            name="account"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            options={bankOptions}
            value={fields.account.id}
            error={errors.account}
            required
            placeHolder="Select a bank account..."
            label="Bank Account"
            notShowLabel={true}
            data-cy="select-bank-account"
            validation={bankAccountValidation}
          />
        </InputWrapper>
        <InputWrapper>
          <StyledLabel>To:</StyledLabel>
          <FormReactSelect
            id="select-to"
            name="product"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            options={reactSelectOptions}
            value={reactSelectOptions
              .flatMap((group) => group.options)
              .filter((option) => option.id === fields.product.id)}
            isOptionDisabled={(option) => {
              return !!(option as OptionLabelFlexAccount).disable;
            }}
            error={errors.product}
            required
            isSearchable={false}
            placeHolder="Select a product..."
            getOptionLabel={(v: any) => {
              return v.name;
            }}
            getOptionValue={(v: any) => {
              return v.name;
            }}
            formatOptionLabel={(
              promissoryNote: unknown,
              meta: { context: string }
            ) => {
              return (
                <OptionLabel
                  promissoryNote={promissoryNote as Product}
                  context={meta.context}
                />
              );
            }}
            label="Product"
            notShowLabel={true}
            handleValue={(value: Product) => {
              return { name: value.name, id: value.id };
            }}
            change={(v, m) => {
              const { interest, term } = v as Product;
              onChangeTerm(undefined, {
                interest: interest.toString(),
                term: term.toString(),
              });
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <StyledLabel>Amount:</StyledLabel>
          <FormInput
            name="amount"
            value={fields.amount}
            error={errors.amount}
            handleErrors={handleErrors}
            onChange={handleAmountChange}
            handleTouch={handleTouch}
            validation={amountValidation}
            message={`We require a minimum balance of $${formatNumbers(
              MINIMUM_AMOUNT,
              1,
              0
            )} in your flex account`}
            required
            isMoney
            placeholder={`Amount (Example: $${formatNumbers(
              SUGGESTED_AMOUNT,
              1,
              0
            )})`}
            label="Amount"
            notShowLabel={true}
            data-cy="amount"
            // animateError
          />
          <div></div>
          <CustomAlert>
            Tip: Recommend Minimum deposit of $
            {formatNumbers(SUGGESTED_AMOUNT, 1, 0)}
          </CustomAlert>
        </InputWrapper>

        <InputWrapper>
          <StyledLabel>Return:</StyledLabel>
          <div style={{ alignSelf: "start" }}>{earned}</div>
          <div></div>
          <CustomAlert variant="secondary">
            Note: Estimated return amount
          </CustomAlert>
        </InputWrapper>
        {/* {fields.product.id !== flexAccountKey && (
                <SwitchWrapper style={{ borderBottom: "none" }}>
                  <BootstrapForm.Check
                    type="switch"
                    label={"Enable automatic reinvestment at end of term"}
                    onChange={handleAutoReInvestSwitch}
                    defaultChecked={autoReInvestSwitch}
                  />
                </SwitchWrapper>
              )} */}
        <SwitchWrapper style={{ marginBottom: "20px" }}>
          <CustomSwitch>
            <StyledInput
              onChange={handleSwitchChange}
              defaultChecked={switchOn}
              data-cy="agree-switch"
            />
            <Slider />
          </CustomSwitch>
          <span>
            I Agree to the{" "}
            <TermAndConditions
              style={{ textDecoration: "none" }}
              href="https://cdn.norhart.com/2023-06-30_Terms_of_Service.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </TermAndConditions>
          </span>
        </SwitchWrapper>
        <BottomDiv>
          <ConfirmButton
            data-cy="add-money-submit"
            type="submit"
            disabled={submitLoading || !switchOn}
            ref={ref}
          >
            <CustomSpinner hide={(!submitLoading)?.toString()} size="sm" />
            {!submitLoading && "Continue"}
          </ConfirmButton>
          <CancelButton type="button" onClick={handleClose}>
            Cancel
          </CancelButton>
        </BottomDiv>
      </Form>
    </>
  );
};

export default AmountForm;
