import { Accordion, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Item,
  LeftWrapper,
  Dot,
  TextWrapper,
  Title,
  Description,
  RightWrapper,
  Arrow,
  NumberWrapper,
  ListTitle,
  TitleWrapper,
  ViewAllButton,
  TopWrapper,
  Interest,
} from "../styles/list";
import { LedgerTransactionOutput, TransactionStatus } from "../types";
import { User, formatDate, formatNumbers } from "../utils";
import PaginationButtons from "./PaginationButtons";
import { useTransactionsList } from "../hooks";
import { AccordionArrow } from "./AccorditionArrow";
import { useMemo } from "react";
interface Props {
  transactions: LedgerTransactionOutput[];
  homepage?: boolean;
  hideTitle?: boolean;
  pagination?: boolean;
  handleNext?: () => void;
  handlePrevious?: () => void;
  isLastPage?: boolean;
  isFirstPage?: boolean;
}

const TransactionsList = ({
  transactions,
  homepage,
  hideTitle,
  pagination,
  handleNext,
  handlePrevious,
  isLastPage,
  isFirstPage,
}: Props) => {
  const navigate = useNavigate();
  const { hasPendingTransactions } = useTransactionsList({ transactions });
  const isDefaultActive = useMemo(
    () =>
      User.getUsersPreferencesFromLocalStorage()
        ?.dashboardTransactionsCollapsed,
    []
  );
  return (
    <PageWrapper display={(!!transactions.length).toString()}>
      <Accordion defaultActiveKey={!!isDefaultActive ? undefined : "0"}>
        <TopWrapper>
          <TitleWrapper>
            {!hideTitle && <ListTitle>Latest Transactions</ListTitle>}
            {homepage && (
              <ViewAllButton onClick={() => navigate("/transactions")}>
                View All
              </ViewAllButton>
            )}
            {homepage && (
              <AccordionArrow
                eventKey="0"
                preferenceKey="dashboardTransactionsCollapsed"
              />
            )}
          </TitleWrapper>
          {pagination && handleNext && handlePrevious && (
            <PaginationButtons
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              isLastPage={!!isLastPage}
              isFirstPage={!!isFirstPage}
            />
          )}
        </TopWrapper>
        <Accordion.Collapse eventKey="0">
          <div>
            <ListGroup>
              {transactions.map(
                ({ description, effectiveDate, amount, id, status }, i) => {
                  return (
                    <Item key={id} style={{ cursor: "default" }}>
                      <LeftWrapper>
                        <div>
                          <Dot status={status} displaydot={"true"} />
                        </div>
                        <TextWrapper>
                          <Title>{description}</Title>
                          <Description>
                            {formatDate(effectiveDate, true)}
                          </Description>
                        </TextWrapper>
                      </LeftWrapper>
                      <RightWrapper>
                        <NumberWrapper>
                          <Title data-cy={`transaction-amount-${i}`}>
                            ${formatNumbers(amount)}
                          </Title>
                          {status === TransactionStatus.pending && (
                            <Pending>Pending</Pending>
                          )}
                        </NumberWrapper>
                        <Arrow displayarrow={""} />
                      </RightWrapper>
                    </Item>
                  );
                }
              )}
            </ListGroup>
            {hasPendingTransactions && (
              <PendingText>
                <strong>Pending:</strong> Bank transfers take between 2-5 days
                to complete
              </PendingText>
            )}
          </div>
        </Accordion.Collapse>
      </Accordion>
    </PageWrapper>
  );
};

interface WrapperProps {
  display: string;
}

const PageWrapper = styled.div<WrapperProps>`
  display: ${(props) => (props.display === "true" ? "block" : "none")};
`;

const Pending = styled(Interest)`
  background-color: #427b2c;
  color: white;
`;

const PendingText = styled.div`
  color: #bcbcbc;
  font-size: 14px;
  padding: 5px 10px;
`;

export default TransactionsList;
