import { useCallback } from "react";
import { CacheKeys } from "../types";
import { User } from "../utils";
import { useFetch } from "./useFetch";

export const useUserPreferences = () => {
  const fetchFunction = useCallback(async () => {
    const response = await User.getUsersPreferences();
    return response;
  }, []);

  const { data, loading } = useFetch({
    cacheKey: CacheKeys.userPreferences,
    fetchFunction,
  });
  return { data, loading };
};
