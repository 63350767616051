import { useContext, useMemo } from "react";
import { useAccordionButton, AccordionContext } from "react-bootstrap";
import styled from "styled-components";
import { Arrow } from "../styles/list";
import { User, debounce } from "../utils";
import { UserPreferences } from "../types/users";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";

interface ContextAwareToggleProps {
  eventKey: string;
  callback?: (eventKey: string) => void;
  preferenceKey: keyof UserPreferences;
}

export const AccordionArrow = ({
  eventKey,
  callback,
  preferenceKey,
}: ContextAwareToggleProps) => {
  const { activeEventKey } = useContext(AccordionContext);
  const debounceFunction = useMemo(() => {
    const callback = async (activeEventKey: AccordionEventKey) => {
      try {
        await User.updateUserPreferences({
          [preferenceKey]: activeEventKey === "0",
        });
      } catch (e) {
        console.error(e);
      }
    };
    return debounce(
      (activeEventKey: AccordionEventKey) => callback(activeEventKey),
      500
    );
  }, [preferenceKey]);

  const handleClick = (e: any) => {
    decoratedOnClick(e);
    debounceFunction(activeEventKey);
  };
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  return (
    <RotateArrow
      className={activeEventKey === "0" ? "rotate" : ""}
      displayarrow="true"
      type="button"
      onClick={handleClick}
    />
  );
};

const RotateArrow = styled(Arrow)`
  transition: transform 0.3s ease-in-out;
  &.rotate {
    transform: rotate(90deg);
  }
`;
