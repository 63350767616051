const integerAccept = /\d+/g;
export const parseInteger = (string?: string) =>
  (string?.match(integerAccept) || []).join("");

export const formatInteger = (string?: string) => {
  const parsed = parseInteger(string);
  const number = Number.parseInt(parsed, 10);
  if (Number.isNaN(number)) {
    return "";
  }
  return number.toLocaleString("en");
};
