import styled from "styled-components";
import BenefitsForYouSection from "./landing/sections/BenefitsForYouSection";
import ConceptSignUp from "./landing/sections/ConceptSignUp";
import EarthVideoSection from "./landing/sections/EarthVideoSection";
import FAQInvestSection from "./landing/sections/FAQInvestSection";
import GallerySection from "./landing/sections/GallerySection";
import GrowYourCash from "./landing/sections/GrowYourCash";
import IRAInvesting from "./landing/sections/IRAInvesting";
import InvestComfortSection from "./landing/sections/InvestComfortSection";
import InvestLegalSection from "./landing/sections/InvestLegalSection";
import InvestOfferingsSection from "./landing/sections/InvestOfferingsSection";
import InvestProposeSection from "./landing/sections/InvestPorposeSection";
import InvestRateSection from "./landing/sections/InvestRateSection";
import InvestStatsSection from "./landing/sections/InvestStatsSection";
import InvestWithUsSection from "./landing/sections/InvestWithUsSection";
import LandingFooter from "./landing/sections/LandingFooter";
import LuxuryBedroom from "./landing/sections/LuxuryBedroom";
import LuxuryPenthouse from "./landing/sections/LuxuryPenthouse";
import SecuritySection from "./landing/sections/SecuritySection";
import StaffSection from "./landing/sections/StaffSection";
import WelcomeInvestCalculatorSection from "./landing/sections/WelcomeInvestCalculatorSection";
import WelcomeVideoSection from "./landing/sections/WelcomeVideoSection";
import { AuthNavbar } from "./navbar/AuthNavbar";
import WhyInvestVideoSection from "./landing/sections/WhyInvestVideoSection";
import TrustpilotWidget from "./TrustpilotWidget";

const InfluencerLanding = ({ children }: { children: JSX.Element }) => {
  return (
    <Container>
      <AuthNavbar darkMode />
      <div style={{ maxWidth: "100vw" }}>
        {children}
        <ConceptSignUp />
        <WelcomeInvestCalculatorSection />
        <InvestRateSection />
        <GrowYourCash />
        <InvestWithUsSection />
        <LuxuryPenthouse />
        <WelcomeVideoSection />
        <InvestStatsSection />
        <StaffSection />
        <WhyInvestVideoSection />
        <InvestOfferingsSection />
        <BenefitsForYouSection />
        <IRAInvesting />
        <LuxuryBedroom />
        <InvestProposeSection />
        <GallerySection />
        <InvestComfortSection />
        <EarthVideoSection />
        <FAQInvestSection />
        <SecuritySection />
        <InvestLegalSection fontColor="#111111" />
      </div>
      <LandingFooter />
      <TrustpilotWidget />
    </Container>
  );
};

const Container = styled.div`
  background: linear-gradient(#2f2f2f, #121212) !important;
  max-height: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
`;

export default InfluencerLanding;
