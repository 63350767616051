import ReactDOM from "react-dom/client";
import "./scss/app.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./amplify/configure";
import AppProvider from "./hooks/AppContext";
// import TagManager from "react-gtm-module";

// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTM_ID!,
//   auth: process.env.REACT_APP_GTM_AUTH!,
//   // preview: process.env.REACT_APP_GTM_ENV!,
// };

// TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AppProvider>
    <App />
  </AppProvider>
);

reportWebVitals();
