import styled from "styled-components";

interface Props {
  animateError?: boolean;
}

export const InputWrapper = styled.div<Props>`
  min-height: ${(props) => (props.animateError ? "100%" : "65px")};
  @media (max-width: 280px) {
    min-height: ${(props) => (props.animateError ? "100%" : "55px")};
  }
`;

export const StyledInput = styled.input`
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.DISABLED_INPUT_BACKGROUND_COLOR
      : theme.INPUT_BACKGROUND}!important;
  color: ${({ theme }) => theme.INPUT_COLOR};
  border-color: ${({ theme }) => theme.INPUT_BORDER_COLOR};
  --bs-form-invalid-color: ${({ theme }) => theme.INPUT_COLOR};
  --bs-form-invalid-border-color: ${({ theme }) => theme.INPUT_COLOR};
  box-shadow: none !important;
  background-image: none !important;
  &:focus {
    color: ${({ theme }) => theme.INPUT_COLOR};
    border-color: ${({ theme }) => theme.PRIMARY_COLOR};
    box-shadow: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.INPUT_COLOR};
  }
  @media (max-width: 280px) {
    height: 30px;
    font-size: 15px;
  }
  color-scheme: ${({ theme }) => theme.INPUT_COLOR_SCHEME};
`;

export const StyledSelect = styled.select`
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.DISABLED_INPUT_BACKGROUND_COLOR
      : theme.INPUT_BACKGROUND}!important;
  color: ${({ theme }) => theme.INPUT_COLOR};
  border-color: ${({ theme }) => theme.INPUT_BORDER_COLOR};
  background-image: ${({ theme }) => `url(${theme.SELECT_ARROW})`} !important;
  box-shadow: none !important;
  &:focus {
    color: ${({ theme }) => theme.INPUT_COLOR};
    border-color: ${({ theme }) => theme.INPUT_BORDER_COLOR};
  }
  &::placeholder {
    color: ${({ theme }) => theme.INPUT_COLOR};
  }
  @media (max-width: 280px) {
    height: 30px;
    font-size: 15px;
  }
  color-scheme: ${({ theme }) => theme.INPUT_COLOR_SCHEME};
`;

export const StyledLabel = styled.label`
  color: ${({ theme }) => theme.INPUT_COLOR} !important;
  &:after {
    background-color: transparent !important;
  }
`;

interface ErrorProps {
  visible: boolean;
}

export const ErrorWrapper = styled.div<ErrorProps>`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  min-height: ${(props) => (props.visible ? "25px" : "0")};
  transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
  @media (max-width: 280px) {
    min-height: ${(props) => (props.visible ? "20px" : "0")};
    font-size: 13px;
  }
`;

export const IsMoneySpan = styled.span`
  background-color: ${({ theme }) => theme.DISABLED_INPUT_BACKGROUND_COLOR};
  color: ${({ theme }) => theme.INPUT_COLOR};
  border-color: ${({ theme }) => theme.INPUT_BORDER_COLOR};
  @media (max-width: 280px) {
    height: 30px;
  }
`;
