import {
  ContentHeroSpace,
  ContentHeroTitle,
  TopHr,
  Container,
} from "../../../styles/adds";
import FaqAccordion from "../components/FaqAccordition";

const FAQInvestSection: React.FC = () => {
  return (
    <section
      className="container-fluid pb-5 pt-0"
      style={{
        background: "linear-gradient(#EFF2F4, #EFF2F4)",
        color: "#365c70",
      }}
    >
      <Container className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <ContentHeroSpace className="hidden-xs"></ContentHeroSpace>
        <ContentHeroTitle
          style={{
            color: "#365c70",
          }}
        >
          Norhart Invest FAQ's
        </ContentHeroTitle>
        <TopHr
          color="#365c70"
          className="hidden-sm hidden-md hidden-lg hidden-xl style-line mt-5 mb-5"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
          }}
        />
        <FaqAccordion ispublic />
      </Container>
    </section>
  );
};

export default FAQInvestSection;
