import { ResponseStatus } from "../types/ResponseStatus";
import { GetUserDocumentUrl } from "../types/documents";
import { Documents } from "../pages/documents/utils/documents";
import { notifications } from "../utils/notifications";
import { useDownloadQueue } from "./useDownloadQueue";

export const useDocumentList = () => {
  const fetchUrl = async (input: GetUserDocumentUrl) => {
    try {
      const response = await Documents.getUrl(input);
      if (!response?.url) throw new Error("No url returned from server");
      return response.url;
    } catch {
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage:
          "Oops, something went wrong trying to download your document",
      });
    }
  };
  const { loading, updateQueue } = useDownloadQueue({ fetchUrl });
  return { loading, updateQueue };
};
