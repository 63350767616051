import styled from "styled-components";
import Table from "react-bootstrap/Table";
import { GreenButton } from "../../styles";
import ModifyProductModal from "../../components/ModifyProductModal";
import { useState } from "react";
import { initialData as emptyInitialData } from "./constant";
import { Loading } from "../../components/Loading";
import { FaPencilAlt } from "react-icons/fa";
import { ProductForm } from "./types";
import { useProductWs } from "../../hooks";
import { FlexAccountInterest } from "./flexAccountInterest";
import { useFlexAccountInterest } from "../../hooks/useFlexAccountInterest";
const Admin = () => {
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<ProductForm>(emptyInitialData);
  const { loading: loadingProducts, products } = useProductWs();
  const handleClose = () => {
    setShowPopUp(false);
  };
  const {
    data: flexAccountInterest,
    loading: loadingInterest,
    updateInterest,
  } = useFlexAccountInterest();
  const handleCreateProduct = () => {
    setInitialData(emptyInitialData);
    setShowPopUp(true);
  };

  const handleEdit = (product: ProductForm) => {
    setInitialData(product);
    setShowPopUp(true);
  };

  if (loadingProducts || loadingInterest) {
    <Container className=" container">
      <Loading />
    </Container>;
  }

  return (
    <>
      <Container className=" container">
        {!!flexAccountInterest && (
          <div style={{ width: "100%" }}>
            <FlexAccountInterest
              initialData={{ interest: flexAccountInterest }}
              updateInterest={updateInterest}
            />
          </div>
        )}
        {!loadingProducts && (
          <div style={{ width: "100%" }}>
            <TableWrapper>
              <StyledTable striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Term</th>
                    <th>Interest</th>
                    <th>Interest type</th>
                    <th>Description</th>
                    <th>Allocation Term</th>
                    <th>Promo</th>
                  </tr>
                </thead>
                <tbody>
                  {!!products.length &&
                    products?.map(
                      ({
                        name,
                        interest,
                        interestType,
                        description,
                        allocationTerm,
                        active,
                        promo,
                        term,
                        id,
                      }) => {
                        const utcDate =
                          promo?.endDate && new Date(promo?.endDate);
                        const formatDate =
                          utcDate &&
                          new Date(
                            utcDate.getTime() -
                              utcDate.getTimezoneOffset() * 60000
                          );
                        const productForm: ProductForm = {
                          name,
                          term: JSON.stringify(term),
                          interest: JSON.stringify(interest),
                          interestType,
                          description,
                          allocationTerm: JSON.stringify(allocationTerm),
                          active: JSON.stringify(active),
                          endDate: formatDate
                            ? formatDate.toISOString().slice(0, 16)
                            : "",
                          newPercentage:
                            JSON.stringify(promo?.newPercentage) || "",
                          id,
                        };
                        return (
                          <tr
                            key={id}
                            data-toggle="collapse"
                            data-target=".multi-collapse1"
                            aria-controls="multiCollapseExample1"
                            style={{ opacity: `${active ? "1" : "0.2"}` }}
                          >
                            <td>
                              <button
                                className="btn"
                                onClick={() => handleEdit(productForm)}
                              >
                                <FaPencilAlt />
                              </button>
                            </td>
                            <td>{name}</td>
                            <td>{term}</td>
                            <td>{interest}</td>
                            <td>{interestType}</td>
                            <td>{description}</td>
                            <td>{allocationTerm}</td>
                            <td>
                              {!!promo && (
                                <PromoSection>
                                  <div>
                                    <strong>End Date: </strong>{" "}
                                    {formatDate &&
                                      formatDate
                                        .toISOString()
                                        .slice(0, 16)
                                        .replace("T", " ")}
                                  </div>
                                  <div>
                                    <strong>New interest:</strong>
                                    {" " + promo?.newPercentage}
                                  </div>
                                </PromoSection>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </StyledTable>
            </TableWrapper>
            <TopBar>
              <GreenButton type="button" onClick={handleCreateProduct}>
                Create Product
              </GreenButton>
            </TopBar>
          </div>
        )}
      </Container>
      <ModifyProductModal
        show={showPopUp}
        handleClose={handleClose}
        initialData={initialData}
      />
    </>
  );
};

export default Admin;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap: 50px;
`;

const TopBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  overflow-x: auto;
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const StyledTable = styled(Table)`
  text-align: center;
  min-width: 1000px;
  vertical-align: middle;
`;

const PromoSection = styled.div`
  display: "flex";
  flex-direction: "column";
  width: "100%";
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
`;
