import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import styled, { css, keyframes } from "styled-components";
import { InterestsPerMonth } from "../types";

interface Props {
  bars: {
    height: number;
    color: string;
    interestsPerMonth?: InterestsPerMonth<string>;
  }[];
}

const GraphicChart = ({ bars }: Props) => {
  return (
    <GraphicWrapper>
      {bars.map(({ height, color, interestsPerMonth }, i) => (
        <div key={i}>
          {interestsPerMonth ? (
            <>
              <InterestBarWrapper>
                <OverlayTrigger
                  placement="right-start"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(injected) =>
                    renderTooltip({
                      amount: interestsPerMonth.totalInterest,
                      injected,
                    })
                  }
                >
                  <Bar index={i} height={height} color={color} />
                </OverlayTrigger>
              </InterestBarWrapper>
              <MonthText>{interestsPerMonth.monthName}</MonthText>
            </>
          ) : (
            <BarWrapper>
              <Bar index={i} height={height} color={color} />
            </BarWrapper>
          )}
        </div>
      ))}
    </GraphicWrapper>
  );
};

const MonthText = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.BANNER_COLOR};
  font-size: 14px;
  margin-top: 4px;
  font-weight: 700;
`;

const grow = (height: number) => keyframes`
  0% {
    height: 0px;
  }
  100% {
    height: ${height}%;
  }
`;

const GraphicWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
  @media (max-width: 380px) {
    gap: 6px;
  }
  @media (max-width: 330px) {
    gap: 4px;
  }
`;

const InterestBarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  gap: 0;
  height: 130px;
`;

const BarWrapper = styled(InterestBarWrapper)`
  height: 150px;
  @media (max-width: 500px) {
    height: 140px;
  }
  @media (max-width: 400px) {
    height: 130px;
  }
  @media (max-width: 380px) {
    height: 120px;
  }
  @media (max-width: 350px) {
    height: 110px;
  }
  @media (max-width: 330px) {
    height: 100px;
  }
  @media (max-width: 280px) {
    height: 80px;
  }
`;

interface BarProps {
  height: number;
  color: string;
  index: number;
}

const Bar = styled.div<BarProps>`
  width: 50px;
  border-radius: 6px;
  height: ${(props) => `${props.height}px`};
  background-color: ${(props) => props.color};
  animation: ${({ height, index }) =>
    css`
      ${grow(height)} ${(index + 2) / 4}s ease-in-out
    `};
  animation-fill-mode: forwards;
  @media (max-width: 900px) {
    width: 48px;
  }
  @media (max-width: 800px) {
    width: 46px;
  }
  @media (max-width: 700px) {
    width: 44px;
  }
  @media (max-width: 600px) {
    width: 42px;
  }
  @media (max-width: 380px) {
    width: 40px;
  }
  @media (max-width: 350px) {
    width: 38px;
  }
  @media (max-width: 330px) {
    width: 35px;
  }
  @media (max-width: 300px) {
    width: 32px;
  }
  @media (max-width: 280px) {
    width: 28px;
  }
`;

const HoverBox = styled(Tooltip)`
  font-size: 14px;
  font-weight: 700;
  .tooltip-inner {
    color: ${({ theme }) => theme.PRIMARY_COLOR} !important;
    background-color: ${({ theme }) => theme.NAVBAR_BACKGROUND} !important;
  }
  --bs-tooltip-bg: ${({ theme }) => theme.NAVBAR_BACKGROUND} !important;
`;

const renderTooltip: ({
  injected,
  amount,
}: {
  amount: string | number;
  injected: OverlayInjectedProps;
}) => React.ReactNode = ({ amount, injected }) => (
  <HoverBox id="button-tooltip" {...injected}>
    {amount}
  </HoverBox>
);

export default GraphicChart;
