import styled from "styled-components";
import NorhartIcon from "../assets/images/norhart-logo-dark.svg";
import { FunctionComponent, ReactNode } from "react";
import { Link } from "react-router-dom";
import { AuthNavbar } from "./navbar/AuthNavbar";
import GraphicChart from "./GraphicChart";
import { GRAPHIC_COLORS } from "../styles";

interface Props {
  children?: ReactNode;
  emojiImg?: string;
  pageName?: string;
}

export const DarkWrapperAuth: FunctionComponent<Props> = ({
  children,
  emojiImg = NorhartIcon,
  pageName,
}) => {
  return (
    <>
      <AuthNavbar pageName={pageName} darkMode />
      <Container>
        <div className="container-fluid">
          <div className="container" style={{ textAlign: "center" }}>
            <Link to="/signin">
              <NorhartImage src={emojiImg} alt="norhart-logo" />
            </Link>
          </div>
        </div>
        <MiddleBackground className="container-fluid text-center">
          {/* <img src={emojiImg} alt="emojis" /> */}
          <InvestTitle>invest</InvestTitle>
          <InvestSubtitle>simply grow your wealth</InvestSubtitle>
          <GraphicChart bars={GRAPHIC_COLORS} />
        </MiddleBackground>

        <BottomContainer className="container-fluid">
          <InputContainer className="container">
            <InputCenter>
              <FormWrapper>{children}</FormWrapper>
              {pageName === "login" && (
                <WrapperBotText>
                  <StyledLink to="/forgot-password/send-email">
                    Forgot password
                  </StyledLink>
                </WrapperBotText>
              )}
            </InputCenter>
          </InputContainer>
        </BottomContainer>
        <BottomContainer>
          <InputContainer>
            <InputCenter>
              <div>
                <HorizontalBar />
                <div>
                  <StyledLinkBottom
                    as={"a"}
                    href="https://cdn.norhart.com/2023-06-30_Terms_of_Service.pdf"
                    target="_blank"
                  >
                    Terms & Conditions
                  </StyledLinkBottom>
                  <VerticalBar>|</VerticalBar>
                  <StyledLinkBottom
                    as={"a"}
                    href="/legal/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </StyledLinkBottom>
                </div>
                <NorhartWrapper>
                  <StyledLinkBottom as={"span"}>
                    © Norhart Invest LLC a{" "}
                    <a
                      style={{ color: "white", fontWeight: "600" }}
                      href="https://www.norhart.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Norhart
                    </a>{" "}
                    company
                  </StyledLinkBottom>
                </NorhartWrapper>
              </div>
            </InputCenter>
          </InputContainer>
        </BottomContainer>
      </Container>
    </>
  );
};

const InvestTitle = styled.h1`
  font-size: 6.5rem;
  font-weight: 800;
  margin-bottom: -5px;
  color: white;
  @media (max-width: 1200px) {
    font-size: 6rem;
  }
  @media (max-width: 800px) {
    font-size: 5.5rem;
  }
  @media (max-width: 500px) {
    font-size: 4.5rem;
  }
  @media (max-width: 400px) {
    font-size: 4rem;
  }
  @media (max-width: 380px) {
    font-size: 3rem;
  }
  @media (max-width: 330px) {
    font-size: 2.5rem;
  }
`;

const InvestSubtitle = styled.h2`
  font-size: 2.5rem;
  font-family: sans-serif;
  color: #b9b9b9;
  margin-bottom: 45px;
  @media (max-width: 1200px) {
    font-size: 2.3rem;
    margin-bottom: 40px;
  }
  @media (max-width: 800px) {
    font-size: 2rem;
    margin-bottom: 35px;
  }
  @media (max-width: 500px) {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
  @media (max-width: 400px) {
    font-size: 1.6rem;
    margin-bottom: 25px;
  }
  @media (max-width: 380px) {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  @media (max-width: 330px) {
    font-size: 1rem;
    margin-bottom: 15px;
  }
  @media (max-height: 500px) {
    margin-bottom: 10px;
  }
`;

const Container = styled.div`
  background: linear-gradient(#000000, #323232) !important;
  min-height: calc(100vh - 50px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  padding-top: 5vh;
`;

const FormWrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 100%;
`;

const NorhartImage = styled.img`
  height: 80px;
  width: 80px;
  @media (max-width: 500px) {
    height: 70px;
    width: 70px;
  }
  @media (max-width: 330px) {
    height: 60px;
    width: 60px;
  }
  @media (max-width: 280px) {
    height: 50px;
    width: 50px;
  }
`;

const MiddleBackground = styled.div`
  padding-top: 20px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 1200px) {
    width: 75%;
  }
  @media (max-width: 800px) {
    width: 90%;
  }
  @media (max-width: 500px) {
    width: 100%;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  @media (max-width: 400px) {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  @media (max-width: 330px) {
    padding-bottom: 0px;
    padding-top: 0px;
  }
`;

const WrapperBotText = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  @media (max-width: 330px) {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
`;

const VerticalBar = styled.span`
  padding-left: 5px;
  padding-right: 5px;
  color: #ffffff;
`;

const HorizontalBar = styled.hr`
  border-color: #ffffff;
  opacity: 0.2;
  margin-top: 0px;
  margin-bottom: 10px;
  border-top: 3px solid rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(255, 255, 255, 1);
`;

const BottomContainer = styled.div`
  margin-top: 20px;
  padding: 0px 20px;
  @media (max-width: 280px) {
    margin-top: 15px;
  }
`;

const InputContainer = styled.div`
  text-align: center;
  max-width: 600px;
`;

const InputCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: auto;
`;
const StyledLink = styled(Link)`
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  text-decoration: none;
`;
const StyledLinkBottom = styled(StyledLink)`
  font-size: 13px;
`;
const NorhartWrapper = styled.div`
  margin-top: "5px";
`;
