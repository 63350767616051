import { useCallback } from "react";
import { CacheKeys } from "../types";
import { FlexAccount } from "../utils";
import { useFetch } from "./useFetch";

export const useFlexAccount = () => {
  const fetchFunction = useCallback(async () => {
    const response = await FlexAccount.getData();
    return response;
  }, []);

  const { data, loading, refetchData } = useFetch({
    cacheKey: CacheKeys.flexAccount,
    fetchFunction,
  });
  return { data, loading, refetchData };
};
