import { Dropdown } from "react-bootstrap";
import { SmallDot } from "../../../styles/list";
import { BannerBoxOptions } from "../../../types";
import { booleanToString } from "../../../utils";
import {
  Toggle,
  StyledMenu,
  CustomDropdownItem,
  ItemWrapper,
  Button,
  ToggleButton,
} from "../styles/bannerBox";

interface DropdownProps {
  normalizedButtonText: string;
  handleChange: (v: BannerBoxOptions) => void;
  displayValue: BannerBoxOptions;
  mobile?: boolean;
}

const BannerBoxDropdown = ({
  normalizedButtonText,
  handleChange,
  displayValue,
  mobile,
}: DropdownProps) => {
  return (
    <Dropdown>
      <Toggle bsPrefix="text-decoration-none">
        {mobile ? <ToggleButton /> : <Button>{normalizedButtonText}</Button>}
      </Toggle>
      <StyledMenu>
        <CustomDropdownItem
          onClick={() => handleChange(BannerBoxOptions.active)}
        >
          <ItemWrapper>
            <SmallDot
              displaydot={booleanToString(
                displayValue === BannerBoxOptions.active
              )}
            />
            Active
          </ItemWrapper>
        </CustomDropdownItem>
        <CustomDropdownItem
          onClick={() => handleChange(BannerBoxOptions.matured)}
        >
          <ItemWrapper>
            <SmallDot
              displaydot={booleanToString(
                displayValue === BannerBoxOptions.matured
              )}
            />
            Matured
          </ItemWrapper>
        </CustomDropdownItem>
        <CustomDropdownItem onClick={() => handleChange(BannerBoxOptions.all)}>
          <ItemWrapper>
            <SmallDot
              displaydot={booleanToString(
                displayValue === BannerBoxOptions.all
              )}
            />
            All
          </ItemWrapper>
        </CustomDropdownItem>
      </StyledMenu>
    </Dropdown>
  );
};

export default BannerBoxDropdown;
