import { CustomError } from "../types";
import { CreateCounterpartyOutput } from "../types/counterparties";

const create = async (identityVerificationId: string) => {
  const path = "/counterparties";
  const response = await fetch(path, {
    method: "POST",
    body: JSON.stringify({
      identityVerificationId,
    }),
  });
  const res = await response.json();
  if (!response.ok) {
    CustomError.throw(res, res?.error || res?.message);
  }
  return res as CreateCounterpartyOutput;
};

export const Counterparty = {
  create,
};
