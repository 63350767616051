import lightArrow from "../assets/icons/light-arrow.svg";
import darkArrow from "../assets/icons/dark-arrow.svg";
import sunIcon from "../assets/icons/sun-light-mode.svg";
import moonIcon from "../assets/icons/moon-dark-mode.svg";

export const Colors = {
  GREEN: "#82cc73",
  YELLOW: "#F1A33C",
  RED: "#DA5946",
  SKY_BLUE: "#6AC5DD",
  BLUE: "#3A82F7",
  VIOLET: "#B260EA",
};

export const DarkColorPalette = {
  BACKGROUND_COLOR: "rgb(28, 28, 30)",
  PRIMARY_COLOR: "white",
  SECONDARY_COLOR: "#000000",
  BANNER_BACKGROUND: "rgb(44, 44, 46)",
  BANNER_COLOR: "rgb(242, 242, 247)",
  NORHART_LOGO_BACKGROUND: "#000000",
  NAVBAR_BACKGROUND: "#48484A",
  LIST_BORDER_COLOR: "transparent",
  NORHART_CIRCLE_LOGO: "rgb(44, 44, 46)",
  INPUT_BACKGROUND: "#48484a",
  INPUT_COLOR: "white",
  INPUT_BORDER_COLOR: "#202021",
  DISABLED_INPUT_BACKGROUND_COLOR: "rgb(44,44,46)",
  SELECT_ARROW: lightArrow,
  DROPDOWN_BORDER: "rgb(99,99,102)",
  ICON: sunIcon,
  DISABLED_BUTTON: "#48484a",
  ALERT_BACKGROUND: "rgb(99,99,102)",
  INPUT_COLOR_SCHEME: "dark",
  ...Colors,
};

export const LightColorPalette = {
  NAVBAR_BACKGROUND: "#E5E5EA",
  BACKGROUND_COLOR: "#ffffff",
  PRIMARY_COLOR: "black",
  SECONDARY_COLOR: "white",
  BANNER_BACKGROUND: "#f5f5f7",
  BANNER_COLOR: "#5d5c5c",
  NORHART_LOGO_BACKGROUND: "#1c1c1c",
  LIST_BORDER_COLOR: "transparent",
  NORHART_CIRCLE_LOGO: "#000000",
  INPUT_BACKGROUND: "#fff",
  INPUT_COLOR: "#000000",
  INPUT_BORDER_COLOR: "#dee2e6",
  DISABLED_INPUT_BACKGROUND_COLOR: "#eaecef",
  SELECT_ARROW: darkArrow,
  DROPDOWN_BORDER: "rgb(209,209,214)",
  ICON: moonIcon,
  DISABLED_BUTTON: "#5d5c5c",
  ALERT_BACKGROUND: "rgb(209,209,214)",
  INPUT_COLOR_SCHEME: "light",
  ...Colors,
};
