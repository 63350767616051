import { FormEvent } from "react";
import { Form as StyledForm } from "../../../styles";
import { Alert } from "react-bootstrap";
import { FormInput } from "../../../components/forms/formsComponents/FormInput";
import { useForm } from "../../../hooks/useForm";
import { AuthButton } from "../../../components/AuthButton";
import { FormSelect } from "../../forms/formsComponents/FormSelect";
import { UserLead } from "../../../utils/user-lead";
import { ResponseStatus } from "../../../types/ResponseStatus";
import { notifications } from "../../../utils/notifications";
import {
  handlePhoneChange,
  handlePhoneKeyDown,
  normalizeString,
} from "../../../utils";

interface Props {
  darkMode?: boolean;
}

const ApplyForm = ({ darkMode }: Props) => {
  const {
    fields,
    errors,
    handleErrors,
    handleFields,
    handleTouch,
    submitLoading,
    onSubmit,
    setFields,
    submitWithEnter: { ref, handleKeyUp },
  } = useForm({
    initialFields: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      investAmount: "",
    },
    initialErrors: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      investAmount: "",
      form: "",
    },
    initialTouch: {
      firstName: false,
      lastName: false,
      email: false,
      phoneNumber: false,
      investAmount: false,
    },
    customErrors: { DefaultError: "" },
  });

  const onSubmitAuth =
    (
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      investAmount: string
    ) =>
    async () => {
      const name = normalizeString(firstName);
      try {
        await UserLead.sendEmail({
          firstName: name,
          lastName: normalizeString(lastName),
          email,
          phoneNumber,
          investAmount,
        });
        notifications({
          status: ResponseStatus.SUCCESS,
          successMessage: `Success! ${name}, Your information was successfully sent.`,
        });
        setFields({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          investAmount: "",
        });
      } catch (e) {
        notifications({
          status: ResponseStatus.ERROR,
          errorMessage:
            "Oops something fail trying to send your information, please try again later",
        });
      }
    };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    onSubmit(
      e,
      onSubmitAuth(
        fields.firstName,
        fields.lastName,
        fields.email,
        fields.phoneNumber,
        fields.investAmount
      )
    );
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit} ref={ref} onKeyUp={handleKeyUp}>
        {!!errors.form && <Alert variant="danger">{errors.form}</Alert>}
        <div className="form-group">
          <FormInput
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            required
            value={fields.firstName}
            name="firstName"
            placeholder="First Name"
            error={errors.firstName}
            errorColor={darkMode ? "black" : "white"}
          />
        </div>
        <div>
          <FormInput
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            required
            name="lastName"
            value={fields.lastName}
            placeholder="Last Name"
            type="lastName"
            error={errors.lastName}
            errorColor={darkMode ? "black" : "white"}
          />
        </div>
        <div className="form-group">
          <FormInput
            style={{ minHeight: "0 !important" }}
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            required
            name="email"
            placeholder="Email"
            value={fields.email}
            error={errors.email}
            errorColor={darkMode ? "black" : "white"}
          />
        </div>
        <div className="form-group">
          <FormInput
            handleErrors={handleErrors}
            handleFields={(phoneNumber) =>
              handlePhoneChange(phoneNumber, handleFields)
            }
            handleTouch={handleTouch}
            onKeyDown={handlePhoneKeyDown}
            required
            value={fields.phoneNumber}
            name="phoneNumber"
            placeholder="Phone Number *"
            error={errors.phoneNumber}
          />
        </div>
        <div className="form-group">
          <FormSelect
            style={{ minHeight: "0 !important" }}
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            required
            name="investAmount"
            placeHolder="Invest Amount"
            value={fields.investAmount}
            error={errors.investAmount}
            options={[
              ["$2,500 -$5,000", "$2,500 -$5,000"],
              ["$5,000-$25,000", "$5,000-$25,000"],
              ["$25,000-$50,000", "$25,000-$50,000"],
              ["$50,000+", "$50,000+"],
            ]}
          />
        </div>
        <AuthButton
          loading={!!submitLoading}
          darkMode={darkMode}
          padlock={!submitLoading}
          style={{ width: "80px" }}
        >
          Submit
        </AuthButton>
      </StyledForm>
    </>
  );
};

export default ApplyForm;
