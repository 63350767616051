import { useMemo, useState } from "react";
import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import { PlaidBankAccount } from "../utils";
import { PlaidEvents } from "../types";
import { notifications } from "../utils/notifications";
import { ResponseStatus } from "../types/ResponseStatus";

interface Props {
  linkToken: string | null;
  id?: string;
  handlePlaidOnSuccess?: () => void;
  handlePlaidOnExit?: () => void;
  handleDelete?: () => Promise<void>;
  turnOffNotifications?: () => void;
  isOAuthRedirect?: boolean;
}

export const useVerifyPlaidBankAccount = ({
  linkToken,
  id,
  handlePlaidOnSuccess,
  handlePlaidOnExit,
  handleDelete,
  turnOffNotifications,
  isOAuthRedirect,
}: Props) => {
  const { onSuccess, onExit, onEvent } = PlaidBankAccount;

  const onLocalEvent = async (
    event: string,
    metadata: PlaidLinkOnEventMetadata
  ) => {
    await onEvent(event, metadata);
    // If the user do too many verification attempts trying to verify a manually added account
    if (
      metadata.error_code === PlaidEvents.TOO_MANY_VERIFICATION_ATTEMPTS &&
      id
    ) {
      try {
        if (handleDelete) {
          await handleDelete();
          return;
        }
        await PlaidBankAccount.deleteAccount(id);
        if (turnOffNotifications) turnOffNotifications();
        notifications({
          status: ResponseStatus.ERROR,
          errorMessage: metadata.error_message!,
        });
        return;
      } catch (e) {
        console.log(e);
      }
    }
  };

  const [waitingBankAccounts, setWaitingBankAccounts] =
    useState<boolean>(false);

  const localOnExit = async (
    err: PlaidLinkError | null,
    metadata: PlaidLinkOnExitMetadata
  ) => {
    await onExit(err, metadata);
    if (handlePlaidOnExit) {
      handlePlaidOnExit();
    }
  };
  const localOnSuccess = async (
    public_token: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    try {
      setWaitingBankAccounts(true);
      const bankAccounts = await onSuccess(public_token, metadata);
      if (bankAccounts) PlaidBankAccount.addToLocalStorage(bankAccounts);
      if (handlePlaidOnSuccess) {
        handlePlaidOnSuccess();
      }
    } catch (e) {
      console.error(e);
    }
    setWaitingBankAccounts(false);
  };

  const config: PlaidLinkOptions = {
    token: linkToken,
    onSuccess: localOnSuccess,
    onExit: localOnExit,
    onEvent: onLocalEvent,
  };

  if (isOAuthRedirect) {
    config.receivedRedirectUri = window.location.href;
  }

  const { open, ready } = usePlaidLink(config);

  const buttonText = useMemo(() => {
    if (ready && !waitingBankAccounts) return "Verify";
    if (waitingBankAccounts) return "Verifying";
    return "Loading";
  }, [ready, waitingBankAccounts]);

  return {
    open,
    ready,
    waitingBankAccounts,
    buttonText,
  };
};
