import CoffeeTable from "../../../assets/images/landing/gallery/coffee-table-design-idea-aroma-candles-and-dried-lavender-bouquet-on-a-metal-tray-sofa-with.jpg";
import CuteDog from "../../../assets/images/landing/gallery/cozy-morning-of-a-cute-dog.jpg";
import ManSmartphone from "../../../assets/images/landing/gallery/man-using-smartphone-at-home.jpg";
import OakdaleLobby from "../../../assets/images/landing/gallery/norhart-oakdale-lobby.png";
import YoungWoman from "../../../assets/images/landing/gallery/shot-of-a-sporty-young-woman-stretching-her-legs-while-exercising-at-home.jpg";
import OakdalePool from "../../../assets/images/landing/gallery/norhart-oakdale-pool.png";

const GallerySection = () => {
  return (
    <div
      className="container-fluid text-center hero p-0 m-0"
      style={{
        color: "#FFF",
        backgroundColor: "#FFF",
      }}
    >
      <div className="jumbotron text-center">
        <div className="w-100">
          <div>
            <img
              src={OakdaleLobby}
              alt=""
              loading="eager"
              className="img-fluid w-50"
              style={{ paddingRight: "2px" }}
            />

            <img
              src={OakdalePool}
              alt=""
              loading="eager"
              className="img-fluid w-50"
              style={{ paddingLeft: "2px" }}
            />
          </div>

          <div>
            <img
              src={CoffeeTable}
              alt=""
              loading="eager"
              className="img-fluid w-25"
              style={{
                paddingBottom: "4px",
                paddingTop: "4px",
                paddingRight: "2px",
              }}
            />

            <img
              src={ManSmartphone}
              alt=""
              loading="eager"
              className="img-fluid w-25"
              style={{
                paddingBottom: "4px",
                paddingTop: "4px",
                paddingRight: "2px",
              }}
            />

            <img
              src={YoungWoman}
              alt=""
              loading="eager"
              className="img-fluid w-25"
              style={{
                paddingBottom: "4px",
                paddingTop: "4px",
                paddingLeft: "2px",
              }}
            />

            <img
              src={CuteDog}
              alt=""
              loading="eager"
              className="img-fluid w-25"
              style={{
                paddingBottom: "4px",
                paddingTop: "4px",
                paddingLeft: "2px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection;
