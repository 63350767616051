import HeroInvestRightSection from "../components/HeroInvestRightSection";
import SecurityPingdom from "../../../assets/images/landing/norhart-security-pingdom-uptime-report.png";

const ReportingSection = () => {
  return (
    <HeroInvestRightSection
      backgroundColorOne="#e1e1e1"
      backgroundColorTwo="#e1e1e1"
      fontTextColor="#333333"
      titleTopLeft="Security Reports"
      badgeTitle="Transparency"
      title="Security Posture"
      subtitle="Verify our security report, SSL report and our server uptime reports."
      imageTitle="Norhart Website Pingdom Uptime Report"
      image={SecurityPingdom}
      buttonName="Security"
      buttonUrl="https://securityheaders.com/?q=https%3A%2F%2Finvest.norhart.com&followRedirects=on"
      buttonName2="Uptime"
      buttonUrl2="http://stats.pingdom.com/i6owumclg489"
      buttonName3="SSL"
      buttonUrl3="https://www.ssllabs.com/ssltest/analyze.html?d=invest.norhart.com&s=65.8.161.56&latest"
      colSizeOne={4}
      colSizeTwo={8}
      bottomMargin={0}
      asoEffect="fade"
    />
  );
};

export default ReportingSection;
