import styled from "styled-components";
import { FunctionComponent } from "react";
import { AuthNavbar } from "../../components/navbar/AuthNavbar";
import FAQInvestSection from "../../components/landing/sections/FAQInvestSection";
import InvestLegalSection from "../../components/landing/sections/InvestLegalSection";
import InvestWithUsSection from "../../components/landing/sections/InvestWithUsSection";
import InvestOfferingsSection from "../../components/landing/sections/InvestOfferingsSection";
import BenefitsForYouSection from "../../components/landing/sections/BenefitsForYouSection";
import InvestProposeSection from "../../components/landing/sections/InvestPorposeSection";
import GallerySection from "../../components/landing/sections/GallerySection";
import ConceptSignUp from "../../components/landing/sections/ConceptSignUp";
import InvestComfortSection from "../../components/landing/sections/InvestComfortSection";
import EarthVideoSection from "../../components/landing/sections/EarthVideoSection";
import WelcomeInvestCalculatorSection from "../../components/landing/sections/WelcomeInvestCalculatorSection";
import InvestRateSection from "../../components/landing/sections/InvestRateSection";
import WelcomeVideoSection from "../../components/landing/sections/WelcomeVideoSection";
import InvestStatsSection from "../../components/landing/sections/InvestStatsSection";
import StaffSection from "../../components/landing/sections/StaffSection";
import LuxuryPenthouse from "../../components/landing/sections/LuxuryPenthouse";
import MainBanner from "../../components/landing/sections/MainBanner";
import GrowYourCash from "../../components/landing/sections/GrowYourCash";
import IRAInvesting from "../../components/landing/sections/IRAInvesting";
import LuxuryBedroom from "../../components/landing/sections/LuxuryBedroom";
import SecuritySection from "../../components/landing/sections/SecuritySection";
import LandingFooter from "../../components/landing/sections/LandingFooter";
import WhyInvestVideoSection from "../../components/landing/sections/WhyInvestVideoSection";
import TrustpilotWidget from "../../components/TrustpilotWidget";

export const Welcome: FunctionComponent = () => {
  return (
    <Container>
      <AuthNavbar darkMode />
      <div style={{ maxWidth: "100vw" }}>
        <MainBanner />
        <ConceptSignUp />
        <WelcomeInvestCalculatorSection />
        <InvestRateSection />
        <GrowYourCash />
        <InvestWithUsSection />
        <LuxuryPenthouse />
        <WelcomeVideoSection />
        <InvestStatsSection />
        <StaffSection />
        <WhyInvestVideoSection />
        <InvestOfferingsSection />
        <BenefitsForYouSection />
        <IRAInvesting />
        <LuxuryBedroom />
        <InvestProposeSection />
        <GallerySection />
        <InvestComfortSection />
        <EarthVideoSection />
        <FAQInvestSection />
        <SecuritySection />
        <InvestLegalSection fontColor="#111111" />
      </div>
      <LandingFooter />
      <TrustpilotWidget />
    </Container>
  );
};

const Container = styled.div`
  background: linear-gradient(#2f2f2f, #121212) !important;
  max-height: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
`;
