import styled from "styled-components";
import { PrivacyPolicy } from "../../../components/PrivacyPolicy";
import { ContentHeroTitle } from "../../../styles/adds";
import { AuthNavbar } from "../../../components/navbar/AuthNavbar";

const page = () => {
  return (
    <>
      <AuthNavbar darkMode />
      <Container className="container-fluid">
        <ContentHeroTitle>Privacy Policy</ContentHeroTitle>
        <PrivacyPolicy ispublic />
      </Container>
    </>
  );
};

const Container = styled.div`
  background: linear-gradient(#000000, #323232) !important;
  min-height: calc(100vh - 50px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  padding-top: 5vh;
`;

export default page;
