import { NorhartWrapper } from "../../components/NorhartWrapper";
import FaqAccordion from "../../components/landing/components/FaqAccordition";

const Faq = () => {
  return (
    <NorhartWrapper title="Norhart Invest FAQ">
      <FaqAccordion />
    </NorhartWrapper>
  );
};

export default Faq;
