import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  company: string;
  title: string;
  subtitle: string;
  buttonName: string;
  buttonUrl: string;
}

/** const */
const InvestRibbionSignup: React.FC<Props> = (props) => {
  return (
    <section className="d-flex align-items-center">
      <div
        className="container-fluid p-0 m-0"
        style={{ background: "#FFFFFF", color: "#111111" }}
      >
        <div className="row mx-0">
          <InvestCtaLeft className="col invest-cta-left">
            <div>{props.company}</div>
            <strong>{props.title}</strong>
            <div className="mb-2"> </div>
            <div
              className="mt-1 mb-3"
              style={{
                backgroundColor: "#51976f",
                width: "50%",
                height: "4px",
              }}
            ></div>

            <Link to={props.buttonUrl}>
              <button type="button" className="btn btn-outline-dark">
                {props.buttonName}
              </button>
            </Link>
          </InvestCtaLeft>
          <InvestCtaRight className="col invest-cta-right">
            {props.subtitle}
          </InvestCtaRight>
        </div>
      </div>
    </section>
  );
};

const InvestCtaLeft = styled.div`
  font-size: 20px;
  text-align: top;
  padding: 50px;
  min-width: 180px;
  padding-bottom: 20px;
  @media (max-width: 550px) {
    margin-bottom: 5px;
  }
  @media (max-width: 320px) {
    padding: 20px;
    font-size: 18px;
  }
`;

const InvestCtaRight = styled.div`
  font-size: 18px;
  text-align: top;
  padding: 50px;
  /* min-width: 180px; */
  @media (max-width: 550px) {
    margin-top: 10px;
  }
  @media (max-width: 320px) {
    padding: 20px;
    font-size: 15px;
    margin-top: 0px;
  }
`;

export default InvestRibbionSignup;
