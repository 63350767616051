import { createGlobalStyle } from "styled-components";
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.BACKGROUND_COLOR};
    color: ${({ theme }) => theme.PRIMARY_COLOR};
  --bs-form-invalid-border-color: ${({ theme }) => theme.INPUT_COLOR};
  --bs-form-invalid-color: ${({ theme }) => theme.INPUT_COLOR};
  --chat-widget-bubble-color: ${({ theme }) =>
    theme.GREEN} !important; //Chat bubble color
  }

  :not(body) {
    --chat-widget-primary-color: unset;
    --chat-widget-active-color: unset;
    --chat-widget-bubble-color: unset;
  }
  `;
