import styled from "styled-components";
import {
  AlertTypes,
  BankAccountsPendingVerification,
  CustomPlaidAccountDetails,
} from "../types/attention";
import { Attention } from "../utils/attention";
import { useMemo } from "react";

interface MicroDepositNotificationProps {
  bankAccountsPendingVerification: BankAccountsPendingVerification;
}

const BankPendingAlerts = ({
  bankAccountsPendingVerification,
}: MicroDepositNotificationProps) => {
  const mapped = useMemo(
    () => Attention.mapping(bankAccountsPendingVerification),
    [bankAccountsPendingVerification]
  );

  return (
    <MicroDepositsWrapper>
      {mapped.map((value) => {
        const [[alertName, dataArray]] = Object.entries(value);
        const AlertComponent =
          Attention.alertComponentMap[alertName as AlertTypes];
        return dataArray.map((data) => {
          if (!AlertComponent || !data?.accounts?.length) return null;

          const { mask, institutionName, name, plaidAccountId } =
            data.accounts[0];
          return (
            <AlertComponent
              key={plaidAccountId}
              bankName={institutionName || name}
              last4={mask}
              token={(data as CustomPlaidAccountDetails)?.linkToken}
              id={plaidAccountId}
            />
          );
        });
      })}
    </MicroDepositsWrapper>
  );
};

const MicroDepositsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  @media (max-width: 400px) {
    margin-top: 10px;
  }
`;

export default BankPendingAlerts;
