import { CurrentBalance, CustomError } from "../types";

const getData = async () => {
  const path = "/total-balance";
  const response = await fetch(path, {
    method: "GET",
  });
  const res = await response.json();
  if (response.ok) {
    return res as CurrentBalance;
  }
  CustomError.throw(res, res?.error || res?.message);
};

export const Balance = {
  getData,
};
