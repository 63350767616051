import styled, { keyframes } from "styled-components";
import { GreenButton } from "../../../styles";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  @media (max-width: 600px) {
    gap: 30px;
  }
  @media (max-width: 500px) {
    gap: 22px;
  }
  @media (max-width: 400px) {
    gap: 15px;
  }
  @media (max-width: 350px) {
    gap: 12px;
  }
`;

export const InvestDataWrapper = styled(Wrapper)`
  @media (max-width: 767px) {
    display: none;
  }
`;

const scaleInOut = keyframes`
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }
`;

export const InvestButton = styled(GreenButton)`
  animation: ${scaleInOut} 2s infinite;
  @media (max-width: 767px) {
    animation: none;
  }
`;

export const BannerWrapper = styled.div`
  background-color: ${({ theme }) => theme.BANNER_BACKGROUND};
  color: ${({ theme }) => theme.BANNER_COLOR};
  border-radius: 8px;
  border: transparent;
  padding: 20px;
  width: 100%;
`;
