import { FaRegCreditCard } from "react-icons/fa";
import styled from "styled-components";
// import { CloseWrapper } from "../styles/closeButton";
// import CloseGrey from "../assets/images/close-grey.png";
import { Alert, Spinner } from "react-bootstrap";
import { Loading } from "./Loading";

interface Props {
  onClose?: () => void;
  needAlert?: boolean;
  needLoading?: boolean;
  ready: boolean;
  waitingBankAccounts: boolean;
  handleClick: () => void;
}

const BankBanner = ({
  onClose,
  needAlert,
  needLoading,
  ready,
  waitingBankAccounts,
  handleClick,
}: Props) => {
  // const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
  //   e.stopPropagation();
  //   if (onClose) onClose();
  // };

  if (waitingBankAccounts && needLoading) return <Loading />;

  return (
    <div>
      {needAlert && (
        <Alert variant="danger">
          You must have a bank account to invest, please click in the below
          button to add one
        </Alert>
      )}
      <BannerWrapper
        onClick={handleClick}
        className="position-relative"
        loading={(!ready || waitingBankAccounts).toString()}
      >
        {/* {!!onClose && (
          <CloseWrapper style={{ position: "absolute" }}>
            <CloseButton
              src={CloseGrey}
              alt=""
              className="object-fit-cover p-0 m-0"
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </CloseWrapper>
        )} */}
        <ContentWrapper style={{ padding: "12px" }}>
          {ready && !waitingBankAccounts ? (
            <CreditIcon />
          ) : (
            <Spinner size="sm" />
          )}
          <div>
            <BannerTitle>Link a new account</BannerTitle>
            <BannerDescription>
              Select your bank to start linking your account
            </BannerDescription>
          </div>
        </ContentWrapper>
      </BannerWrapper>
    </div>
  );
};

const CreditIcon = styled(FaRegCreditCard)`
  font-size: 25px;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

interface WrapperProps {
  loading: string;
}

const BannerWrapper = styled.div<WrapperProps>`
  width: 100%;
  background-color: ${({ theme }) => theme.BANNER_BACKGROUND};
  border-radius: 5px;
  cursor: ${(props) => (props.loading === "true" ? "wait" : "pointer")};
`;
const BannerTitle = styled.h5`
  font-size: 16px;
  margin-bottom: 2px;
`;
const BannerDescription = styled.p`
  font-size: 11px;
  margin-bottom: 0;
`;

// const CloseButton = styled.img`
//   height: 25px;
//   width: 25px;
//   @media (max-width: 450px) {
//     height: 20px;
//     width: 20px;
//   }
// `;

export default BankBanner;
